<template>

    <div :class="'collapse ' + classes" :id="id_modal">

        <a class="btn" :id="'toggle-' + id_modal" data-toggle="collapse" :href="'#' + id_modal" role="button" aria-expanded="false" :aria-controls="id_modal" hidden>X</a>

        <div class="modal-stage-body py-2" :id="'body-' + id_modal">
            <slot>

            </slot>
        </div>
    </div>

</template>

<script>
    import {bus} from '../../app'
    export default {
        props:['id_modal','classes','validate'],
        created(){


            //Validar os campos do modal stage antes de prosseguir
            if(this.validate){
                bus.$on(this.id_modal + '-validate', (obj) => {

                    //Verificando (percorrendo) todos os <inputs> e <textareas>
                    if(this.validate.input){
                        var input = '';
                        for(var c = 0; c < this.validate.input.length ;c++){
                            input = document.getElementById(this.validate.input[c]);

                            //Verificando se o campo é obrigatório (required)
                            if(input.hasAttribute('required') && input.value === ''){
                                this.$noty.error('Preencha os campos obrigatórios antes de prosseguir!',{timeout:4000});
                                input.focus();
                                input.classList.add('is-invalid');
                                return false;
                            }

                            //Verificando se é restrito pelo mínimo de caracteres (minlength)
                            if(input.hasAttribute('minlength')){
                                if(input.value.length < input.getAttribute('minlength')){
                                    this.$noty.error('O campo ' + this.validate.input[c] + ' foi preenchido incorretamente!',{timeout:4000});
                                    input.focus();
                                    input.classList.add('is-invalid');
                                    return false;
                                }
                            }

                            input.classList.remove('is-invalid');
                        }
                    }

                    //bus_emit
                    if(this.validate.emit){
                        var i;
                        for (i = 0; i < this.validate.emit.length; i++) {
                            bus.$emit(this.validate.emit[i]);
                        }
                    }
                });
            }

            //Efeito slide - É só chamar através da 'props' toggle (toggle=true)
            bus.$on('toggle-' + this.id_modal, (obj) => {
                //Vai clicar no botão toggle oculto
                document.getElementById('toggle-' + this.id_modal).click();

                //Dando foco no primeiro campo do modal caso exista algum
                if(this.validate.input && document.getElementById(this.id_modal).hasClass('show')){
                    document.getElementById(this.validate.input[0]).focus();
                }

            });


            //Remover ou Incluir a classe 'show' do elemento. OBS: sem o efeito slide
            bus.$on(this.id_modal + '-show', (obj) => {
                //Adicionando classe 'show' no elemento
                document.getElementById(this.id_modal).classList.add('show');
            });

            bus.$on(this.id_modal + '-hidde', (obj) => {
                //Removendo classe 'show' no elemento
                document.getElementById(this.id_modal).classList.remove('show');
            });

        }
    }
</script>

<style scoped>

</style>
