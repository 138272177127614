<template>
    <form v-bind:id="id_form" method="post">
        <div v-if="!btn_submit && edit" class="pb-2 mb-2">
            <button  type="button" class="btn btn-light float-right p-1 text-muted text-hover-primary mb-2" title="Editar"
                     v-on:click="editForm()"
            >
                <i class="font-size-12 fas fa-pencil-alt"></i>
            </button>
        </div>


        <slot>

        </slot>

        <div class="row justify-content-end">
            <div class="col">
                <button v-if="btn_submit" :id="'btn_submit-' + id_form" type="button" v-on:click="requestForm()" class="btn btn-primary float-right ml-2">Confirmar</button>

                <div class="float-right">
                    <slot name="botoes"></slot>
                </div>
            </div>
        </div>
    </form>
</template>
<script>
    import {bus} from '../../app'
    export default {
        props:[
            'id_form',
            'inputs',
            'stores',
            'request',
            'btn_form',
            'submit',
            'edit',
        ],
        data: function () {
            return {
                action: this.request,
                btn_submit: this.submit
            }
        },
        methods:{
            requestForm(){
                if(this.submit){
                    this.action.loading = ['add_spin'];
                    this.action.id_loading = 'btn_submit-' + this.id_form;
                }

                var url = this.request;

                //Validações e Inputs
                if(this.inputs){
                    url.get_input = [];
                    for(var c = 0; c < this.inputs.length ;c++){

                        if(document.getElementById(this.inputs[c])){
                            url.get_input.push(this.inputs[c]);

                            if(document.getElementById(this.inputs[c]).hasAttribute('required') && document.getElementById(this.inputs[c]).value === '' && !document.getElementById(this.inputs[c]).hasAttribute('hidden')){
                                this.$noty.error('Preencha os campos obrigatórios antes de prosseguir!',{
                                    timeout:4000
                                });

                                document.getElementById(this.inputs[c]).focus();
                                document.getElementById(this.inputs[c]).classList.add('is-invalid'); //Adiciona se estiver com problemas
                                setTimeout(() => {
                                    document.getElementById(this.inputs[c]).classList.remove('is-invalid'); //Adiciona se estiver com problemas
                                },3000);
                                return false;
                            }

                            document.getElementById(this.inputs[c]).classList.remove('is-invalid'); //Remove se estiver ok
                        }
                    }
                }

                bus.$emit('gerencia-request',url);
            },
            setForm(obj){
                //Configurando o formulário
                if(obj.set_registro){
                    this.$store.commit('setRegistro',obj.set_registro);
                }

                if(obj.disabled){
                    this.readOnly(obj.disabled);
                }

                if(obj.edit){
                    this.editForm();
                }

                if(obj.request){
                    this.action = obj.request;
                }else{
                    this.action = this.request;
                }
            },
            editForm(){
                //Configurando o formulário
                this.readOnly(false);
            },
            readOnly(application){
                var c;
                if(application === true){
                    for(c = 0; c < this.inputs.length ;c++){
                        document.getElementById(this.inputs[c]).disabled = true; //Adicionando atributo
                    }
                    this.btn_submit = false;
                }else{
                    for(c = 0; c < this.inputs.length ;c++){
                        document.getElementById(this.inputs[c]).disabled = false; //Adicionando atributo
                    }
                    this.btn_submit = true;
                }
            }
        },
        created(){

            bus.$on('submit-' + this.id_form, (obj) => {
                this.requestForm();
            });

            bus.$on('set-' + this.id_form, (obj) => {
                this.setForm(obj);
            });
        },
        destroyed() {
            bus.$off('submit-' + this.id_form);
            bus.$off('set-' + this.id_form);
        },
    }
</script>

<style type="scss">

</style>
