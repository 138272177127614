require('./bootstrap');

window.Vue = require('vue');
import Vuex from 'Vuex';

//Vue Select
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
// Set the components prop default to return our fresh components
Vue.component('v-select', vSelect);

//Calendar
import VCalendar from 'v-calendar';
Vue.use(VCalendar, {
    componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
    datePicker:{popover:{visibility:'focus'}},
});

//Mask
import VueTheMask from 'vue-the-mask';
Vue.use(VueTheMask);

import moment from 'moment-timezone'; //Formatos : https://momentjs.com/docs/#/displaying/format/
require('moment/locale/pt-br');
Vue.use(require('vue-moment'), {moment});
moment.tz.setDefault("America/Sao_Paulo");

//Mask Money
import money from 'v-money'
Vue.use(money, {precision: 2});

//Vue-noty
import VueNoty from 'vuejs-noty';
import 'vuejs-noty/dist/vuejs-noty.css';
Vue.use(VueNoty,{layout: 'bottomRight', theme: 'bootstrap-v4', progressBar: true, timeout:2000});

//Vue Tour
import VueTour from 'vue-tour';
require('vue-tour/dist/vue-tour.css');
Vue.use(VueTour);

//Tiny Editor de Texto
import Editor from '@tinymce/tinymce-vue'

//Timeline
import LightTimeline from 'vue-light-timeline';
Vue.use(LightTimeline);

//Vuex
const store = new Vuex.Store({
    state:{
        alerta:{},
        assinatura:{},
        atualizacao:{},
        catalogo:{},
        chat:{},
        conta:{},
        contrato:{},
        config:{},
        chamado:{},
        dashboard:{},
        despesa:{},
        evento:{},
        filter:{},
        form:{},
        fornecedor:{},
        force:{},
        function:null,
        grupo:{},
        grafico:{},
        head_noti:{},
        head_msg:{},
        head_chat:null,
        head_chamado:{},
        img:null,
        item:{},
        lista:{},
        local:{},
        marcador:{},
        mensagem:{},
        msg:{},
        modal:{},
        municipios:{},
        nf:{},
        nav:false,
        ordem:{},
        paginate:{},
        processo:{},
        pedido:{},
        permissao:{},
        registro:{},
        recurso:{},
        request:{},
        relatorio:{},
        requisicao:{},
        subregistro:{},
        subnav:false,
        subtab:false,
        suporte:{},
        tab:false,
        tag:false,
        usuario:{},
        unidade:{},
        waiting_request:false,
    },
    mutations:{
        setEvento(state,obj){state.evento = obj;},
        setForm(state,obj){state.form = obj;},
        setNav(state,obj){state.nav = obj;},
        setSubNav(state,obj){state.subnav = obj;},
        setTab(state,obj){state.tab = obj;},
        setSubTab(state,obj){state.subtab = obj;},
        setLista(state,obj){state.lista = obj;},
        setModal(state,obj){state.modal = obj;},
        setMensagem(state,obj){state.msg = obj;},
        setPaginate(state,obj){state.paginate[obj.id_paginate] = obj.data;},
        setRegistro(state,obj){state.registro = obj;},
        setRecurso(state,obj){state.recurso = obj;},
        setProcesso(state,obj){state.processo = obj;},
        setPedido(state,obj){state.pedido = obj;},
        setContrato(state,obj){state.contrato = obj;},
        setCatalogo(state,obj){state.catalogo = obj;},
        setUsuario(state,obj){state.usuario = obj;},
        setUnidade(state,obj){state.unidade = obj;},
        setOrdem(state,obj){state.ordem = obj;},
        setChamado(state,obj){state.chamado = obj;},
        setNf(state,obj){state.nf = obj;},
        setItem(state,obj){state.item = obj;},
    }
});

//COMPONENTES (A - Z)
Vue.component('add-item-processo', require('./components/formularios/AddItemProcesso').default);
Vue.component('anexos', require('./components/plugins/Anexos').default);
Vue.component('animate-number', require('./components/recursos/AnimatedNumber').default);
Vue.component('busca-2', require('./components/recursos/Busca-2').default);
Vue.component('busca-3', require('./components/recursos/Busca-3').default);
Vue.component('collapse', require('./components/collapse/Collapse').default);
Vue.component('cards-chamados', require('./components/cards/Cards-Chamados').default);
Vue.component('card-img', require('./components/cards/CardImg').default);
Vue.component('chat', require('./components/plugins/Chat').default);
Vue.component('chart-donut', require('./components/charts/Chart-Donut').default);
Vue.component('chart-line', require('./components/charts/Chart-Line').default);
Vue.component('chart-bar', require('./components/charts/Chart-Bar').default);
Vue.component('comentarios', require('./components/plugins/Comentarios').default);
Vue.component('comentarios-2', require('./components/plugins/Comentarios-2').default);
Vue.component('date-picker', require('./components/plugins/Datepicker').default);
Vue.component('dropdown', require('./components/recursos/Dropdown').default);
Vue.component('endereco-2', require('./components/recursos/Endereco-2').default);
Vue.component('editor-de-comentarios', require('./components/plugins/EditorDeComentarios').default);
Vue.component('editor-de-mensagens', require('./components/plugins/EditorDeMensagens').default);
Vue.component('formulario', require('./components/formularios/Formulario').default);
Vue.component('formulario-2', require('./components/formularios/Formulario-2').default);
Vue.component('form-export', require('./components/formularios/FormExport').default);
Vue.component('form-steps', require('./components/formularios/FormSteps').default);
Vue.component('gerenciador-request', require('./components/recursos/GerenciadorRequests').default);
Vue.component('img-vue', require('./components/recursos/Img').default);
Vue.component('input-search', require('./components/formularios/InputSearch').default);
Vue.component('input-range', require('./components/formularios/InputRange').default);
Vue.component('loading', require('./components/plugins/Loading').default);
Vue.component('mensagens', require('./components/plugins/Mensagens').default);
Vue.component('modal-3', require('./components/modal/Modal-3').default);
Vue.component('modal-stages', require('./components/modal/ModalStages').default);
Vue.component('nota-fiscal', require('./components/formularios/NotaFiscal').default);
Vue.component('paginate', require('./components/tabelas/Paginate').default);
Vue.component('password-confirm', require('./components/recursos/PasswordConfirm').default);
Vue.component('progress-bar', require('./components/graficos/ProgressBar').default);
Vue.component('request', require('./components/recursos/Request').default);
Vue.component('select-multiple', require('./components/formularios/Select-multiple').default);
Vue.component('select-reativo', require('./components/formularios/SelectReativo').default);
Vue.component('select-format-2', require('./components/formularios/SelectFormat-2').default);
Vue.component('select-3', require('./components/formularios/Select-3').default);
Vue.component('select-4', require('./components/formularios/Select-4').default);
Vue.component('tabela-5', require('./components/tabelas/Tabela-5').default);
Vue.component('tabela-6', require('./components/tabelas/Tabela-6').default);
Vue.component('timeline', require('./components/plugins/Timeline').default);
Vue.component('tour', require('./components/plugins/Tour').default);
Vue.component('upload-files', require('./components/plugins/Upload-files').default);

//MarkDown
import VueMarkdown from 'vue-markdown';
//busEvent
export const bus = new Vue();
//Loaders
import { HollowDotsSpinner } from 'epic-spinners';

import _ from 'lodash';

const app = new Vue({
    el: '#app',
    store,
    data: function () {
        return {
            assinatura:{},
            estados:{},
            flash:null,
            form:null,
            files:null,
            grupo:null,
            grafico:null,
            locais:null,
            mensagem:null,
            nav:null,
            municipios:{},
            item:null,
            render:true,
            relatorio:{},
            subnav:null,
            subtab:null,
            subgrupo:null,
            tab:null,
            usuario:null,
            _buscar:null,
        }
    },
    components: {
        HollowDotsSpinner,
        editor:Editor,
        VueMarkdown
    },
    methods:{
        gerenciadorRequest: (obj) => {
            bus.$emit('gerencia-request',obj);
        },
        busEmit: (emit,timeout = 0,event = null) => {
            setTimeout(() => {if(typeof emit === 'string'){bus.$emit(emit,event);}else if(typeof emit === 'object'){bus.$emit(emit.emit,emit.obj)}},timeout);
        },
        busEmitVal: (emit,value,timeout = 0) => {
            if(timeout === 0){bus.$emit(emit,value);}
            else{setTimeout(() => {bus.$emit(emit,value);},timeout);}
        },
        innerHTML(id_element,value){
            document.getElementById(id_element).innerHTML = value;
        },
        addObjectToState(state,obj){
            let st = {};
            for(var key in this.$store.state[state]){
                st[key] = this.$store.state[state][key];
            }
            for (var keyObj in obj){
                st[keyObj] = obj[keyObj];
            }
            this.$store.state[state] = st;
        },
        addDataRequest(id_request,obj){
            let newData = {};
            for(var key in this.$store.state.request[id_request].data){
                newData[key] = this.$store.state.request[id_request].data[key];
            }
            for (var keyObj in obj){
                newData[keyObj] = obj[keyObj];
            }
            this.$store.state.request[id_request].data = newData;
        },
        hiddeId(array){
            var i;
            for (i = 0; i < array.length; i++) {
                document.getElementById(array[i]).classList.add('d-none');
            }
        },
        showId(array){
            var i;
            for (i = 0; i < array.length; i++) {
                document.getElementById(array[i]).classList.remove('d-none');
            }
        },
        classListUpdate(aplication,id_elemento,classe){

            if(document.getElementById(id_elemento) === null){
                return false;
            }
            if(aplication === 'add'){
                document.getElementById(id_elemento).classList.add(classe);
            }else{
                document.getElementById(id_elemento).classList.remove(classe);
            }
        },
        focusId(id_elemento,timeout = 200){
            setTimeout(function(){
                if(typeof id_elemento === 'string'){
                    if(document.getElementById(id_elemento)){
                        document.getElementById(id_elemento).focus()
                    }
                }else{
                    for(let id in id_elemento){
                        if(document.getElementById(id_elemento[id])){
                            document.getElementById(id_elemento[id]).focus();
                            break;
                        }
                    }
                }
            },timeout);
        },
        click(id_elemento,timeout = 200){
            setTimeout(function(){
                if(document.getElementById(id_elemento)){
                    document.getElementById(id_elemento).click()
                }
            },timeout);
        },
        select(obj,timeout = 200){
            setTimeout(function(){
                if(document.getElementById(obj.id)){
                    document.getElementById(obj.id).value = obj.valor;
                }
            },timeout);
        },
        redirect(obj){
            this.$noty.info('Redirecionando...',{layout: 'bottomCenter', timeout:2000});
            if(typeof obj === 'string'){window.location.href = obj;}
            else{//Verificando a opção de montar URL
                if(obj.montar_url){
                    obj.url = '';
                    for(let part in obj.montar_url){
                        if(obj.montar_url[part].valor){obj.url+= obj.montar_url[part].valor;}
                        else if(obj.montar_url[part].state){obj.url+= this.$store.state[obj.montar_url[part].state][obj.montar_url[part].chave];}
                    }
                }
                window.location.href = obj.url;
            }
        },
        redirectLink(obj){
            if(typeof obj === 'string'){window.location.href = obj;}
            else{//Verificando a opção de montar URL
                if(obj.montar_url){
                    obj.url = '';
                    for(let part in obj.montar_url){
                        if(obj.montar_url[part].valor){obj.url+= obj.montar_url[part].valor;}
                        else if(obj.montar_url[part].state){obj.url+= this.$store.state[obj.montar_url[part].state][obj.montar_url[part].chave];}
                    }
                }
                window.location.href = obj.url;
            }
        },
        action(obj, timeout = 0){
            if(obj.link){this.redirectLink(obj.link);}
            if(obj.set_store){setTimeout( () =>{if(obj.set_store.att){this.$store.state[obj.set_store.state][obj.set_store.att] = obj.set_store.value;}else{this.$store.state[obj.set_store.state] = obj.set_store.value;}},timeout)}
            if(obj.set_input){document.getElementById(obj.set_input.id).value = obj.set_input.value;}
            if(obj.click){this.click(obj.click,timeout);}
        },
        limitText(string,limit){
            if(string && string.length >= limit){
                return string.substring(0,limit) + '...';
            }else{
                return string;
            }
        },
        onSearchVueSelect(search, loading,request,lista_key) {
            if(search.length > 0) {
                loading(true);
                this.search(search,loading,request,lista_key, this);
            }
        },
        search: _.debounce((search,loading,request,lista_key, vm) => {
            // vm.$options.data = {nome:'Testando',id_unidade:1};
            axios({
                url: request.url,
                method: 'POST',
                data: request.data
            })
                .then(res => {
                    var obj = {};
                    obj[lista_key] = res.data;
                    vm.addObjectToState('lista', obj);
                });

            loading(false);
        }, 1500),
        select_2(select,options = {},timeout = 500){
            options.language = {
                noResults:function(){return"Nenhum resultado encontrado"},
                errorLoading:function(){return"Os resultados não puderam ser carregados."},
                loadingMore:function(){return"Carregando mais resultados…"},
                searching:function(){return"Buscando…"},
                removeAllItems:function(){return"Remover todos os itens"}
            }
            setTimeout( ()=>{$(select).select2(options);},timeout);
        },
        featherIcons(){setTimeout(function (){feather.replace();},200)},
        inputRequired(){
            setTimeout(function (){
                var el = $("label ~ input:required, label ~ select:required, label ~ textarea:required").parent().find('label').not('.required_input');
                el.addClass('required_input');
                el.append('<span class=\'text-danger\' title=\'Campo obrigatório\'>&nbsp;*</span>');
            },500);
        },
        exportar(obj){bus.$emit('form-export',obj);},
        confirmAlert(obj){bus.$emit('confirm-alert',obj);},
        noty(obj){
            if(obj.noty === 'warning'){
                this.$noty.warning(obj.content,{layout:'center', timeout:2000});
            }
        },
        baixar(obj){bus.$emit('form-download',obj);},
        downloadPDF(obj){bus.$emit('download-pdf',obj);},
        renderChart(obj){bus.$emit('render-chart',obj);},
        inArray(needle, haystack) {
            var length = haystack.length;
            for(var i = 0; i < length; i++) {
                if(haystack[i] === needle) return true;
            }
            return false;
        },
        collapse(id_element,event = null){
            if(!event){$('#' + id_element).collapse();}
            else{$('#' + id_element).collapse(event);}
        },
        in_array(search,array){
            var c = 0;
            for (var n in array){
                if(array[n] === search){c++;}
            }
            return c > 0;
        },
        round(value, decimals) {
            return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
        },
        iconeAnexo(format){
            switch (format) {
                case 'jpg':
                case 'JPG':
                case 'jpeg':
                case 'JPEG':
                case 'bmp':
                case 'png':
                case 'PNG':
                    return "<i class='far fa-file-image' style='color: #3498db'></i>";
                case 'doc':
                case 'docx':
                    return "<i class='far fa-file-word text-primary'></i>";
                case 'txt':
                    return "<i class='far fa-file-alt'></i>";
                case 'pdf':
                    return "<i class='far fa-file-pdf text-danger'></i>";
                case 'xls':
                case 'xlsx':
                    return "<i class='fas fa-file-excel text-success'></i>";
                case 'zip':
                case 'rar':
                    return "<i class='far fa-file-archive text-muted'></i>";
                default:
                    return "<i class='far fa-file'></i>";
            }
        },
    },
    filters:{
        numberFormat:function (valor,digitos_fracao = null) {
            var options = {};
            if(digitos_fracao){
                options.minimumFractionDigits = digitos_fracao;
                options.maximumFractionDigits = digitos_fracao;
            }
            return new Intl.NumberFormat('pt-BR',options).format(valor);
        },
        float2d:function (valor) {
            if(valor === null || valor === undefined){
                valor = 0;
            }
            var vlr = parseFloat(valor).toFixed(2);
            var parts = vlr.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            return parts.join(",");
        },
        float4d:function (valor) {
            if(valor === null || valor === undefined){
                valor = 0;
            }
            var vlr = parseFloat(valor).toFixed(4);
            var parts = vlr.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{5})+(?!\d))/g, ".");
            return parts.join(",");
        },
        dateView(date){
            var data_view = new Date(date);
            return ("0" + data_view.getDate()).slice(-2) + '/' + ("0" + (data_view.getMonth() + 1)).slice(-2) + '/' + data_view.getFullYear() ;
        },
        cnpj(cnpj){
            //43.210.987/6543-21
            var n1 = cnpj.slice(0, -12);
            var n2 = cnpj.slice(-12, -9);
            var n3 = cnpj.slice(-9, -6);
            var n4 = cnpj.slice(-6, -2);
            var n5 = cnpj.slice(-2);
            return n1 + '.' + n2 + '.' + n3 + '/' + n4 + '-' + n5;
        },
        tipoContrato: function (tipo){
            switch (tipo){
                case 1:
                    return 'ARP';
                case 2:
                    return 'Contrato';
                case 3:
                    return 'Termo / Certidão';
                case 4:
                    return 'Empenho';
                default:
                    return '';
            }
        },
        areaSistema: function (tipo){
            switch (tipo){
                case 1:
                    return 'Usuário';
                case 2:
                    return 'Unidade';
                case 3:
                    return 'Produtos e Serviços';
                case 4:
                    return 'Elenco';
                case 5:
                    return 'Estimativa e Captações de Demandas - Gestor';
                case 6:
                    return 'Processo - Unidade';
                case 7:
                    return 'Processo';
                case 8:
                    return 'Termos de Referência';
                case 9:
                    return 'Cotação';
                case 10:
                    return 'Fornecedor';
                case 11:
                    return 'Pedido';
                case 12:
                    return 'Ordem';
                case 13:
                    return 'Nota Fiscal';
                case 14:
                    return 'Recurso';
                case 15:
                    return 'Pedido Unidade';
                case 16:
                    return 'Nota Fiscal Unidade';
                case 17:
                    return 'Autorizador Ordem';
                case 18:
                    return 'Recurso';
                case 19:
                    return 'Contrato';
                case 20:
                    return 'Evidências - Notas Fiscais';
                case 21:
                    return 'Chamado';
                case 22:
                    return 'Chamado Unidade';
                default:
                    return '';
            }
        },
    },
    created() {
        bus.$on('display_id', obj => {
            var i;
            if(obj.display === true){
                for (i = 0; i < obj.id.length; i++) {
                    document.getElementById(obj.id[i]).classList.remove('d-none');
                }
            }else{
                for (i = 0; i < obj.id.length; i++) {
                    document.getElementById(obj.id[i]).classList.add('d-none');
                }
            }
        });

        bus.$on('class_list_update', obj => {
            for(var c in obj){
                if(document.getElementById(obj[c].id) !== null){
                    if(obj[c].aplication === 'add'){
                        document.getElementById(obj[c].id).classList.add(obj[c].class);
                    }else{
                        document.getElementById(obj[c].id).classList.remove(obj[c].class);
                    }
                }
            }

        });

        bus.$on('action', (obj) => {
            if(obj.link){this.redirectLink(obj.link);}

            if (obj.click) {
                var timeout = obj.click.timeout || 0;
                setTimeout(function () {if(document.getElementById(obj.click.id)){document.getElementById(obj.click.id).click()}},timeout)
            }

            if (obj.set_store) {
                if(obj.set_store.att){this.$store.state[obj.set_store.state][obj.set_store.att] = obj.set_store.value;
                }else{this.$store.state[obj.set_store.state] = obj.set_store.value;}
            }

            if (obj.focus_id) {
                this.focusId(obj.focus_id);
            }
            if(obj.input_required){ this.inputRequired();}
            if(obj.download){ this.baixar(obj.download);}
        });
    },
    mounted() {
        document.getElementById('app').style.display = 'block';

        $(document).on('focusin', function(e) {
            if ($(e.target).closest(".tox-dialog").length) {
                e.stopImmediatePropagation();
            }
        });

        $(".hover-collapse-head").hover(
            function() {
                console.log('hover');
                $('.hover-collapse').collapse('show');
            }, function() {
                $('.hover-collapse').collapse('hide');
            }
        );
    }
});
