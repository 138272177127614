<template>
    <select :class="classes" :name="nome" :id="id" v-on:change="ativarCascata()" v-model="$store.state.registro[chave_registro]">
        <option disabled selected>Selecione...</option>
        <option v-for="option in registro" :value="option.id">{{option.nome}}</option>
    </select>
</template>

<script>
    import {bus} from '../../app'
    export default {
        props:['registros','id','id_cascade','nome','classes','url_population','link','chave_registro','chave_registro_store','valor'],
        data: function () {
            return{
                registro: this.registros
            }
        },
        methods: {
            ativarCascata(){
                bus.$emit('select_cascade_' + this.id, document.getElementById(this.id).value);
            },
            buscarLista(url,selecionar_id) {
                document.getElementById(this.id).value = ''; //Seleção em branco

                axios.get(url).then(res => {
                    this.registro = res.data;

                    if(selecionar_id){
                        setTimeout(() => {
                            document.getElementById(this.id).value = selecionar_id;
                        },100);
                    }
                });
            },
        },
        created(){


                bus.$on('select_cascade_' + this.id_cascade, ($event) => {
                    this.buscarLista(this.url_population + document.getElementById(this.id_cascade).value,null);
                });


                //Preenchedo o select caso o Evento seja acionado. Vai buscar o valor nos $store
                bus.$on('setRegistro', () => {

                    document.getElementById(this.id).value = ''; //Seleção em branco
                    if(this.chave_registro){
                        var registro = this.$store.state.registro;
                        for(var chave in registro){
                            if(chave === this.chave_registro){
                                if(registro[chave] && !this.id_cascade){
                                    //Ativado se não for reativo a outro select
                                    document.getElementById(this.id).value = registro[chave];
                                }else if(registro[chave] && this.id_cascade){
                                    //Ativado se for reativo a outro select
                                    this.buscarLista(this.url_population + document.getElementById(this.id_cascade).value,registro[chave]);
                                }
                            }
                        }
                    }
                });


        },
        mounted(){
            if(this.valor){
                document.getElementById(this.id).value = this.valor;
            }
        }
    }
</script>

<style type="scss">

</style>
