<template>
    <form autocomplete="off" v-on:submit.prevent>
        <div class="form-group">
            <label for="cep" class="required_input">CEP <span class='text-danger' title='Campo obrigatório'>&nbsp;*</span></label>

            <div class="row">
                <div class="col-lg-3 pr-0">
                    <input type="text" class="form-control text-right" name="cep" id="cep" placeholder="CEP" v-mask="'#####-###'" v-model="cep_" v-on:keyup="blockWatch = false" required>
                </div>
                <div class="col-2 pl-1 pt-2" id="spinner-cep" style="display: none">
                    <i class="fas fa-circle-notch fa-spin text-primary" style="font-size: 1.2rem;"></i>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label for="endereco">Endereço</label>
            <input type="text" class="form-control" name="endereco" id="endereco" placeholder="Avenida, Rua, Logradouro..." maxlength="255" v-model="endereco_" v-on:change="changeEndereco()" required>
        </div>

        <div class="form-group">
            <div class="row">
                <div class="col-6 col-md-3">
                    <label for="numero">Número</label>
                    <input type="text" class="form-control col-md-12 text-center" name="numero" id="numero" placeholder="Número" maxlength="10" v-model="numero_" v-on:change="changeEndereco()">
                </div>

                <div class="col-lg-6">
                    <label for="bairro">Bairro</label>
                    <input type="text" class="form-control col-md-12" name="bairro" id="bairro" placeholder="Bairro" maxlength="100" v-model="bairro_" v-on:change="changeEndereco()" required>
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="row">
                <div class="col-lg-8">
                    <label for="estado" class="required_input">Estado <span class='text-danger' title='Campo obrigatório'>&nbsp;*</span></label>

                    <v-select key="select-estado"
                              label="nome"
                              class="font-size-08"
                              :options="estados"
                              placeholder="Selecione..."
                              :reduce="(option) => option.id"
                              :filterable="true"
                              :clearable="false"
                              v-model="id_estado"
                              v-on:input="changeEndereco();getMunicipiosEstado()"
                              :disabled="disabled_fields"
                    >
                        <template #open-indicator="{ attributes }">
                            <span v-bind="attributes"><i class="mdi mdi-chevron-down font-size-12"></i></span>
                        </template>
                        <template v-slot:option="option">
                            {{ option.nome }}
                        </template>
                        <template #spinner="{ loading }">
                            <div
                                v-if="loading"
                                class="spinner-border spinner-border-sm text-primary"  role="status"
                            >
                            </div>
                        </template>
                    </v-select>
                </div>
                <div class="col-lg-8 pt-3">
                    <label for="municipio" class="required_input">Municipio <span class='text-danger' title='Campo obrigatório'>&nbsp;*</span></label>
                    <v-select key="select-municipio"
                              label="nome"
                              class="font-size-08"
                              :options="municipios"
                              placeholder="Selecione..."
                              :reduce="(option) => option.id"
                              :filterable="true"
                              :clearable="false"
                              v-model="id_municipio"
                              v-on:input="changeEndereco()"
                              :disabled="disabled_fields"
                    >
                        <template #open-indicator="{ attributes }">
                            <span v-bind="attributes"><i class="mdi mdi-chevron-down font-size-12"></i></span>
                        </template>
                        <template v-slot:option="option">
                            {{ option.nome }}
                        </template>
                        <template #spinner="{ loading }">
                            <div
                                v-if="loading"
                                class="spinner-border spinner-border-sm text-primary"  role="status"
                            >
                            </div>
                        </template>
                        <template slot="no-options">
                            <div class="text-darkgold">
                                Um estado deve ser selecionado...
                            </div>
                        </template>
                    </v-select>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label for="endereco">Complemento</label>
            <input type="text" class="form-control" name="complemento" id="complemento" placeholder="Complemento" maxlength="255" v-on:change="changeEndereco()" v-model="complemento_">
        </div>

        <div v-if="d_referencia" class="form-group">
            <label for="referencia">Referência</label>
            <input type="text" class="form-control" name="referencia" id="referencia" placeholder="Ponto de referência" v-on:change="changeEndereco()" v-model="referencia_">
            <small class="text-muted">* Informe um ponto de referência para facilitar a localização.</small>
        </div>
    </form>
</template>

<script>
import {bus} from '../../app';
export default {
    props:['id_endereco','path','cep','estado','municipio','endereco','complemento','referencia','numero','bairro','d_referencia'],
    data: function () {
        return {
            'cep_': this.cep,
            'estados':[],
            'municipios':[],
            'estado_': this.estado,
            'id_estado': this.estado,
            'municipio_': this.municipio,
            'id_municipio':this.municipio,
            'endereco_': this.endereco,
            'complemento_': this.complemento,
            'numero_': this.numero,
            'bairro_': this.bairro,
            'referencia_': this.referencia,
            'blockWatch':false,
            'disabled_fields':false,
        }
    },
    methods:{
        changeCEP(){
            if(this.cep_.length === 9){
                document.getElementById('spinner-cep').style.display = 'block';

                var vlr = this.cep_;

                while(vlr.indexOf("-") != -1){
                    vlr = vlr.replace("-", "");
                }

                var url = {
                    url: this.path + '/webservice/cep',
                    data:{
                        cep:vlr
                    },
                    bus_emit:['webservice_cep']
                };

                bus.$emit('gerencia-request',url);

            }else{
                this.disabled_fields = false;
            }
        },
        preencherCamposEndereco(data){
            this.endereco_ = data.logradouro;
            this.bairro_ = data.bairro;
            this.id_estado = data.id_estado;
            this.id_municipio = data.id_municipio;

            //Atualizando lista de municípios.
            this.getMunicipiosEstado();
            this.$store.state.registro.municipio = data.id_municipio;


            if(!this.cep){
                if(this.endereco_){
                    document.getElementById('numero').focus();
                }else{
                    document.getElementById('endereco').focus();
                }
            }

            if(this.cep_.length >= 8){
                this.disabled_fields = true;
            }

            if(this.blockWatch === true){
                setTimeout(function () {
                    this.blockWatch = false;
                },500);
            }
            this.changeEndereco();
        },
        changeEndereco(){
            this.$emit('change',{
                id_estado:this.id_estado,
                id_municipio:this.id_municipio,
                endereco:this.endereco_,
                bairro:this.bairro_,
                cep:this.cep_,
                numero:this.numero_,
                complemento:this.complemento_,
                referencia:this.referencia_
            })
        },
        getMunicipiosEstado(){
            bus.$emit('gerencia-request',{
                url: this.path + '/municipios/' + this.id_estado, method:'GET',bus_emit:['get_municipios-' + this.id_endereco]
            });
        }
    },
    watch: {
        cep_: function (newVal, oldVal) {
            if(this.blockWatch === true){
                //Bloqueando webservice caso valores tenhando sido inseridos através dos props
                if(newVal.length === 9){
                    this.blockWatch = false;
                }
                return false;
            }

            if(newVal.length === 9){
                document.getElementById('spinner-cep').style.display = 'block';

                var vlr = this.cep_;

                while(vlr.indexOf("-") != -1){
                    vlr = vlr.replace("-", "");
                }

                var url = {
                    url: this.path + '/webservice/cep',
                    data:{
                        cep:vlr
                    },
                    bus_emit:['get_webservice_cep']
                };

                bus.$emit('gerencia-request',url);

            }else{
                this.disabled_fields = false;
            }
        }
    },
    created(){
        this.blockWatch = true;

        bus.$on('get_webservice_cep', (data) => {
            if(data.erro){
                this.$noty.error('CEP não encontrado, tente novamente.',{timeout:3000});
            }else{
                this.preencherCamposEndereco(data);
            }
            document.getElementById('spinner-cep').style.display = 'none';
        });

        bus.$on('set-' + this.id_endereco, (data) => {
            this.blockWatch = true;
            this.cep_ = this.$store.state.registro.cep;
            this.complemento_ = this.$store.state.registro.complemento;
            this.referencia_ = this.$store.state.registro.referencia;

            var obj = {};
            obj.logradouro = this.$store.state.registro.endereco;
            obj.id_estado = this.$store.state.registro.id_estado;
            obj.id_municipio = this.$store.state.registro.id_municipio;
            obj.bairro = this.$store.state.registro.bairro;

            this.numero_ = this.$store.state.registro.numero;

            this.preencherCamposEndereco(obj);

        });

        bus.$on('get_estados-' + this.id_endereco, (data) => {
            this.estados = data;
        });
        bus.$on('get_municipios-' + this.id_endereco, (data) => {
            this.municipios = data;
        });
    },
    mounted(){
        if(this.cep){
            this.disabled_fields = false;
        }

        bus.$emit('gerencia-request',{url: this.path + '/estados', method:'GET',bus_emit:['get_estados-' + this.id_endereco]});
        this.changeEndereco();
    },
    destroyed() {
        bus.$off('set-' + this.id_endereco);
        bus.$off('get_webservice_cep');
        bus.$off('get_estados-' + this.id_endereco);
        bus.$off('get_municipios-' + this.id_endereco);
    },
}
</script>

<style scoped>

</style>
