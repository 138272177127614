<template>
    <div class="position-relative">
        <input :type="input_type" :name="input_name" :class="input_class" :id="input_id" :placeholder="input_placeholder" autocomplete="off" v-model="valor">

        <div class="px-0 pt-2 bg-white text-center" :id="'loading-' + input_id" style="font-size: 0.5rem; z-index: 10000;color: #007BFF; border-radius: 0.5rem; width:80px; display: none">
            <div class="px-3">
                <hollow-dots-spinner
                        :animation-duration="1000"
                        :dot-size="8"
                        :dots-num="3"
                        color="#007BFF"
                />
            </div>
            carregando...
        </div>

        <div class="py-2 font-size-07" :id="'msg-' + input_id">

        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import {bus} from '../../app';
    import {HollowDotsSpinner} from 'epic-spinners';
    export default {
        components: {HollowDotsSpinner},
        props:['input_type','input_name','input_id','input_class','input_value','input_placeholder','required','request'],
        data: function () {
            return {
                requisicao: this.request,
                valor:this.input_value,
                debouncedGet:'',
            }
        },
        methods:{
            //Delay para emitir a request
            buscar: _.debounce(function (val) {

                if(val.length > 3){
                    document.getElementById('loading-' + this.input_id).style.display = 'block';
                    //Request
                    this.request.data.valor = val;
                    this.request.data.id_msg = 'msg-' + this.input_id;
                    this.request.bus_emit.push('return-' + this.input_id);
                    bus.$emit('gerencia-request',this.request);
                }
            }, 2000),
        },
        watch: {
            valor: function (newVal, oldVal) {
                document.getElementById('msg-' + this.input_id).innerHTML = '';
                this.buscar(newVal);
            }
        },
        created() {
            bus.$on('return-' + this.input_id, (obj) => {
                document.getElementById('loading-' + this.input_id).style.display = 'none';
            });
        },
        mounted(){
            if(this.required){
                document.getElementById(this.input_id).setAttribute('required','required');
            }


        },
    }
</script>

<style scoped>

</style>