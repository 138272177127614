<template>
    <datepicker
        :id="id_input"
        :name="name_input"
        :input-class="'form-control ' +  class_input"
        :wrapper-class="'p-0 ' + largura"
        :bootstrap-styling="true"
        :calendar-class="'rounded ' + class_caledario"
        :language="linguagem"
        :disabledDates="desabilitar"
        :format="formato_data"
        v-model="valorcampo"
        :use-utc="utc"
        :calendar-button="caledarButton"
        calendar-button-icon="far fa-calendar-alt"
        :typeable="false"
        @input="change($event)"
        parse="dd/MM/yyyy"
    >
    </datepicker>
</template>

<script>
    import Datepicker from 'vuejs-datepicker';
    import {ptBR} from 'vuejs-datepicker/dist/locale';
    import {bus} from '../../app';
    export default {
        components: {
            Datepicker
        },
        props:['id_input','name_input','valor_input','class_input','class_caledario','largura','desabilitar_datas','formato','required','valor','emit','hide_calendar_button','utc'],
        data () {
            return {
                linguagem: ptBR,
                desabilitar: this.desabilitar_datas,
                formato_data: 'dd/MM/yyyy',
                valorcampo: this.valor ? this.valor : '',
                caledarButton:true,
            }
        },
        methods:{
            change(date){
                if(this.emit){
                    var i;
                    var emitir;
                    for (i = 0; i < this.emit.length; i++) {
                        emitir = this.emit[i];
                        if(emitir.obj){
                            if(emitir.obj.disable_dates){
                                if(emitir.obj.disable_dates.to === true){
                                    emitir.obj.disable_dates.to = this.valorcampo;
                                }
                                if(emitir.obj.disable_dates.from === true){
                                    emitir.obj.disable_dates.from = this.valorcampo;
                                }
                            }
                            if(typeof emitir.obj.value === 'number'){
                                let data = new Date(this.valorcampo);
                                emitir.obj.value = data.setDate(this.valorcampo.getDate() + emitir.obj.value)}
                            bus.$emit(emitir.emit,emitir.obj);
                        }else if(emitir.request){
                            bus.$emit('gerencia-request',emitir.request);
                        }
                    }
                }
                date = (date.getFullYear() + "-" + ((date.getMonth() + 1)) + "-" + (date.getDate() ))
                this.$emit('change',date);
            },
            formatter(date) {
                return moment(date).format(this.formato_data);
            }
        },
        created(){
            if(this.hide_calendar_button){
                this.caledarButton = false;
            }
           /* Regras para desabilitar datas
                 = {
                    to: new Date(2016, 0, 5), //Desativa todas as datas antes da data especificada
                    from: new Date(2016, 0, 26), //Desativa todas as data após a data especificada
                    days: [6, 0], // Escolher os dias da semana. O exemplo desabilita sábados e domingos
                    daysOfMonth: [29, 30, 31], // Desabilita os dias do mês
                }
           */
            //console.log(document.querySelector('#' + this.id_width).getBoundingClientRect().top)

           if(this.formato){
               this.formato_data = this.formato;
           }

           bus.$on('set-' + this.id_input, (obj) => {
                if(obj.disable_dates){this.desabilitar = obj.disable_dates;}
                if(obj.value){this.valorcampo = obj.value}
                if(obj.focus){document.getElementById(this.id_input).click()}
           });

            bus.$on('clear-' + this.id_input, (obj) => {
                this.valorcampo = null;
                this.$emit('change',this.valorcampo);
                this.desabilitar = this.desabilitar_datas;
            });
        },
        mounted(){
            if(this.required){
                document.getElementById(this.id_input).setAttribute('required','required');
            }
        },
        destroyed() {
            bus.$off('set-' + this.id_input);
            bus.$off('clear-' + this.id_input);
        },
    }
</script>

<style scoped>

</style>
