<template>
    <div class="row">
        <div class="col">
            <form autocomplete="off">
            <div class="input-group">
                <textarea v-if="text_area" type="text" :id="id_select" :class="'form-control ' + class_select" v-model="buscar" :placeholder="placeholder_select" :title="title_select" autocomplete="off" rows="5"
                          v-on:focusin="displayDropDown(true)"
                          v-on:focusout.passive="displayDropDown(false)"
                          v-on:keydown.enter.stop.prevent
                ></textarea>

                <input v-else type="text" :id="id_select" :class="'form-control ' + class_select" v-model="buscar" :placeholder="placeholder_select" :title="title_select" autocomplete="off"
                    v-on:focusin="displayDropDown(true)"
                    v-on:focusout.passive="displayDropDown(false)"
                    v-on:keydown.enter.stop.prevent
                >



                <div v-if="editInput" :id="'edit-' + id_select" class="input-group-append">
                    <button class="btn btn-outline-secondary" type="button" v-html="text_edit" v-on:click="editarInput()"></button>
                </div>
                <div v-else class="input-group-append">
                    <span :id="'icon_search-' + id_select" class="input-group-text" :for="id_select"><i class="fas fa-search"></i></span>
                </div>
            </div>

            <div :id="'dropdown-' + id_select" class="position-relative d-none border-0">
                <form class="card position-absolute col-12 border-secondary shadow p-0" style="z-index: 10000" autocomplete="off" v-on:submit.prevent>

                    <div v-for="(reg,index) in registros" :key="id_select + '-option-' + index" class="cursor-pointer bg-hover-ligthgrey py-2 px-3" v-on:click="emitAct(reg)" v-html="labelOption(reg,options)"></div>

                    <div :id="'alert_empty-' + id_select" class="d-none">
                        <div class="alert alert-warning m-0">
                            Nenhum registro encontrado!
                            <slot name="empty"></slot>
                        </div>
                    </div>
                    <div :id="'help_dropdown-' + id_select">
                        <div class="p-0" v-html="help_dropdown"></div>
                    </div>
                </form>
            </div>

            </form>
        </div>
    </div>
</template>

<script>
import {bus} from '../../app';
import _ from 'lodash';

export default {
        props:['id_select','class_select','placeholder_select','placeholder_dropdown','title_select','options','state','chave_value','request','text_edit','set_store','required','text_area'],
        data: function () {
            return {
                buscar:'',
                registros: {},
                url:this.request,
                block: false,
                editInput:false,
                busy:false,
                help_dropdown: this.placeholder_dropdown || this.placeholder_select,
            }
        },
        methods:{
            requisicao(){
                this.busy = true;
                bus.$emit('gerencia-request',this.url);
            },
            emitAct(registro){
                this.block = true;
                this.buscar = this.labelOption(registro,this.options).replace(/(<([^>]+)>)/gi, "");
                this.editInput = true;

                if(this.set_store){
                    this.store_ = registro[this.chave_value];
                }

                this.$emit('select_item',registro);
                this.readOnly(true);
                this.displayDropDown(false);
            },
            editarInput(){
                this.readOnly(false);
                this.editInput = false;
                this.buscar = '';
                if(this.set_store){
                    this.store_ = null;
                }
                this.registros = {};
                this.helpDropdown(true);
                setTimeout(() => {
                    this.block = false;
                    this.focusInput();
                },500);
                this.$emit('select_item',{});
            },
            focusInput(){
                if(document.getElementById(this.id_select)){
                    setTimeout(() => {
                        document.getElementById(this.id_select).focus();
                    },500);
                }
            },
            /*==============================MÉTODOS DE EXIBIÇÃO================================*/
            labelOption(registro,val){
                let arrayKeys = val.match(/{[\w\d]+}/g); //Pegando todas as variáveis (keys) inseridas na string no valor da célula
                if(arrayKeys && arrayKeys.length > 0){
                    let keyString = '';
                    for (let key in arrayKeys){ //Substituindo {keys} pelos respectivos valores do registro
                        keyString = arrayKeys[key].substring(arrayKeys[key].lastIndexOf("{") + 1, arrayKeys[key].lastIndexOf("}"));
                        val = val.replace('{' + keyString + '}',registro[keyString]);
                    }
                    return val;
                }else{
                    return registro[val];
                }
            },
            displayDropDown(view){
                var el = document.getElementById('dropdown-' + this.id_select);
                if(view === true && this.block === false){
                    if(el){el.classList.remove('d-none');}
                }else if(view === false){
                    setTimeout(() => {if(el){el.classList.add('d-none');}},250);
                }
            },
            readOnly(option){
                if(document.getElementById(this.id_select)){
                    if(option === true){
                        document.getElementById(this.id_select).setAttribute('readonly','readonly');
                    }else{
                        document.getElementById(this.id_select).removeAttribute('readonly');
                    }
                }
            },
            load_select(){
                this.alertEmpty(false);
                this.helpDropdown(true);
                if(document.getElementById('icon_search-' + this.id_select)){
                    document.getElementById('icon_search-' + this.id_select).classList.add('text-color-pulse-primary');
                    document.getElementById('icon_search-' + this.id_select).classList.remove('text-color-animate-grey');
                    document.getElementById('icon_search-' + this.id_select).classList.remove('text-color-animate-danger');
                }
                this.help_dropdown = "<div class='alert alert-light p-1 text-center m-0 text-color-pulse-primary'>buscando...</div>"
            },
            type_select(){
                this.registros = {};
                this.helpDropdown(true);
                this.alertEmpty(false);
                document.getElementById('icon_search-' + this.id_select).classList.add('text-color-animate-grey');
                document.getElementById('icon_search-' + this.id_select).classList.remove('text-color-pulse-primary');
                document.getElementById('icon_search-' + this.id_select).classList.remove('text-color-animate-danger');
                this.help_dropdown = "<div class='alert alert-light p-1 text-center m-0 text-color-animate-grey'>digitando...</div>"
            },
            erro_select(){
                this.helpDropdown(true);
                this.alertEmpty(false);
                document.getElementById('icon_search-' + this.id_select).classList.add('text-color-animate-danger');
                document.getElementById('icon_search-' + this.id_select).classList.remove('text-color-pulse-primary');
                document.getElementById('icon_search-' + this.id_select).classList.remove('text-color-animate-grey');
                this.help_dropdown = "<div class='alert alert-light p-1 text-center m-0 text-color-animate-danger'>precisa de mais caracteres!</div>"
            },
            blank_select(){
                document.getElementById('icon_search-' + this.id_select).classList.remove('text-color-animate-grey');
                document.getElementById('icon_search-' + this.id_select).classList.remove('text-color-pulse-primary');
                document.getElementById('icon_search-' + this.id_select).classList.remove('text-color-animate-danger');
                this.help_dropdown = this.placeholder_dropdown;
            },
            alertEmpty(view){
                if(document.getElementById('alert_empty-' + this.id_select)){
                    if(view === true){
                        document.getElementById('alert_empty-' + this.id_select).classList.remove('d-none');
                    }else{
                        document.getElementById('alert_empty-' + this.id_select).classList.add('d-none');
                    }
                }
            },
            helpDropdown(view){
                if(document.getElementById('help_dropdown-' + this.id_select)){
                    if(view === true){
                        document.getElementById('help_dropdown-' + this.id_select).classList.remove('d-none');
                    }else{
                        document.getElementById('help_dropdown-' + this.id_select).classList.add('d-none');
                    }
                }
            },
            //Delay para emitir a request
            search: _.debounce(function (val) {
                if(this.busy === true){return false;}
                if(val.length === 0){ //Nenhum resultado (limpar registros)
                    this.blank_select();
                    //this.displayDropDown(false);
                    return false;
                }else if(val.length < 3 && val.length > 0){ //Precisa de mais caracteres
                    this.erro_select();
                    return false;
                }
                //Request
                this.url.data.busca = this.buscar;
                this.load_select();
                this.requisicao();
            }, 1500),
        },
        computed: {
            store_: {
                get: function() {
                    return this.$store.state[this.state][this.set_store];
                },
                set: function(val) {
                    this.$store.state[this.state][this.set_store] = val;
                    this.$emit('change_store', {value:val,'descricao':this.buscar});
                }
            }
        },
        watch: {
            buscar: function (newVal, oldVal) {
                if(this.block === true){
                    return false;
                }
                this.type_select();
                //this.displayDropDown(false);
                this.search(newVal);
            }
        },
        created: function () {
            if(this.set_store){
                this.store_ = null;
            }

            //Adicionando elemento bus_emit para retornar ser emitido para get_response
            if (this.url.bus_emit) {
                this.url.bus_emit.push('get_response-' + this.id_select);
            } else {
                this.url.bus_emit = ['get_response-' + this.id_select];
            }


            bus.$on('get_response-' + this.id_select, (obj) => {
                this.helpDropdown(false);
                this.registros = (obj.data ? obj.data : obj);

                if (this.registros.length > 0) {
                    this.alertEmpty(false);
                } else {//Caso nenhuma opção seja encontrada, poderá (slot) apresentar uma opção de cadastro de registro, ou qualquer outra opção.
                    this.alertEmpty(true);
                }
                this.blank_select();
                if(this.registros.length === 1){
                    this.emitAct(this.registros[0]);
                }else{
                    this.displayDropDown(true);
                }

                this.busy = false;
            });

            bus.$on('clear-' + this.id_select, (url) => {
                this.editarInput();
            });

            bus.$on('set-' + this.id_select, (val) => {
                if (this.set_store) {
                    this.store_ = val.value;
                    this.displayDropDown(false);
                    this.block = true;
                    this.buscar = val.descricao;
                }
                if (val.readonly) {
                    this.readOnly(val.readonly);

                } else {
                    this.readOnly(true);
                }
                if (val.input && val.input === true) {
                    this.editarInput();
                } else {
                    this.editInput = true;
                }
                //document.getElementById(this.id_select).value = val.descricao;
            });
        },
        mounted(){
            if(this.required){
                document.getElementById(this.id_select).setAttribute('required','required');
            }
        },
    }
</script>

<style scoped>

</style>
