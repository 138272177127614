<template>
    <div>
        <form :id="id_form" v-on:submit.prevent autocomplete="off">
            <div v-if="this.show_steps" class="text-center pb-3 px-1">
                <div class="row justify-content-center">
                    <div v-for="(step,index) in steps" class="col-auto d-flex align-items-center cursor-pointer hover-zoom-05" :id="id_form + '-step-' + index"
                          v-on:click="selectStep(index)"
                    >
                        <transition name="zoom_in">
                            <span v-if="parseInt(index) === current_step"  class="text-primary text-center" :key="'label_current-' + id_form + index">
                                <span v-html="step.titulo || index"></span>
                                <div style="margin-top: -10px;min-height: 20px;padding: 4px"><i class="fas fa-circle text-primary" style="font-size: 0.4rem;"></i></div>
                            </span>
                        </transition>
                        <span v-if="parseInt(index) < current_step" :title="step.titulo" :key="'label_before-' + id_form + index"><i class="fas fa-circle text-primary"></i></span>
                        <span v-else-if="parseInt(index) > current_step" :title="step.titulo" :key="'label_after-' + id_form + index"><i class="far fa-circle text-primary"></i></span>
                    </div>
                </div>
            </div>

            <slot></slot>
        </form>
        <div class="row pt-3 px-1">
            <div v-if="btn_prev" class="col" :key="id_form + '-btn_prev_step'">
                <button class="btn btn-block btn-light" :id="id_form + '-btn_prev_step'" v-on:click="prevStep()">Voltar</button>
            </div>
            <div v-if="btn_next" class="col" :key="id_form + '-btn_next_step'">
                <button class="btn btn-block btn-primary" :id="id_form + '-btn_next_step'" v-on:click="nextStep()" v-html="titulo_btn_next"></button>
            </div>
        </div>
    </div>

</template>

<script>
    import {bus} from "../../app";
    export default {
        props:['id_form','steps','show_steps','request'],
        data: function () {
            return {
                current:1,
                current_element:null,
                next_element:null,
                prev_element:null,
                btn_next:true,
                btn_prev:false,
                titulo_btn_next:'Próximo',
                url:this.request,
            }
        },
        methods:{
            sendRequest(){
                if(!this.url.loading){
                    this.url.loading = ['add_spin'];
                    this.url.id_loading =  this.id_form + '-btn_next_step';
                }
                bus.$emit('gerencia-request',this.url);
            },
            nextStep(){
                if(this.validarInputsStep() === false){return false;}
                if(this.next_element){
                    this.current_element.classList.toggle('closed')
                    this.current_element.classList.toggle('oyh');
                    this.next_element.classList.toggle('closed')
                    this.next_element.classList.toggle('oyh')
                    this.current_step++;
                    this.focusId();
                    this.emitStep();
                }else{
                    //Finalizando Form
                    this.sendRequest();
                }
            },
            prevStep(){
                this.current_element.classList.toggle('closed')
                this.current_element.classList.toggle('oyh');
                this.prev_element.classList.toggle('closed')
                this.prev_element.classList.toggle('oyh')
                this.current_step--;
                this.focusId();
            },
            configBtnNext(){
                if(this.steps[this.current_step].titulo_btn_next){this.titulo_btn_next = this.steps[this.current_step].titulo_btn_next}
                else if(this.next_element){this.titulo_btn_next = 'Próximo'}
                else{this.titulo_btn_next = 'Finalizar'}
            },
            focusBlankField(id){
                let el = document.getElementById(id);
                if (el){
                    setTimeout(() => {el.focus();},300);
                    el.classList.add('is-invalid');
                    setTimeout(() => {el.classList.remove('is-invalid');},4000)
                }
            },
            focusId(){
                if(this.steps[this.current_step].focus){
                    bus.$emit('action',{focus_id:this.steps[this.current_step].focus});
                    // setTimeout(() => {document.getElementById(this.steps[this.current_step].focus).focus();},300);
                }
            },
            emitStep(){
                this.$emit('change_step',this.current_step);
                if(this.steps[this.current_step].emit){
                    for (var emit in this.steps[this.current_step].emit){
                        bus.$emit(this.steps[this.current_step].emit[emit],{});
                    }
                }
            },
            validarInputsStep(){
                if(this.steps[this.current_step].validate){
                    if (this.steps[this.current_step].validate.funcao() === false){
                        this.$noty.warning(this.steps[this.current_step].validate.msg_erro,{layout:'center', timeout:3000});
                        return false;
                    }
                }

                for (var inp in this.steps[this.current_step].inputs){
                    var input = document.getElementById(this.steps[this.current_step].inputs[inp]);

                    if(input && input.hasAttribute('required') && input.value === ''){
                        input.focus();
                        input.classList.add('is-invalid');
                        setTimeout(() => {input.classList.remove('is-invalid');},4000)
                        return false;
                    }
                }
                for (var str in this.steps[this.current_step].store){
                    if(!this.$store.state[this.steps[this.current_step].store[str].state][this.steps[this.current_step].store[str].chave]){
                        var msg_erro = this.steps[this.current_step].store[str].msg_erro || 'Preencha os campos obrigatórios antes de prosseguir!';
                        if(this.steps[this.current_step].store[str].focus){
                            this.focusBlankField(this.steps[this.current_step].store[str].focus);
                        }//Focar no campo desejado
                        this.$noty.warning(msg_erro,{layout:'center', timeout:3000});
                        return false;
                    }
                }
            },
            selectStep(step){
                if(step > this.current_step && this.validarInputsStep() === false){return false;}
                this.current_element.classList.toggle('closed');
                this.current_element.classList.toggle('oyh');
                document.getElementById(this.id_form + '-' + step).classList.toggle('closed');
                document.getElementById(this.id_form + '-' + step).classList.toggle('oyh');
                this.current_step = step;
            },
            resetForm(){
                for (let step in this.steps){
                    if(this.steps[step].inputs){
                        for(let input in this.steps[step].inputs){
                            document.getElementById(this.steps[step].inputs[input]).value = '';
                        }
                    }
                }
                this.selectStep(1);
            }
        },
        computed: {
            current_step: {
                get: function() {
                    return this.current;
                },
                set: function(val) {
                    this.current = parseInt(val);

                    this.prev_element = document.getElementById(this.id_form + '-' + (this.current - 1));
                    this.current_element = document.getElementById(this.id_form + '-' + this.current);
                    this.next_element = document.getElementById(this.id_form + '-' + (this.current + 1));

                    if(this.prev_element){this.btn_prev = true;}else{this.btn_prev = false;}
                    this.configBtnNext();
                    // this.configBadgeStep();
                }
            },
        },
        created() {
            //Adicionando elemento bus_emit para retornar ser emitido para get_response
            if(this.url.bus_emit){
                this.url.bus_emit.push('get_response-' + this.id_form);
            }else{
                this.url.bus_emit = ['get_response-' + this.id_form];
            }

            bus.$on('reset-' + this.id_form, obj => {
                this.resetForm();
            });
        },
        mounted() {
            this.current_step = 1;

            if(Object.keys(this.steps).length > 1){
                for (var step in this.steps){
                    document.getElementById(this.id_form + '-' + step).classList.add('slider');
                    document.getElementById(this.id_form + '-' + step).classList.add('px-1');
                    if(step > 1){
                        document.getElementById(this.id_form + '-' + step).classList.add('closed');
                        document.getElementById(this.id_form + '-' + step).classList.add('oyh');
                    }
                }
            }
        },
        destroyed() {
            bus.$off('get_response-' + this.id_form);
            bus.$off('reset-' + this.id_form);
        },
    }
</script>

<style scoped>
    .slider {
        max-height: 1000px; /* approximate max height */

        transition-property: all;
        transition-duration: .2s;
        transition-timing-function: cubic-bezier(0, 1, 0.2, 1);
    }

    .oyh{
        overflow-y: hidden;
    }

    .slider.closed {
        max-height: 0;
    }
</style>
