<template>
    <!-- LOADING -->
    <div>
        <slot></slot>
        <div v-show="!hide_paginate && registros.last_page > 1" :id="'paginate-' + id_request">
            <div class="d-flex justify-content-end">
                <paginate :id_paginate="id_request" v-on:buscarPagina="pagina($event)"></paginate>
            </div>
        </div>
        <div v-if="carregando" class="row">
            <div class="text-center col d-flex align-items-center justify-content-center py-3">
                <hollow-dots-spinner
                    :key="'loading-' + id_request"
                    :animation-duration="1000"
                    :dot-size="10"
                    :dots-num="3"
                    color="#6F787D"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import {bus} from "../../app";
    import { HollowDotsSpinner } from 'epic-spinners';
    export default {
        components: {HollowDotsSpinner},
        props:[
            'id_request',
            'request',
            'display_loading',
            'start',
            'delay',
            'hide_paginate',
            'repeat',
        ],
        data() {
            return {
                carregando:false,
                url: this.request,
                uri: this.request.url,
                registros:{},
                interval:null,
                loop_index:0,
                loop_obj:{},
                loop_array:{},
                loop_tot:0
            }
        },
        methods: {
            population(obj) {
                let url = {};
                for(let send_url in this.$store.state.request[this.id_request]){
                    url[send_url] = this.$store.state.request[this.id_request][send_url];
                }
                if(obj && obj.id_element){
                    url.loading = ['spin'];
                    url.id_loading = obj.id_element;
                }

                if(this.display_loading){this.carregando = true;}
                bus.$emit('gerencia-request', url);
            },
            pagina(url){
                this.$store.state.request[this.id_request].url = url;
                this.population(this.$store.state.request[this.id_request]);
            },
            clearPaginate(){
                this.$store.state.request[this.id_request].url = this.uri;
            },
            nextLoop(){
                this.loop_index++;
                if(this.loop_array[this.loop_index]){
                    this.requestLoopIndex(this.loop_index);
                }else{
                    this.finishLoop();
                }
            },
            initLoop(obj){
                this.$store.state.waiting_request = this.id_request;
                this.loop_obj = obj;
                this.loop_array = obj.array;
                this.loop_index = 0;
                this.loop_tot = this.loop_array.length;
                this.requestLoopIndex(0);
                this.$emit('init_loop',this.registros);
            },
            requestLoopIndex(index){
                if(this.display_loading){this.carregando = true;}
                let sleep = this.loop_obj.timeout || 0;
                let url = this.$store.state.request[this.id_request];
                for (var chave in this.loop_obj.chaves){
                    url.data[this.loop_obj.chaves[chave]] = this.loop_array[index][this.loop_obj.chaves[chave]];
                }
                if(this.loop_obj.loading){
                    url.loading = [this.loop_obj.loading.type];
                    url.id_loading = this.loop_obj.loading.prefix + this.loop_array[index][this.loop_obj.chaves[chave]];
                    if(this.loop_obj.loading.element){url.element_loading = this.loop_obj.loading.element;}
                }
                this.$emit('loop_state',{index:index,tot:this.loop_tot});
                setTimeout(()=>{bus.$emit('gerencia-request', this.$store.state.request[this.id_request])}, sleep);
            },
            finishLoop(){
                this.$store.state.waiting_request = false;
                this.loop_obj = {};
                this.loop_array = {};
                this.loop_index = 0;
                this.loop_tot = 0;
                this.$emit('finish_loop',this.registros);
            },
        },
        created() {
            if(this.url.bus_emit){
                this.url.bus_emit.push('get_response-' + this.id_request);
            }else{
                this.url.bus_emit = ['get_response-' + this.id_request];
            }

            this.$store.state.request[this.id_request] = this.url;
            this.$store.state.request[this.id_request].uri = this.uri;

            bus.$on('get_response-' + this.id_request, (obj) => {
                this.carregando = false;
                this.registros = obj;
                bus.$emit('set_paginate-' + this.id_request, obj);
                bus.$emit('get_response-busca-' + this.id_request, obj);
                if(this.loop_tot > 0){this.nextLoop();}
                this.$emit('response',obj);
            });

            bus.$on('refresh-' + this.id_request, (obj) => {
                this.clearPaginate();
                this.population(obj);
            });

            bus.$on('set_request-' + this.id_request, (obj) => {
                this.clearPaginate();
                if(obj.validate){
                    return false;
                }else if(obj.valor === null){
                    delete this.$store.state.request[this.id_request].data[obj.chave];
                }else{
                    this.$store.state.request[this.id_request].data[obj.chave] = obj.valor;
                }
                this.population(this.$store.state.request[this.id_request]);
            });

            bus.$on('pagina-' + this.id_request, (obj) => {
                this.pagina(obj);
            });

            bus.$on('loop-' + this.id_request, (obj) => {
                 this.initLoop(obj);
            });
        },
        mounted: function () {
            if (this.request && this.start !== false) {
                var timeout = 0;
                if (this.delay) {timeout = this.delay;}
                setTimeout(()=>{this.population();},timeout);
                if(this.repeat){
                    this.interval = setInterval(() => {this.population();},this.repeat);
                }
            }
        },
        beforeDestroy() {
            this.carregando = false;
        },
        destroyed() {
            bus.$off('get_response-' + this.id_request);
            bus.$off('refresh-' + this.id_request);
            bus.$off('set_request-' + this.id_request);
            bus.$off('pagina-' + this.id_request);
            bus.$off('loop-' + this.id_request);
            clearInterval(this.interval);
        }
    }
</script>

<style scoped>

</style>
