<template>
    <div class="row" style="position: relative">

        <div class="col-12" :id="'header-' + id_table">
            <slot name="header"></slot>
        </div>

        <!-- PAGINATION -->
        <div v-if="paginate.last_page > 1 && !$slots.header" :id="'paginate-' + id_table" class="col-auto">
            <div class="float-right">
                <paginate :id_paginate="id_table" v-on:buscarPagina="pagina($event)"></paginate>
            </div>
        </div>
        <!-- END PAGINATION -->

        <div class="col-12">
                <table :id="id_table" class="table table-sm table-responsive-sm table-hover mb-0">

                    <!-- --------------------------CABEÇALHO----------------------------- -->
                    <thead>
                        <tr>
                            <th v-if="n_linha" class="align-middle " width="1">{{n_linha}}</th>
                            <th v-if="$scopedSlots.cell_row">

                            </th>
                            <th v-for="(titulo,index) in content" :class="titulo.class_th + ' ' + (!condicaoCelula(index) ? 'd-none' : '')" :title="titulo.title">
                                <div v-if="condicaoCelula(index) === true"
                                     v-html="titulo.titulo"
                                ></div>
                            </th>
                        </tr>
                    </thead>
                    <!-- ---------------------------------------------------------------- -->

                    <!-- --------------------------CORPO----------------------------- -->
                    <tbody>
                    <tr v-show="alerta_superior" ><td class="text-center " colspan="20" v-html="alerta_superior"></td></tr>
                    <tr v-for="(registro, row) in listaRegistros" :class="marcarBodyTr(registro)" :id="id_table + '-' + row" :key="'row-'+id_table+'-' + row">
                        <!-- contador -->
                        <td v-if="n_linha" class="align-middle text-center" width="1">{{ paginate !== '' ? paginate.from + row : row + 1 }}</td>

                        <td v-if="$scopedSlots.cell_row" style="width: 10px">
                            <slot name="cell_row" v-bind:reg="registro"></slot>
                        </td>

                        <!-- células -->
                        <td v-for="(cell, index,key) in content"
                            :class="'align-middle ' + content[index].class_td + ' ' + (!condicaoCelula(index,registro) ? 'd-none' : '')" :width="content[index].width"
                            :key="id_table + '-' + row + '-' + index"
                        >
                            <!-- --------------------------CÉLULA TIPO DATA----------------------------- -->
                            <div v-if="(content[index].tipo === 'data' || !content[index].tipo) && condicaoCelula(index,registro) === true && conteudo(index,registro) === true"
                                 :class="'text-wrap ' + (content[index].request ? 'text-hover-primary text-hover-underline cursor-pointer' : '')"
                                v-html="celula(registro,cell.chave,row,key + 1)"
                                :title="titleCelula(index,registro)"
                                v-on:click="emitAction(content[index],registro,'cell-' + id_table + '-' + row + '-' + key)"
                                :id="id_table + '-cell-' + row + '-' + index"
                            >
                            </div>

                            <form v-else-if="condicaoCelula(index,registro) === true && conteudo(index,registro) === true && (content[index].tipo === 'input-integer' || content[index].tipo === 'input-money')" autocomplete="off" v-on:submit.prevent>
                                <div class="form-row">
                                    <div class="col-3 d-flex align-items-center">
                                         <span :id="id_table + '-label_input-' + row + '-' + index"></span>
                                    </div>


                                    <!-- --------------------------CÉLULA TIPO INPUT - INTEGER----------------------------- -->
                                    <input
                                        v-if="content[index].tipo === 'input-integer' && condicaoCelula(index,registro) === true && conteudo(index,registro) === true"
                                        type="text" :id="id_table + '-input-' + row + '-' + index"
                                        class="form-control col-9 px-1 text-right mx-0"
                                        :value="registro[content[index].chave]" autocomplete="off"
                                        v-money="{decimal:',',thousands: '.',precision:0}"
                                        v-on:focusin="focusNow(id_table + '-input-' + row + '-' + index)"
                                        v-on:focusout="emitInput(registro,row,index)"
                                        v-on:keyup.40.13.prevent="nextInput(row,index)"
                                        v-on:keyup.38.prevent="prevInput(row,index)"
                                    >

                                    <!-- --------------------------CÉLULA TIPO INPUT - MONEY----------------------------- -->
                                    <input
                                        v-else-if="content[index].tipo === 'input-money' && condicaoCelula(index,registro) === true && conteudo(index,registro) === true"
                                        type="text" :id="id_table + '-input-' + row + '-' + index"
                                        class="form-control col-9 px-1 text-right mx-0"
                                        :value="parseFloat(registro[content[index].chave]).toFixed(content[index].mask.precision)"
                                        autocomplete="off"
                                        v-money="content[index].mask"
                                        v-on:focusin="focusNow(id_table + '-input-' + row + '-' + index)"
                                        v-on:focusout="emitInput(registro,row,index)"
                                        v-on:keyup.40.13.prevent="nextInput(row,index)"
                                        v-on:keyup.38.prevent="prevInput(row,index)"
                                    >
                                </div>
                            </form>

                            <!-- --------------------------CÉLULA WIDGET----------------------------- -->
                            <div v-else-if="condicaoCelula(index,registro) === true && conteudo(index,registro) === true && content[index].tipo === 'widget'">

                                    <span :class="'position-relative p-2 font-size-1 ' + (content[index].request ? 'cursor-pointer text-hover-primary ' : '') + ' ' + ( registro[content[index].chave] > 0 ? 'text-info' : 'text-lightgrey')" :title="content[index].title" :id="'widget-row-' + row + '-btn-' + index + '-' + id_table"
                                          v-on:click="emitAction(content[index],registro,'widget-row-' + row + '-btn-' + index + '-' + id_table)"
                                          v-html="content[index].icon + ( registro[content[index].chave] > 0 ? '<span class=\'font-size-05 badge badge-pill bg-waterwhite text-muted\' style=\'position: absolute;top: 0;right:0\'>' + registro[content[index].chave] + '</span>' : '')"
                                    >
                                    </span>

                            </div>

                            <!-- --------------------------CÉLULA BUTTON----------------------------- -->
                            <div v-else-if="condicaoCelula(index,registro) === true && conteudo(index,registro) === true && content[index].tipo === 'button'">
                                <button :class="'btn ' + content[index].class" :id="'btn-row-' + row + '-btn-' + index + '-' + id_table" :title="content[index].title"
                                        v-on:click="emitAction(content[index],registro,'btn-row-' + row + '-btn-' + index + '-' + id_table)"
                                        v-html="content[index].val"
                                >
                                </button>
                            </div>

                            <!-- --------------------------CÉLULA DROPDOWN----------------------------- -->
                            <div v-else-if="condicaoCelula(index,registro) === true && conteudo(index,registro) === true && content[index].tipo === 'dropdown'" class="dropdown position-relative">
                                <button :class="'btn ' + content[index].class" type="button" :id="'dropdown-row-' + row + '-btn-' + index + '-' + id_table" :title="content[index].title"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                        v-html="content[index].val || celula(registro,cell.chave,row,key + 1)"
                                >
                                </button>
                                <div class="dropdown-menu dropdown-menu-right" :aria-labelledby="'dropdown-row-' + row + '-btn-' + index + '-' + id_table">
                                    <a v-for="option in content[index].options" v-if="condicaoOption(option,registro) === true" :class="'dropdown-item cursor-pointer py-2 ' + option.class"
                                       v-on:click="emitAction(option,registro,'dropdown-row-' + row + '-btn-' + index + '-' + id_table)"
                                       v-html="valCell(registro,'NULL',option.label)"
                                    ></a>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr v-show="alerta_inferior" ><td class="text-center" colspan="20" v-html="alerta_inferior"></td></tr>
                    </tbody>
                    <!-- ---------------------------------------------------------------- -->
                </table>

                <!-- LOADING -->
                <div v-if="carregando" class="p-2 text-center col-12 d-flex align-items-center justify-content-center">
                    <hollow-dots-spinner
                        :animation-duration="1000"
                        :dot-size="10"
                        :dots-num="3"
                        color="#6F787D"
                    />
                </div>

                <div v-if="$slots.empty_rows" :id="'alert_table-' + id_table"  class="col-12 px-0 d-none mt-2">
                    <slot name="empty_rows">

                    </slot>
                </div>
                <div v-else :id="'alert_table-' + id_table" class="alert alert-warning col-12 d-none mt-2" role="alert">
                    Nenhum registro encontrado!

                    <slot name="alert_table">

                    </slot>
                </div>

                <div :id="'alert_validate-' + id_table" class="alert alert-info col-12 d-none mt-2" role="alert">
                    Utilize o campo de busca e/ou os filtros para encontrar os registros!
                </div>

                <!-- PAGINATION -->
                <div :id="'paginate-' + id_table + '-2'">
                    <div class="float-right pt-2">
                        <paginate :id_paginate="id_table + '-2'" v-on:buscarPagina="pagina($event)"></paginate>
                    </div>
                </div>

                <hr class="mt-0">
        </div>


    </div>
</template>

<script>
    import {bus} from '../../app';
    import { HollowDotsSpinner } from 'epic-spinners';
    export default {
        components: {HollowDotsSpinner},
        props:[
            'id_table',
            'style_table',
            'n_linha',
            'content',
            'body_tr',
            'validate',
            'request',
            'break',
            'group_store_index',
        ],
        data: function () {
            return {
                alerta_superior:'',
                alerta_inferior:'',
                url: this.request,
                uri: this.request.url,
                ordemAux: this.ordem || "asc",
                ordemAuxCol: this.ordemcol || 0,
                listaRegistros:{},
                group_store:{},
                paginate: '',
                carregando: (!(!this.validate || !this.break)),
                lastDirectionalFocusRow:'',
                blockDiretionalInput:false,
                disabledAction:[], //Serve para impedir que a mesma ação se repita antes da tabela ser atualizada,
                editIdInput:{},
                foco:null,
                changePageInput:false,
            }
        },
        methods:{
            population(url) {
                //Verificar se a necessidade de validar o request 'validar_populations'
                if(document.getElementById(this.id_table) === null){
                    console.log('Ação interompida');
                    return false;
                }
                this.registros_encontrados();
                document.getElementById('alert_validate-' + this.id_table).classList.add('d-none');
                this.loading(true);
                bus.$emit('gerencia-request',url);
            },
            pagina(url){
                this.url.url = url;
                this.population(this.url);
                // if(this.changePageInput){
                //     var next_row = 0;
                //     if(this.lastDirectionalFocusRow !== 0){
                //         next_row = this.listaRegistros.length - 1;
                //     }
                //     setTimeout(()=>{},500)
                //     if(document.getElementById(this.id_table + '-input-' + next_row) !== null){
                //         document.getElementById(this.id_table + '-input-' + next_row).focus();
                //     }
                // }
                // this.changePageInput = false;
            },
            clearPaginate(){
                this.url.url = this.uri;
            },
            emitAction(option,registro,id){
                if(this.disabledAction[id]){
                    this.$noty.warning('Essa ação já foi executada!',{layout: 'center', progressBar: false, timeout:1500});
                    return false;
                }
                if(option.request){
                    this.sendRequest(option,registro,id);
                }else if(option.emit){
                    bus.$emit(option.emit,registro);
                }
                else if(option.link){
                    this.link(option.link,registro);
                }else if(option.export){
                    this.exportFile(option,registro);
                }
            },
            sendRequest(option,registro,id){
                if(option.request.disable_action){ //Disable this action before updating table
                    this.disabledAction[id] = true;
                }
                if(option.get_chaves){
                    var i = 0;
                    for (i = 0; i < option.get_chaves.length; i++) {
                        option.request.data[option.get_chaves[i]] = registro[option.get_chaves[i]];
                    }
                }
                if(option.request.loading){
                    option.request.id_loading = id;
                    option.request.content = id;
                }
                let req = option.request;
                bus.$emit('gerencia-request',req);
            },
            exportFile(option,registro){
                if(option.get_chaves){
                    var i = 0;
                    for (i = 0; i < option.get_chaves.length; i++) {
                        option.export[option.get_chaves[i]] = registro[option.get_chaves[i]];
                    }
                }
                this.$emit('export',option.export)
            },
            emitInput(registro,row,index){
                if(this.content[index].request){
                    this.emitInputRequest(registro,row,index)
                }
                if(this.content[index].group_key){
                    this.emitInputGroupKey(registro,row,index)
                }
            },
            emitInputGroupKey(registro,row,index){
                //Verificar se houve alteração no valor
                let inp = this.content[index];
                let valor = document.getElementById(this.id_table + '-input-' + row + '-' + index).value;
                this.setGroupKey(this.content[index].group_key,registro,valor)
            },
                emitInputRequest(registro,row,index){
                //Verificar se houve alteração no valor
                let inp = this.content[index];
                let valor = document.getElementById(this.id_table + '-input-' + row + '-' + index).value;
                if(inp.tipo === 'input-money' && registro[inp.chave] === this.float4dDB(valor)){return false;}
                else if(inp.tipo === 'input-integer' && this.removerCaract(registro[inp.chave]) === this.removerCaract(valor)){return false;}

                this.editIdInput[row + '-' + index] = true;

                if(inp.get_chaves){
                    var i = 0;
                    for (i = 0; i < inp.get_chaves.length; i++) {
                        inp.request.data[inp.get_chaves[i]] = registro[inp.get_chaves[i]];
                    }
                }

                var emit = 'input-' + this.id_table;
                if(inp.request.bus_emit){inp.request.bus_emit.push(emit);}else{inp.request.bus_emit = [emit];}
                inp.request.data[inp.chave] = valor;
                inp.request.data.response = {'input':row + '-' + index,'value':valor};
                this.showLoadInput(row + '-' + index);
                bus.$emit('gerencia-request',inp.request);
                if(inp.tipo === 'input-money'){this.listaRegistros[row][inp.chave] = this.float4dDB(valor);}
                else if(inp.tipo === 'input-integer'){this.listaRegistros[row][inp.chave] = valor;}
            },
            setGroupKey(group_key,registro,valor = null){
                if(!this.group_store[registro[this.group_store_index]]){
                    this.group_store[registro[this.group_store_index]] = {};
                }
                this.group_store[registro[this.group_store_index]][this.group_store_index] = registro[this.group_store_index];
                this.group_store[registro[this.group_store_index]][group_key] = valor;
                this.$emit('group_store',this.group_store);
            },
            focusNow(id){
                this.foco = id;
            },
            showLoadInput(input){
                if(document.getElementById(this.id_table + '-label_input-' + input)){
                    document.getElementById(this.id_table + '-label_input-' + input).innerHTML = "<i class='fas fa-circle-notch fa-spin text-primary' title='Carregando'></i>";
                }
            },
            showSuccessInput(input){
                if(document.getElementById(this.id_table + '-label_input-' + input)){
                    document.getElementById(this.id_table + '-label_input-' + input).innerHTML = "<i class='fas fa-check text-success' title='Alterado'></i>";
                }
            },
            clearLabelInput(input){
                if(document.getElementById(this.id_table + '-label_input-' + input)){
                    document.getElementById(this.id_table + '-label_input-' + input).innerHTML = "";
                }
            },
            prevInput(row,index){
                if(this.blockDiretionalInput === true){
                    return false;
                }
                this.lastDirectionalFocusRow = row;
                let prev = (row - 1) + '-' + index;
                if(document.getElementById(this.id_table + '-input-' + prev) !== null){
                    document.getElementById(this.id_table + '-input-' + prev).focus();
                }else{
                    this.emitInput(this.listaRegistros[row],row,index);
                    if(this.paginate.current_page > 1){
                        this.blockDiretionalInput = true;
                        this.alerta_superior = '<span class="text-info">Voltando para <strong>pagina anterior</strong>...</span>';
                        this.changePageInput = true;
                        this.pagina(this.uri + '?page=' + (this.paginate.current_page - 1));
                    }
                }
            },
            nextInput(row,index){
                if(this.blockDiretionalInput === true){
                    return false;
                }
                this.lastDirectionalFocusRow = row;
                let next = (row + 1) + '-' + index;
                if(document.getElementById(this.id_table + '-input-' + next) !== null){
                    document.getElementById(this.id_table + '-input-' + next).focus();
                }else{
                    this.emitInput(this.listaRegistros[row],row,index);
                    if(this.paginate.current_page < this.paginate.last_page){
                        this.blockDiretionalInput = true;
                        this.alerta_inferior = '<span class="text-info">Indo para <strong>próxima página</strong>...</span>';
                        this.changePageInput = true;
                        this.pagina(this.uri + '?page=' + (this.paginate.current_page + 1));
                    }
                }
            },
            tdAction(registro,td,id){
                if(this.content[td].request){
                    var request = this.content[td].request;
                    if(this.content[td].get_chaves){
                        var chaves = this.content[td].get_chaves;
                        var i = 0;
                        for (i = 0; i < chaves.length; i++) {
                            request.data[chaves[i]] = registro[chaves[i]];
                        }
                    }
                    bus.$emit('gerencia-request',request);
                }else if(this.content[td].link){
                    this.link(this.content[td].link,registro);
                }
            },
            celula(registro,chave,linha,key){

                if(this.content[key].filters !== undefined){
                    return this.filtros(registro,chave,this.content[key].filters);
                }else if(this.content[key].mask !== undefined && registro !== null){
                    return this.mask(this.content[key].mask,registro[chave]);
                }else if(this.content[key].img !== undefined){
                    return this.imagem(registro[chave],this.content[key].img);
                }else if(this.content[key].val !== undefined){
                    return this.valCell(registro,chave,this.content[key].val);
                }

                return registro[chave];
            },
            titleCelula(index,registro){
                if(this.content[index].title){
                    return registro[this.content[index].title.chave];
                }else{
                    return '';
                }
            },
            condicaoCelula(key,registro = {}){
                if(this.content[key].condicao){
                    if(this.content[key].condicao.if && typeof this.content[key].condicao.if === 'object'){
                        return this.condicaoCelulaObj(this.content[key].condicao.if,registro);
                    }else{
                        return !!this.content[key].condicao.if;
                    }
                }
                return true;
            },
            conteudo(key,registro = {}){
                if(this.content[key].conteudo){
                    if(this.content[key].conteudo.if && typeof this.content[key].conteudo.if === 'object'){
                        return this.condicaoCelulaObj(this.content[key].conteudo.if,registro);
                    }else{
                        return !!this.content[key].conteudo.if;
                    }
                }
                return true;
            },
            condicaoCelulaObj(condition,registro){
                if(typeof condition.val !== 'undefined'){
                    return condition.val === registro[condition.chave];
                }else if(condition.in){
                    var c = 0;
                    for (var n in condition.in){
                        if(condition.in[n] === registro[condition.chave]){c++;}
                    }
                    return c > 0;
                }else if(condition.not_null){
                    return registro[condition.chave] !== null
                }
            },
            condicaoOption(option,registro = {}){
                if(option.condicao){
                    if(option.condicao.if && typeof option.condicao.if === 'object'){
                        return this.condicaoCelulaObj(option.condicao.if,registro);
                    }else{
                        return !!option.condicao.if;
                    }
                }
                return true;
            },
            imagem(registro,imagem){
                if(registro){
                    return "<img src='" + this.request.path + registro +"' class='" + (imagem.class ? imagem.class : 'rounded-circle') + "' width='" + (imagem.width ? imagem.width : '24') + "' height='" + (imagem.height ? imagem.height : '24') + "' style='" + imagem.style + "'>"
                }else{
                    return imagem.blank_img;
                }

            },
            filtros(registro,chave,filters){
                if(filters.translate){
                    return filters.translate[registro[chave]];
                }else if(filters.val){
                    return this.filtrosVal(registro,chave,filters.val);
                }
                return registro[chave];
            },
            valCell(registro,chave,val){
                let arrayKeys = val.match(/{[\w\d]+}/g); //Pegando todas as variáveis (keys) inseridas na string no valor da célula
                let keyString = '';
                for (let key in arrayKeys){ //Substituindo {keys} pelos respectivos valores do registro
                    keyString = arrayKeys[key].substring(arrayKeys[key].lastIndexOf("{") + 1, arrayKeys[key].lastIndexOf("}"));
                    val = val.replace('{' + keyString + '}',registro[keyString]);
                }
                return val;
            },
            filtrosVal(registro,chave,val){
                if(registro[chave] === null || parseFloat(registro[chave]) === 0){
                    return val.null;
                }else if(val.not_null){
                    if(val.not_null.montar){
                        return this.montarVal(registro[chave],val.not_null.montar);
                    }else{
                        return this.valCell(registro,chave,val.not_null);
                    }
                }
                return registro[chave];
            },
            montarVal(registro,montar){
                var obj = '';
                for (let part in montar) {
                    if (montar[part] === '{registro}') {
                        obj += registro;
                    }else if(montar[part].mask){
                        obj += this.mask(montar[part].mask,registro)
                    } else {
                        obj += montar[part];
                    }
                }

                return obj;
            },
            mask(content,registro){
                if(content.type === 'float_2d' || content.type === 'float_4d'){
                    return this.maskFloat(content.type,registro);
                }else if(content.type === 'integer'){
                    return this.integer(registro);
                }else if(content.type === 'cnpj'){
                    return this.maskCNPJ(registro);
                }
            },
            maskFloat(type,registro){
                switch (type) {
                    case 'float_2d':
                        return this.float2d(registro);
                    case 'float_4d':
                        return this.float4d(registro);
                    case 4:
                        return this.float4d(registro);
                }
            },
            float2d(registro){
                if(registro === null || registro === undefined){
                    registro = 0;
                }
                var vlr = parseFloat(registro).toFixed(2);
                var parts = vlr.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                return parts.join(",");
            },
            float4d(registro){
                if(registro === null || registro === undefined){
                    registro = 0;
                }
                var vlr = parseFloat(registro).toFixed(4);
                var parts = vlr.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{5})+(?!\d))/g, ".");
                return parts.join(",");
            },
            integer(registro){
                return parseInt(registro).toLocaleString();
            },
            float4dDB(vlr){
                var parts = vlr.toString().split(",");
                parts[0] = parts[0].replace(/\B(?=(\d{5})+(?!\d))/g, ",");
                return (parseFloat(parts.join("."))).toString();
            },
            maskCNPJ(cnpj){
                //43.210.987/6543-21
                var n1 = cnpj.slice(0, -12);
                var n2 = cnpj.slice(-12, -9);
                var n3 = cnpj.slice(-9, -6);
                var n4 = cnpj.slice(-6, -2);
                var n5 = cnpj.slice(-2);
                return n1 + '.' + n2 + '.' + n3 + '/' + n4 + '-' + n5;
            },
            marcarBodyTr(registro){
                if(this.body_tr){
                    if(this.body_tr.marcar){
                        return this.highlightTR(this.body_tr.marcar,registro);
                    }else if(this.body_tr.highlights){
                        let classes = '';
                        for(let highlight in this.body_tr.highlights){
                            classes+=' ' + this.highlightTR(this.body_tr.highlights[highlight],registro);
                        }
                        return classes;
                    }
                }
            },
            highlightTR(marca,registro){
                if(marca.if){
                    let c = 0;
                    for(let condicao in marca.if){
                        if(marca.if[condicao].valor !== undefined){
                            if(parseInt(marca.if[condicao].valor) === parseInt(registro[marca.if[condicao].chave])){
                                c++;
                            }
                        }else if(marca.if[condicao].in){
                            let arrayIn = marca.if[condicao].in;
                            if(arrayIn.indexOf(registro[marca.if[condicao].chave]) !== -1){
                                c++;
                            }
                        }else if(marca.if[condicao].maior !== undefined){
                            if(parseInt(marca.if[condicao].maior) < parseInt(registro[marca.if[condicao].chave])){
                                c++;
                            }
                        }else if(marca.if[condicao].modal !== undefined){
                            if((parseInt(registro[marca.if[condicao].chave]) % 2) === parseInt(marca.if[condicao].modal)){
                                c++;
                            }
                        }
                    }
                    if(c > 0){
                        return marca.class;
                    }

                }
            },
            removerCaract(valor){
                valor = valor.toString();
                valor = valor.replace(".","");
                valor = valor.replace(".","");
                valor = valor.replace(".","");
                let espacos = /\s/g;
                let caracateres = valor.replace(espacos, "");
                let remove = /á|é|í|ó|ú/g;  // adicione os caracteres indesejáveis
                return caracateres.replace(remove, "");
            },
            link(obj,registro){
                //Verificando a opção de montar URL
                obj.url = '';
                this.$noty.info('Redirecionando...',{layout: 'bottomCenter', timeout:false});
                if(obj.montar_url){
                    for(let part in obj.montar_url){
                        if(part === 'chave'){
                            if(registro[obj.montar_url[part]] === undefined || !registro[obj.montar_url[part]]){
                                console.log('Request não pode ser enviada. chave_store => ' +  obj.montar_url[part] + ' indefinida');
                                return false;
                            }
                            obj.url+= registro[obj.montar_url[part]];
                        }else{
                            obj.url+= obj.montar_url[part];
                        }
                    }
                }
                window.location.href =  obj.url;
            },
            /*=====================================NOTIFICAÇÕES DE TABELA===============================*/
            registros_encontrados(){
                document.getElementById('alert_table-' + this.id_table).classList.add('d-none');
            },
            nenhum_registro_encontrado(){
                document.getElementById('alert_table-' + this.id_table).classList.remove('d-none');
            },
            loading(aplication){
                this.carregando = aplication;
            },
            /*===============================================================================================*/
        },
        created() {
            //Adicionando elemento bus_emit para retornar ser emitido para get_response
            if(this.url.bus_emit){
                this.url.bus_emit.push('get_response-' + this.id_table);
            }else{
                this.url.bus_emit = ['get_response-' + this.id_table];
            }


            bus.$on('get_response-' + this.id_table, (obj) => {
                this.alerta_superior = '';
                this.alerta_inferior = '';
                this.listaRegistros = obj.data || obj;

                //Enable the actions disabled
                this.disabledAction = [];

                //Verificando se algum registro foi encontrado
                if (this.listaRegistros.length > 0) {
                    this.registros_encontrados();
                } else {
                    this.nenhum_registro_encontrado();
                }

                //Enviando informações ao componente busca
                bus.$emit('get_response-busca-' + this.id_table, obj);


                //Configurando Paginate
                bus.$emit('set_paginate-' + this.id_table, obj);
                bus.$emit('set_paginate-' + this.id_table + '-2', obj);
                if (obj.last_page > 1) {
                    bus.$emit('paginate', obj);
                    this.paginate = obj;
                } else {
                    this.paginate = '';
                    if (obj.current_page > 1) {
                        this.pagina(obj.prev_page_url);
                        return false;
                    }
                }

                if(this.lastDirectionalFocusRow !== ''){
                    setTimeout(() => {
                        if(this.lastDirectionalFocusRow === 0){
                            for(var input in this.content){
                                var el = document.getElementById(this.id_table + '-input-' + (this.paginate.per_page - 1) + '-' + input);
                                if(el && (this.content[input].tipo === 'input-integer' || this.content[input].tipo === 'input-money')){
                                    document.getElementById(this.id_table + '-input-' + (this.paginate.per_page - 1) + '-' + input).focus();
                                }
                            }
                        }else{
                            for(var input in this.content){
                                if(this.content[input].tipo === 'input-integer' || this.content[input].tipo === 'input-money'){
                                    document.getElementById(this.id_table + '-input-0' + '-' + input).focus();
                                }
                            }
                        }
                        this.blockDiretionalInput = false
                    },100);
                }

                this.loading(false);
            });

            bus.$on('refresh-' + this.id_table, (url) => {
                if(url !== undefined && url.url){
                    this.url = url;
                    this.population(url);
                }else{
                    if(this.paginate !== ''){
                        this.pagina(this.paginate.path + '?page=' + this.paginate.current_page);
                    }else{
                        this.population(this.url);
                    }
                }
            });

            bus.$on('pagina-' + this.id_table, (obj) => {
               this.pagina(obj);
            });

            bus.$on('reset-' + this.id_table, (url) => {
                this.paginate = {};
                this.listaRegistros = [];
                this.loading(true);
                this.population(this.url);
            });

            bus.$on('clear-' + this.id_table, (url) => {
                this.listaRegistros = [];
            });


            bus.$on('input-' + this.id_table, (obj) => {
                this.showSuccessInput(obj.input);
                setTimeout(() => {
                    this.clearLabelInput(obj.input);
                },1500);
                this.editIdInput[obj.input] = false;
            });


            bus.$on('set_request-' + this.id_table, (obj) => {
                if(obj.validate){
                    this.listaRegistros = [];
                    this.paginate = [];
                    this.registros_encontrados();
                    this.clearPaginate();
                    document.getElementById('alert_validate-' + this.id_table).classList.remove('d-none');
                    return false;
                }else if(obj.valor === null){
                    delete this.url.data[obj.chave];
                }else{
                    this.url.data[obj.chave] = obj.valor;
                }
                this.clearPaginate();
                this.population(this.url);
            });

        },
        destroyed() {
            bus.$off('get_response-' + this.id_table);
            bus.$off('set_request-' + this.id_table);
            bus.$off('input-' + this.id_table);
            bus.$off('clear-' + this.id_table);
            bus.$off('reset-' + this.id_table);
            bus.$off('pagina-' + this.id_table);
            bus.$off('refresh-' + this.id_table);
        },
        mounted() {
            if(this.request && !this.break){
                if(this.validate){
                    document.getElementById('alert_validate-' + this.id_table).classList.remove('d-none');
                    return false;
                }
                this.population(this.url);
            }
        },
    }
</script>

<style>

    td{
        vertical-align: middle;
    }

</style>
