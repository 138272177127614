<template>
    <select class="text-dark form-control" :name="id_select" :id="id_select"
            v-on:change="change()">
        <slot></slot>
    </select>
</template>

<script>
import {bus} from '../../app';
export default {
    props:['id_select'],
    data: function () {
        return {
            registers:null,
        }
    },
    methods:{
        change(){
            console.log('change')
        }
    },
    mounted(){
        var options = {}
        options.language = {
            noResults:function(){return"Nenhum resultado encontrado"},
            errorLoading:function(){return"Os resultados não puderam ser carregados."},
            loadingMore:function(){return"Carregando mais resultados…"},
            searching:function(){return"Buscando…"},
            removeAllItems:function(){return"Remover todos os itens"}
        }
        $('#' + this.id_select).select2(options);

        $('#' + this.id_select).on('change', (e) => {
            this.$emit('change',e);
        });
    }
}
</script>

<style scoped>

</style>
