<template>
    <div :id="id_list" class="row">

<!--        <div v-if="busca || filtrar" class="col-12 pb-1 px-0">-->
<!--            &lt;!&ndash; BUSCA E FILTROS &ndash;&gt;-->
<!--            <busca-->
<!--                :busca="busca"-->
<!--                :id_busca="busca"-->
<!--                :placeholder_buscar="placeholder_buscar"-->
<!--                :filtros="filtrar"-->
<!--                :validate="validate"-->

<!--            >-->
<!--                &lt;!&ndash; PAGINATION &ndash;&gt;-->
<!--                <div :id="'paginate-' + id_list + '-1'" class="col d-none">-->
<!--                    <div class="float-right">-->
<!--                        <paginate :id_paginate="id_list + '-1'" v-on:buscarPagina="pagina($event)"></paginate>-->
<!--                    </div>-->
<!--                </div>-->

<!--                &lt;!&ndash; END PAGINATION &ndash;&gt;-->
<!--            </busca>-->
<!--            &lt;!&ndash; END BUSCA E FILTROS &ndash;&gt;-->
<!--        </div>-->

        <div class="col" :id="'header-' + id_list">
            <slot name="header"></slot>
        </div>

        <!-- PAGINATION -->
        <div v-if="paginate.last_page > 1 && !$slots.header" :id="'paginate-' + id_list + '-1'" class="col-auto">
            <div class="float-right">
                <paginate :id_paginate="id_list + '-1'" v-on:buscarPagina="pagina($event)"></paginate>
            </div>
        </div>
        <!-- END PAGINATION -->

        <div class="col-12">
            <div class="row px-0">
                <div v-for="(registro,index) in registers" :class="class_item">
                    <div :id="id_list + '-' + index" :class="class_card  + ' ' + (condition_item ? condition_item.if[registro[condition_item.chave_registro]].class :'')" :title="(condition_item ? condition_item.if[registro[condition_item.chave_registro]].title :'')">
                        <div :class="'row ' + class_item_row">
                            <div :class="class_item_col_img">
                                <!-- IMAGEM -->
                                <img v-if="registro.src_img" class="img-thumbnail" :src="path + '/' +  registro.src_img" alt="" :style="style_img"/>

                                <div v-else class="border p-2 text-center" :style="style_img" v-html="if_no_img"></div>
                                <!-- END IMAGEM -->

                            </div>
                            <div :class="class_item_col_description">
                                <div v-for="descricao in description" :class="descricao.class" v-html="formatarDescricao(registro,descricao)">

                                </div>


                                <div v-if="action">
                                    <!-- DROPDOWN -->
                                    <button v-if="action.dropdown" :id="'btn-list-' + id_list + '-' + index" :class="'btn btn-sm ' + ( action.dropdown.class || 'btn-light' ) + ' text-center'" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Opções">
                                        <i class="fas fa-ellipsis-v mx-1"></i>
                                    </button>
                                    <div v-if="action.dropdown" class="dropdown-menu dropdown-menu-left shadow">
                                        <!-------------------------Actions------------------------->
                                        <div class="py-1" v-for="option in action.dropdown.options">
                                            <a :class="'dropdown-item-table dropdown-item ' + (option.class || ' text-primary')" style="cursor: pointer"
                                               v-on:click="emitAction(option,registro,'btn-list-' + id_list + '-' + index)"
                                            >{{option.titulo}}</a>
                                        </div>

                                    </div>
                                    <!-- END DROPDOWN -->

                                    <!-- OBJ IF-->
                                    <span v-if="action.button_if" :class="action.button_if.classes">
                                    <button v-if="conditionIf(registro,option)" v-for="(option,indice) in action.button_if.options" :id="'button_if-' + id_list + '-' + index + '-' + indice" :class="'btn ' +  (option.classes || ' btn-sm')" :title="option.title" v-on:click="emitAction(option,registro,'button_if-' + id_list + '-' + index + '-' + indice)" v-html="option.titulo">
                                    </button>
                            </span>
                                    <!--  -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div :id="'alert_table-' + id_list" class="col-12 d-none mt-2" role="alert">
                    <div class="alert alert-warning">
                        Nenhum registro encontrado!
                    </div>
                </div>

                <div :id="'alert_validate-' + id_list" class="alert alert-info col-12 d-none mt-2" role="alert">
                    Utilize o campo de busca e/ou os filtros para encontrar os registros!
                </div>
            </div>
        </div>


        <!-- LOADING -->
        <div v-if="carregando" class="p-2 text-center col-12 d-flex align-items-center justify-content-center">
            <hollow-dots-spinner
                :animation-duration="1000"
                :dot-size="10"
                :dots-num="3"
                color="#6F787D"
            />
        </div>


    </div>
</template>

<script>
import {bus} from '../../app';
import { HollowDotsSpinner } from 'epic-spinners';
export default {
    components: {HollowDotsSpinner},
    props:[
        'id_list',
        'class_item',
        'class_card',
        'condition_item',
        'class_item_row',
        'class_item_col_img',
        'class_item_col_description',
        'style_img',
        'registros',
        'display_registros',
        'request',
        'img',
        'path',
        'if_no_img',
        'description',
        'acao',
        'validate',
        'break',
    ],
    data: function () {
        return {
            url: this.request,
            uri: this.request.url,
            buscar: '',
            ordemAux: this.ordem || "asc",
            ordemAuxCol: this.ordemcol || 0,
            registers: this.registros,
            paginate: '',
            action: this.acao,
            carregando: (!this.validate),
            disabledAction:[] //Serve para impedir que a mesma ação se repita antes da tabela ser atualizada
        }
    },
    methods:{
        population(url) {
            //Verificar se a necessidade de validar o request 'validar_populations'
            this.registros_encontrados();
            document.getElementById('alert_validate-' + this.id_list).classList.add('d-none');
            this.loading(true);
            bus.$emit('gerencia-request',url);
        },
        pagina(url){
            this.url.url = url;
            this.population(this.url);
        },
        clearPaginate(){
            this.url.url = this.uri;
        },
        emitAction(option,registro,id){

            if(this.disabledAction[id]){
                this.$noty.warning('Essa ação já foi executada!',{
                    layout: 'center',
                    progressBar: false,
                    timeout:1500
                });
                return false;
            }

            if(option.request){
                if(option.request.disable_action){
                    //Disable this action before updating table
                    this.disabledAction[id] = true;
                }

                if(option.chave_registros){
                    var i = 0;
                    for (i = 0; i < option.chave_registros.length; i++) {
                        option.request.data[option.chave_registros[i]] = registro[option.chave_registros[i]];
                    }
                }

                if(option.request.loading){
                    option.request.id_loading = id;
                }

                bus.$emit('gerencia-request',option.request);
            }else if(option.link){
                this.link(option.link,registro);
            }

        },
        formatarDescricao(registro,descricao){
            var valor = '';
            if(descricao.obj){
                for(var part in descricao.obj){

                    if(descricao.obj[part].chave_registro){
                        valor+= registro[descricao.obj[part].chave_registro];
                    }else if(descricao.obj[part].text){
                        valor+= descricao.obj[part].text;
                    }
                }
                return valor;
            }else if(descricao.filters){
                return descricao.filters.translate[registro[descricao.filters.chave_registro]];
            } else{
                valor+= registro[descricao.chave_registro];
                return valor;
            }
        },
        link(obj,registro){
            //Verificando a opção de montar URL
            obj.url = '';

            if(obj.montar_url){
                for(let part in obj.montar_url){
                    if(part === 'chave'){
                        if(registro[obj.montar_url[part]] === undefined){
                            console.log('Request não pode ser enviada. chave_store => ' +  obj.montar_url[part] + ' indefinida');
                            return false;
                        }
                        obj.url+= registro[obj.montar_url[part]];
                    }else{
                        obj.url+= obj.montar_url[part];
                    }
                }
            }

            window.location.href =  obj.url;
        },
        conditionIf(registro,option){
            let c = option.if.length;
            for(let condicao in option.if){
                if(option.if[condicao].valor !== undefined){
                    if(parseInt(option.if[condicao].valor) === parseInt(registro[option.if[condicao].chave_registro])){
                        c--;
                    }
                }else if(option.if[condicao].in){
                    let arrayIn = option.if[condicao].in;
                    if(arrayIn.indexOf(registro[option.if[condicao].chave_registro]) !== -1){
                        c--;
                    }
                }
            }
            if(c === 0){
                return true;
            }else {
                return false;
            }
        },
        /*=====================================NOTIFICAÇÕES DE TABELA===============================*/
        registros_encontrados(){
            document.getElementById('alert_table-' + this.id_list).classList.add('d-none');
        },
        nenhum_registro_encontrado(){
            document.getElementById('alert_table-' + this.id_list).classList.remove('d-none');
        },
        loading(aplication){
            this.carregando = aplication;
        },
    },
    computed:{

    },
    created() {
        //Adicionando elemento bus_emit para retornar ser emitido para get_response
        if(this.url.bus_emit){
            this.url.bus_emit.push('get_response-' + this.id_list);
        }else{
            this.url.bus_emit = ['get_response-' + this.id_list];
        }


        //Escuta o callback do emit do gerencia-request
        if (this.url) {
            bus.$on('get_response-' + this.id_list, (obj) => {
                this.registers = obj.data;

                //Enable the actions disabled
                this.disabledAction = [];

                //Verificando se algum registro foi encontrado
                if(this.registers.length > 0){
                    this.registros_encontrados();
                }else{
                    this.nenhum_registro_encontrado();
                }

                //Enviando informações ao componente busca
                bus.$emit('get_response-busca-' + this.id_list, obj);

                //Configurando Paginate
                bus.$emit('set_paginate-' + this.id_list, obj);
                bus.$emit('set_paginate-' + this.id_list + '-1',obj);
                if (obj.last_page > 1) {
                    bus.$emit('paginate',obj);
                    this.paginate = obj;
                }else{
                    this.paginate = '';
                    if(obj.current_page > 1){
                        this.pagina(obj.prev_page_url);
                        return false;
                    }
                }

                this.loading(false);
            });
        }

        bus.$on('pagina-' + this.id_list, (obj) => {
            this.pagina(obj);
        });

        bus.$on('refresh-' + this.id_list, (obj) => {
            if(this.paginate !== ''){
                this.pagina(this.paginate.path + '?page=' + this.paginate.current_page);
            }else{
                this.population(this.url);
            }
        });

        bus.$on('set_request-' + this.id_list, (obj) => {
            if(obj.validate){
                this.listaRegistros = [];
                this.paginate = [];
                this.registros_encontrados();
                this.clearPaginate();
                document.getElementById('alert_validate-' + this.id_list).classList.remove('d-none');
                return false;
            }else if(obj.valor === null){
                delete this.url.data[obj.chave];
            }else{
                this.url.data[obj.chave] = obj.valor;
            }
            this.clearPaginate();
            this.population(this.url);
        });
    },
    mounted() {
        if(this.request && !this.break){
            if(this.validate){
                document.getElementById('alert_validate-' + this.id_list).classList.remove('d-none');
                return false;
            }
            this.population(this.url);
        }
    },
    destroyed() {
        bus.$off('get_response-' + this.id_list);
        bus.$off('refresh-' + this.id_list);
        bus.$off('pagina-' + this.id_list);
    },
}
</script>

<style scoped>

</style>
