<template>
    <div>
        <div class="card" :id="id_mensagens">
            <div class="card-header py-2 px-3 border-bottom">
                <span class="text-muted">
                    <i class="far fa-envelope mr-2"  title="Mensagens"></i>
                    <span v-if="paginate.total" class="font-size-07">{{paginate.total === 1 ? '1 mensagem' : paginate.total + ' mensagens'}}</span>
                </span>
                <slot name="header"></slot>
            </div>

            <!-- LOADING -->
            <div v-if="carregando" class="p-2 text-center col-12 d-flex align-items-center justify-content-center">
                <hollow-dots-spinner
                    :animation-duration="1000"
                    :dot-size="10"
                    :dots-num="3"
                    color="#6F787D"
                />
            </div>

            <ul class="list-group list-group-flush">
                <li v-for="msg in mensagens" :class="'bg-hover-ligthgrey list-group-item py-2 px-3 cursor-pointer ' + (parseInt(msg.alerta) === 1 ? 'font-weight-bold' : 'text-muted')"
                    v-on:click="getMsg(msg.id_mensagem)"
                >

                    <div class="row">
                        <div class="col-auto pr-0 pl-2">
                            <img v-if="msg.img_usuario" class="rounded-circle" width="24" height="24" :src="path + '/' + msg.img_usuario" style="">
                            <div v-else class='p-1 border rounded-circle text-center d-flex align-items-center justify-content-center text-center' style='width: 24px;height: 24px'><i class='text-lightgrey far fa-user font-size-06'></i></div>
                        </div>
                        <div class="col px-2">
                            <div class="d-flex justify-content-between align-items-center">
                                <p class="font-size-06 font-weight-bold">{{msg.nome_usuario}}</p>
                                <p class="font-size-06 text-muted">{{msg.data_exibicao}}</p>
                            </div>
                            <div class="font-size-07">{{msg.titulo}}</div>
                        </div>
                    </div>
                </li>

                <li :id="'alerta_vazio-' + id_mensagens" class="list-group-item py-2 px-3 d-none font-size-07 text-muted" v-html="alerta_vazio"></li>

            </ul>



            <!-- MAIS COMENTÁRIOS -->
            <ul :id="'before_msgs-' + id_mensagens" class="list-group list-group-flush p-1 text-center d-none font-size-08">
                <a class="text-primary m-0" title="Mostrar mensagens anteriores" style="cursor:pointer"
                   v-on:click="paginaAnterior()"
                >{{paginate.total - paginate.to + ((paginate.total - paginate.to) === 1 ? ' mensagem anterior' : ' mensagens anteriores')}}  ...</a>

                <span class="m-0 text-color-pulse-primary d-none" >Carregando...</span>
            </ul>
            <!-------------->

        </div>
        <!-- ========================================= BACKDROP DINÂMICO=============================================== -->
        <div :id="'modal_mensagem-' + id_mensagens" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg shadow" role="document">
                <div class="modal-content" style="border-radius: 0.3rem;">

                    <div class="modal-body p-4">

                        <div class="form-group">
                            <!-- IMAGEM -->
                            <div class="row">
                                <div class="col-auto">
                                    <img v-if="src_img" class="rounded-circle" :src="src_img" alt="user" style="height: 35px; width: 35px"/>

                                    <div v-else class="border rounded-circle d-flex align-items-center justify-content-center text-center" style="height: 35px; width: 35px">
                                        <i class='far fa-user text-lightgrey font-size-1'></i>
                                    </div>
                                </div>
                                <div class="col p-0">
                                    <div type="text" class="text-muted font-size-07">Enviado por {{usuario_msg}} - <span v-if="data_msg">{{data_msg | moment('DD/MM/YYYY à\\s HH:mm')}}</span></div>
                                    <div v-if="!d_destinatarios" type="text" class="text-muted font-size-07">
                                        <a class="axios-link" v-on:click="d_destinatarios = true">Para:
                                            <span v-for="(dest,index) in destinatarios" v-if="index <= 2">
                                                {{dest.first_name}} <{{dest.email}}>;
                                            </span>
                                            <span v-if="destinatarios.length > 3"> e mais {{(destinatarios.length - 3)}} ...</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <transition name="slide-down">
                                <div v-if="d_destinatarios" class="card p-3 mt-2 shadow-none font-size-07">
                                    <div class="mb-2"><a class="axios-link font-size-07" v-on:click="d_destinatarios = false">Ocultar destinatários</a></div>
                                    <div v-for="(dest,index) in destinatarios" class="text-muted text-hover-underline">
                                        {{dest.first_name}} <{{dest.email}}>
                                        <span v-if="dest.data_leitura" class="ml-2" title="Leitura confirmada">
                                            <i class="fas fa-check text-success"></i>
                                            {{dest.data_leitura | moment('DD/MM/YYYY à\\s HH:mm')}}
                                        </span>
                                    </div>
                                </div>
                            </transition>
                            <!-- END IMAGEM -->
                        </div>

                        <div v-if="area_msg" class="form-group bg-watergrey rounded p-2">
                            <div class="text-muted font-size-08 font-weight-bold">{{areaMsg(area_msg)}}</div>
                            <a v-if="area_link" :href="area_link_msg" class="btn btn-sm text-left btn-link p-0 mt-1">{{area_detalhes_msg}}</a>
                            <span v-else>{{area_detalhes_msg}}</span>
                        </div>


                        <div class="form-group pt-3 pb-1">
                            <div type="text" class="font-weight-bold font-size-1">{{titulo_msg}}</div>
                        </div>

                        <div class="form-group">
                            <anexos
                                v-if="id_msg && alerta === 0"
                                :id_anexo="'anexos_mensagem-' + id_msg"
                                :key="'anexos_mensagem-' + id_msg"
                                :display_files_inline="true"
                                :request="{
                                    path:path,
                                    url:path+'/gerenciar_request/anexos',
                                    data:{
                                        function:'get_anexos',
                                        id_mensagem:id_msg
                                    },
                                }"
                                :data_request_download="{function:'download_anexo'}"
                                >
                            </anexos>
                        </div>


                        <div class="form-group">
                            <div v-html="conteudo_msg"></div>
                        </div>

                    </div>
                </div>
            </div>

            <button :id="'btn_toggle-modal_mensagem-' + id_mensagens" type="button" class="btn btn-primary" data-toggle="modal" :data-target="'#modal_mensagem-' + id_mensagens"
                    hidden>
                Toggle
            </button>
        </div>

        <!-- ========================================= BACKDROP ESTÁTICO=============================================== -->
        <div :id="'modal_mensagem_static-' + id_mensagens" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-lg shadow" role="document">
                <div class="modal-content" style="border-radius: 0.3rem;">

                    <div class="modal-body p-4">

                        <div class="form-group">
                            <!-- IMAGEM -->
                            <div class="row">
                                <div class="col-auto">
                                    <img v-if="src_img" class="rounded-circle" :src="src_img" alt="user" style="height: 35px; width: 35px"/>

                                    <div v-else class="border rounded-circle d-flex align-items-center justify-content-center text-center" style="height: 35px; width: 35px">
                                        <i class='far fa-user text-lightgrey font-size-1'></i>
                                    </div>
                                </div>
                                <div class="col">
                                    <div type="text" class="text-muted font-size-07">Enviado por {{usuario_msg}} - <span v-if="data_msg">{{data_msg | moment('DD/MM/YYYY à\\s HH:mm')}}</span></div>
                                    <div v-if="!d_destinatarios" type="text" class="text-muted font-size-07">
                                        <a class="axios-link" v-on:click="d_destinatarios = true">Para:
                                            <span v-for="(dest,index) in destinatarios" v-if="index <= 2">
                                                {{dest.first_name}} <{{dest.email}}>;
                                            </span>
                                            <span v-if="destinatarios.length > 3"> e mais {{(destinatarios.length - 3)}} ...</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <transition name="slide-down">
                                <div v-if="d_destinatarios" class="card p-3 mt-2 shadow-none font-size-07">
                                    <div class="mb-2"><a class="axios-link font-size-07" v-on:click="d_destinatarios = false">Ocultar destinatários</a></div>
                                    <div v-for="(dest,index) in destinatarios" class="text-muted text-hover-underline">
                                        {{dest.first_name}} <{{dest.email}}>
                                        <span v-if="dest.data_leitura" class="ml-2" title="Leitura confirmada">
                                            <i class="fas fa-check text-success"></i>
                                            {{dest.data_leitura | moment('DD/MM/YYYY à\\s HH:mm')}}
                                        </span>
                                    </div>
                                </div>
                            </transition>
                            <!-- END IMAGEM -->
                        </div>

                        <div v-if="area_msg" class="form-group rounded p-2 bg-watergrey">
                            <div class="text-muted font-size-08 font-weight-bold">{{areaMsg(area_msg)}}</div>
                            <a v-if="area_link" :href="area_link_msg" class="btn btn-sm text-left btn-link p-0 mt-1">{{area_detalhes_msg}}</a>
                            <span v-else>{{area_detalhes_msg}}</span>
                        </div>

                        <div class="form-group py-3">
                            <div type="text" class="font-weight-bold font-size-1">{{titulo_msg}}</div>
                        </div>

                        <div class="form-group">
                            <anexos
                                v-if="id_msg && alerta === 1"
                                :id_anexo="'anexos_mensagem_static-' + id_msg"
                                :key="'anexos_mensagem_static-' + id_msg"
                                :display_files_inline="true"
                                :request="{
                                    path:path,
                                    url:path+'/gerenciar_request/anexos',
                                    data:{
                                        function:'get_anexos',
                                        id_mensagem:id_msg
                                    },
                                }"
                                :data_request_download="{function:'download_anexo'}"
                            >
                            </anexos>
                        </div>

                        <div class="form-group">
                            <div v-html="conteudo_msg"></div>
                        </div>

                        <div class="form-row pt-4">
                            <div class="col-lg">
                                <button type="button" class="btn btn-sm btn-block btn-light text-success mt-3"
                                        v-on:click="confirmarLeitura(id_msg)" title="Confirmar a leitura da mensagem"
                                >
                                    Confirmar leitura <i class="ml-2 fas fa-check"></i>
                                </button>
                            </div>
                            <div class="col-lg" v-if="area_msg && area_link">
                                <button type="button" class="btn btn-sm btn-block btn-light text-success mt-3" data-toggle="modal" :data-target="'#modal_mensagem_static-' + id_mensagens"
                                        v-on:click="confirmarLeituraLink(id_msg,area_link_msg)" title="Ao confirmar a leitura da mensagem você será redirecionado para a referência da mensagem."
                                >
                                    Confirmar e ir para {{area_detalhes_msg.substring(0,25)}}... <i class="ml-2 fas fa-check"></i>
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <button :id="'btn_toggle-modal_mensagem_static-' + id_mensagens" type="button" class="btn btn-primary" data-toggle="modal" :data-target="'#modal_mensagem_static-' + id_mensagens" hidden>
                Toggle
            </button>
        </div>


    </div>
</template>

<script>
    import {bus} from '../../app';
    import { HollowDotsSpinner } from 'epic-spinners';
    export default {
        components: {HollowDotsSpinner},
        props:[
            'id_mensagens',
            'request',
            'alertas',
            'area_link',
            'vazio',
            'path'
        ],
        data() {
            return {
                url: this.request,
                uri: this.request.url,
                mensagens:'',
                alerta:0,
                id_msg:'',
                area_msg:'',
                area_detalhes_msg:'',
                area_link_msg:'',
                titulo_msg:'',
                conteudo_msg:'',
                data_msg:'',
                usuario_msg:'',
                src_img:null,
                backdrop:'static',
                paginate:'',
                after_msgs:'',
                before_msgs:false,
                alerta_vazio:this.vazio || 'Sem mensagens!',
                block: false,
                carregando: true,
                destinatarios:{},
                d_destinatarios:false,
            }
        },
        methods:{
            population(url) {
                bus.$emit('gerencia-request',url);
            },
            paginaAnterior() {
                if(this.block === true){
                    return false;
                }

                var url = this.url;
                url.url = this.uri + '?page=' + (this.paginate.current_page + 1);
                this.after_msgs = this.mensagens;
                this.loading_before_comments(true);
                this.block = true;
                this.before_msgs = true;
                this.population(url);
            },
            getResponse(){
                if(this.url.bus_emit){
                    this.url.bus_emit.push('get_response-' + this.id_mensagens);
                }else{
                    this.url.bus_emit = ['get_response-' + this.id_mensagens];
                }
            },
            mensagem(msg){
                return '<div class=\'font-size-06 text-muted text-left \'>' + msg.data_exibicao + '</div>' + ( msg.titulo !== null ? msg.titulo : '<span class="font-italic">Sem assunto...</span>');
            },
            getMsg(id_mensagem){
                this.d_destinatarios = false;
                bus.$emit('gerencia-request',{
                    url:this.url.url,
                    method:this.url.method,
                    data:{
                        function:'get_mensagem',
                        id_mensagem:id_mensagem
                    },
                    bus_emit:['get_msg-' + this.id_mensagens],
                    loading:['page']
                });
            },
            areaMsg(area){
                switch (area) {
                    case 4:
                        return 'Catálogo';
                    case 6:
                    case 7:
                        return 'Processo';
                    case 15:
                        return 'Pedido';
                    default:
                        return '';
                }
            },
            confirmarLeitura(id_mensagem){
                this.closeModalStatic();
                bus.$emit('gerencia-request',{
                    url:this.url.url,
                    data:{
                        function:'confirmar_leitura_mensagem',
                        id_mensagem:id_mensagem
                    },
                    bus_emit:['refresh-' + this.id_mensagens,'refresh-mensagens-navbar'],
                    loading:['page']
                });
            },
            confirmarLeituraLink(id_mensagem,link){
                bus.$emit('gerencia-request',{
                    url:this.url.url,
                    method:this.url.method,
                    data:{
                        function:'confirmar_leitura_mensagem',
                        id_mensagem:id_mensagem,
                    },
                    redirect:link,
                    loading:['page']
                });
            },
            verificarAlertas(){
                let leng = this.mensagens.length;
                for(leng; leng >=  1; leng-- ){
                    if(parseInt(this.mensagens[leng - 1].alerta) === 1){
                        this.getMsg(this.mensagens[leng - 1].id_mensagem);
                        break;
                    }
                }
            },
            loading(aplication){
                this.carregando = aplication;
            },
            beforeComments(aplication){
                if(aplication === true){
                    document.getElementById('before_msgs-' +  this.id_mensagens).classList.remove('d-none');
                }else{
                    document.getElementById('before_msgs-' +  this.id_mensagens).classList.add('d-none');
                }
            },
            loading_before_comments(aplication){
                if(aplication === true){
                    document.getElementById('before_msgs-' + this.id_mensagens).children[0].classList.add('d-none');
                    document.getElementById('before_msgs-' + this.id_mensagens).children[1].classList.remove('d-none');
                }else{
                    document.getElementById('before_msgs-' + this.id_mensagens).children[0].classList.remove('d-none');
                    document.getElementById('before_msgs-' + this.id_mensagens).children[1].classList.add('d-none');
                }
            },
            alertaVazio(aplication){
                if(aplication === true){
                    document.getElementById('alerta_vazio-' + this.id_mensagens).classList.remove('d-none');
                }else{
                    document.getElementById('alerta_vazio-' + this.id_mensagens).classList.add('d-none');
                }
            },
            closeModalStatic(){
                $('#modal_mensagem_static-' + this.id_mensagens).modal('hide');
            }
        },
        created(){
            //Adicionando elemento bus_emit para retornar ser emitido para get_response
            this.getResponse();

            bus.$on('get_response-' + this.id_mensagens, (obj) => {
                this.loading(false);
                var msgs = [];

                if(obj.data.length > 0){
                    let i = 0;
                    let c;

                    if(this.after_msgs){
                        for(var msg in this.after_msgs){
                            msgs[i] = this.after_msgs[msg];
                            i++;
                        }
                    }

                    for(c = 0; c < obj.data.length; c++){
                        msgs[i] = obj.data[c];
                        i++;
                    }

                    this.alertaVazio(false);
                }else{
                    this.alertaVazio(true);
                }

                this.mensagens = msgs;

                this.paginate = obj;

                if(this.paginate.current_page < this.paginate.last_page){
                    this.beforeComments(true);
                }else{
                    this.beforeComments(false);
                }

                this.loading_before_comments(false);

                this.block = false;

                if(this.alertas === true){
                    this.verificarAlertas();
                }
            });

            bus.$on('get_msg-' + this.id_mensagens, (obj) => {
                this.id_msg = obj.id_mensagem;
                this.area_msg = obj.area;
                if(obj.area !== null){
                    this.area_detalhes_msg = obj.area_detalhes;
                    this.area_link_msg = obj.area_link;
                }

                this.alerta = obj.alerta;
                this.titulo_msg = obj.titulo;
                this.conteudo_msg = obj.conteudo;
                this.data_msg = obj.data_exibicao;
                this.usuario_msg = obj.nome_usuario;
                this.src_img = (obj.src_img ? this.path + '/' + obj.src_img : null);
                this.destinatarios = obj.destinatarios;

                if (parseInt(obj.alerta) === 1) {
                    setTimeout(() => {
                        $('#modal_mensagem_static-' + this.id_mensagens).modal('show');
                    }, 750);
                } else {
                    $('#modal_mensagem-' + this.id_mensagens).modal('show');
                }
            });

            bus.$on('refresh-' + this.id_mensagens, (obj) => {
                if(this.before_msgs === true){
                    this.after_msgs = '';
                }
                this.url.url = this.uri;
                this.population(this.url);
            });
        },
        mounted() {
            if(this.url){
                this.population(this.url);
            }
        },
        destroyed() {
            bus.$off('get_msg-' + this.id_mensagens);
            bus.$off('get_response-' + this.id_mensagens);
            bus.$off('refresh-' + this.id_mensagens);
        },
    }
</script>

<style scoped>

</style>
