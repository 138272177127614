<template>
    <form v-bind:id="id_form" method="post" :style="barra_scroll" :action="acao.url">

        <slot></slot>

            <div class="row justify-content-end">
                <div class="col">
                    <button v-if="submit" type="button" v-on:click="requestForm()" class="btn btn-primary float-right ml-2">Confirmar</button>

                    <div class="float-right">
                        <slot name="botoes"></slot>
                    </div>
                </div>
            </div>
    </form>
</template>
<script>
    import {bus} from '../../app'
    export default {
        props:['id_form','acao','btn_form','submit','scroll','bus_on_submit'],
        data: function () {
            return {
                action: this.acao,
                barra_scroll: '',
            }
        },
        methods:{
            requestForm(){
                //Validações
                if(this.action.get_input){

                    for(var c = 0; c < this.action.get_input.length ;c++){
                        if(document.getElementById(this.action.get_input[c])){
                            if(document.getElementById(this.action.get_input[c]).hasAttribute('required') && document.getElementById(this.action.get_input[c]).value === ''){
                                this.$noty.error('Preencha os campos obrigatórios antes de prosseguir!',{
                                    timeout:4000
                                });

                                document.getElementById(this.action.get_input[c]).focus();
                                document.getElementById(this.action.get_input[c]).classList.add('is-invalid'); //Adiciona se estiver com problemas
                                return false;
                            }
                            document.getElementById(this.action.get_input[c]).classList.remove('is-invalid'); //Remove se estiver ok
                        }
                    }
                }

                if(this.$store.state.form.url){
                    this.action.url = this.$store.state.form.url;
                }

                if(this.$store.state.form.method){
                    this.action.method = this.$store.state.form.method;
                }

                bus.$emit('gerencia-request',this.action);
            }
        },
        created(){
            if(this.scroll){
                this.barra_scroll = 'height:' + this.scroll + 'vh;overflow-y: auto;'
            }

            if(this.bus_on_submit){
                bus.$on(this.bus_on_submit, (obj) => {
                    this.requestForm();
                });
            }
        }
    }
</script>

<style type="scss">

</style>
