<template>
    <div class="row">
        <div class="col" :id="'header-' + id_cards">
            <slot name="header"></slot>
        </div>

        <!-- PAGINATION -->
        <div v-if="paginate.last_page > 1 && !$slots.header" :id="'paginate-' + id_cards" class="col-12 pb-2">
            <div class="float-right">
                <paginate :id_paginate="id_cards" v-on:buscarPagina="pagina($event)"></paginate>
            </div>
        </div>
        <!-- END PAGINATION -->

        <div class="col-12" :id="'body-' + id_cards">
            <div v-for="(chamado,index) in listaRegistros" :key="id_cards + '-' + chamado.id_chamado" :class="'card ' + class_cards + ' ' + (chamado.status_chamado === 0 ? 'bg-lightgold' : '')" :id="'registro-' + id_cards + '-' + index">
                <div class="row">
                    <div class="col-lg-auto d-flex align-items-center justify-content-center">
                        <div class="h-100 text-center">
                            <div class="font-size-07 font-weight-bold" title="Número do chamado">{{ chamado.cod }}</div>
                            <i v-if="chamado.status_chamado === 0" data-feather="alert-octagon" class="mb-1 text-darkgold" style="width: 36px;height: 36px"></i>
                            <i v-else-if="chamado.status_chamado === 1" data-feather="alert-octagon" class="mb-1 text-info" style="width: 36px;height: 36px"></i>
                            <i v-else-if="chamado.status_chamado === 2" data-feather="alert-octagon" class="mb-1 text-primary" style="width: 36px;height: 36px"></i>
                            <i v-else-if="chamado.status_chamado === 3" data-feather="alert-octagon" class="mb-1 text-success" style="width: 36px;height: 36px"></i>
                            <i v-else-if="chamado.status_chamado === 9" data-feather="alert-octagon" class="mb-1 text-danger" style="width: 36px;height: 36px"></i>
                        </div>
                    </div>
                    <div class="col-lg pl-lg-0">
                        <div class="font-size-07 text-wrap" title="Elemento ou objeto relacionado ao Chamado"><span class="font-weight-light">{{ chamado.area_nome }}</span>:
                            {{ chamado.area_referencia }}
                        </div>
                        <div class="font-size-1 text-wrap mt-1">{{chamado.assunto_chamado}}</div>
                        <div class="pb-2 pb-lg-0">
                            <div v-if="chamado.tags" class="text-wrap d-block d-lg-inline mb-2 mb-lg-0">
                                <span v-for="tag in chamado.tags" :class="'badge mr-2 mb-1 font-weight-normal ' + tag.class" :style="(tag.color !== '' ? 'background-color: ' + tag.color : '')" :title="tag.name">
                                    <span v-if="tag.html_icon" v-html="tag.html_icon" class="mr-1 mr-lg-0 px-1"></span>
                                    <span class="d-inline d-lg-none">{{ tag.name }}</span>
                                </span>
                            </div>

                            <div class="d-block d-lg-inline" title="Status do Chamado">
                                <span v-if="chamado.status_chamado === 0" class="badge badge-pill badge-warning font-weight-normal">Pendente</span>
                                <span v-else-if="chamado.status_chamado === 1" class="badge badge-pill badge-info font-weight-normal">Em análise</span>
                                <span v-else-if="chamado.status_chamado === 2" class="badge badge-pill badge-primary font-weight-normal">Encaminhado</span>
                                <span v-else-if="chamado.status_chamado === 3" class="badge badge-pill badge-success font-weight-normal">Resolvido</span>
                                <span v-else-if="chamado.status_chamado === 9" class="badge badge-pill badge-danger font-weight-normal">Cancelado</span>

                                <span v-if="chamado.id_chamado_resolucao">
                                <span v-if="chamado.status_chamado_resolucao === 1">
                                    <i class="fas fa-arrow-right mx-1 text-lightgrey"></i>
                                    <span class="badge badge-pill badge-light border" title="Confirmações / Usuários Participantes">
                                        <i class="fas fa-user-friends text-lightgrey mr-1"></i><span class="text-muted"> {{chamado.n_confirmacao_usuarios_participantes }} / {{ chamado.n_usuarios_participantes }}</span>
                                    </span>
                                    <i class="fas fa-arrow-right mx-1 text-lightgrey"></i>
                                    <span class="badge badge-pill badge-light border border-success text-success font-weight-normal" title="Confirmação da alteração para RESOLVIDO foi solicitada">Resolvido</span>
                                </span>
                                <span
                                    v-else-if="chamado.status_chamado_resolucao === 3 && chamado.status_chamado !== 3 && chamado.status_chamado !== 9">
                                    <i class="fas fa-arrow-right mx-1 text-lightgrey"></i>
                                    <span
                                        class="badge badge-pill badge-light text-darkgold border border-warning font-weight-normal"
                                        title="Alteração para RESOLVIDO Protestada">
                                        <i class="fas fa-hand-paper text-color-animate-darkgold mr-1"></i> Protestado
                                    </span>
                                    <i class="fas fa-arrow-right mx-1 text-lightgrey"></i>
                                    <span
                                        class="badge badge-pill badge-light border border-success text-success font-weight-normal"
                                        title="Confirmação da alteração para RESOLVIDO foi solicitada">Resolvido</span>
                                </span>
                            </span></div>
                        </div>
                    </div>
                    <div class="col-lg-5 border-left-lg">
                        <div v-if="chamado.unidades" class="font-size-07 text-wrap" title="Unidades envolvidas"><i class="fas fa-university text-lightgrey mr-1"></i> {{chamado.unidades}}</div>
                        <div v-if="chamado.usuarios" class="font-size-07 text-wrap" title="Usuários envolvidos"><i class="fas fa-user-friends text-lightgrey mr-1"></i> {{chamado.usuarios}}</div>
                        <div v-if="chamado.fornecedores" class="font-size-07 text-wrap" title="Fornecedores envolvidos"><i class="fas fa-handshake text-lightgrey mr-1"></i> {{chamado.fornecedores}}</div>
                        <div v-if="chamado.anexos" class="font-size-07 text-wrap" title="Anexos"><i class="fas fa-paperclip text-lightgrey mr-1"></i>{{chamado.anexos}}</div>
                    </div>
                    <div class="col-lg-auto">
                        <button class="btn btn-light border border-white h-100 w-100" :id="'btn_acessar-' + id_cards + '-' + index" title="Acessar"
                                v-on:click="getChamado(chamado)"
                        >
                            <i class="mdi mdi-exit-to-app"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- LOADING -->
        <div v-show="listaRegistros.length == 0" class="p-2 text-center col-12 d-none" :id="'loading-' + id_cards" style="border-radius: 0.5rem">
            <div style="margin-left: 42%">
                <hollow-dots-spinner
                    :animation-duration="1000"
                    :dot-size="10"
                    :dots-num="3"
                    color="#6F787D"
                />
            </div>
        </div>

        <div v-if="alert" class="col-12 mt-2">
            <div class="alert alert-warning" role="alert">Nenhum chamado encontrado!</div>
        </div>

        <!-- PAGINATION -->
        <div :id="'paginate-' + id_cards + '-2'" class="col-12">
            <div class="float-right pt-2">
                <paginate :id_paginate="id_cards + '-2'" v-on:buscarPagina="pagina($event)"></paginate>
            </div>
        </div>


    </div>
</template>

<script>
import {bus} from '../../app';
import { HollowDotsSpinner } from 'epic-spinners';
export default {
    components: {HollowDotsSpinner},
    props:[
        'id_cards',
        'class_cards',
        'card',
        'acao',
        'validate',
        'request',
        'break',
        'path',
        'acesso'
    ],
    data: function () {
        return {
            url: this.request,
            uri: this.request.url,
            listaRegistros:[],
            paginate: '',
            action: this.acao,
            alert:false,
        }
    },
    methods: {
        population(url) {
            this.loading(true);
            bus.$emit('gerencia-request', url);
        },
        pagina(url){
            this.url.url = url;
            this.population(this.url);
        },
        clearPaginate(){
            this.url.url = this.uri;
        },
        getChamado(registro){
            this.$noty.info('redirecionando...',{layout: 'center'});
            window.location.href = '/chamados/' + registro.id_chamado;
        },
        loading(aplication){
            if(aplication === true){
                document.getElementById('loading-' +  this.id_cards).classList.remove('d-none');
            }else{
                document.getElementById('loading-' +  this.id_cards).classList.add('d-none');
            }
        },
        featherIcons(){setTimeout(function (){feather.replace();},200)},
    },
    created() {
        //Adicionando elemento bus_emit para retornar ser emitido para get_response
        if(this.url.bus_emit){
            this.url.bus_emit.push('get_response-' + this.id_cards);
        }else{
            this.url.bus_emit = ['get_response-' + this.id_cards];
        }

        this.$store.state.request[this.id_cards] = this.url;
        this.$store.state.request[this.id_cards].uri = this.uri;

        bus.$on('get_response-' + this.id_cards, (obj) => {
            this.listaRegistros = obj.data;

            if (this.busca || this.filtrar) {
                //Enviando informações ao componente busca
                bus.$emit('get-' + this.busca, obj);
            }

            //Verificando se algum registro foi encontrado
            this.alert = this.listaRegistros.length <= 0;

            //Enviando informações ao componente busca
            bus.$emit('get_response-busca-' + this.id_cards, obj);

            //Configurando Paginate
            bus.$emit('set_paginate-' + this.id_cards, obj);
            bus.$emit('set_paginate-' + this.id_cards + '-2', obj);
            if (obj.last_page > 1) {
                this.paginate = obj;
            } else {
                this.paginate = '';
                if (obj.current_page > 1) {
                    this.pagina(obj.prev_page_url);
                    return false;
                }
            }
            this.loading(false);
            this.featherIcons();
        });

        bus.$on('refresh-' + this.id_cards, (url) => {
            if(url && url.url){
                this.$store.state.request[this.id_cards] = url;
                this.population(this.$store.state.request[this.id_cards]);
            }else{
                this.population(this.$store.state.request[this.id_cards]);
            }
        });

        bus.$on('pagina-' + this.id_cards, (obj) => {
            this.pagina(obj);
        });

        bus.$on('clear-' + this.id_cards, (url) => {
            this.listaRegistros = [];
        });

        bus.$on('set_request-' + this.id_cards, (obj) => {
            if(obj.validate){
                this.listaRegistros = [];
                this.paginate = [];
                this.clearPaginate();
                return false;
            }else if(obj.valor === null){
                delete this.$store.state.request[this.id_cards].data[obj.chave];
            }else{
                this.$store.state.request[this.id_cards].data[obj.chave] = obj.valor;
            }
            this.clearPaginate();
            this.population(this.$store.state.request[this.id_cards]);
        });

    },
    mounted() {
        if(this.request && !this.break){
            this.population(this.url);
        }
    },
    destroyed() {
        bus.$off('refresh-' + this.id_cards);
        bus.$off('get_response-' + this.id_cards);
        bus.$off('set_request-' + this.id_cards);
        bus.$off('pagina-' + this.id_cards);
    },
}
</script>

<style scoped>

</style>
