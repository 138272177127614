<template>
    <div class="row chat-wrapper">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row position-relative">


                        <div class="col-12 chat-content">
                            <div class="chat-header border-bottom pb-2">
                                <div class="d-flex justify-content-between">
                                    <div class="d-flex align-items-center">

                                    </div>
                                </div>
                            </div>

                            <div class="chat-body">
                                <ul class="messages">

                                    <li class="message-item friend">
                                        <img src="" class="img-xs rounded-circle" alt="avatar">
                                        <div class="content">
                                            <div class="message">
                                                <div class="bubble">
                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                </div>
                                                <span>8:12 PM</span>
                                            </div>
                                        </div>
                                    </li>

                                    <li class="message-item me">
                                        <img src="" class="img-xs rounded-circle" alt="avatar">
                                        <div class="content">
                                            <div class="message">
                                                <div class="bubble">
                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry printing and typesetting industry.</p>
                                                </div>
                                            </div>
                                            <div class="message">
                                                <div class="bubble">
                                                    <p>Lorem Ipsum.</p>
                                                </div>
                                                <span>8:13 PM</span>
                                            </div>
                                        </div>
                                    </li>

                                </ul>
                            </div>

                            <div class="chat-footer d-flex">
                                <form class="search-form flex-grow mr-2">
                                    <div class="input-group">
                                        <input type="text" class="form-control rounded-pill" id="chatForm" placeholder="Digite sua mensagem">
                                    </div>
                                </form>
                                <div>
                                    <button type="button" class="btn btn-primary btn-icon rounded-circle">
                                        <i class="far fa-paper-plane"></i>
                                    </button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Comentarios-2"
    }
</script>

<style scoped>

</style>
