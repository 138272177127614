<template>

    <div :class="'collapse ' + class_colapse" :id="id_collapse">

        <slot></slot>

        <!-- TOGGLE -->
        <button class="d-none" :id="'btn-' + id_collapse" type="button" data-toggle="collapse" :data-target="'#' + id_collapse" aria-expanded="false" :aria-controls="id_collapse">
            Toggle
        </button>
        <!-- END TOGGLE -->
    </div>

</template>

<script>
    import {bus} from '../../app';
    export default {
        props:['id_collapse','class_colapse','bus_on','btn_voltar'],
        created(){
            //Verificando obj bus_on
            if(this.bus_on){

                //Verificando a existencia do bus_on.hide para esconder o componente
                if(this.bus_on.hide){
                    bus.$on(this.bus_on.hide,(obj) => {
                        let coll = document.getElementById(this.id_collapse);
                        coll.classList.remove("show");
                    });
                }

                //Verificando a existencia do bus_on.show para exibir o componente
                if(this.bus_on.show){
                    bus.$on(this.bus_on.show,(obj) => {
                        let coll = document.getElementById(this.id_collapse);
                        coll.classList.add("show");
                    });
                }

                //Verificando a existencia do bus_on.toggle para transições do componente
                if(this.bus_on.toggle){
                    bus.$on(this.bus_on.toggle,(obj) => {
                        let botao = document.getElementById('btn-' + this.id_collapse);
                        botao.click();
                    });
                }

            }
        }
    }
</script>

<style scoped>

</style>