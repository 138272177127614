<template>
    <animated-number
        :value="value"
        :formatValue="formatTo"
        :duration="duration"
    />
</template>

<script>
import AnimatedNumber from "animated-number-vue";

export default {
    components: {
        AnimatedNumber
    },
    props:[
        'number','duration','prefix'
    ],
    data() {
        return {
            value: this.number
        };
    },
    methods: {
        formatTo(value) {
            return `${value.toLocaleString()}`;
        }
    }
};
</script>

<style scoped>

</style>
