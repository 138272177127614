<template>
    <div>
        <form id="form-export" target="_blank" :action="path" method="post" hidden>
            <input type="hidden" name="_token" :value="token" />

            <input v-for="(att,key) in atributos" type="text" :name="key" :value="att" hidden/>
        </form>

        <form id="form-download" :action="path_download" method="post" hidden>
            <input type="hidden" name="_token" :value="token" />

            <input v-for="(att,key) in atributos" type="text" :name="key" :value="att" hidden/>
        </form>

        <modal-3
            id_modal="modal_view_img"
            class_modal="modal-xg shadow"
        >
            <div class="row py-2">
                <div class="col text-wrap">
                    <h6>{{ img.nome_arquivo }}</h6>
                    <div v-if="img.marks" class="my-1" v-html="img.marks"></div>
                    <div v-if="img.detalhes_arquivo" class="my-1 text-justify" v-html="img.detalhes_arquivo"></div>
                </div>
                <div class="col-auto d-flex justify-content-end align-items-end">
                    <button class="btn btn-light btn-icon ml-3 rounded-circle" title="Baixar" v-on:click="downloadFile()">
                        <i class="mdi mdi-download icon-sm"></i>
                    </button>

                    <button v-if="img.delete" class="btn btn-light btn-icon ml-3 text-hover-danger rounded-circle" title="Excluir" v-on:click="deleteFile(img)">
                        <i class="far fa-trash-alt text-inherit"></i>
                    </button>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-center" style="width: 100% !important;">
                <img :src="img.path" style="max-width: 100%">
            </div>
        </modal-3>

        <!--  MODAL CONFIRM ALERT  -->
        <div class="modal fade" id="modal_confirm_alert" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div :class="'modal-content shadow ' + confirm_alert.class">
                    <div class="modal-body">
                        <div v-html="confirm_alert.content"></div>

                        <div v-if="confirm_alert.options" class="mt-3">
                             <div v-for="option in confirm_alert.options" class="mb-2 cursor-pointer text-hover-underline" :class="{'text-primary ':!option.class}" v-on:click="option.event">
                                 {{option.label}}
                             </div>
                        </div>

                        <div class="row pt-3">
                            <div v-if="confirm_alert.decline" class="col">
                                <button :class="'btn btn-sm btn-block ' + (confirm_alert.decline_class ? confirm_alert.decline_class : 'btn-light')" data-toggle="modal" data-target="#modal_confirm_alert" v-on:click="declineConfirmAlert()">
                                    Não
                                </button>
                            </div>
                            <div v-if="this.confirm_alert.accept" class="col">
                                <button :class="'btn btn-sm btn-block ' + (confirm_alert.accept_class ? confirm_alert.accept_class : 'btn-light')" data-toggle="modal" data-target="#modal_confirm_alert" v-on:click="acceptConfirmAlert()">
                                    Sim
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {bus} from '../../app';
    import { jsPDF } from "jspdf";
    export default {
        name: "Export",
        props:['path','path_download','gestor','token'],
        data: function () {
            return {
                atributos: {},
                confirm_alert:{},
                img:{},
            }
        },
        methods:{
            busemit(emit,obj){bus.$emit(emit,obj)},
            deleteFile(file){
                file.request_delete.bus_emit.push('hidde_modal-modal_view_img');
                file.request_delete.loading = ['page'];
                bus.$emit('gerencia-request',file.request_delete);
            },
            downloadFile(){
                document.getElementById('form-download').submit();
            },
            getInput(obj){
                var i = 0;
                for (i = 0; i < obj.get_input.length; i++) {
                    if (typeof obj.get_input[i] === 'string' && document.getElementById(obj.get_input[i])) {
                        if (document.getElementById(obj.get_input[i]).hasAttribute('required') && document.getElementById(obj.get_input[i]).value === '' && !document.getElementById(obj.get_input[i]).hasAttribute('hidden')) {
                            this.$noty.error('Preencha os campos obrigatórios antes de prosseguir!', {timeout: 4000});
                            document.getElementById(obj.get_input[i]).focus();
                            document.getElementById(obj.get_input[i]).classList.add('is-invalid');
                            setTimeout(() => {
                                document.getElementById(obj.get_input[i]).classList.remove('is-invalid');
                            }, 3000);
                            this.end_loading(obj);
                            return false;
                        }
                        obj[obj.get_input[i]] = document.getElementById(obj.get_input[i]).value || '';

                    } else if (typeof obj.get_input[i] === 'object' && document.getElementById(obj.get_input[i].id)) {
                        let el = document.getElementById(obj.get_input[i].id);
                        obj[obj.get_input[i].chave] = (el ? el.value : null);
                    }
                }
                delete obj.get_input;
                return obj;
            },
            getRadio(obj){
                var i = 0;
                for (i = 0; i < obj.get_radio.length; i++) {
                    obj[obj.get_radio[i]] = document.querySelector('input[name=' + obj.get_radio[i]  + ']:checked').value;
                }
                return obj;
            },
            getStore(obj){
                var i = 0;
                for (i = 0; i < obj.get_store.length; i++) {
                    if(typeof obj.get_store[i] === 'string'){
                        obj[obj.get_store[i]] = this.$store.state.registro[obj.get_store[i]];
                    }else{
                        obj[obj.get_store[i].chave] = this.$store.state[obj.get_store[i].state][obj.get_store[i].chave];
                    }
                }
                return obj;
            },
            getState(obj){
                var i = 0;
                for (i = 0; i < obj.get_state.length; i++) {
                    obj[obj.get_state.data[i]] = this.$store.state[obj.get_state.state][obj.get_state.data[i]];
                }
                return obj;
            },
            declineConfirmAlert(){
                if(this.confirm_alert.decline){
                    this.confirm_alert.decline();
                }
                this.confirm_alert = {};
            },
            acceptConfirmAlert(){
                if(this.confirm_alert.accept){
                    this.confirm_alert.accept();
                }
                this.confirm_alert = {};
            },
            downloadPDF(obj){
                const doc = new jsPDF({
                    orientation: obj.orientation || "l",
                });
                const width = doc.internal.pageSize.getWidth();
                const height = doc.internal.pageSize.getHeight();
                const halfwidth = width / 2;

                //Montar cabeçalho com as informações do gestor
                doc.setFontSize(8);
                var logo = new Image();
                logo.src = this.gestor.src_logo;
                doc.addImage(logo, 'JPG', 10, 5, 10, 10);
                doc.text(this.gestor.nome, width - 10, 8,{align:'right'});
                doc.setFont('Helvetica','italic');
                let date = new Date();
                date = date.getDate().toString().padStart(2, "0") + '/' + (date.getMonth() + 1).toString().padStart(2, "0") + '/' + date.getFullYear() + ' às ' + date.getHours().toString().padStart(2, "0") + ':' + date.getMinutes().toString().padStart(2, "0") + ':' + date.getSeconds().toString().padStart(2, "0");
                doc.text('Relatório extraído em ' + date + ' no endereço eletrônico ' + window.location.origin, 10, height - 10,{align:'left'});


                //Montar corpo do documento
                doc.setFont('Helvetica','normal');
                doc.setFontSize(11);
                doc.text(obj.text, halfwidth, 10,{align:'center'});

                if(obj.canvas && obj.canvas.length > 0){
                    for (let canv in obj.canvas){
                        let newCanvas = document.querySelector('#' + obj.canvas[canv]);

                        //create image from dummy canvas
                        let newCanvasImg = newCanvas.toDataURL("image/png", 1.0);

                        let imgW = width * 0.95;
                        let imgH = newCanvas.height * (imgW / newCanvas.width); //Ajustando altura

                        let marginX = (width - imgW) / 2;
                        let marginY = (height - imgH) / 2;

                        // doc.addImage(newCanvasImg, 'PNG', 10, 10, newCanvasImg.innerWidth, newCanvasImg.innerHeight,'imagem','NONE');

                        doc.addImage(newCanvasImg, 'PNG', marginX, marginY, imgW, imgH);
                    }
                }

                doc.save("exportMBS.pdf");
            }
        },
        created() {
            bus.$on('confirm-alert', (obj) => {
                this.confirm_alert = obj;
                $('#modal_confirm_alert').modal('show');
            });

            bus.$on('form-export', (obj) => {
                this.atributos = obj
                //Verificando função get_input
                if(obj.get_input){this.atributos = this.getInput(this.atributos);}
                if(obj.get_radio){this.atributos = this.getRadio(this.atributos);}
                if(obj.get_store){this.atributos = this.getStore(this.atributos);}
                if(obj.get_state){this.atributos = this.getState(this.atributos);}

                setTimeout(function () {
                    document.getElementById('form-export').submit();
                },250)
            });

            bus.$on('form-download', (obj) => {
                this.atributos = obj
                setTimeout(function () {
                    document.getElementById('form-download').submit();
                },250)
            });

            bus.$on('show-img', (obj) => {
                this.img = obj;
                this.atributos = obj;
                bus.$emit('show_modal-modal_view_img');
            });

            bus.$on('download-pdf', (obj) => {
                this.downloadPDF(obj);
            });
        },
        mounted() {
            $('#modal_confirm_alert').on("hidden.bs.modal", () => {
                if(this.confirm_alert.decline){
                    this.confirm_alert.decline();
                }
                this.confirm_alert = {};
            })
        }
    }
</script>

<style scoped>

</style>
