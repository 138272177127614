<template>
    <div :id="id_anexo">
        <div v-if="display_images" class="d-flex justify-content-center align-items-center">
            <transition-group name="slide-fade">
                <div v-for="img in images" :key="img.id_anexo + '-img'" class="d-inline-flex dropdown mx-2 my-3 position-relative">
                    <div v-if="marks" class="position-absolute" style="top: -20px;left: 0" v-html="getMarks(img)"></div>
                    <img :src="uri + '/storage/' + img.path + '/' + img.id_anexo" :id="img.id_anexo + '-img'" class="cursor-pointer border shadow-sm hover-zoom-10 image-anexo"
                    v-on:click="viewImg(img)" style="max-width: 120px;max-height: 120px">
                </div>

            </transition-group>
        </div>

        <div v-if="!display_files_inline">
            <transition-group name="slide-fade">
                <div v-for="(anexo,index) in anexos" :key="anexo.id_anexo" class="dropdown py-1 text-left p-0" v-show="!display_images || (display_images && !filterExtensionImg(anexo.formato))">
                    <a :id="'anexo-' + anexo.id_anexo" :key="anexo.id_anexo + '-' + index"
                       class="cursor-pointer text-hover-primary text-hover-underline dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span class="mr-2" v-html="iconeAnexo(anexo.formato)"></span><span v-html="anexo.nome_arquivo"></span>
                        <span v-if="anexo.n_assinaturas > 0" class="ml-2" title="Documento assinado eletrônicamente">
                            <i class="fas fa-file-signature text-primary"></i>
                        </span>
                        <span v-if="marks" class="ml-2" v-html="getMarks(anexo)"></span>
                    </a>
                    <div v-if="data_request_download || request_delete" class="dropdown-menu shadow rounded"
                         :aria-labelledby="'anexo-' + anexo.id_anexo">

                        <a v-if="anexo.assinar || permitir_assinatura || anexo.n_assinaturas > 0" class="dropdown-item text-hover-primary cursor-pointer" v-on:click="assinar(anexo.id_anexo)">
                            <i class="fas fa-file-signature text-inherit"></i> Assinatura</a>

                        <a v-if="data_request_download" class="dropdown-item text-hover-primary cursor-pointer"
                           v-on:click="downloadFile(anexo)"><i class="fas fa-download text-inherit"></i> Baixar</a>

                        <a v-if="request_delete" class="dropdown-item text-hover-danger cursor-pointer" v-on:click="deleteFile(anexo)">
                            <i class="far fa-trash-alt text-inherit"></i> Excluir</a>
                    </div>
                </div>
            </transition-group>
            <div v-if="!carregando && anexos.length === 0" class="alert alert-warning">
                Nenhum arquivo encontrado!
            </div>
            <!-- LOADING -->
            <div v-if="carregando" class="p-2 text-center col-12 d-flex align-items-center justify-content-center">
                <hollow-dots-spinner
                    :animation-duration="1000"
                    :dot-size="10"
                    :dots-num="3"
                    color="#6F787D"
                />
            </div>
        </div>
        <div v-else-if="display_files_inline">
            <div class="row" v-show="!display_images || (display_images && !filterExtensionImg(anexo.formato))">
                <div class="col-auto" v-for="(anexo,index) in anexos" :key="anexo.id_anexo">
                    <div class="dropdown py-1 text-left p-0">
                        <a :id="'anexo-' + anexo.id_anexo" :key="anexo.id_anexo + '-' + index"
                           class="cursor-pointer text-hover-primary text-hover-underline dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                           :title="anexo.nome_arquivo"
                        >
                            <span class="mr-2" v-html="iconeAnexo(anexo.formato)"></span><span v-html="limitText(anexo.nome_arquivo,17 )"></span>
                            <span v-if="marks" class="ml-2" v-html="getMarks(anexo)"></span>
                        </a>
                        <div v-if="data_request_download || request_delete" class="dropdown-menu shadow rounded"
                             :aria-labelledby="'anexo-' + anexo.id_anexo">
                            <a v-if="anexo.assinar || permitir_assinatura || anexo.n_assinaturas > 0" class="dropdown-item text-hover-primary cursor-pointer" v-on:click="assinar(anexo.id_anexo)">
                                <i class="fas fa-file-signature text-inherit"></i> Assinatura</a>

                            <a v-if="data_request_download" class="dropdown-item text-hover-primary cursor-pointer"
                               v-on:click="downloadFile(anexo)"><i class="mdi mdi-download icon-sm"></i> Baixar</a>

                            <a v-if="request_delete" class="dropdown-item text-hover-danger cursor-pointer" v-on:click="deleteFile(anexo)">
                                <i class="far fa-trash-alt text-inherit"></i> Excluir</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
    import {bus} from '../../app';
    import { HollowDotsSpinner } from 'epic-spinners';
    export default {
        components: {HollowDotsSpinner},
        props:['id_anexo','request','timeout','break','marks','request_delete','data_request_download','display_images','display_files_inline','permitir_assinatura'],
        data() {
            return {
                url: this.request,
                uri:this.request.path,
                anexos: {},
                carregando:true,
                images:{},
            }
        },
        methods:{
            population(){
                this.carregando = true;
                bus.$emit('gerencia-request',this.url);
            },
            downloadFile(anexo){
                let obj = this.data_request_download;
                obj.id_anexo = anexo.id_anexo
                bus.$emit('action', {download:obj});
            },
            deleteFile(anexo){
                var obj = this.request_delete;
                obj.confirm_alert = {content:'Tem certeza que deseja remover o anexo?',class:'border-danger',btn:'btn btn-sm btn-block btn-danger'};
                obj.data.id_anexo = anexo.id_anexo;
                if(obj.bus_emit){obj.bus_emit.push('refresh-' + this.id_anexo);}else{obj.bus_emit = ['refresh-' + this.id_anexo];}
                obj.loading = ['add_spin'];
                obj.id_loading = 'anexo-' + anexo.id_anexo;
                bus.$emit('gerencia-request',obj);
            },
            getMarks(anexo){
                var response = '';
                for (var mark in this.marks){
                    if(anexo[mark]){
                        response+= '<span class=\'mr-2\'>' + this.marks[mark] + '</span>';
                    }
                }
                return response;
            },
            limitText(string,limit){
                if(string.length <= limit){
                    return string;
                }else{
                    return string.substring(0,limit) + '...';
                }
            },
            iconeAnexo(format){
                switch (format) {
                    case 'jpg':
                    case 'JPG':
                    case 'jpeg':
                    case 'JPEG':
                    case 'bmp':
                    case 'png':
                    case 'PNG':
                        return '<i class="far fa-file-image" style="color: #3498db"></i>';
                    case 'doc':
                    case 'docx':
                        return '<i class="far fa-file-word text-primary"></i>';
                    case 'txt':
                        return '<i class="far fa-file-alt"></i>';
                    case 'pdf':
                        return '<i class="far fa-file-pdf text-danger"></i>';
                    case 'xls':
                    case 'xlsx':
                        return '<i class="fas fa-file-excel text-success"></i>';
                    case 'zip':
                    case 'rar':
                        return '<i class="far fa-file-archive text-muted"></i>';
                    default:
                        return '<i class="far fa-file"></i>';
                }
            },
            getResponse(){
                if(this.url.bus_emit){
                    this.url.bus_emit.push('get_response-' + this.id_anexo);
                }else{
                    this.url.bus_emit = ['get_response-' + this.id_anexo];
                }
            },
            busemit(emit,obj){bus.$emit(emit,obj)},
            assinar(id_anexo){
                this.$emit('assinar',{id_anexo:id_anexo})
            },
            filterExtensionImg(extension){
                switch (extension) {
                    case 'jpg':
                    case 'JPG':
                    case 'jpeg':
                    case 'JPEG':
                    case 'bmp':
                    case 'png':
                    case 'PNG':
                        return true;
                    default:
                        return false;
                }
            },
            viewImg(img){
                let obj = {};
                let del = false;
                if(this.request_delete){
                    obj = this.request_delete;
                    del = true;
                    obj.confirm_alert = {content:'Tem certeza que deseja remover o anexo?',class:'border-danger',btn:'btn btn-sm btn-block btn-danger'};
                    obj.data.id_anexo = img.id_anexo;
                    if(obj.bus_emit){obj.bus_emit.push('refresh-' + this.id_anexo);}else{obj.bus_emit = ['refresh-' + this.id_anexo];}
                    obj.loading = ['add_spin'];
                    obj.id_loading = 'anexo-' + img.id_anexo;
                }
                let imgview = {
                    path:this.uri + '/storage/' + img.path + '/' + img.id_anexo,
                    nome_arquivo: img.nome_arquivo,
                    detalhes_arquivo:img.detalhes,
                    id_anexo:img.id_anexo,
                    formato:img.formato,
                    data_criacao:img.data_criacao,
                    delete: img.delete || del,
                    request_delete:obj
                }
                if(this.marks){imgview.marks = this.getMarks(img);}

                for(let dados in this.data_request_download){imgview[dados] = this.data_request_download[dados];}

                bus.$emit('show-img',imgview);
            }
        },
        computed: {
            anexos_: {
                get: function () {
                    return this.anexos;
                },
                set: function (obj) {
                    this.anexos = obj;
                    if(this.display_images){
                        var img = [];
                        for (let anexo in this.anexos) {if(this.filterExtensionImg(this.anexos[anexo].formato)){img.push(this.anexos[anexo]);}}
                        this.images = img;
                    }
                }
            }
        },
        created() {
            if(this.request){
                this.getResponse();

                bus.$on('get_response-' + this.id_anexo, (obj) => {
                    this.anexos_ = obj;
                    this.carregando = false;
                    this.$emit('emit_anexos',obj);
                });

                bus.$on('refresh-' + this.id_anexo, (obj) => {this.population();});
                bus.$on('reset-' + this.id_anexo, (obj) => {
                    this.anexos_ = {};
                    this.population();
                });
            }
        },
        mounted() {
            if(this.request && !this.break){
                if(this.timeout){
                    setTimeout(() => {
                        this.population();
                    },this.timeout);
                }else{
                    this.population();
                }
            }
        },
        destroyed() {
            bus.$off('get_response-' + this.id_anexo);
            bus.$off('refresh-' + this.id_anexo);
            bus.$off('reset-' + this.id_anexo);
        }
    }
</script>

<style scoped>
    .image-anexo{
        opacity: 0.8;
    }
    .image-anexo:hover{
        opacity: 1;
    }
</style>
