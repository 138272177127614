<template>
    <form autocomplete="off" v-on:submit.prevent :title="title">
        <multiselect
            id="id"
            v-model="value"
            :options="options"
            :label="text"
            :track-by="chave"
            :placeholder="placeholder"
            :preselectFirst="preselect_first"
            tag-placeholder="Adicione uma nova tag"
            :multiple="true"
            :taggable="true"
            :searchable="busca"
            :allow-empty="false"
            :disabled="isDisabled"
            @input="onChange"
        ></multiselect>
    </form>
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
    // OR register locally
    components: { Multiselect },
    props:['id','placeholder','busca','opcoes','text','chave','title','valor','preselect_first'],
    data () {
        return {
            value: this.valor,
            options: this.opcoes,
            isDisabled: false,
        }
    },
    methods: {
        onChange (value) {
            this.$emit('set_value',this.value);
        },
        onSelect (option) {

        },
        onTouch () {

        }
    }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>

</style>
