<template>
    <div>
        <div v-if="!realocar_item" class="row">
            <div class="col">
                <h5>
                    ADICIONAR ITEM
                    <span v-if="modalidade === 8"> PARA <span class="text-info">CREDENCIAMENTO</span></span>
                    <span v-if="modalidade === 9"> PARA <span class="text-info">CHAMADA PÚBLICA</span></span>
                </h5>
                <div v-if="modalidade === 8" class="text-muted">Após finalizar o registro, você poderá informar os fornecedores credenciados.</div>
                <div v-if="modalidade === 9" class="text-muted">Após finalizar o registro, você poderá informar os fornecedores.</div>
            </div>
            <div v-if="$slots.cabecalho" class="col d-none d-lg-block">
                <slot name="cabecalho">

                </slot>
            </div>
        </div>
        <h5 v-else class="text-darkgold">
            REALOCAR ITEM
        </h5>
        <form autocomplete="off" @submit.prevent class="mt-2">
            <div class="form-row py-3">
                <div class="col-lg-3" v-if="processo.lotes">
                    <div class="input-group">
                        <div class="input-group-prepend">
                        <span class="input-group-text"
                        >Lote</span>
                        </div>
                        <input :id="id_form + '-lote'" type="number" class="form-control font-weight-bold" placeholder="Número" autocomplete="off"
                               v-model="lote"
                               v-on:keydown.enter="focusId(id_form + '-numero')"
                               :readonly="d_descricao"
                        >
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="input-group">
                        <div class="input-group-prepend">
                        <span class="input-group-text"
                        >Item</span>
                        </div>
                        <input :id="id_form + '-numero'" type="number" class="form-control font-weight-bold" placeholder="Número" autocomplete="off"
                               v-model="ordem"
                               v-on:keydown.enter="setNumero"
                               :readonly="d_descricao"
                        >
                    </div>
                </div>
                <div class="col-lg-auto">
                    <button :key="id_form + '-btn_confirm_numero'" title="Confirmar número do item" v-if="ordem && ordem.length > 0 && d_descricao === false" class="h-100 btn btn-primary" v-on:click="setNumero" :id="id_form + '-btn_confirm_numero'">
                        Confirmar
                    </button>
                    <button :key="id_form + '-btn_editar_numero'" v-else-if="d_descricao === true" class="h-100 btn btn-outline-secondary" v-on:click="ordem = null;disabledItem();focusid(id_form + '-numero')" :id="id_form + '-btn_confirm_numero'" title="Editar número do item">
                        Editar
                    </button>
                </div>
            </div>
        </form>
        <div v-if="d_descricao" class="form-row">
            <div v-if="realocar_item === false" class="col-12">
                <!--=================================Item===================================-->
                <select-3
                        :id_select="id_form + '-busca_item_add'"
                        class_select="font-size-08"
                        placeholder_select="Descrição"
                        placeholder_dropdown="<div class='alert alert-info p-1 text-center m-0'>Digite uma palavra para encontrarmos o item.</div>"
                        :required="true"
                        :text_area="true"
                        title_select=""
                        options="descricao"
                        chave_value="id_item"
                        state="item"
                        set_store="id_item"
                        text_edit="Editar"
                        :request="{
                            url:uri+'/itens',
                            data:{
                                function:'get_itens',
                            },
                        }"

                        v-on:change_store="setItem($event)"
                >
                    <div slot="empty">
                        <button type="button" class="btn btn-link px-0 mt-2 border-0 font-weight-bold"
                                v-on:click="busemit('show_modal-modal_novo_item',{}); focusid('descricao_item');"
                        >Registrar novo item</button>
                    </div>
                </select-3>
            </div>
            <div v-else class="col-12">
                <div  class="input-group">
                    <textarea type="text" class="form-control font-size-08" v-model="this.descricao"   autocomplete="off" disabled></textarea>
                </div>
            </div>
        </div>

        <div v-if="cota && (modalidade === 1 || modalidade === 2)" class="form-row pt-3">
            <div class="col-lg">
                <div v-if="cota" class="input-group">
                    <div class="form-control bg-lightgrey d-flex align-items-center">
                        <div v-if="cota == null" class="font-size-08"> AMPLA PARTICIPAÇÃO</div>
                        <div v-if="cota == 1" class="font-size-08"><span class='badge badge-pill badge-dark border border-dark font-size-06' title='Licitação Exclusiva'>E</span> Licitação EXCLUSIVA</div>
                        <div v-else-if="cota == 2" class="font-size-08"><span class='badge badge-pill badge-secondary border border-secondary text-warning font-size-06' title='Cota Reservada'>R</span> Cota RESERVADA</div>
                        <div v-else-if="cota == 3" class="font-size-08"><span class='badge badge-pill badge-secondary border border-secondary font-size-06' title='Cota Principal'>P</span> Cota PRINCIPAL</div>
                    </div>
                    <div v-if="!realocar_item" class="input-group-append">
                      <button class="btn btn-outline-secondary" type="button" v-on:click="editCota()">Editar</button>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="d_lista_cotas_reg || d_select_cotas" class="pt-3">
            <div class="card p-3">
                <div class="font-size-sm-06" v-html="text_select_cota"></div>
                <div v-if="d_lista_cotas_reg">
                    <table class="table mb-0 mt-2">
                        <thead>
                        <tr>
                            <th v-if="id_contrato" scope="col" class="p-1 font-size-06 text-muted border-bottom-0 border-top-0">Contrato</th>
                            <th v-if="processo.lotes" scope="col" class="p-1 font-size-06 text-muted border-bottom-0 border-top-0">Lote</th>
                            <th scope="col" class="p-1 font-size-06 text-muted border-bottom-0 border-top-0">Item</th>
                            <th scope="col" class="text-right p-1 font-size-06 text-muted border-bottom-0 border-top-0 d-none d-lg-table-cell" width="10%">Valor unitário</th>
                            <th scope="col" class="text-right p-1 font-size-06 text-muted border-bottom-0 border-top-0 d-none d-lg-table-cell" width="10%">Qtd</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="item in list_cotas_reg">
                            <td v-if="id_contrato" class="font-size-07 font-weight-bold p-1 text-left text-wrap">
                                <strong>{{item.identificador_contrato}}</strong> -
                                <span>{{item.razao_social}}</span>
                            </td>
                            <td v-if="processo.lotes" class="font-size-07 p-1" style="width: 4%">
                                {{ item.lote }}
                            </td>
                            <td class="font-size-07 p-1">
                                <span v-if="item.cota == 1" class='badge badge-pill badge-dark border border-dark font-size-06' title='Licitação Exclusiva'>E</span>
                                <span v-if="item.cota == 2" class='badge badge-pill badge-secondary border border-secondary text-warning font-size-06' title='Cota Reservada'>R</span>
                                <span v-if="item.cota == 3" class='badge badge-pill badge-secondary border border-secondary font-size-06' title='Cota Principal'>P</span>
                                {{ item.ordem }} - {{item.descricao}}
                            </td>

                            <td class="text-right p-1 d-none d-lg-table-cell">
                                <input v-if="modalidade === 8" class="font-size-06 form-control-plaintext p-0 text-right" disabled  v-money="{decimal:',',thousands: '.',precision:4}"  :value="parseFloat(item.vlr_unit).toFixed(4)">
                                <input v-else class="font-size-06 form-control-plaintext p-0 text-right" disabled  v-money="{decimal:',',thousands: '.',precision:4}"  :value="parseFloat(item.valor_contratado).toFixed(4)">

                            </td>

                            <td class="font-size-06 font-weight-bold p-1 text-right d-none d-lg-table-cell">
                                <input class="font-weight-bold font-size-07 form-control-plaintext p-0 text-right" disabled  v-money="{decimal:',',thousands: '.',precision:0}" :value="item.qtd">
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div v-if="d_select_cotas && (modalidade === 1 || modalidade === 2)" class="mt-2">
                    <div class='text-muted font-size-08 mb-2'>Como está definido o item no Pregão? Selecione uma das opções para seguir.</div>
                    <div class="row">
                        <div v-if="d_cota_exclusiva" class="col-lg pb-2 pb-lg-0">
                            <button class="btn btn-block btn-outline-primary h-100"
                                    :id="id_form + 'btn-set_cota-e'"
                                    v-on:click="setCota(null)"
                                    v-on:keydown.right.prevent.self="focusId(id_form + 'btn-set_cota-p')"
                            >
                                AMPLA Participação
                            </button>
                        </div>
                        <div v-if="d_cota_exclusiva" class="col-lg pb-2 pb-lg-0">
                            <button class="btn btn-block btn-outline-dark h-100"
                                    :id="id_form + 'btn-set_cota-e'"
                                    v-on:click="setCota(1)"
                                    v-on:keydown.right.prevent.self="focusId(id_form + 'btn-set_cota-p')"
                            >
                                Licitação EXCLUSIVA
                            </button>
                        </div>
                        <div v-if="d_cota_principal" class="col-lg pb-2 pb-lg-0">
                            <button class="btn btn-block btn-outline-secondary h-100"
                                    :id="id_form + 'btn-set_cota-p'"
                                    v-on:click="setCota(3)"
                                    v-on:keydown.right.prevent.self="focusId(id_form + 'btn-set_cota-r')"
                                    v-on:keydown.left.prevent.self="focusId(id_form + 'btn-set_cota-e')"
                            >
                                Cota PRINCIPAL
                            </button>
                        </div>
                        <div v-if="d_cota_reservada" class="col-lg pb-2 pb-lg-0">
                            <button class="btn btn-block btn-outline-secondary h-100"
                                    :id="id_form + 'btn-set_cota-r'"
                                    v-on:click="setCota(2)"
                                    v-on:keydown.left.prevent.self="focusId(id_form + 'btn-set_cota-p')"
                            >
                                Cota RESERVADA
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="id_contrato" class="mt-5">
            <h5 class="text-left mb-3">Dados de Fornecimento</h5>
            <div class="input-group" title="Quantidade Contratada">
                <div class="input-group-prepend">
                    <span class="input-group-text">Contrato</span>
                </div>
                <div class="form-control bg-lightgrey p-1 d-flex align-items-center align-center"><strong>{{identificador_contrato}}</strong> -
                    {{razao_social}}
                </div>
                <div v-if="!contrato" class="input-group-append">
                    <button class="btn btn-outline-secondary" type="button" v-on:click="editContrato()">Editar</button>
                </div>
            </div>
        </div>

        <div v-if="d_select_contratos" class="pt-3">
            <div class="card border-primary p-3 shadow">
                <div class='text-muted font-size-08 mb-2'>Selecione o contrato relacionado ao item.</div>
<!--                <tabela-4-->
<!--                        :id_table="id_form + '-tabela-unidades'"-->
<!--                        class_table="table-sm"-->
<!--                        class_td="py-1"-->
<!--                        :busca="id_form + '-busca_descricao_unidade'"-->
<!--                        class_busca="col-lg-3"-->
<!--                        placeholder_buscar="Nome"-->

<!--                        n_linha="#"-->
<!--                        :cabecalho="{-->
<!--                            1:{chave:'identificador',titulo:'Contrato',class_th:'',class_td:'font-weight-bold'},-->
<!--                            2:{chave:'razao_social',titulo:'Razão Social',class_td:'text-left',class_th:'text-left'},-->
<!--                        }"-->

<!--                        :registros="[]"-->

<!--                        :request="{-->
<!--                            url:uri + '/contratos',-->
<!--                            data:{-->
<!--                                function:'list_contratos',-->
<!--                                id_processo:this.id_processo,-->
<!--                                paginate:10-->
<!--                            }-->
<!--                        }"-->

<!--                        :acao="{-->
<!--                            button:{-->
<!--                                classes:'float-right',-->
<!--                                options:[-->
<!--                                    {-->
<!--                                        titulo:'Selecionar',-->
<!--                                        title:'Confirmar Unidade relacionada',-->
<!--                                        classes:'btn btn-primary p-1 font-size-07',-->
<!--                                        emit:this.id_form + '-get_response_contrato',-->
<!--                                    },-->
<!--                                ]-->
<!--                            },-->
<!--                        }"-->

<!--                        style_table="font-size: 0.7rem"-->
<!--                        class_thead="text-muted font-size-06"-->
<!--                >-->

<!--                </tabela-4>-->
            </div>
        </div>

        <form autocomplete="off" v-on:submit.prevent>
        <div v-if="d_valores" class="form-row pt-3">
            <div v-if="modalidade === 8" class="pb-2 text-muted col-12">
                Valores totais e demais características do item disponibilizado para credenciamento.
            </div>
            <div v-if="modalidade === 9" class="pb-2 text-muted col-12">
                Quantidades total do item disponibilizados para a Chamada Pública.
            </div>
            <div class="col-lg-6 pb-3 pb-lg-0">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text" title="Quantidade Contratada">Unidade de Medida</span>
                    </div>
                    <input v-if="!realocar_item" :id="id_form + '-unidade_medida'" type="text" class="form-control text-left text-uppercase"
                           v-model="unidade_medida"

                           v-on:keydown.enter.prevent.self="focusId(id_form + '-qtd')"
                           v-on:keydown.right.prevent.self="focusId(id_form + '-qtd')"
                           :readonly="realocar_item"
                    >
                </div>
            </div>
            <div class="col-lg-6 pb-3">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span v-if="!realocar_item" class="input-group-text" title="Quantidade Contratada">Quantidade</span>
                        <span v-else class="input-group-text" title="Saldo para realocação">Saldo</span>
                    </div>
                    <input v-if="!realocar_item" :id="id_form + '-qtd'" type="text" class="form-control text-right" placeholder="Qtd"
                           v-model="qtd_"
                           v-money="{decimal:',',thousands: '.',precision:0}"
                           v-on:keydown.left.prevent.self="focusId(id_form + '-unidade_medida')"
                           v-on:keydown.enter.prevent.self="focusId(id_form + '-vlr_unit')"
                           v-on:keydown.right.prevent.self="focusId(id_form + '-vlr_unit')"
                    >
                    <input v-else type="text" class="form-control text-right"
                           v-model="qtd_"
                           v-money="{decimal:',',thousands: '.',precision:0}"
                           disabled
                    >
                </div>
            </div>

            <!--     ==================== Os valores da Chamada pública não serão definidos no registro do item       -->
            <div v-if="status === 1 && modalidade !== 9" class="col-lg-6 pb-3">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text"
                        >Valor Unit</span>
                    </div>
                    <input :id="id_form + '-vlr_unit'" type="text" class="form-control text-right" placeholder="Valor Unit"
                           v-model="vlr_unit_"
                           v-money="{decimal:',',thousands: '.',precision:4}"
                           v-on:keydown.enter.prevent.self="focusId(id_form + '-fabril')"
                           v-on:keydown.left.prevent.self="focusId(id_form + '-qtd')"
                           v-on:keydown.right.down.prevent.self="focusId(id_form + '-fabril')"
                    >
                </div>
            </div>

            <!--     ==================== Os valores da Chamada pública não serão definidos no registro do item       -->
            <div v-if="status === 1 && modalidade !== 9" class="col-lg-6 pb-3">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text"
                        >Valor</span>
                    </div>
                    <input :id="id_form + '-vlr'" type="text" class="form-control text-right disabled font-weight-bold"
                           :value="(parseFloat(this.vlr_unit) * parseFloat(this.qtd)).toFixed(2)"
                           v-money="{decimal:',',thousands: '.',precision:2}"
                           disabled
                    >
                </div>
            </div>

            <div v-if="status === 1 && ( parseFloat(vlr) > 0 || (modalidade === 9 && this.qtd > 0))" class="col-12 pt-3">
                <div class="form-row">
                    <!--  Não é necessário adicionar a marca no registro inicial do item para Credenciamento e Chamada Pública      -->
                    <div v-if="modalidade !== 8 && modalidade !== 9" class="col-lg-6 pb-2 pb-lg-0">
                        <div class="input-group">
                            <div class="input-group-prepend">
                        <span class="input-group-text">Marca</span>
                            </div>
                            <input :id="id_form + '-fabril'" v-model="fabril" type="text" class="form-control text-right"
                                   v-on:keydown.enter.prevent.self="focusId(id_form + '-apresentacao')"
                                   v-on:keydown.left.up.prevent.self="focusId(id_form + '-vlr_unit')"
                                   v-on:keydown.right.prevent.self="focusId(id_form + '-apresentacao')"
                            >
                        </div>
                    </div>
                    <div class="col-lg-6 pb-2 pb-lg-0">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">Apresentação</span>
                            </div>
                            <input :id="id_form + '-apresentacao'" v-model="apresentacao" type="text" class="form-control text-right"
                                   v-on:keydown.enter.prevent.self="focusId(id_form + '-fator_emb')"
                                   v-on:keydown.left.prevent.self="focusId(id_form + '-fabril')"
                                   v-on:keydown.right.prevent.self="focusId(id_form + '-fator_emb')"
                                   v-on:keydown.up.prevent.self="focusId(id_form + '-vlr_unit')"
                            >
                        </div>
                        <small v-if="modalidade === 8" class="pb-2 text-muted">
                            * Como o item irá ser apresentado. Ex.: DIÁRIA, HORA...
                        </small>
                        <small v-if="modalidade === 9" class="pb-2 text-muted">
                            * Como o item irá ser apresentado. Ex.: UNIDADE, KG ,DIÁRIA...
                        </small>
                    </div>
                </div>
                <!--  Não é necessário adicionar o fator embalagem no registro inicial do item para Credenciamento e Chamada Pública      -->
                <div v-if="modalidade !== 8 && modalidade !== 9" class="form-row pt-3">
                    <div class="col-lg-3 pb-2 pb-lg-0">
                        <div class="input-group">
                            <div class="input-group-prepend">
                        <span class="input-group-text">Fator Emb</span>
                            </div>
                            <input :id="id_form + '-fator_emb'" v-model="fator_emb" type="number" class="form-control text-right"
                                   v-on:keydown.enter.prevent.self="focusId(id_form + 'btn-registrar_item')"
                                   v-on:keydown.left.prevent.self="focusId(id_form + '-apresentacao')"
                                   v-on:keydown.right.prevent.self="focusId(id_form + 'btn-registrar_item')"
                                   v-on:keydown.up.prevent.self="focusId(id_form + '-apresentacao')"
                            >
                        </div>
                    </div>
                </div>

                <button v-if="!realocar_item" :id="id_form + 'btn-registrar_item'" class="btn btn-primary btn-block mt-4" v-on:click="register()">Registrar</button>
                <button v-else :id="id_form + 'btn-registrar_item'" class="btn btn-primary btn-block mt-4" v-on:click="realocar()">Realocar</button>
            </div>
            <!--      Para itens não Homologados      -->
            <div v-else-if="status !== 1 && parseInt(qtd) > 0" class="col-12 pt-3">
                <button :id="id_form + 'btn-registrar_item'" class="btn btn-primary btn-block mt-4" v-on:click="register()">Registrar</button>
            </div>

        </div>
        </form>

        <div class="container pt-3">
            <div class="text-center" v-html="atividade"></div>

            <div v-if="opcoesExtras" class="pt-3">
                <div class="text-muted text-left font-size-08">Voçê deseja realizar alguma alteração no item?</div>

                <button v-if="btn_realocar_item" class="btn btn-link font-size-08 btn-block px-0 text-left"
                    v-on:click="realocarItem()"
                >
                    Realocar item no Contrato atual.
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import {bus} from "../../app";

    export default {
        props:['id_form','uri','contrato','modalidade','processo'],
        data: function () {
            return {
                registro:null,
                lote:null,
                ordem:null,
                id_item:null,
                id_processo:this.$store.state.processo.id_processo,
                id_contrato:null,
                d_select_contratos:false,
                razao_social:null,
                identificador_contrato:null,
                status:null,
                d_select_status:false,
                text_select_status:null,
                descricao:null,
                d_descricao:false,
                cota:null,
                d_select_cotas:false,
                d_cota_exclusiva:false,
                d_cota_reservada:false,
                d_cota_principal:false,
                d_lista_cotas_reg:false,
                list_cotas_reg:null,
                text_select_cota:null,
                d_valores:false,
                vlr_unit:null,
                vlr:null,
                qtd:null,
                apresentacao:null,
                fator_emb:1,
                fabril:null,
                unidade_medida:null,
                atividade:null,
                waiting_request:false,
                btn_registrar:false,
                opcoesExtras:false,
                btn_realocar_item:false,
                realocar_item:false,
            }
        },
        methods:{
            setNumero(){
                this.disabledItem();
                if(this.processo.lotes && (!this.lote || this.lote === '')){
                    this.atividade = "<span class='text-danger font-size-09'>Informe o número do lote para prosseguir.</span>";
                    return false;
                }
                if(!this.ordem){return false;}
                this.atividade = "<span class='text-color-pulse-primary font-size-09'>Verificando número do item...</span>";
                if(this.waiting_request === true){return false;}
                this.waiting_request = true;
                //Verificando número do item no processo
                let dados = {
                    function:'get_ordem_item_processo',
                    id_processo:this.$store.state.processo.id_processo,
                    ordem:parseInt(this.ordem),
                };

                if(this.processo.lotes){
                    dados.lote = this.lote;
                }

                bus.$emit('gerencia-request', {
                    url:this.uri + '/processo_itens',
                    data:dados,
                    bus_emit:[this.id_form + '-get_response_ordem'],
                    loading:['add_spin'],
                    id_loading:this.id_form + '-btn_confirm_numero'
                });
            },
            setItem(registro){
                if(!this.$store.state.item.id_item){
                    this.disabledCotas();
                    this.atividade = "<div class='text-muted font-size-08 mt-5 mb-2'>Insira o descritivo do item que deseja registrar. Utilize a campo de descrição para encontrar os registros disponíveis no sistema.</div>";
                    return false;
                }
                this.registro = registro;
                this.id_item = registro.value || registro.id_item;
                this.descricao = registro.descricao;
                this.atividade = "<span class='text-color-pulse-primary font-size-09'>Verificando item...</span>";
                if(this.waiting_request === true){return false;}
                this.waiting_request = true;
                //Verificar item no processo
                bus.$emit('gerencia-request', {
                    url:this.uri + '/processo_itens',
                    data:{
                        function:'get_id_item_processo',
                        id_processo:this.$store.state.processo.id_processo,
                        id_item:this.id_item,
                    },
                    bus_emit:[this.id_form + '-get_response_id_item'],
                    loading:['page']
                });
            },
            verificarItens(itens){
                if(this.modalidade === 1 || this.modalidade === 2){//Pregão
                    if(itens.length === 1){
                        if(parseInt(itens[0].cota) === 1 || itens[0].cota === null){
                            this.text_select_cota = "<span class='text-danger font-size-08 mb-2'>Você não pode registrar esse item! Ele já foi registrado como: " + (parseInt(itens[0].cota) === 1 ? "<strong>Licitação EXCLUSIVA</strong>.</span>" : "<strong>Ampla PARTICIPAÇÃO</strong>.</span>");
                        }else if(parseInt(itens[0].cota) === 2){
                            this.text_select_cota = "<span class='text-darkgold font-size-08 mb-2'>Este item já foi registrado como <strong>Cota RESERVADA</strong>.</span>";
                            this.d_cota_principal = true;
                            this.d_select_cotas = true;
                        }else if(parseInt(itens[0].cota) === 3){
                            this.text_select_cota = "<span class='text-darkgold font-size-08 mb-2'>Este item já foi registrado como <strong>Cota PRINCIPAL</strong>.</span>";
                            this.d_cota_reservada = true;
                            this.d_select_cotas = true;
                        }
                        this.listaAlertaCotas(itens);
                    }else if(itens.length === 2){
                        this.atividade = "<span class='text-danger font-size-09 mb-2'>Este item já foi registrado no processo mais de uma vez (Cotas).</span>";
                        this.listaAlertaCotas(itens);
                    }
                    this.focusBtnCotas();
                }else{
                    if(itens.length > 0){
                        this.atividade = "<span class='text-danger font-size-09 mb-2'>Este item já foi registrado no processo!</span>";
                        this.listaAlertaCotas(itens);
                    }else{
                        this.setCota(null);
                    }
                }
            },
            disabledItem(){
                this.atividade = "<span class='text-muted font-size-08 mb-2'>Informe o número identificador do item no processo/contrato que deseja inserir.</span>";
                this.registro = null;
                this.id_item = null;
                this.descricao = null;
                this.d_descricao = false;
                this.id_fornecedor = null;
                this.$store.state.item.id_item = null;
                this.realocar_item = false;
                this.disabledCotas();
            },
            setCota(val){
                this.cota = val;
                this.disabledSelectCotas();
                this.setStatus(1);
                //this.displaySelectStatus();
            },
            editCota(){
                this.disabledCotas();
                this.setItem(this.registro);
            },
            displayTodasOpcoesDeCotas(){
                this.d_select_cotas = true;
                this.d_cota_exclusiva = true;
                this.d_cota_reservada = true;
                this.d_cota_principal = true;
                this.focusBtnCotas();
            },
            disabledCotas(){
                this.cota = null;
                this.disabledStatus();
                this.disabledSelectCotas();
            },
            disabledSelectCotas(){
                this.d_select_cotas = false;
                this.d_cota_exclusiva = false;
                this.d_cota_reservada = false;
                this.d_cota_principal = false;
                this.text_select_cota = null;
                this.d_lista_cotas_reg = false;
                this.list_cotas_reg = null;
                this.disableOpcoesExtras();
            },
            listaAlertaCotas(itens){
                this.list_cotas_reg = itens;
                this.d_lista_cotas_reg = true;
            },
            setStatus(val){
                this.status = val;
                this.d_select_status = false;
                if(this.contrato){
                    this.setContrato(this.contrato);
                }else if(this.modalidade === 8 || this.modalidade === 9){ //Modalidade Credenciamento
                    this.d_valores = true;
                }
            },
            displaySelectStatus(){
                this.d_select_status = true;
                bus.$emit('action', {focus_id:this.id_form + 'btn-set_status-h'});
            },
            disabledStatus(){
                this.status = null;
                this.d_select_status = false;
                this.disableContrato();
            },
            editStatus(){
                this.status = null;
                this.d_select_status = true;
                this.disableContrato();
            },
            selectContrato(){
                this.d_select_contratos = true;
            },
            setContrato(id_contrato){
                this.atividade = "<span class='text-color-pulse-primary font-size-09'>Inserindo contrato...</span>";
                bus.$emit('gerencia-request', {
                    url:this.uri + '/contratos',
                    data:{
                        function:'get_contrato',
                        id_contrato:id_contrato,
                    },
                    bus_emit:[this.id_form + '-get_response_contrato'],
                });
            },
            editContrato(){
                this.disableContrato();
                this.d_select_contratos = true;
            },
            disableContrato(){
                this.id_contrato = null;
                this.identificador_contrato = null;
                this.razao_social = null;
                this.d_select_contratos = false;
                this.disabledValores();
            },
            setValor(){
                this.vlr = (parseFloat(this.vlr_unit) * parseFloat(this.qtd)).toFixed(2);
            },
            floatDB(vlr){
                if(!vlr){return false;}
                var parts = vlr.replace(/\./g,'');
                parts = parts.split(",");
                for(var c = 0;c < parts.length; c++){
                    parts[c] = parts[c].replace(/\B(?=(\d{5})+(?!\d))/g, ",");
                }
                parts = parts.join(".");
                parts = parts.replace(',','');
                return parts;
            },
            integerDB(vlr){
                if(!vlr){return false;}
                var parts = vlr.split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{5})+(?!\d))/g, "");
                return parts.join("");
            },
            disabledValores(){
                this.vlr_unit = null;
                this.vlr = null;
                this.qtd = null;
                this.apresentacao = null;
                this.fabril = null;
                this.fator_emb = 1;
                this.d_valores = false;
                this.disableOpcoesExtras();
            },
            validarDadosItem(){
                if(!this.id_processo){return false;}
                if(!this.ordem){return false;}
                if(!this.id_item){return false;}
                if(!this.unidade_medida){return false;}
                //if(!this.cota){return false;}
                if(!this.status){return false;}
                if(this.status === 1){
                    if(this.contrato && !this.id_contrato){return false;}
                    if(!this.qtd){return false;}
                    if(!this.vlr_unit && this.modalidade !== 9){return false;} //Valor unitário não será exigido inicialmente para a modalidade credenciamento
                }
                if(this.processo.lotes){ //Caso o processo seja configurado em lotes
                    if(!this.lote || this.lote === 0 || this.lote === ''){
                        return false;
                    }
                }
                return true;
            },
            register(){
                this.atividade = null;
                if(this.validarDadosItem() === false || this.waiting_request === true){
                    console.log('Não é possível registrar o item');
                    this.atividade = "<span class='text-danger font-size-08 mb-2'>Não é possível registrar o item! Verifique se todas as informações foram registradas corretamente.</span>";;
                    return false;
                }

                bus.$emit('gerencia-request', {
                    url:this.uri + '/processo_itens',
                    data:{
                        function:'store_item',
                        id_processo:this.id_processo,
                        lote:this.lote,
                        ordem:this.ordem,
                        id_item:this.id_item,
                        unidade_medida:this.unidade_medida,
                        cota:this.cota,
                        status_item_compra:this.status,
                        id_contrato:this.id_contrato,
                        qtd:this.qtd,
                        vlr_unit:this.vlr_unit,
                        fabril:this.fabril,
                        apresentacao:this.apresentacao,
                        fator_emb:parseInt(this.fator_emb),
                        modalidade:this.modalidade
                    },
                    bus_emit:[this.id_form + '-get_response_register','refresh-tabela-itens_contrato','refresh-tabela-objeto_processo_fase_2','refresh-processo','refresh-contrato'],
                    loading:['add_spin'],
                    id_loading:this.id_form + 'btn-registrar_item'
                });
            },
            focusId(id){
                if(document.getElementById(id)){document.getElementById(id).focus();}
            },
            focusBtnCotas(){
                setTimeout(() => {
                    if(document.getElementById(this.id_form + 'btn-set_cota-e')){document.getElementById(this.id_form + 'btn-set_cota-e').focus();}
                    else if(document.getElementById(this.id_form + 'btn-set_cota-p')){document.getElementById(this.id_form + 'btn-set_cota-p').focus();}
                    else if(document.getElementById(this.id_form + 'btn-set_cota-r')){document.getElementById(this.id_form + 'btn-set_cota-r').focus();}
                },250);
            },
            disableOpcoesExtras(){
                this.opcoesExtras = false;
                this.btn_realocar_item = false;
            },
            realocarItem(){
                this.realocar_item = true;
                this.atividade = null;
                this.disabledSelectCotas();

                this.id_item = this.registro.id_item;
                this.descricao = this.registro.descricao;
                this.d_descricao = true;
                this.qtd_ = this.registro.saldo;
                this.setCota(this.registro.cota);
                this.setStatus(this.registro.status_item_compra);

            },
            realocar(){
                if(this.validarDadosItem() === false || this.waiting_request === true){
                    console.log('Não é possível realocar o item');
                    return false;
                }

                bus.$emit('gerencia-request', {
                    url:this.uri + '/processo_itens',
                    data:{
                        function:'realocar_item',
                        id_processo:this.id_processo,
                        id_processo_item_compra:this.registro.id_processo_item_compra,
                        id_contrato:this.id_contrato,
                        qtd:this.qtd,
                        vlr_unit:this.vlr_unit,
                        fabril:this.fabril,
                        apresentacao:this.apresentacao,
                        fator_emb:parseInt(this.fator_emb)
                    },
                    bus_emit:[this.id_form + '-get_response_register','refresh-tabela-itens_contrato','refresh-tabela-objeto_processo_fase_2','refresh-processo','refresh-contrato'],
                    loading:['add_spin'],
                    id_loading:this.id_form + 'btn-registrar_item'
                });
            },
            busemit(emit,obj = {}){
                bus.$emit(emit,obj);
            },
            focusid(id){
                setTimeout( () => {
                    document.getElementById(id).focus();
                },750);
            }
        },
        computed: {
            vlr_unit_: {
                get: function() {
                    return this.vlr_unit;
                },
                set: function(val) {
                    this.vlr_unit = this.floatDB(val);
                    this.setValor();
                }
            },
            qtd_:{
                get: function() {
                    return this.qtd;
                },
                set: function(val) {
                    this.qtd = this.integerDB(val);
                    this.setValor();
                }
            }
        },
        created() {
            this.atividade = "<span class='text-muted font-size-08 mb-2'>Informe o número identificador do item no processo/contrato que deseja inserir.</span>";

            bus.$on(this.id_form + '-get_response_ordem', (obj) => {
                this.waiting_request = false;
                this.atividade = null;
                if(obj.length > 0){
                    this.registro = obj[0];
                    this.atividade = "<span class='text-danger font-size-08 mb-2 font-weight-bold'>Este item já foi registrado!</span>";
                    // this.text_select_cota = obj[0].descricao;
                    this.listaAlertaCotas(obj);
                    if(this.contrato && this.contrato !== this.registro.id_contrato){
                        this.opcoesExtras = true;
                        this.btn_realocar_item = true;
                    }
                }else{
                    this.d_descricao = true;
                    bus.$emit('action', {focus_id:this.id_form + '-busca_item_add'});
                }
            });

            bus.$on(this.id_form + '-get_response_id_item', (obj) => {
                this.waiting_request = false;
                this.atividade = null;
                if(obj.length > 0){
                    this.verificarItens(obj);
                }else{
                    if(this.modalidade === 1 || this.modalidade === 2){ //Pregão
                        this.disabledCotas();
                        this.displayTodasOpcoesDeCotas();
                    }else{
                        this.setCota(null);
                    }
                }
            });

            bus.$on(this.id_form + '-get_response_contrato', (obj) => {
                this.waiting_request = false;
                this.atividade = null;
                this.d_select_contratos = false;
                this.id_contrato = obj.id_contrato;
                this.identificador_contrato = obj.identificador;
                this.razao_social = obj.razao_social;
                this.d_valores = true;
                if(!this.realocar_item){
                    bus.$emit('action', {focus_id:this.id_form + '-unidade_medida'});
                }else{
                    bus.$emit('action', {focus_id:this.id_form + '-vlr_unit'});
                }
            });

            bus.$on(this.id_form + '-get_response_register', (obj) => {
                this.waiting_request = false;
                if(obj.status === 'success'){
                    this.atividade = "<span class='text-success font-size-08 mb-2'>O item foi adicionado! Você pode adiconar outro item.</span>";
                    this.disabledItem();
                    this.ordem = null;
                    bus.$emit('action', {focus_id:this.id_form + '-numero'});
                }
            });

            bus.$on(this.id_form + '-get_response_novo_item', (obj) => {
                this.waiting_request = false;
                bus.$emit('set-' + this.id_form + '-busca_item_add', {
                    value:obj.id_item,
                    readonly:true,
                    descricao:obj.descricao
                });
            });
        }
    }
</script>

<style scoped>

</style>
