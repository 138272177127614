<template>
    <div>
        <modal-3
                id_modal="modal_danfe"
                class_modal="modal-xg shadow"
        >
            <form autocomplete="off" v-on:submit.prevent="">
                <div class="row py-2">
                    <div class="col-auto d-flex align-items-center">
                        <h4 class="mb-3">
                            <span v-if="status_form === 'registro'" class="text-primary"><i class="fas fa-file-invoice-dollar mr-2"></i> NOVA NF</span>
                            <span v-if="status_form === 'consulta'"><i class="fas fa-file-invoice-dollar mr-2"></i> NOTA FISCAL</span>
                        </h4>
                    </div>
                    <div v-if="!edit_form && (tipo_usuario == 1 || (permissao_gestor && (permissao_gestor[13] == 2 || permissao_gestor[13] == 1)) || (permissao_unidade && (permissao_unidade[16] == 1 || permissao_unidade[16] == 2) && id_unidade_usuario == id_unidade))"
                         class="col d-flex align-items-center justify-content-end" v-bind:show="[featherIcons()]">

                        <button v-if="!show_anotacoes && (tipo_usuario == 1 || (permissao_gestor && (permissao_gestor[13] == 2 || permissao_gestor[13] == 1)))" :id="'btn-' + id_form + '-anotacoes'" class="btn btn-icon btn-rounded ml-3 position-relative d-none d-lg-inline btn-light" :class="{'bg-waterprimary':anotacoes && !show_anotacoes}"
                                v-on:click="showAnotacoes()"
                                title="Anotações"
                        >
                            <i data-feather="edit" style="width: 18px;height: 18px"></i>
                        </button>

                        <button :id="'btn-' + id_form + '-anexos'" :class="'btn btn-icon btn-rounded ml-3 position-relative d-none d-lg-inline ' + (nav === 'anexos' ? 'btn-primary' : 'btn-light')"
                                title="Arquivos e anexos"
                                v-on:click="(nav !== 'anexos' ? upload_anexos = false : ''); nav = 'anexos';"
                        ><i class="fas fa-paperclip"></i>
                            <span v-if="nav !== 'anexos' && n_anexos > 0" class="badge badge-pill badge-info position-absolute right top">{{n_anexos}}</span>
                        </button>

                        <button :id="'btn-' + id_form + '-chamados'" :class="'btn btn-icon btn-rounded ml-3 position-relative d-none d-lg-inline ' + (nav === 'chamados' ? 'btn-primary' : 'btn-light')"
                                title="Chamados"
                                v-on:click="nav = 'chamados';featherIcons()"
                        >
                            <i data-feather="alert-octagon" style="width: 18px;height: 18px"></i>
                            <span v-if="nav !== 'chamados' && n_chamados > 0" class="badge badge-pill badge-info position-absolute right top">{{n_chamados}}</span>
                        </button>

                        <button :id="'btn-' + id_form + '-historico'" :class="'btn btn-icon btn-rounded ml-3 d-none d-lg-inline ' + (nav === 'historico' ? 'btn-primary' : 'btn-light')"
                                title="Histórico da Nota Fiscal"
                                v-on:click="getHistoricoNF(); nav = 'historico';"
                        ><i class="fas fa-history"></i></button>

                        <dropdown
                            key="dropdown_opcoes_nf"
                            id_dropdown="dropdown_opcoes_nf"
                            :btn="{
                             class:'btn btn-icon btn-rounded ml-3 btn-light',
                             titulo:'<i class=\'fas fa-ellipsis-v\'></i>'
                        }"
                            :drop="{class:'shadow shadow-sm py-3',align:'dropleft'}"
                        >
                            <a v-if="tipo_usuario == 1 || (permissao_gestor && (permissao_gestor[13] == 2 || permissao_gestor[13] == 1))" class="dropdown-item d-lg-none cursor-pointer my-2"
                               v-on:click="showAnotacoes()"
                            >
                                <i data-feather="edit" style="width: 18px;height: 18px"></i> Anotações
                            </a>

                            <a class="dropdown-item d-lg-none cursor-pointer my-2" v-on:click="(nav !== 'anexos' ? upload_anexos = false : ''); nav = 'anexos';">
                                <i class="fas fa-paperclip mr-2"></i> Anexos
                            </a>

                            <a class="dropdown-item d-lg-none cursor-pointer my-2" v-on:click="nav = 'chamados';featherIcons()">
                                <i class="fas fa-exclamation-triangle"></i> Chamados
                            </a>

                            <a class="dropdown-item d-lg-none cursor-pointer my-2" v-on:click="getHistoricoNF(); nav = 'historico';">
                                <i class="fas fa-history"></i> Histórico
                            </a>

                            <a class="dropdown-item cursor-pointer my-2"
                               v-on:click="nav = 'nf';busemit('tour',{
                                        steps:[
                                            {target:'#content-modal_danfe',focus:['bg'],highlight:true,content:'Todas as informações e funcionalidades referentes a NF que estiver acessando estará disponível nessa janela.',params:{placement:'left'}},
                                            {target:'#' + id_form + '-info_fornecedor',highlight:true,header:'FORNECEDOR',content:'Aqui você poderá identificar a Razão Social e número da Nota Fiscal.',params:{placement:'bottom'}},
                                            {target:'#' + id_form + '-info_pedido',highlight:true,header:'ELEMENTOS RELACIONADOS',content:'Nessa área você poderá visualizar os elementos vinculados à NF (Contrato, Pedido, Ordem...).',params:{placement:'bottom'}},
                                            {target:'#' + id_form + '-info_destinatario',highlight:true,header:'UNIDADE REQUISITANTE/DESTINATÁRIA',content:'Aqui você poderá ver a unidade vinculada à Nota Fiscal.',params:{placement:'bottom'}},
                                            {target:'#' + id_form + '-info_status',highlight:true,header:'STATUS',content:'Visualize e edite o status atual da Nota Fiscal aqui.',params:{placement:'top'}},
                                            {target:'#' + id_form + '-info_data_emissao',highlight:true,header:'EMISSÃO',content:'Data de emissão da Nota Fiscal.',params:{placement:'top'}},
                                            {target:'#' + id_form + '-info_data_recebimento',highlight:true,header:'RECEBIMENTO',content:'Data de recebimento da Nota Fiscal.<br><div class=\'alert alert-warning\'>Só estará visível após a confirmação do recebimento.</div>',params:{placement:'top'}},
                                            {target:'#' + id_form + '-info_valor',highlight:true,header:'VALOR TOTAL',content:'Valor total da Nota Fiscal.',params:{placement:'top'}},
                                            {target:'#' + id_form + '-info_itens',highlight:true,header:'ITENS',content:'Nessa área você pode visualizar os itens e valores detalhados da NF.',params:{placement:'top'}},
                                            {target:'#btn-' + id_form + '-anexos',highlight:true,header:'ANEXOS',content:'Acesse e edite os anexos da NF clicando aqui.',action:[{click:'btn-' + id_form + '-anexos'}],params:{placement:'left'}},
                                            {target:'#btn-' + id_form + '-chamados',highlight:true,header:'CHAMADOS',content:'Visualize e abra chamados para a NF clicando aqui.',action:[{click:'btn-' + id_form + '-chamados'}],params:{placement:'left'}},
                                            {target:'#btn-' + id_form + '-historico',highlight:true,header:'HISTÓRICO',content:'Acompanhe todo o histórico de acesso e edição da Nota Fiscal clicando aqui.',action:[{click:'btn-' + id_form + '-historico'}],params:{placement:'left'}},
                                            {target:'#dropdown_opcoes_nf',highlight:true,header:'MAIS OPÇÕES',content:'Acesse mais funcionalidades da NF clicando nesse botão.',action:[{click:'btn-' + id_form + '-info_nf'}],params:{placement:'left'}},
                                        ]
                                    })"
                            >
                                GDN - Guia Dinâmico de Navegação
                            </a>

                            <a v-if="this.status_pedido !== 4 && this.status_nf < 4 && (tipo_usuario == 1 || permissao_gestor && permissao_gestor[13] == 2)" class="dropdown-item cursor-pointer text-hover-danger my-2" v-on:click="excluirNF()">
                                Excluir NF
                            </a>
                            <a v-else-if="this.status_pedido !== 4 && this.status_nf == 4 && (tipo_usuario == 1 || (permissao_gestor && permissao_gestor[13] == 2))" class="dropdown-item cursor-pointer text-lightgrey disabled my-2" disabled title="Essa ação não poderá ser executada enquanto a Nota Fiscal estiver com status 'Pago'">
                                Excluir NF
                            </a>
                            <a v-else-if="this.status_pedido === 4 && (tipo_usuario == 1 || (permissao_gestor && permissao_gestor[13] == 2))" class="dropdown-item cursor-pointer text-lightgrey disabled my-2"  title="Essa ação não poderá ser executada após a finalização do pedido relacionado.">
                                Excluir NF <small>(Essa ação não pode ser executada após a finalização do pedido.)</small>
                            </a>
                        </dropdown>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 pb-2">

                        <div v-if="permissao_unidade && permissao_unidade[16] == 2 && id_unidade_usuario == id_unidade && status_form === 'consulta' && parseInt(status_nf) === 1" class="px-0 pb-3">
                            <button class="btn btn-block btn-success text-center" v-on:click="modalConfirmRecebimento()">Confirmar recebimento <i class="fas fa-check"></i></button>
                        </div>

                        <!--        ANOTAÇÕES            -->
                        <div v-if="this.show_anotacoes && !edit_form && (tipo_usuario == 1 || (permissao_gestor && (permissao_gestor[13] == 1 || permissao_gestor[13] == 2)))" class="alert bg-waterprimary border shadow-sm">
                            <div class="row pb-1">
                                <div class="col text-dark font-size-07">
                                    Anotações da NF:
                                </div>
                                <div v-if="display_anotacoes === 'read'" class="col-auto text-right">
                                    <button v-if="tipo_usuario == 1 || permissao_gestor[13] == 2" class="btn btn-sm btn-link p-0 mr-2" v-on:click="display_anotacoes = 'edit'">Editar</button>
                                    <button class="btn btn-sm btn-link p-0" v-on:click="show_anotacoes = false">Ocultar</button>
                                </div>
                                <div v-else-if="display_anotacoes === 'edit'" class="col-auto text-right">
                                    <button class="btn btn-sm btn-link text-danger p-0" v-on:click="display_anotacoes = 'read';getNF(id_nf)">Cancelar</button>
                                </div>
                            </div>
                            <div v-if="display_anotacoes === 'read'" v-html="anotacoes" style="overflow-y: auto;overflow-x: hidden;max-height: 100px"></div>
                            <div v-else-if="display_anotacoes === 'edit'">
                                <editor
                                    v-model="anotacoes"
                                    model-events="change keydown blur focus paste"
                                    id="anotacoes_nf"
                                    key="anotacoes_nf"
                                    :init="{
                                     height: 180,
                                     menubar: false,
                                     language: 'pt_BR',
                                     toolbar:'undo redo | bold'
                                }"
                                >
                                </editor>

                                <button :id="'btn-' + id_form + '-update_anotacoes'" class="btn btn-primary btn-sm btn-block mt-3" v-on:click="updateAnotacoes()">Salvar</button>
                            </div>
                        </div>

                        <form autocomplete="off" v-on:submit.prevent>
                            <div class="form-row" :id="id_form + '-info_fornecedor'">
                                <div class="col-lg-9">
                                    <!--=================================FORNECEDOR===================================-->
                                    <select-3
                                        v-show="edit_form"
                                        :id_select="id_form + '-busca_razao_social'"
                                        class_select="text-uppercase"
                                        placeholder_select="Razão social ou CNPJ"
                                        placeholder_dropdown="<div class='alert alert-info p-1 text-center m-0'>Digite a razão social ou cnpj para iniciar a busca.</div>"
                                        :required="true"
                                        title_select=""
                                        options="razao_social"
                                        chave_value="id_fornecedor"
                                        state="nf"
                                        set_store="id_fornecedor"
                                        text_edit="Editar"
                                        :request="{
                                            url:url + '/gerenciar_request/fornecedores',
                                            get_state:{state:'nf',data:['id_fornecedor']},
                                            data:{
                                                fillable:['ac_fornecedor.id_fornecedor','ac_fornecedor.razao_social'],
                                                function:'get',
                                                paginate:10,
                                            },
                                        }"

                                        v-on:change_store="setFornecedor($event)"
                                    >
                                    </select-3>

                                    <div v-show="edit_form === false" class="form-control bg-lightgrey">
                                        {{razao_social}}
                                    </div>
                                </div>
                                <!--=================================NÚMERO NF===================================-->
                                <div class="col-lg-3">
                                    <div v-show="edit_form && campo_numero_nf === true" class="input-group mt-3 mt-lg-0">
                                        <input type="number" :id="id_form + '-numero_danfe'" class="form-control text-center" :aria-describedby="id_form + 'numero_nf_label'"
                                               v-on:change="setNumeroNF()"
                                               v-on:keyup.enter.prevent="setNumeroNF()"
                                               v-model="numero_nf"
                                               placeholder="Número da NF"
                                        >
                                        <div class="input-group-append">
                                            <button v-if="campo_numero_nf_confirm && numero_nf && (numero_nf != null || numero_nf != '')" class="btn btn-outline-secondary" type="button"
                                                    v-on:click="setNumeroNF()"
                                            >Confirmar</button>
                                            <button v-else-if="campo_numero_nf_edit" class="btn btn-outline-secondary" type="button"
                                                    v-on:click="editForm(true); editNumeroNF()"
                                            >Editar</button>

                                            <span v-else class="input-group-text" :id=" id_form + 'numero_nf_label'" v-html="campo_numero_nf_label"></span>
                                        </div>
                                    </div>

                                    <div v-show="edit_form === false" class="form-control bg-lightgrey text-left text-lg-center mt-3 mt-lg-0">
                                        {{numero_nf}}
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div v-if="nav !== 'nf'">
                            <button :id="'btn-' + id_form + '-info_nf'" class="btn btn-secondary btn-sm btn-block my-3" v-on:click="nav = 'nf'; getNF(id_nf)">Ver informações da Nota Fiscal</button>
                        </div>

                        <transition name="slide-down">
                            <div v-show="nav === 'nf'">
                                <!--=================================DADOS DO PEDIDO, ORDEM, CONTRATO===================================-->
                                <div>
                                    <div class="input-group mt-3" v-if="identificador_ordem || identificador_contrato || identificador_pedido" :id="id_form + '-info_pedido'">
                                        <div class="form-control" style="height: auto !important;">
                                            <div class="row">
                                                <div class="col-auto pr-4 my-1 my-lg-0" v-if="identificador_ordem" title="Ordem relacionada">
                                                    <div><i class="fas fa-file-export text-lightgrey"></i> {{identificador_ordem}}</div>
                                                </div>
                                                <div class="col-auto pr-4 my-1 my-lg-0" v-if="identificador_contrato" title="Contrato relacionado">
                                                    <div><i class="far fa-file-alt text-lightgrey"></i> {{identificador_contrato}}</div>
                                                </div>
                                                <div class="col-auto pr-4 my-1 my-lg-0" v-if="identificador_pedido" title="Pedido relacionado">
                                                    <div><i class="fas fa-cube text-lightgrey"></i> {{identificador_pedido}}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="input-group-append" v-if="edit_form && this.ordens && this.ordens.data.length > 1">
                                            <button class="btn btn-outline-secondary float-right" v-on:click="editOrdem()">Editar</button>
                                        </div>
                                    </div>

                                    <div v-show="tabela_ordens && edit_form" class="pt-3">
                                        <div class="card border-primary p-3 shadow">
                                            <h5 class="text-center text-primary">Identifique a Ordem relacionada a Nota Fiscal</h5>
                                            <div class="text-muted"><small>* Exibindo apenas ordens que possuam faturamento pendentes.</small></div>
                                            <tabela-6
                                                :id_table="id_form + '-tabela-ordens'"
                                                :key="id_form + '-tabela-ordens'"
                                                :break="true"
                                                :request="{
                                                url:url + '/gerenciar_request/ordens',
                                                get_state:{state:'nf',data:['id_fornecedor']},
                                                data:{
                                                    function:'list_ordens',
                                                    status_ordem:2, //Ordens autorizadas
                                                    paginate:10
                                                },
                                            }"
                                            >
                                                <template v-slot:header>
                                                    <busca-2
                                                        :set_componente="id_form + '-tabela-ordens'"
                                                        placeholder_busca="Número"
                                                        :busca="true"
                                                    ></busca-2>
                                                </template>
                                                <template v-slot:thead>
                                                    <th class="font-size-07 font-size-sm-05">ORDEM</th>
                                                    <th class="font-size-07 font-size-sm-05">CONTRATO</th>
                                                    <th class="font-size-07 font-size-sm-05" style="width: 5%"></th>
                                                </template>
                                                <template v-slot:body="ordem">
                                                    <td class="font-size-08 font-size-sm-05">
                                                        {{ordem.data.nome_tipo_ordem_abreviado}} <strong>{{ordem.data.identificador_ordem}}</strong>
                                                    </td>
                                                    <td class="font-size-08 font-size-sm-05">
                                                        {{ordem.data.nome_tipo_contrato_abreviado}} <strong>{{ordem.data.identificador_contrato}}</strong>
                                                    </td>
                                                    <td class="font-size-08 font-size-sm-05">
                                                        <button class="btn btn-tiny btn-primary"
                                                                v-on:click="busemit(id_form + '-get_ordem',{
                                                                id_ordem:ordem.data.id_ordem,
                                                                identificador_ordem:ordem.data.identificador_ordem,
                                                                identificador_pedido:ordem.data.identificador_pedido
                                                            })"
                                                        >
                                                            Selecionar
                                                        </button>
                                                    </td>
                                                </template>
                                            </tabela-6>
                                        </div>
                                    </div>
                                </div>

                                <!--=================================DESTINATÁRIO===================================-->
                                <div>
                                    <div class="input-group mt-3" v-if="id_unidade" :id="id_form + '-info_destinatario'">
                                        <div class="form-control" style="height: auto !important;">
                                            <div class="row">
                                                <div class="col-auto pr-4" v-if="id_unidade" title="Unidade requisitante / Destinatário">
                                                    <div>
                                                        <i class="fas fa-university text-lightgrey"></i> {{nome_unidade}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="input-group-append" v-if="edit_form && this.unidades.data && this.unidades.data.length > 1">
                                            <button class="btn btn-sm btn-outline-secondary float-right" v-on:click="editUnidade()">Editar</button>
                                        </div>
                                    </div>

                                    <div v-show="edit_form && tabela_unidades" class="pt-3">
                                        <div class="card border-primary p-3 shadow">
                                            <h5 class="text-center text-primary">Identifique a Unidade relacionada a Nota Fiscal</h5>
                                            <div class="text-muted"><small>* Exibindo apenas unidades que possuam faturamento pendentes.</small></div>

                                            <tabela-6
                                                :id_table="id_form + '-tabela-unidades'"
                                                :key="id_form + '-tabela-unidades'"
                                                :break="true"
                                                :request="{
                                                url:url + '/gerenciar_request/ordens',
                                                get_state:{state:'nf',data:['id_ordem']},
                                                data:{
                                                    function:'list_unidades_ordem',
                                                    status_pedido_unidade_item:2,
                                                    pendentes:true,
                                                    paginate:10
                                                },
                                                bus_emit:[id_form + '-get_response_unidades'],
                                                loading:['page']
                                            }"
                                            >
                                                <template v-slot:header>
                                                    <busca-2
                                                        :set_componente="id_form + '-tabela-unidades'"
                                                        placeholder_busca="Nome"
                                                        :busca="true"
                                                    ></busca-2>
                                                </template>
                                                <template v-slot:thead>
                                                    <th class="font-size-07 font-size-sm-05">NOME</th>
                                                    <th class="font-size-07 font-size-sm-05" style="width: 5%">

                                                    </th>
                                                </template>
                                                <template v-slot:body="unidade">
                                                    <td class="font-size-08 font-size-sm-05">
                                                        {{unidade.data.nome_unidade}}
                                                    </td>
                                                    <td class="font-size-08 font-size-sm-05">
                                                        <button class="btn btn-tiny btn-primary"
                                                                v-on:click="busemit(id_form + '-get_unidade',{
                                                                id_unidade:unidade.data.id_unidade,
                                                                nome_unidade:unidade.data.nome_unidade
                                                            })"
                                                        >
                                                            Selecionar
                                                        </button>
                                                    </td>
                                                </template>
                                            </tabela-6>
                                        </div>
                                    </div>
                                </div>

                                <!--=================================DADOS DA NF (STATUS, VALOR, DATA EMISSÃO===================================-->
                                <div class="form-row pt-3" v-if="valor_nf > 0">
                                    <div class="col-lg">
                                        <div v-show="status_nf" :id="id_form + '-info_status'">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <label class="input-group-text text-muted">
                                                        <span class="font-size-07">Status</span>
                                                    </label>
                                                </div>
                                                <select v-if="tipo_usuario == 1 || (permissao_gestor && permissao_gestor[13] == 2)" :class="'text-dark form-control ' + status_nf_format" v-model="status_nf">
                                                    <option value="1" v-if="tipo_usuario == 1 || (permissao_gestor && permissao_gestor[13] == 2)">Faturado</option>
                                                    <option value="2">Entregue</option>
                                                    <option value="3" v-if="tipo_usuario == 1 || (permissao_gestor && permissao_gestor[13] == 2)">À pagar</option>
                                                    <option value="4" v-if="tipo_usuario == 1 || (permissao_gestor && permissao_gestor[13] == 2)">Pago</option>
                                                </select>
                                                <div v-else :class="'text-dark form-control ' + status_nf_format">
                                                    <span v-if="status_nf == 1">Faturado</span>
                                                    <span v-else-if="status_nf == 2">Entregue</span>
                                                    <span v-else-if="status_nf == 3">À pagar</span>
                                                    <span v-else-if="status_nf == 4">Pago</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg pt-3 pt-lg-0">
                                        <div class="input-group" :id="id_form + '-info_data_emissao'">
                                            <div class="input-group-prepend">
                                                <label class="input-group-text text-muted">
                                                    <span class="font-size-07">Emissão</span>
                                                </label>
                                            </div>
                                            <div v-if="edit_form" class="form-control border-0 bg-transparent p-0">
                                                <vc-date-picker class="inline-block h-full"
                                                                v-model="data_emissao"
                                                                mode="date"
                                                                :timezone="'UTC'"
                                                                :model-config="{type: 'string',mask: 'YYYY-MM-DD'}"
                                                                :max-date="new Date()"
                                                                v-on:input="changeDataEmissao()"
                                                >
                                                    <template v-slot="{ inputValue, inputEvents, togglePopover }">
                                                        <input
                                                            :id="id_form + '-data_emissao'"
                                                            :name="id_form + '-data_emissao'"
                                                            :value="inputValue"
                                                            class="form-control text-center"
                                                            v-on="inputEvents"
                                                            v-on:keydown.enter.prevent.self
                                                        />
                                                    </template>
                                                </vc-date-picker>
                                            </div>
                                            <div v-else class="form-control border-0 p-0">
                                                <div  class="form-control bg-transparent text-center bg-white">
                                                    {{ data_emissao | moment('DD/MM/YYYY')}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg pt-3 pt-lg-0">
                                        <div class="input-group" :id="id_form + '-info_data_recebimento'">
                                            <div class="input-group-prepend">
                                                <label class="input-group-text text-muted">
                                                    <span class="font-size-07">Recebimento</span>
                                                </label>
                                            </div>
                                            <div v-if="data_recebimento_show" class="form-control border-0 bg-transparent p-0">
                                                <vc-date-picker class="inline-block h-full"
                                                                v-if="edit_form || (status_form === 'consulta' && parseInt(status_nf) > 1 && parseInt(status_nf_validation) === 1 && (tipo_usuario == 1 || (permissao_unidade || permissao_unidade[16] == 2)))"
                                                                v-model="data_recebimento"
                                                                :min-date="data_emissao"
                                                                :max-date="new Date()"
                                                                mode="date"
                                                                :timezone="'UTC'"
                                                                :model-config="{type: 'string',mask: 'YYYY-MM-DD'}"
                                                >
                                                    <template v-slot="{ inputValue, inputEvents, togglePopover }">
                                                        <input
                                                            :id="id_form + '-data_recebimento'"
                                                            :name="id_form + '-data_recebimento'"
                                                            :value="inputValue"
                                                            class="form-control text-center"
                                                            v-on="inputEvents"
                                                            v-on:keydown.enter.prevent.self
                                                        />
                                                    </template>
                                                </vc-date-picker>
                                                <div v-else class="form-control bg-transparent text-center bg-white">
                                                    {{ data_recebimento | moment('DD/MM/YYYY')}}
                                                </div>
                                            </div>

                                            <div v-else class="form-control text-center" title="Liberado na confirmação do recebimento">
                                                <i class="fas fa-lock text-lightgrey"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg pt-3 pt-lg-0">
                                        <div class="input-group" v-show="valor_nf > 0" :id="id_form + '-info_valor'">
                                            <div class="input-group-prepend">
                                    <span class="input-group-text text-lightgrey bg-white border-right-0">
                                        <i class="fas fa-dollar-sign"></i>
                                    </span>
                                            </div>
                                            <input type="text" class="form-control bg-transparent font-weight-bold text-right border-left-0"
                                                   :value="valor_nf"
                                                   v-money="{decimal:',',thousands: '.',precision:2}"
                                                   style="width: auto"
                                                   disabled

                                            >
                                        </div>
                                    </div>
                                </div>

                                <div v-if="btn_change_status && (tipo_usuario == 1 || (permissao_gestor && permissao_gestor[13] === 2)) && status_form === 'consulta'" class="px-0 pt-3">
                                    <button class="btn btn-block btn-primary text-center" v-on:click="updateStatus()" :id="id_form + '-btn_update_status'">Confirmar alteração de status</button>
                                </div>

                                <!--=================================ITENS===================================-->
                                <div :id="id_form + '-info_itens'">
                                    <div v-show="tabela_itens" class="pt-3">
                                        <div class="card">
                                            <form class="p-2" autocomplete="off" v-on:submit.prevent>
                                                <div v-if="edit_form">
                                                    <button class="btn btn-sm btn-outline-secondary float-right" v-on:click="editItens()">Editar</button>
                                                </div>
                                                <table class="table">
                                                    <thead>
                                                    <tr>
                                                        <th class="border-top-0 p-1 font-size-07 font-size-sm-05" width="3%">#</th>
                                                        <th class="border-top-0 p-1 font-size-07 font-size-sm-05">Descrição</th>
                                                        <th class="border-top-0 p-1 text-right d-none d-lg-table-cell font-size-07 font-size-sm-05">Vlr unit</th>
                                                        <th class="border-top-0 p-1 text-right d-none d-lg-table-cell font-size-07 font-size-sm-05">IR</th>
                                                        <th class="border-top-0 p-1 text-right font-size-07 font-size-sm-05">Qtd</th>
                                                        <th class="border-top-0 p-1 text-right font-size-07 font-size-sm-05">Valor</th>
                                                        <th class="border-top-0 p-1 text-right d-none d-lg-table-cell font-size-07 font-size-sm-05">Recurso</th>
                                                        <th class="border-top-0 p-1 text-right font-size-07 font-size-sm-05" v-if="edit_form"></th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr v-for="(item,index) in itens">
                                                        <td class="p-1 py-lg-2 align-middle font-size-08 font-size-sm-05" width="3%">{{parseInt(index) + 1}}</td>
                                                        <td class="p-1 py-lg-2 align-middle text-wrap font-sm-reduce font-size-08 font-size-sm-05">{{item.descricao}}</td>
                                                        <td class="p-1 py-lg-2 align-middle text-right d-none d-lg-table-cell font-size-08 font-size-sm-05">
                                                            <input v-if="edit_form && item.valor_variavel" type="text" :id="id_form + '-input_tabela_valor_itens-' + index" class="border bg-transparent rounded p-1 text-right font-size-08 font-size-sm-05 w-sm-4"
                                                                   :value="item.valor_unit"
                                                                   v-money="{decimal:',',thousands: '.',precision:4}"
                                                                   v-on:keyup="changeValorItem(item,index)"
                                                                   style="max-width: 7.5rem"
                                                            >
                                                            <input v-else type="text" class="border-0 bg-transparent rounded p-1 text-right font-size-08 font-size-sm-05 w-sm-4"
                                                                   :value="item.valor_unit"
                                                                   v-money="{decimal:',',thousands: '.',precision:4}"
                                                                   style="max-width: 7.5rem"
                                                                   disabled
                                                            >
                                                        </td>
                                                        <td class="p-1 py-lg-2 align-middle text-right d-none d-lg-table-cell font-size-08 font-size-sm-05">
                                                            <input v-if="item.ir_percent" type="text"
                                                                   class="border-0 bg-transparent rounded p-1 text-right font-size-08 font-size-sm-05 w-sm-4"
                                                                   :value="item.ir_percent"
                                                                   v-money="{decimal:',',thousands: '.',precision:2,prefix:'% '}"
                                                                   style="max-width: 4rem"
                                                                   title="Retenção de IR"
                                                                   disabled
                                                            >
                                                        </td>
                                                        <td class="p-1 py-lg-2 align-middle text-right">
                                                            <input v-if="edit_form" type="text" :id="id_form + '-input_tabela_itens-' + index" class="border rounded p-1 text-right font-size-08 font-size-sm-05 w-sm-4"
                                                                   :value="(item.decimal ? parseFloat(item.qtd).toFixed(4) : parseInt(item.qtd))"
                                                                   v-money="{decimal:',',thousands: '.',precision:(item.decimal ? 4 : 0)}"
                                                                   v-on:keyup="changeQtdItem(item,index)"
                                                                   v-on:keyup.40.enter.prevent="nextInput(index)"
                                                                   v-on:keyup.38.prevent="prevInput(index)"
                                                                   style="max-width: 6.5rem"
                                                            >
                                                            <input v-else type="text" class="border-0 bg-transparent p-1 text-right font-size-08 font-size-sm-05 w-sm-4"
                                                                   :value="(item.decimal ? parseFloat(item.qtd).toFixed(4) : parseInt(item.qtd))"
                                                                   v-money="{decimal:',',thousands: '.',precision:(item.decimal ? 4 : 0)}"
                                                                   style="max-width: 6.5rem"
                                                                   disabled
                                                            >
                                                        </td>
                                                        <td class="p-1 py-lg-2 align-middle text-right">
                                                            <input type="text" class="border-0 bg-transparent rounded p-1 font-weight-bold text-right font-size-08 font-size-sm-05 w-sm-4"
                                                                   :value="item.valor"
                                                                   v-money="{decimal:',',thousands: '.',precision:2}"
                                                                   style="max-width: 7.5rem"
                                                                   disabled
                                                            >
                                                        </td>
                                                        <td class="p-1 py-lg-2 align-middle text-right d-none d-lg-table-cell font-size-08 font-size-sm-05 text-wrap">{{item.sigla_recurso}}</td>
                                                        <td class="p-1 py-lg-2 align-middle text-right font-size-08 font-size-sm-05" width="5%" v-if="edit_form">
                                                            <button class="btn btn-icon btn-light text-danger" title="remover" v-on:click="removerItem(index)"><i class="far fa-trash-alt"></i></button>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </form>
                                        </div>
                                    </div>

                                    <div v-show="edit_form && tabela_itens_incluir" class="pt-3">
                                        <div class="card border-primary p-3 shadow">
                                            <h5 class="text-center text-primary mb-2">Identifique os Itens relacionados a Nota Fiscal</h5>
                                            <div class="text-muted"><small>* Exibindo apenas itens que possuam faturamento pendentes.</small></div>
                                            <table class="table table-hover">
                                                <thead>
                                                <tr>
                                                    <th scope="col" class="border-top-0 py-1 font-size-07 font-size-sm-05">Descrição</th>
                                                    <th scope="col" class="border-top-0 p-1 text-right font-size-07 font-size-sm-05">Vlr unit</th>
                                                    <th scope="col" class="border-top-0 p-1 text-right font-size-07 font-size-sm-05">Qtd Pendente</th>
                                                    <th scope="col" class="border-top-0 p-1 text-right font-size-07 font-size-sm-05">Vlr Total</th>
                                                    <th scope="col" class="border-top-0 py-1 text-right font-size-07 font-size-sm-05">Recurso</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="(item,index) in itens_incluir" v-show="(id_recurso === null || item.id_recurso === id_recurso) && parseInt(item.saldo) > 0" class="cursor-pointer" :id="id_form + '-tabela_incluir_itens-' + index" v-on:click="selectItem(index,item)">
                                                    <td class="py-1 text-wrap font-size-08 font-size-sm-05">
                                                        <i class="fas fa-check text-success d-none" :id="id_form + '-tabela_incluir_itens_check-' + index"></i>
                                                        {{item.descricao}}
                                                    </td>
                                                    <td class="p-1 text-right">
                                                        <input type="text" class="border-0 bg-transparent rounded p-1 text-right font-size-08 font-size-sm-05"
                                                               :value="item.valor_contratado"
                                                               v-money="{decimal:',',thousands: '.',precision:4}"
                                                               style="max-width: 6rem"
                                                               disabled
                                                        >
                                                    </td>
                                                    <td class="p-1 text-right">
                                                        <input type="text" class="border-0 bg-transparent rounded p-1 text-right font-size-08 font-size-sm-05"
                                                               :value="(item.decimal ? parseFloat(item.saldo).toFixed(4) : parseInt(item.saldo))"
                                                               v-money="{decimal:',',thousands: '.',precision:(item.decimal ? 4 : 0)}"
                                                               style="max-width: 6rem"
                                                               disabled
                                                        >
                                                    </td>
                                                    <td class="p-1 text-right">
                                                        <input type="text" class="border-0 bg-transparent rounded p-1 text-right font-size-08 font-size-sm-05"
                                                               :value="item.valor_saldo"
                                                               v-money="{decimal:',',thousands: '.',precision:2}"
                                                               style="max-width: 6rem"
                                                               disabled
                                                        >
                                                    </td>
                                                    <td class="py-1 text-right font-size-08 font-size-sm-05" width="10%">{{item.sigla_recurso}}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            <div class="text-primary mt-2">{{count_itens_incluir}} itens selecionados</div>
                                            <button v-if="count_itens_incluir > 0" class="btn btn-primary btn-primary text-center mt-2 font-weigth-bold" v-on:click="setItens()">Confirmar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </transition>

                        <transition name="slide-down">
                            <div v-if="status_form === 'registro' && tabela_itens && upload_anexos_registro" class="card mt-3">
                                <div class="card-body">
                                    <div class="mb-3">
                                        Anexar documentos
                                    </div>

                                    <upload-files
                                        key="upload_file"
                                        :request="{post:url + '/gerenciar_request/anexos'}"

                                        :submit="false"
                                        name_file="arquivo_upload"
                                        id_file="anexos_nf_registro"
                                        :maximum_files="3"
                                        :dados="{
                                            '_token':token,
                                            function:'upload_anexos_nf'
                                        }"

                                        :drop_file="true"
                                        :edit_info="true"
                                        :img_size_limit="300000"
                                        v-on:success="upload_anexos_registro = false"
                                        v-on:empty_submit="upload_anexos_registro = false"
                                    >
                                    </upload-files>
                                </div>
                            </div>
                        </transition>

                        <transition name="slide-down">
                            <div v-if="nav === 'historico'" class="my-3 pt-3">
                                <h4 class="text-center text-primary"><i class="fas fa-history mr-2 mb-2"></i> HISTÓRICO</h4>
<!--                                <div class="d-flex align-items-center justify-content-center">-->
<!--                                    <button v-if="historico_acessos === false" class="btn btn-sm btn-link mb-2" v-on:click="historico_acessos = true; getHistoricoNF()">Mostrar registros de acessos</button>-->
<!--                                    <button v-else-if="historico_acessos === true" class="btn btn-sm btn-link mb-2" v-on:click="historico_acessos = false; getHistoricoNF()">Ocultar registros de acessos</button>-->
<!--                                </div>-->
                                <ul class="timeline">
                                    <li v-for="registro in historico" class="event" :data-date="registro.data_exec">
                                        <div class="row">
                                            <div class="col-auto">
                                                <img v-if="registro.src_img" class="rounded-circle" width="30" height="30" :src="url + '/' + registro.src_img" alt="user">
                                                <div v-else class='p-1 border rounded-circle text-center d-flex align-items-center justify-content-center text-center' style='width: 30px;height: 30px'><i class='text-lightgrey far fa-user font-size-07'></i></div>
                                            </div>
                                            <div class="col pl-0">
                                                <h6>{{ registro.first_name }} <small>({{registro.email}})</small></h6>
                                                <p>{{ registro.detalhes || 'Acessou a nota fiscal' }}</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </transition>

                        <transition name="slide-down">
                            <div v-if="nav === 'anexos'" class="my-3 pt-3">
                                <h4 class="text-center text-primary mb-3"><i class="fas fa-paperclip"></i> ANEXOS</h4>

                                <anexos
                                    :id_anexo="id_form + '-anexos'"
                                    :display_images="true"
                                    key="list_anexos_nf"
                                    :request="{
                                    path:url,
                                    url:url + '/gerenciar_request/anexos',
                                    data:{
                                        function:'get_anexos_nf',
                                        id_nf:this.id_nf,
                                    },
                                }"
                                    v-on:emit_anexos="getAnexosNF($event);"

                                    :marks="{
                                    evidencia:'<i class=\'fas fa-check-circle text-success\' title=\'Evidência de entrega\'></i>',
                                }"

                                    :request_delete="{
                                    url:url + '/gerenciar_request/anexos',
                                    data:{function:'remover_anexo_nf',id_nf:this.id_nf},
                                    bus_emit:['refresh-cards_nfs']
                                }"

                                :data_request_download="{function:'download_anexo_nf'}"

                                 v-on:assinar="assinarAnexo($event)"

                                >

                                </anexos>

                                <div v-if="tipo_usuario == 1 || (permissao_gestor && permissao_gestor[13] == 2) || (permissao_unidade && permissao_unidade[16] == 2 && id_unidade_usuario == id_unidade)" class="pt-3">
                                    <upload-files
                                        v-if="upload_anexos"
                                        key="upload_file"
                                        :request="{post:url + '/gerenciar_request/anexos'}"

                                        name_file="arquivo_upload"
                                        id_file="anexos_nf"
                                        :maximum_files="max_anexos"
                                        :dados="{
                                        '_token':token,
                                        function:'upload_anexos_nf',
                                        id_nf:id_nf
                                    }"

                                        :drop_file="true"
                                        :edit_info="true"
                                        :img_size_limit="300000"
                                        v-on:success="busemit('refresh-' + id_form + '-anexos',{}); busemit('refresh-cards_nfs',{})"
                                    >
                                    </upload-files>
                                </div>

                            </div>
                        </transition>

                        <transition name="slide-down">
                            <div v-if="nav === 'chamados'" class="my-3 pt-3">
                                <h4 class="text-center text-primary mb-3" >
                                    <i data-feather="alert-octagon" class="mb-1"></i>
                                    CHAMADOS
                                </h4>

                                <cards-chamados
                                    :id_cards="'cards_chamados-' + id_form"
                                    :key="'cards_chamados-' + id_form"
                                    class_cards="p-2 mb-3 bg-hover-ligthgrey"
                                    class_busca="col-lg-4"
                                    :acesso="(permissao_gestor && (permissao_gestor[21] > 0)) || (permissao_unidade && permissao_unidade [22] > 0) || tipo_usuario === 1"
                                    :path="url"
                                    :request="{
                                    url:url + '/gerenciar_request/chamados',
                                    data:{
                                        function:'list',
                                        id_nf:id_nf,
                                        paginate:10
                                    },
                                }"
                                >
                                </cards-chamados>
                                <div v-if="(permissao_gestor && permissao_gestor[21] == 2) || (permissao_unidade && permissao_unidade [22] == 2) || tipo_usuario == 1" class="mt-4">
                                    <button class="btn btn-link btn-block" v-on:click="novaChamado()">Abrir novo chamado</button>
                                </div>
                            </div>
                        </transition>

                        <div class="pt-3">
                            <div class="text-center" v-html="atividade"></div>

                            <div v-if="opcoesExtras" class="pt-3">
                                <button v-if="btn_ver_nf" class="btn btn-link btn-block px-0 text-center"
                                        v-on:click="setNF(registro)">
                                    Ver Nota Fiscal.
                                </button>
                            </div>
                        </div>
                        <button v-if="btn_registrar" v-on:click="register()" :id="id_form + '-btn_registrar_danfe'"
                                class="btn btn-block btn-primary text-center mt-3">REGISTRAR
                        </button>
                    </div>
                </div>

                <div v-if="btn_nova_danfe" class="row">
                    <div class="col">
                        <button v-on:click="resetNumeroNF()" class="btn btn-block btn-primary text-center mt-3"><strong>NOVA
                            DE</strong> <span class="font-italic">{{ razao_social }}</span></button>
                    </div>
                    <div class="col col-lg-3">
                        <button v-on:click="resetForm()" class="btn btn-block btn-primary text-center mt-3">NOVA</button>
                    </div>
                </div>
            </form>

        </modal-3>

        <modal-3
                :key="id_form + '-modal_confirmar_recebimento_nf'"
                v-if="permissao_unidade && permissao_unidade[16] == 2 && status_form === 'consulta' && parseInt(status_nf) === 1"
                :id_modal="id_form + '-modal_confirmar_recebimento_nf'"
                class_modal_content="border-success shadow"
                :backdrop="(evidencia_recebimento_obrigatoria ? 'static' : false)"
                v-on:opened="function () {
                    focusId(id_form + '-data_confirmar_recebimento');
                    if(n_anexos > 0){
                        getAnexos();
                    }
                }"
        >
            <div class="pb-3">
                <h4 class="text-success mb-3">Confirmar recebimento <i class="fas fa-check"></i></h4>

                <div>Nota Fiscal: <strong>{{numero_nf}}</strong></div>
                <div>Fornecedor: <strong>{{razao_social}}</strong></div>
                <div>Emissao: <strong>{{data_emissao.getDate() + '/' + (data_emissao.getMonth() + 1) + '/' + data_emissao.getFullYear()}}</strong></div>
                <div>Valor: <input type="text" class="bg-transparent text-dark border-0 font-weight-bold text-left border-left-0"
                                   :value="valor_nf"
                                   v-money="{decimal:',',thousands: '.',precision:2,prefix:'R$ '}"
                                   style="width: auto"
                                   disabled></div>

                <div class="py-2 text-justify mb-2">Confirmo o recebimento da Nota Fiscal, conforme informações acima, e atesto para os devidos fins que os produtos/serviços foram
                    entregues de acordo com o descrito neste documento.
                </div>

                <div v-if="n_anexos > 0 && config.anexos && config.anexos.assinar_automaticamente_recebimento_nf" class="card shadow-none mb-3 border-success bg-lightsuccess">
                    <div class="card-body p-2">
                        <i class="fas fa-file-signature text-inherit mr-2"></i>
                        Ao confirmar o recebimento os seguintes arquivos serão assinados eletrônicamente:

                        <div class="pt-2">
                            <div v-for="anexo in anexos" class="d-flex align-items-center">
                                <a class="font-size-08 text-primary cursor-pointer text-hover-underline d-flex align-items-center"
                                   v-on:click="baixar({
                                    function:'download_anexo_nf',
                                    id_anexo:anexo.id_anexo
                               })"
                                >
                                    <span v-html="iconeAnexo(anexo.formato)"></span>
                                    <span class="ml-2">{{ anexo.nome_arquivo }}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" v-if="data_emissao">
                    <div class="col-12 col-lg-8">
                        <div class="d-block pt-2 d-lg-none font-weight-bold">Data de recebimento:</div>
                        <div class="input-group">
                            <div class="input-group-prepend d-none d-lg-flex" :id="'label_campo-' + id_form + '-data_recebimento'">
                                <label class="input-group-text text-muted">
                                    <span class="font-size-07">Recebimento</span>
                                </label>
                            </div>
                            <form class="form-control border-0 p-0" autocomplete="off" v-on:submit.prevent>
                                <vc-date-picker class="inline-block h-full"
                                                :key="'data_recebimento-' + id_nf + data_emissao.getDate() + (data_emissao.getMonth() + 1) + data_emissao.getFullYear()"
                                                v-model="data_recebimento"
                                                :min-date="data_emissao"
                                                :max-date="new Date()"
                                                mode="date"
                                                :timezone="'UTC'"
                                                :model-config="{type: 'string',mask: 'YYYY-MM-DD'}"
                                >
                                    <template v-slot="{ inputValue, inputEvents, togglePopover }">
                                        <input
                                            :id="id_form + '-data_confirmar_recebimento'"
                                            :name="id_form + '-data_confirmar_recebimento'"
                                            :value="inputValue"
                                            class="form-control text-center"
                                            v-on="inputEvents"
                                            v-on:keydown.enter.prevent.self
                                        />
                                    </template>
                                </vc-date-picker>
                            </form>
                        </div>
                    </div>
                    <div v-if="!evidencia_recebimento_obrigatoria" class="col-12 col-lg-4 pt-2 pt-lg-0">
                        <button v-if="data_recebimento" class="btn btn-block btn-success h-100 " :id="id_form + '-btn_confirm_recebimento'" v-on:click="confirmRecebimento()">Confirmar</button>
                    </div>

                    <div v-if="evidencia_recebimento_obrigatoria && data_recebimento"  class="col-12 pt-3">
                        <div class="text-success"><i class="fas fa-camera mr-2"></i> Adicione uma comprovação do recebimento.</div>
                        <div><small class="text-muted">Para concluir a confirmação, adicionar alguma imagem, foto ou arquivo que comprove o recebimento da Nota Fiscal.</small></div>

                        <div class="pt-3">
                            <upload-files
                                v-if="evidencia_recebimento_obrigatoria && data_recebimento"
                                key="upload_evidencia_nf"
                                :request="{post:url + '/gerenciar_request/anexos'}"

                                name_file="arquivo_upload"
                                id_file="evidencias_nf"
                                :maximum_files="3"
                                :dados="{
                                     '_token':token,
                                     function:'upload_anexos_nf',
                                     evidencia:true,
                                     id_nf:id_nf
                                }"
                                :edit_info="true"
                                :drop_file="true"
                                :img_size_limit="300000"
                                v-on:success="confirmRecebimento()"
                            >
                            </upload-files>
                        </div>
                    </div>
                </div>
            </div>

        </modal-3>
    </div>
</template>

<script>
    import _ from 'lodash';
    import {bus} from "../../app";
    import {HollowDotsSpinner} from "epic-spinners";
    import Editor from "@tinymce/tinymce-vue";
    import VueMarkdown from "vue-markdown";
    export default {
        props:['id_form','id_pedido','unidade','permissao_unidade','permissao_gestor','id_unidade_usuario','nome_unidade_usuario','tipo_usuario','url','token','maximo_anexos'],
        components: {
            editor:Editor,
        },
        data: function () {
            return {
                id_fornecedor:null,
                anotacoes:null,
                razao_social:null,
                id_nf:null,
                id_ordem:null,
                id_unidade:null,
                id_recurso:null,
                nome_unidade:null,
                identificador_ordem:null,
                identificador_pedido:null,
                identificador_contrato:null,
                numero_nf:'',
                valor_nf:0,
                status_nf:null,
                status_pedido:null,
                status_nf_format:null,
                status_nf_validation:null,
                data_emissao:null,
                data_emissao_show:false,
                data_recebimento:null,
                data_recebimento_show:false,
                display_anotacoes:'read',
                show_anotacoes:false,
                ordens:{},
                unidades:{},
                itens:{},
                itens_incluir:{},
                count_itens_incluir:0,
                campo_numero_nf:false,
                campo_numero_nf_label:'',
                campo_numero_nf_confirm:false,
                campo_numero_nf_edit:false,
                tabela_ordens:false,
                tabela_unidades:false,
                tabela_itens:false,
                tabela_itens_incluir:false,
                atividade:null,
                waiting_request:false,
                edit_form:false,
                status_form:'consulta', //consulta,registro
                keyConfirmRecebimento:1,
                btn_nova_danfe:false,
                btn_registrar:false,
                btn_change_status:false,
                opcoesExtras:false,
                btn_ver_nf:false,
                registro:{},
                historico:{},
                anexos:{},
                n_anexos:0,
                n_chamados:0,
                nav:'nf',
                evidencia_recebimento_obrigatoria:false,
                config:[],
                permitir_anexos:false,
                carregando:false,
                max_anexos:this.maximo_anexos,
                upload_anexos:false,
                upload_anexos_registro:false,
            }
        },
        methods:{
            resetForm(){
                this.edit_form = true;
                this.btn_nova_danfe = false;
                this.upload_anexos_registro = true;
                this.disableNF();
                setTimeout(() => {bus.$emit('set-' + this.id_form + '-busca_razao_social', {input:true});},200);
            },
            setNF(obj){
                this.nav = 'nf';
                this.form_ = 'consulta';
                this.display_anotacoes = 'read';
                this.show_anotacoes = !!obj.anotacoes;
                this.disableOpcoesExtras();
                this.id_nf = obj.id_nf;
                this.anotacoes = obj.anotacoes;
                this.id_ordem = obj.id_ordem;
                this.id_fornecedor = obj.id_fornecedor;
                this.razao_social = obj.razao_social;
                this.numero_nf = obj.numero_nf;
                this.identificador_pedido = obj.identificador_pedido;
                this.identificador_contrato = obj.identificador_contrato;
                this.identificador_ordem = obj.identificador_ordem;
                this.id_unidade = obj.id_unidade;
                this.nome_unidade = obj.nome_unidade;
                this.status_nf = obj.status_nf;
                this.status_pedido = obj.status_pedido;
                this.status_nf_validation = obj.status_nf;
                this.data_emissao = new Date(obj.data_emissao);
                this.data_recebimento = new Date(obj.data_recebimento);
                if(this.status_nf > 1 && this.data_recebimento !== null){this.data_recebimento_show = true;}else{this.data_recebimento_show = false;}
                this.valor_nf = obj.valor;
                this.itens = obj.itens;
                this.tabela_itens = true;
                this.unidades = {};
                this.ordens = {};
                this.evidencia_recebimento_obrigatoria = obj.config ? obj.config.evidencia_recebimento_obrigatoria : false;
                this.config = obj.config;
                this.permitir_anexos = obj.config ? obj.config.permitir_anexos : true;
                this.historico = {};
                this.anexos = {};
                this.n_anexos = obj.anexos;
                this.n_chamados = obj.chamados;
                this.historico_acessos = false;
            },
            setFornecedor(obj){
                if(obj.value){
                    this.id_fornecedor = obj.value;
                    this.razao_social = obj.descricao;
                    this.getOrdens(this.id_fornecedor);
                }else{
                    this.disableNF();
                    this.atividade = null;
                }
            },
            setNumeroNF(){
                if(this.campo_numero_nf_confirm === false || !this.numero_nf || this.numero_nf === ''){return false;}
                this.campo_numero_nf_confirm = false;
                this.campo_numero_nf_label = "<i class='fas fa-circle-notch fa-spin' style='color: inherit !important; font-size: inherit'></i>";
                this.getNF();
            },
            disabledNumeroNF(option){
                if(document.getElementById(this.id_form + '-numero_danfe')){
                    if(option === true){
                        document.getElementById(this.id_form + '-numero_danfe').setAttribute('readonly','readonly');
                    }else{
                        document.getElementById(this.id_form + '-numero_danfe').removeAttribute('readonly');
                    }
                }
            },
            getOrdens(id_fornecedor){
                this.atividade = "<span class='text-color-pulse-primary font-size-09'>Verificando ordens e entregas pendentes do fornecedor...</span>";
                if(this.waiting_request === true){return false;}
                this.waiting_request = true;
                let request = {
                    url:this.url + '/gerenciar_request/ordens',
                    data:{
                        function:'list_ordens_simplificada',
                        id_fornecedor:id_fornecedor,
                        pendentes:true, //Nfs pendentes
                        status_ordem:2, //Ordens autorizadas
                        paginate:10
                    },
                    bus_emit:[this.id_form + '-get_response_ordens'],
                    loading:['page']
                };

                if(this.id_pedido){request.data.id_pedido = this.id_pedido;}
                if(this.unidade){request.data.id_unidade = this.unidade;}

                if(parseInt(this.tipo_usuario) !== 1 && this.permissao_gestor && (parseInt(this.permissao_gestor[13]) === 0 || this.permissao_gestor[13] === null) && this.permissao_unidade && parseInt(this.permissao_unidade[16]) > 0){request.data.id_unidade = this.id_unidade_usuario;}
                bus.$emit('gerencia-request', request);
            },
            getUnidadesOrdem(){
                this.atividade = "<span class='text-color-pulse-primary font-size-09'>Buscando unidades requisitantes...</span>";
                if(this.waiting_request === true){return false;}
                this.waiting_request = true;
                bus.$emit('gerencia-request', {
                    url:this.url + '/gerenciar_request/ordens',
                    data:{
                        function:'list_unidades_ordem',
                        id_ordem:this.id_ordem,
                        status_pedido_unidade_item:2,
                        pendentes:true,
                        paginate:10
                    },
                    bus_emit:[this.id_form + '-get_response_unidades'],
                    loading:['page']
                });
            },
            getItensUnidadeOrdem(){
                this.atividade = "<span class='text-color-pulse-primary font-size-09'>Buscando itens...</span>";
                if(this.waiting_request === true){return false;}
                this.waiting_request = true;
                bus.$emit('gerencia-request', {
                    url:this.url + '/gerenciar_request/ordens',
                    data:{
                        function:'list_itens_ordem',
                        id_ordem:this.id_ordem,
                        id_unidade:this.id_unidade,
                        status_pedido_unidade_item:2,
                        paginate:1000
                    },
                    bus_emit:[this.id_form + '-get_response_itens_unidade'],
                    loading:['page']
                });
            },
            getNF(id_nf = null){
                if(!id_nf){
                    this.disableOpcoesExtras();
                    if(this.waiting_request === true){return false;}
                    this.atividade = "<span class='text-color-pulse-primary font-size-09'>Verificando número da NF...</span>";
                    this.waiting_request = true;
                    bus.$emit('gerencia-request', {
                        url:this.url + '/gerenciar_request/nfs',
                        data:{
                            function:'get_nf',
                            id_nf:this.id_nf,
                            id_fornecedor:this.id_fornecedor,
                            numero:this.numero_nf,
                        },
                        bus_emit:[this.id_form + '-get_response_numero_nf'],
                        loading:['page']
                    });
                }else{
                    this.waiting_request = true;
                    bus.$emit('gerencia-request', {
                        url:this.url + '/gerenciar_request/nfs',
                        data:{
                            function:'get_nf',
                            id_nf:id_nf,
                        },
                        bus_emit:[this.id_form + '-get_nf'],
                        loading:['page']
                    });
                }
            },
            getHistoricoNF(){
                if(this.waiting_request === true){return false;}
                this.atividade = "<span class='text-color-pulse-primary font-size-09'>Buscando histórico da Nota Fiscal...</span>";
                this.waiting_request = true;
                bus.$emit('gerencia-request', {
                    url:this.url + '/gerenciar_request/nfs',
                    data:{
                        function:'get_historico_nf',
                        id_nf:this.id_nf,
                        acessos:this.historico_acessos,
                    },
                    bus_emit:[this.id_form + '-get_historico_nf'],
                });
            },
            getChamado(registro){
                this.$noty.info('redirecionando...',{layout: 'center'});
                window.location.href = '/chamados/' + registro.id_chamado;
            },
            novaChamado(){
                this.$store.commit('setChamado',{
                    id_area:13,
                    id_nf:this.id_nf,
                    area_referencia:this.numero_nf + ' - ' + this.razao_social,
                    unidades:{id_unidade:this.id_unidade,nome_unidade:this.unidade},
                    tags:[{id_tag:1,name:'OCORRÊNCIA'}],
                    assunto_chamado:'',
                    detalhes_chamado:'',
                });
                bus.$emit('show_modal-modal_nova_chamado', {});
                setTimeout(function () {document.getElementById('assunto_chamado').focus();},750);
            },
            getAnexosNF(obj){
                this.max_anexos = parseInt(this.maximo_anexos) - obj.length;
                this.upload_anexos = this.max_anexos > 0;
            },
            editNumeroNF(){
                this.disabledNumeroNF(false);
                this.campo_numero_nf = true;
                this.campo_numero_nf_edit = false;
                this.campo_numero_nf_confirm = true;
                this.focusId(this.id_form + '-numero_danfe');
                this.campo_numero_nf_label = "Informe";
                this.disableOrdem();
                this.disableUnidade();
                this.disableItens();
                this.disableDataRecebimento();
                this.disableDataEmissao();
                this.status_nf = 0;
                this.btn_registrar = false;
                this.btn_nova_danfe = false;
            },
            resetNumeroNF(){
                this.nav = 'nf';
                this.disableOrdens();
                this.edit_form = true;
                this.numero_nf = '';
                this.anotacoes = null;
                this.btn_nova_danfe = false;
                this.editNumeroNF();
                setTimeout(() => {document.getElementById(this.id_form + '-numero_danfe').focus()},200);
            },
            disableNumeroNF(){
                this.campo_numero_nf = false;
                this.numero_nf = null;
            },
            setOrdem(obj){
                this.id_ordem = obj.id_ordem;
                this.$store.state.nf.id_ordem = obj.id_ordem;
                this.identificador_ordem = obj.identificador_ordem;
                this.identificador_pedido = obj.identificador_pedido;
                this.identificador_contrato = obj.identificador_pedido;
                this.tabela_ordens = false;
            },
            editOrdem(){
                this.disableOrdem();
                this.disableUnidade();
                this.disableItens();
                this.getOrdens(this.id_fornecedor);
            },
            disableOrdem(){
                this.id_ordem = null;
                this.$store.state.nf.id_ordem = null;
                this.identificador_ordem = null;
                this.identificador_pedido = null;
                this.identificador_contrato = null;
                this.tabela_ordens = false;
            },
            disableOrdens(){this.ordens = {};},
            setUnidade(obj){
                this.id_unidade = obj.id_unidade;
                this.$store.state.nf.id_unidade = obj.id_unidade;
                this.nome_unidade = obj.nome_unidade;
                this.tabela_unidades = false;
            },
            editUnidade(){
                this.disableUnidade();
                this.disableItens();
                this.getUnidadesOrdem();
            },
            disableNF(){
                this.registro = {};
                this.historico = {};
                this.anexos = {};
                this.upload_file = false;
                this.n_anexos = 0;
                this.historico_acessos = false;
                this.nav = 'nf';
                this.id_nf = null;
                this.razao_social = null;
                this.id_fornecedor = null;
                this.disableNumeroNF();
                this.disableOrdem();
                this.disableUnidade();
                this.disableItens();
                this.disableDataEmissao();
                this.disableDataRecebimento();
                this.status_nf = 0;
            },
            disableUnidade(){
                this.id_unidade = null;
                this.$store.state.nf.id_unidade = null;
                this.tabela_unidades = false;
            },
            selectItem(index,item){
                if(this.itens_incluir[index].selected){
                    delete this.itens_incluir[index].selected;
                    document.getElementById(this.id_form + '-tabela_incluir_itens-' + index).classList.remove('bg-lightsuccess');
                    document.getElementById(this.id_form + '-tabela_incluir_itens-' + index).classList.remove('font-weight-bold');
                    document.getElementById(this.id_form + '-tabela_incluir_itens_check-' + index).classList.add('d-none');
                    if(this.itens_incluir.findIndex(value => value.selected === true) === -1){
                        this.id_recurso = null;
                    }
                    this.count_itens_incluir--;
                }else{
                    if(parseInt(this.itens_incluir[index].saldo) === 0){return false;}
                    this.itens_incluir[index].selected = true;
                    this.id_recurso = item.id_recurso;
                    this.count_itens_incluir++;
                    document.getElementById(this.id_form + '-tabela_incluir_itens-' + index).classList.add('bg-lightsuccess');
                    document.getElementById(this.id_form + '-tabela_incluir_itens-' + index).classList.add('font-weight-bold');
                    document.getElementById(this.id_form + '-tabela_incluir_itens_check-' + index).classList.remove('d-none');
                }
            },
            setItens(){
                let c = 0;
                for(var item in this.itens_incluir){
                    if(this.itens_incluir[item].selected === true){
                        this.itens[c] = this.itens_incluir[item];
                        this.itens[c].decimal = this.itens_incluir[item].decimal;
                        this.itens[c].valor_variavel = this.itens_incluir[item].valor_variavel;
                        this.itens[c].qtd = this.itens_incluir[item].saldo;
                        this.itens[c].valor_unit = this.itens_incluir[item].valor_contratado;
                        this.itens[c].valor = parseFloat(this.round(parseFloat(this.itens_incluir[item].qtd) * parseFloat(this.itens_incluir[item].valor_contratado),2)).toFixed(2);
                        this.itens[c].qtd_max = this.itens[c].qtd;
                        this.itens[c].valor_max = this.itens[c].valor;
                        c++;
                    }
                }
                this.tabela_itens_incluir = false;
                this.tabela_itens = true;
                this.btn_registrar = true;
                this.status_nf = this.setStatusNovaNF();
                this.upload_anexos_registro = true;
                this.sumValorTotal();
                this.focusId(this.id_form + '-data_emissao');
            },
            setStatusNovaNF(){
                if(parseInt(this.tipo_usuario) === 1 || (this.permissao_gestor && this.permissao_gestor[13] === 2)){
                    return 1;
                }else{
                    return 2;
                }
            },
            editItens(){
                this.disableItens();
                this.getItensUnidadeOrdem();
            },
            disableItens(){
                this.id_recurso = null;
                this.itens = {};
                this.tabela_itens = false;
                this.itens_incluir = {};
                this.tabela_itens_incluir = false;
                this.count_itens_incluir = 0;
                this.btn_registrar = false;
                this.valor_nf = 0;
                this.disableDataEmissao();
                this.disableDataRecebimento();
            },
            prevInput(row){
                var prev = parseInt(row) - 1;
                if(document.getElementById(this.id_form + '-input_tabela_itens-' + prev) !== null){
                    document.getElementById(this.id_form + '-input_tabela_itens-' + prev).focus();
                }
            },
            nextInput(row){
                var next = parseInt(row)  + 1;
                if(document.getElementById(this.id_form + '-input_tabela_itens-' + next) !== null){
                    document.getElementById(this.id_form + '-input_tabela_itens-' + next).focus();
                }
            },
            changeQtdItem:_.debounce(function (registro,row) {
                var qtd = document.getElementById(this.id_form + '-input_tabela_itens-' + row).value;

                if(registro.decimal){
                    let parts = qtd.toString().split(',');
                    parts[0] = parts[0].replace(/\B(?=(\d{5})+(?!\d))/g, ',');
                    qtd = (parseFloat(parts.join('.'))).toString();
                }else{
                    qtd = qtd.replace(/\./g, '');
                }

                if(parseFloat(qtd) <= parseFloat(registro.qtd_max) && parseFloat(qtd) > parseFloat('0.0000').toPrecision(5)){
                    this.itens[row].qtd = qtd;
                    this.itens[row].valor = parseFloat(parseFloat(qtd) * parseFloat(this.itens[row].valor_unit)).toFixed(2);
                }
                this.refreshItens();
            },500),
            changeValorItem:_.debounce(function (registro,row) {
                var vlr = document.getElementById(this.id_form + '-input_tabela_valor_itens-' + row).value;

                let parts = vlr.toString().split(',');
                parts[0] = parts[0].replace(/\B(?=(\d{5})+(?!\d))/g, ',');
                vlr = (parseFloat(parts.join('.'))).toString();

                if(parseFloat(vlr) > parseFloat('0.0000').toPrecision(5)){
                    this.itens[row].valor_unit = parseFloat(vlr).toFixed(4);
                    this.itens[row].valor = parseFloat(parseFloat(this.itens[row].qtd) * parseFloat(vlr)).toFixed(2);
                }
                this.refreshItens();
            },500),
            sumValorTotal(){
                this.valor_nf = 0;
                for(let item in this.itens){
                    this.valor_nf = parseFloat(parseFloat(this.valor_nf) + parseFloat(this.itens[item].valor)).toFixed(2)
                }
            },
            refreshItens(){
                var itens = this.itens;
                this.itens = {};
                this.itens = itens;
                this.sumValorTotal();
            },
            removerItem(index){
                delete this.itens[index];
                if(Object.keys(this.itens).length === 0){
                    this.editItens();
                }
                this.refreshItens();
            },
            changeDataEmissao(){
                if(parseInt(this.status_nf) > 1){this.enableDataRecebimento();}else{this.disableDataRecebimento();}
            },
            changeDataRecebimento(data){
                this.data_recebimento = data;
            },
            disableDataEmissao(){
                this.data_emissao_show = false;
                this.data_emissao = null;
            },
            disableDataRecebimento(){
                this.data_recebimento_show = false;
                this.data_recebimento = null;
            },
            enableDataRecebimento(){
                if(this.data_recebimento_show === true){
                    this.disableDataRecebimento();
                    setTimeout(() => {this.data_recebimento_show = true;},200);
                } else{this.data_recebimento_show = true;}

                if(!this.data_recebimento || this.data_recebimento < this.data_emissao){
                    this.data_recebimento = null;
                }
            },
            showAnotacoes(){
                if(!this.anotacoes){
                    this.display_anotacoes = 'edit';
                }
                this.show_anotacoes = true;
            },
            register(){
                if(this.validateRegister() === false){return false;}
                if(this.waiting_request === true){return false;}
                this.waiting_request = true;
                bus.$emit('gerencia-request', {
                    url:this.url + '/gerenciar_request/nfs',
                    data:{
                        function:'registrar_nf',
                        id_fornecedor:this.id_fornecedor,
                        numero:this.numero_nf,
                        id_ordem:this.id_ordem,
                        id_unidade:this.id_unidade,
                        id_recurso:this.id_recurso,
                        itens:this.itens,
                        data_emissao:this.data_emissao,
                        data_recebimento:this.data_recebimento,
                        status_nf:parseInt(this.status_nf),
                    },
                    bus_emit:[this.id_form + '-get_response_registrar_nf','refresh-cards_nfs','refresh-pedido'],
                    loading:['add_spin'],
                    id_loading:this.id_form + '-btn_registrar_danfe'
                });
            },
            validateRegister(){
                if(this.id_fornecedor === null || this.id_fornecedor === ''){return false}
                if(this.numero_nf === null || this.numero_nf === ''){return false}
                if(this.id_ordem === null || this.id_ordem === ''){return false}
                if(this.id_unidade === null || this.id_unidade === ''){return false}
                if(this.itens === null || this.itens === ''){return false}
                if(this.id_recurso === null || this.id_recurso === ''){return false}
                if(this.data_emissao === null  || this.data_emissao === ''){
                    this.focusId(this.id_form + '-data_emissao');
                    this.$noty.warning('Informe a data de emissão!',{layout: 'center', progressBar: false, timeout:1500});
                    return false
                }
                if(this.status_nf === null || this.status_nf === ''){return false}
                if(parseInt(this.status_nf) > 1){
                    if(this.data_recebimento === null  || this.data_recebimento === ''){
                        this.focusId(this.id_form + '-data_recebimento');
                        this.$noty.warning('Informe a data de recebimento!',{layout: 'center', progressBar: false, timeout:1500});

                        return false
                    }
                }

                return true;
            },
            editForm(option){
                if(option === true){
                    this.edit_form = true;
                }else{
                    this.edit_form = false;
                }
            },
            focusId(id){
                setTimeout(() => {
                    document.getElementById(id).focus();
                },100);
            },
            modalNF(option){
                if(option === true){bus.$emit('show_modal-modal_danfe');}else{bus.$emit('hidde_modal-modal_danfe');}
            },
            modalConfirmRecebimento(){
                this.disableDataRecebimento();
                this.keyConfirmRecebimento++;
                setTimeout(() => {
                    bus.$emit('show_modal-' + this.id_form + '-modal_confirmar_recebimento_nf',{});
                    // if(this.data_recebimento === null){document.getElementById(this.id_form + '-data_confirmar_recebimento').click();}
                },100);
            },
            confirmRecebimento(){
                var password_confirm = false;
                var assinar_anexos = false;
                if (this.n_anexos > 0 && this.config.anexos && this.config.anexos.assinar_anexos && this.config.anexos.assinar_automaticamente_recebimento_nf){
                    password_confirm = true;
                    assinar_anexos = true;
                }

                bus.$emit('gerencia-request', {
                    password_confirm:password_confirm,
                    url:this.url + '/gerenciar_request/nfs',
                    data:{
                        function:'update_status_nf',
                        assinar_anexos:assinar_anexos,
                        id_nf:this.id_nf,
                        id_ordem:this.id_ordem,
                        data_emissao:this.data_emissao,
                        data_recebimento:this.data_recebimento,
                        status_nf:2,
                    },
                    bus_emit:['refresh-cards_nfs','hidde_modal-' + this.id_form + '-modal_confirmar_recebimento_nf'],
                    loading:['add_spin'],
                    id_loading:this.id_form + '-btn_confirm_recebimento',
                    emit_request:[
                        {
                            url:this.url + '/gerenciar_request/nfs',
                            data:{
                                function:'get_nf',
                                id_nf:this.id_nf,
                            },
                            bus_emit:[this.id_form + '-get_nf','refresh-pedido'],
                            loading:['page']
                        }
                    ],
                });
            },
            disableOpcoesExtras(){
                this.opcoesExtras = false;
                this.btn_ver_nf = false;
            },
            updateAnotacoes(){
                if(this.status_form === 'consulta'){
                    bus.$emit('gerencia-request', {
                        url:this.url + '/gerenciar_request/nfs',
                        data:{
                            function:'update_anotacoes_nf',
                            id_nf:this.id_nf,
                            anotacoes:this.anotacoes,
                        },
                        bus_emit:['refresh-cards_nfs'],
                        loading:['add_spin'],
                        id_loading:this.id_form + '-btn_update_anotacoes',
                        emit_request:[
                            {
                                url:this.url + '/gerenciar_request/nfs',
                                data:{
                                    function:'get_nf',
                                    id_nf:this.id_nf,
                                },
                                bus_emit:[this.id_form + '-get_nf'],
                                loading:['page']
                            }
                        ],
                    });
                }else if(this.status_form === 'registro'){
                    bus.$emit('gerencia-request', {
                        url:this.url + '/gerenciar_request/nfs',
                        data:{
                            function:'update_anotacoes_nf',
                            id_nf:this.id_nf,
                            anotacoes:this.anotacoes,
                        },
                        bus_emit:['refresh-cards_nfs'],
                        loading:['add_spin'],
                        id_loading:this.id_form + '-btn_update_anotacoes',
                    });
                    this.display_anotacoes = 'read';
                }
            },
            updateStatus(){
                if(parseInt(this.status_nf) > 1){
                    if(this.data_recebimento === null  || this.data_recebimento === '' || (new Date(this.data_recebimento)).setHours(0,0,0,0) < (new Date(this.data_emissao)).setHours(0,0,0,0)){
                        this.focusId(this.id_form + '-data_recebimento');
                        this.$noty.warning('Informe a data de recebimento!',{layout: 'center', progressBar: false, timeout:1500});
                        return false
                    }
                }
                bus.$emit('gerencia-request', {
                    url:this.url + '/gerenciar_request/nfs',
                    data:{
                        function:'update_status_nf',
                        id_nf:this.id_nf,
                        id_ordem:this.id_ordem,
                        data_emissao:this.data_emissao,
                        data_recebimento:this.data_recebimento,
                        status_nf:this.status_nf,
                    },
                    bus_emit:['refresh-cards_nfs'],
                    loading:['add_spin'],
                    id_loading:this.id_form + '-btn_update_status',
                    emit_request:[
                        {
                            url:this.url + '/gerenciar_request/nfs',
                            data:{
                                function:'get_nf',
                                id_nf:this.id_nf,
                            },
                            bus_emit:[this.id_form + '-get_nf','refresh-ordem','refresh-pedido','refresh-tabela-pedidos_item_nfs'],
                            loading:['page']
                        }
                    ],
                });
            },
            baixar(obj){
                this.$emit('baixar',obj);
            },
            removerAnexo(id_anexo){
                bus.$emit('gerencia-request', {
                    confirm_alert:'confirm_remover_anexo_nf',
                    url:this.url + '/gerenciar_request/anexos',
                    data:{
                        function:'remover_anexo_nf',
                        id_anexo:id_anexo,
                        id_nf:this.id_nf
                    },
                    bus_emit:['refresh-cards_nfs','form_danfe-refresh_anexos_nf'],
                    loading:['page']
                });
            },
            getAnexos(){
                bus.$emit('gerencia-request', {
                    url:this.url + '/gerenciar_request/anexos',
                    data:{
                        function:'get_anexos_nf',
                        id_nf:this.id_nf
                    },
                    bus_emit:[this.id_form + '-get_anexos'],
                    loading:['page']
                });
            },
            busemit(emit,obj){
                bus.$emit(emit,obj)
            },
            iconeAnexo(format){
                switch (format) {
                    case 'jpg':
                    case 'JPG':
                    case 'jpeg':
                    case 'JPEG':
                    case 'bmp':
                    case 'png':
                    case 'PNG':
                        return '<i class="far fa-file-image" style="color: #3498db"></i>';
                    case 'doc':
                    case 'docx':
                        return '<i class="far fa-file-word text-primary"></i>';
                    case 'txt':
                        return '<i class="far fa-file-alt"></i>';
                    case 'pdf':
                        return '<i class="far fa-file-pdf text-danger"></i>';
                    case 'xls':
                    case 'xlsx':
                        return '<i class="fas fa-file-excel text-success"></i>';
                    case 'zip':
                    case 'rar':
                        return '<i class="far fa-file-archive text-muted"></i>';
                    default:
                        return '<i class="far fa-file"></i>';
                }
            },
            featherIcons(){
                setTimeout(function (){feather.replace();},200)
            },
            excluirNF(){
                bus.$emit('gerencia-request', {
                    confirm_alert:{content:'Tem certeza que deseja excluir a NF <strong>' + this.numero_nf + '</strong>  - ' + this.razao_social + '? <br><br> Essa ação implicará em possíveis alterações nos pedidos e ordens vinculados à NF (Alteração do status de itens e ordens).',class:'border-danger',btn:'btn btn-sm btn-block btn-danger'},
                    password_confirm:true,
                    url:this.url + '/gerenciar_request/nfs',
                    data:{
                        function:'excluir_nf',
                        id_ordem:this.id_ordem,
                        id_nf:this.id_nf
                    },
                    bus_emit:['hidde_modal-modal_danfe','refresh-cards_nfs','refresh-ordem','refresh-pedido','refresh-tabela-pedidos_item_nfs'],
                    loading:['page']
                });
            },
            assinarAnexo(obj){
              this.$emit('assinar_anexo',obj);
            },
            round(value, decimals) {
                return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
            }
        },
        computed: {
            form_: {
                get: function() {
                    return this.status_form;
                },
                set: function(val) {
                    this.atividade = null;
                    switch (val) {
                        case 'registro':
                            if(this.status_form === 'consulta'){
                                this.resetForm();
                            }
                            this.modalNF(true);
                            break;
                        case 'consulta':
                            this.btn_nova_danfe = false;
                            this.btn_change_status = false;
                            this.btn_registrar = false;
                            this.edit_form = false;
                            this.upload_anexos_registro = false;
                            break;
                    }
                    this.status_form = val;
                }
            },
        },
        watch: {
            status_nf: function (newVal, oldVal) {
                switch (parseInt(newVal)) {
                    case 1:
                        this.status_nf_format = 'bg-lightgold';
                        if(this.status_form === 'registro'){this.disableDataRecebimento();}else{this.data_recebimento_show = false;}
                        break;
                    case 2:
                        this.status_nf_format = 'bg-lightsuccess';
                        if(this.data_recebimento_show === false && this.data_emissao !== null){this.enableDataRecebimento();}
                        break;
                    case 3:
                        this.status_nf_format = 'bg-lightprimary';
                        if(this.data_recebimento_show === false && this.data_emissao !== null){this.enableDataRecebimento();}
                        break;
                    case 4:
                        this.status_nf_format = 'bg-lightgrey';
                        if(this.data_recebimento_show === false && this.data_emissao !== null){this.enableDataRecebimento();}
                        break;
                }

                if(this.status_form === 'consulta' && parseInt(newVal) !== parseInt(this.status_nf_validation)){this.btn_change_status = true;}else{this.btn_change_status = false;}
            }
        },
        created() {
            bus.$on('set-' + this.id_form, (obj) => {
                if(obj.form){this.form_ = obj.form;}
            });

            bus.$on(this.id_form + '-get_nf', (obj) => {
                this.form_ = 'consulta';
                this.waiting_request = false;
                setTimeout(() => {this.setNF(obj);},100);
            });

            bus.$on(this.id_form + '-get_historico_nf', (obj) => {
                this.atividade = null;
                this.waiting_request = false;
                this.historico = obj;
            });

            bus.$on(this.id_form + '-refresh_anexos_nf', (obj) => {
                this.atividade = null;
                this.waiting_request = false;
                this.getAnexosNF();
            });

            bus.$on(this.id_form + '-confirm_recebimento', (obj) => {
                this.setNF(obj);
                this.modalConfirmRecebimento();
            });

            bus.$on(this.id_form + '-get_response_numero_nf', (obj) => {
                this.waiting_request = false;
                this.atividade = null;
                if(obj.id_nf){
                    this.registro = obj;
                    this.atividade = "<span class='text-danger font-size-09'>Esta Nota Fiscal já foi registrada para <strong>" + this.registro.nome_unidade + "</strong>!</span>";
                    this.opcoesExtras = true;
                    if(parseInt(this.tipo_usuario) === 1 || (this.permissao_gestor && parseInt(this.permissao_gestor[13]) > 0) || (this.permissao_unidade && parseInt(this.permissao_unidade[16]) > 0 && parseInt(this.registro.id_unidade) === parseInt(this.unidade))){
                        this.btn_ver_nf = true;
                    }
                    this.editNumeroNF();
                }else{
                    this.disabledNumeroNF(true);
                    if(this.ordens.data){
                        bus.$emit(this.id_form + '-get_response_ordens', this.ordens);
                    }else{
                        this.getOrdens(this.id_fornecedor);
                    }
                    this.campo_numero_nf_edit = true;
                    this.campo_numero_nf_label = "Informe";
                }
            });

            bus.$on(this.id_form + '-get_response_ordens', (obj) => {
                this.waiting_request = false;
                this.atividade = null;
                this.ordens = obj;
                if(this.id_fornecedor === null){return false;}
                if(this.ordens.data.length > 0){
                    if(this.numero_nf === null || this.numero_nf === ''){
                        this.editNumeroNF();
                    }else{
                        if(this.ordens.data.length === 1){
                            bus.$emit(this.id_form + '-get_ordem', this.ordens.data[0]);
                        }else{
                            bus.$emit('get_response-' + this.id_form + '-tabela-ordens', this.ordens);
                            this.tabela_ordens = true;
                        }
                    }
                }else{
                    this.atividade = "<span class='text-danger font-size-09'>Fornecedor não possue pendência " + (this.id_pedido ? "<strong>para este pedido</strong>" : "") + "!</span>";
                }
            });

            bus.$on(this.id_form + '-get_response_unidades', (obj) => {
                this.waiting_request = false;
                if(this.id_fornecedor === null || this.id_ordem === null){return false;}
                this.atividade = null;
                this.unidades = obj;
                if(this.unidades.data.length === 1){
                    bus.$emit(this.id_form + '-get_unidade', this.unidades.data[0]);
                }else{
                    this.tabela_unidades = true;
                }
                bus.$emit('get_response-' + this.id_form + '-tabela-unidades', this.unidades);
            });

            bus.$on(this.id_form + '-get_response_itens_unidade', (obj) => {
                this.waiting_request = false;
                if(this.id_fornecedor === null || this.id_ordem === null || this.id_unidade === null){return false;}
                this.atividade = null;
                this.itens_incluir = obj.data;
                this.tabela_itens_incluir = true;
            });

            bus.$on(this.id_form + '-get_anexos', (obj) => {
                this.anexos = obj;
            });

            bus.$on(this.id_form + '-get_ordem', (obj) => {
                this.waiting_request = false;
                this.setOrdem(obj);
                // if((this.id_pedido && this.id_unidade_usuario) || (parseInt(this.tipo_usuario) !== 1 && (parseInt(this.permissao_gestor) === 0 || this.permissao_gestor === null) && parseInt(this.permissao_unidade) > 0)){
                //     bus.$emit(this.id_form + '-get_unidade', {
                //         id_unidade:parseInt(this.id_unidade_usuario),
                //         nome_unidade:this.nome_unidade_usuario
                //     });
                // }else{
                //     this.getUnidadesOrdem();
                // }
                if((this.permissao_gestor && parseInt(this.permissao_gestor[13]) === 2) || parseInt(this.tipo_usuario) === 1){
                    this.getUnidadesOrdem();
                } else{
                    bus.$emit(this.id_form + '-get_unidade', {
                        id_unidade:parseInt(this.id_unidade_usuario),
                        nome_unidade:this.nome_unidade_usuario
                    });
                }
            });

            bus.$on(this.id_form + '-get_unidade', (obj) => {
                this.waiting_request = false;
                this.setUnidade(obj);
                this.getItensUnidadeOrdem();
            });

            bus.$on(this.id_form + '-get_response_registrar_nf', (obj) => {
                this.waiting_request = false;
                this.btn_registrar = false;
                this.btn_nova_danfe = true;
                this.id_nf = obj.id_nf;
                // this.busemit('submit-anexos_nf',{});
                bus.$emit('submit-anexos_nf_registro',{data:{id_nf:obj.id_nf}});
                this.show_anotacoes = false;
                this.editForm(false);
            });
        },
        destroyed() {
            bus.$off('set-' + this.id_form);
            bus.$off(this.id_form + '-get_nf');
            bus.$off(this.id_form + '-get_historico_nf');
            bus.$off(this.id_form + '-refresh_anexos_nf');
            bus.$off(this.id_form + '-confirm_recebimento');
            bus.$off(this.id_form + '-get_response_numero_nf');
            bus.$off(this.id_form + '-get_response_ordens');
            bus.$off(this.id_form + '-get_response_unidades');
            bus.$off(this.id_form + '-get_response_itens_unidade');
            bus.$off(this.id_form + '-get_anexos');
            bus.$off(this.id_form + '-get_ordem');
            bus.$off(this.id_form + '-get_unidade');
            bus.$off(this.id_form + '-get_response_registrar_nf');
        },
    }
</script>

<style scoped>
    @media(max-width: 900px){
        .w-sm-4{
            width: 4rem;
        }
    }
</style>
