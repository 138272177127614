<template>
    <!--  MODAL CONFIRM ALERT  -->
    <div class="modal fade" id="confirm_alert-gerencia_request" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div :class="'modal-content shadow ' + confirm_alert_class">
                <div class="modal-body">
                    <div v-html="confirm_alert_content"></div>
                    <div class="row pt-3">
                        <div class="col">
                            <button class="btn btn-sm btn-block btn-light" data-toggle="modal" data-target="#confirm_alert-gerencia_request" v-on:click="declineConfirmAlert(confirm_alert_request)">
                                Não
                            </button>
                        </div>
                        <div class="col">
                            <button :class="(confirm_alert_btn ? confirm_alert_btn : 'btn btn-sm btn-block btn-secondary')" v-on:click="requestConfirmAlert(confirm_alert_request)">
                                Sim
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {bus} from '../../app';
    //Sweet Alert 2
    import Swal from 'sweetalert2/dist/sweetalert2.js';
    import 'sweetalert2/src/sweetalert2.scss';
    export default {
        props:['path','emit','checkbox','select','click'],
        data: function () {
            return {
                confirm_alert_class:'',
                confirm_alert_btn:null,
                confirm_alert_content:'',
                confirm_alert_request:'',
                fila:[],
                runing_fila:false,
            }
        },
        methods:{
          runFila(){
              if(this.fila.length === 1 && !this.runing_fila){
                  var req = this.fila.shift();
                  this.runing_fila = true;
                  this.request(req);
              }
          },
          requestConfirmAlert(obj){
              $('#confirm_alert-gerencia_request').modal('hide');
              obj.confirm_alert_autorized = true; //Precisa ser adicionado para que não seja bloqueada novamente
              this.request(obj);
          },
          declineConfirmAlert(obj){
              if(obj.confirm_alert.decline){
                  var decline = obj.confirm_alert.decline;
                  if(decline.request){
                      return this.request(decline.request);
                  }
                  if(decline.bus_emit) {
                      this.busEmit(decline.bus_emit,{});
                  }
              }
          },
          request(obj){
              if(obj.confirm_alert && !obj.confirm_alert_autorized){ //Abrir alerta para confirmação da Request. Essa tarefa será interrompida , mas poderá ser reiniciada através do componente ConfirmAlert
                  let request = obj;
                  this.confirmAlert(request);
                  if(this.fila.length > 0){
                      this.request(this.fila.shift());
                  }else{
                      this.runing_fila = false;
                  }
                  return false;
              }else if(obj.password_confirm){
                  var request = obj;
                  bus.$emit('password_confirm',request);
                  if(this.fila.length > 0){
                      this.request(this.fila.shift());
                  }else{
                      this.runing_fila = false;
                  }
                  return false;
              }

              if(obj.redirect){window.location.href = obj.url;}

              if(obj.loading){this.loading(obj);}
              //Verificando a opção de montar URL
              if(obj.montar_url){
                  obj.url = '';
                  for(let part in obj.montar_url){
                      if(part === 'chave_store'){
                          if(this.$store.state.registro[obj.montar_url[part]] === undefined){
                              // console.log('Request não pode ser enviada. chave_store => ' +  obj.montar_url[part] + ' indefinida');
                              if(this.fila.length > 0){
                                  this.request(this.fila.shift());
                              }else{
                                  this.runing_fila = false;
                              }
                              this.end_loading(obj);
                              return false;
                          }
                          obj.url+= this.$store.state.registro[obj.montar_url[part]];
                      }else{
                          obj.url+= obj.montar_url[part];

                      }
                  }
              }

              //Verificando função get_input
              if(obj.get_input){obj = this.getInput(obj);}
              if(obj.get_radio){obj = this.getRadio(obj);}
              if(obj.get_store){
                  obj = this.getStore(obj);
                  if (!obj){
                      return false;
                  }
              }
              if(obj.get_state){obj = this.getState(obj);}

              axios({
                  url: obj.url,
                  method: obj.method || 'POST',
                  token: obj.token,
                  data:obj.data
              }).then(res => {
                  if(this.fila.length > 0){
                      this.request(this.fila.shift());
                  }else{
                      this.runing_fila = false;
                  }
                  this.response(res,obj);
              }).catch(error => {
                  this.end_loading(obj);
                  if(this.fila.length > 0){
                      this.request(this.fila.shift());
                  }else{
                      this.runing_fila = false;
                  }
                  if (error.response.status === 419){ //Sessão Expirada
                      Swal.fire({
                          title: 'Sessão expirada!',
                          text: 'A sessão expirou, iremos redirecioná-lo para a tela de login.',
                          icon: 'error',
                          showConfirmButton:false,
                          backdrop: false,
                          allowEscapeKey:false,
                          allowEnterKey:false
                      });
                      setTimeout(()=>{window.location.href = this.path + '/login'},5000);
                      return false;
                  }
                  if(error.response.data && error.response.data.errors) {
                      var erros = error.response.data.errors;
                      for(var erro in erros){
                          var el = document.getElementById(erro);
                          if(el){
                              this.$noty.error(erros[erro][0],{layout:'center', timeout:2000});
                              el.focus();
                              el.classList.add('is-invalid');
                              setTimeout(() => {el.classList.remove('is-invalid');},3000);
                          }else{
                              this.$noty.error(erros[erro][0],{layout:'center', timeout:3000});
                          }
                          break;
                      }
                  }else if(error.response.data.code){
                      if(error.response.data.id_element){
                          var el = document.getElementById(error.response.data.id_element);
                          if(el){
                              el.focus();
                              el.classList.add('is-invalid');
                              setTimeout(() => {el.classList.remove('is-invalid');},7000);
                          }
                      }
                      this.$noty.error(error.response.data.message,{
                          layout:'center',
                          timeout:5000
                      });
                  } else{
                      this.$noty.error('A ação não pode ser executada!',{
                          layout:'center',
                          timeout:3000
                      });
                  }
              });

          },
          response(res,obj = {}){
              this.end_loading(obj);
              //response = redirect
              if(res.data.force){return this.force(res.data.force,obj)}
              if(res.data.checked){this.checked_icon(res.data.checked)}
              if(res.data.redirect){this.redirect(res);}
              else if(res.data.redirect_fast){window.location.href = res.data.redirect_fast;}
              if(obj.redirect){window.location.href = obj.redirect;}
              //response = refresh
              if(res.data.refresh){document.location.reload();}
              //Notificação/Noty com redirecionamento
              if(res.data.redirect_noty){this.redirectNoty(res);}
              if(res.data.sweet_alert){this.sweetAlert(res);}

              //Notificação/Noty
              if(res.data.status){this.noty(res);}
              //Mensagem via código HTML
              if(res.data.msg_html){this.msgHTML(res);}
              //Emitir aviso que o Registro do store foi modificado
              if(obj.set_registro){this.$store.commit('setRegistro',res.data);}
              if(obj.set_state){this.commitState(obj.set_state,res.data);}
              if(obj.set_substate){this.commitSubState(obj.set_substate,res.data);}
              if(obj.set_store){
                  if(obj.set_store.chave){
                      this.$store.state[obj.set_store.state][obj.set_store.chave] = obj.set_store.value;
                  }else{
                      this.$store.state[obj.set_store.state] = obj.set_store.value;
                  }
              }
              //Fechar Modal
              if(obj.fade_modal){document.getElementById('close-' + obj.fade_modal).click();}
              //Limpar campos
              if(obj.clear_input || obj.clear_store || obj.clear_state){this.limparCampos(obj);}
              //Focus
              if(obj.focus_input || res.data.focus_input){
                  setTimeout(function () {
                      if (document.getElementById(obj.focus_input) || document.getElementById(res.data.focus_input)) {
                          document.getElementById(obj.focus_input || res.data.focus_input).focus();
                      }
                  }, 500);
              }
              //bus_emit
              if(obj.bus_emit){this.busEmit(obj.bus_emit,res);}
              if(res.data.bus_emit){this.busEmit(res.data.bus_emit,res);}
              //bus_emit obj
              if(obj.bus_emit_obj){this.busEmitObj(obj);}
              //bus_emit_nav
              if(obj.bus_emit_nav){this.busEmit(obj.bus_emit_nav[this.$store.state.nav],res);}
              if(obj.bus_emit_sub_nav){this.busEmit(obj.bus_emit_sub_nav[this.$store.state.subnav],res);}
              //emit_request
              if(obj.emit_request){this.emitRequest(obj);}
              if(obj.reicons){this.featherIcons();}
              if(obj.confirm_alert){delete obj.confirm_alert_autorized;}//Precisa ser removido para que requisições da fila ou subsequentes não sejam autorizadas sem confirmação
          },
          confirmAlert(obj){
              if(typeof obj.confirm_alert === 'string'){
                  bus.$emit(obj.confirm_alert,obj);
              }else{
                  this.confirm_alert_content = obj.confirm_alert.content;
                  this.confirm_alert_class = obj.confirm_alert.class;
                  this.confirm_alert_btn = obj.confirm_alert.btn;
                  this.confirm_alert_request = obj;
                  $('#confirm_alert-gerencia_request').modal('show');
              }
          },
          loading(obj) {
              var data = {};
              for(let load in obj.loading){
                  data.type = obj.loading[load];
                  data.id = obj.id_loading || '';
                  data.display = true;
              }
              if(obj.element_loading){
                  data.element = obj.element_loading;
              }
              bus.$emit('loading',data);
          },
          end_loading(obj) {
              var data = {};
              for(let load in obj.loading){
                  data.type = obj.loading[load];
                  data.id = obj.id_loading;
                  data.display = false;
              }
              bus.$emit('loading',data);
          },
          force(force,obj = {}){
              this.$store.state.force = force.data;
              if(force.bus_emit){bus.$emit(force.bus_emit,force);}
              if(force.confirm_alert){
                  obj.confirm_alert = force.confirm_alert;
                  obj.data.force = true;
                  this.confirmAlert(obj);
              }
              return false;
          },
          checked_icon(id_element){
              var el = document.getElementById(id_element);
              if(el){
                  var coord = el.getBoundingClientRect();
                  $('#app').append("<div id='checked-"+ id_element +"' class='text-success position-absolute p-2' style='z-index: 2000;top:" + (coord.y + window.scrollY) + "px;left: " + ( coord.x + window.scrollX ) + "px '>" +
                      "<i class='fas fa-check text-success' title='Alterado'></i>" +
                      "</div>");
                  setTimeout(function () {
                      $('#checked-'+ id_element).remove();
                  },750)
              }
          },
          redirect(res){
              this.$noty.info('Redirecionando...',{
                  layout: 'bottomCenter',
                  progressBar: true,
                  timeout:2000
              });
              setTimeout(function () {
                  window.location.href = res.data.redirect;
              },2000);
          },
          noty(res){
              //notificações
              if(res.data.status === 'success'){
                  this.$noty.success(res.data.msg,{
                      layout:  res.data.layout || 'bottomRight',
                      timeout: res.data.timeout || 3000
                  });
              }else if(res.data.status === 'warning'){
                  this.$noty.warning(res.data.msg,{
                      layout:  res.data.layout || 'bottomRight',
                      timeout: res.data.timeout || 3000
                  });
              }else if(res.data.status === 'danger'){
                  this.$noty.error(res.data.msg,{
                      layout:  res.data.layout || 'bottomRight',
                      timeout: res.data.timeout || 3000
                  });
              }else if(res.data.status === 'info'){
                  this.$noty.info(res.data.msg,{
                      layout:  res.data.layout || 'bottomRight',
                      timeout: res.data.timeout || 3000
                  });
              }
          },
          sweetAlert(res){
              Swal.fire({
                  title: res.data.sweet_alert.title || '',
                  text: res.data.sweet_alert.text || '',
                  icon: res.data.sweet_alert.icon || undefined,
                  iconHtml: res.data.sweet_alert.icon_html || undefined,
                  showConfirmButton:false,
                  timer:res.data.sweet_alert.timer || 5000,
              });
          },
          redirectNoty(res) {
              switch(res.data.redirect_noty.status) { //Inserindo mensagem no elemento
                  case 'info':
                      this.$noty.info(res.data.redirect_noty.msg,{
                          layout: 'center',
                          progressBar: true,
                          timeout:res.data.redirect_noty.timeout
                      });
                      break;
                  case 'success':
                      this.$noty.success(res.data.redirect_noty.msg,{
                          layout: 'center',
                          progressBar: true,
                          timeout:res.data.redirect_noty.timeout
                      });
                      break;
                  case 'warning':
                      this.$noty.warning(res.data.redirect_noty.msg,{
                          layout: 'center',
                          progressBar: true,
                          timeout:res.data.redirect_noty.timeout
                      });
                      break;
                  case 'danger':
                      this.$noty.error(res.data.redirect_noty.msg,{
                          layout: 'center',
                          progressBar: true,
                          timeout:res.data.redirect_noty.timeout
                      });
                      break;
                  default:
                      this.$noty.show(res.data.redirect_noty.msg,{
                          layout: 'center',
                          progressBar: true,
                          timeout:res.data.redirect_noty.timeout
                      });
              }

              setTimeout(function () {
                  window.location.href = res.data.redirect_noty.url;
              },res.data.redirect_noty.timeout);
          },
          getInput(obj){
              var i = 0;
              for (i = 0; i < obj.get_input.length; i++) {
                  if (typeof obj.get_input[i] === 'string' && document.getElementById(obj.get_input[i])) {
                      if (document.getElementById(obj.get_input[i]).hasAttribute('required') && document.getElementById(obj.get_input[i]).value === '' && !document.getElementById(obj.get_input[i]).hasAttribute('hidden')) {
                          this.$noty.error('Preencha os campos obrigatórios antes de prosseguir!', {timeout: 4000});
                          document.getElementById(obj.get_input[i]).focus();
                          document.getElementById(obj.get_input[i]).classList.add('is-invalid');
                          setTimeout(() => {
                              document.getElementById(obj.get_input[i]).classList.remove('is-invalid');
                          }, 3000);
                          this.end_loading(obj);
                          return false;
                      }
                      obj.data[obj.get_input[i]] = document.getElementById(obj.get_input[i]).value || '';

                  } else if (typeof obj.get_input[i] === 'object' && document.getElementById(obj.get_input[i].id)) {
                      var el = document.getElementById(obj.get_input[i].id);
                      obj.data[obj.get_input[i].chave] = (el ? el.value : null);
                  }
              }
              return obj;
          },
          getRadio(obj){
              var i = 0;
              for (i = 0; i < obj.get_radio.length; i++) {
                  obj.data[obj.get_radio[i]] = document.querySelector('input[name=' + obj.get_radio[i]  + ']:checked').value;
              }
              return obj;
          },
          getStore(obj){
              var i = 0;
              for (i = 0; i < obj.get_store.length; i++) {
                  if(typeof obj.get_store[i] === 'string'){
                      obj.data[obj.get_store[i]] = this.$store.state.registro[obj.get_store[i]];
                  }else{
                      var val = this.$store.state[obj.get_store[i].state][obj.get_store[i].chave];
                      if(obj.get_store[i].required && !val){
                          var msg_erro = obj.get_store[i].required.msg_erro || 'Preencha os campos obrigatórios antes de prosseguir!';
                          this.$noty.error(msg_erro,{layout:'center', timeout:3000});
                          this.end_loading(obj);
                          if(this.fila.length > 0){
                              this.request(this.fila.shift());
                          }else{
                              this.runing_fila = false;
                          }
                          return false;
                      }
                      obj.data[obj.get_store[i].chave] = val;
                  }
              }
              return obj;
          },
          getState(obj){
              var i = 0;
              for (i = 0; i < obj.get_state.data.length; i++) {
                  obj.data[obj.get_state.data[i]] = this.$store.state[obj.get_state.state][obj.get_state.data[i]];
              }
              return obj;
          },
          msgHTML(res){
              var element = document.getElementById(res.data.msg_html.id_element);
              if(res.data.msg_html.status){
                  element.innerHTML = '<div class="alert alert-' + res.data.msg_html.status + '">' +
                      res.data.msg_html.msg + ' ' +
                      (res.data.msg_html.button ? this.buttonHTML(res) : '') +
                      '</div>';
              }else{
                  element.innerHTML = res.data.msg_html.msg + (res.data.msg_html.button ? this.buttonHTML(res) : '');
              }
          },
          buttonHTML(res){
              var btn = '<button ';

              if (res.data.msg_html.button.link) {
                  btn += 'onclick="botaoLink(\'' + res.data.msg_html.button.link + '\')" '
              }

              btn += " type='button' class='" + res.data.msg_html.button.class + "' title='" + res.data.msg_html.button.title + "'>" + res.data.msg_html.button.text + "</button>"

              return btn;
          },
          limparCampos(obj){
              var i;
              if(obj.clear_input){
                  for (i = 0; i < obj.clear_input.length; i++) {
                      document.getElementById(obj.clear_input[i]).value = '';
                  }
              }

              if(obj.clear_store && obj.get_store){
                  for (i = 0; i < obj.get_store.length; i++) {
                      this.$store.state.registro[obj.clear_store[i]] = '';
                  }
              }

              if(obj.clear_state){
                  for (i = 0; i < obj.clear_state.data.length; i++) {
                      this.$store.state[obj.clear_state.state][obj.clear_state.data[i]] = '';
                  }
              }
          },
          busEmit(bus_emit,res){
              if(!bus_emit){return false;}
              var i;
              for (i = 0; i < bus_emit.length; i++) {
                  if(typeof bus_emit[i] === 'string'){
                      bus.$emit(bus_emit[i],res.data);
                  }else{
                      let emit = bus_emit[i];
                      setTimeout(() => {bus.$emit(emit.emit,res.data);},emit.timeout);
                  }
              }
          },
          busEmitObj(obj) {
              var emit;
              for (emit in obj.bus_emit_obj) {
                  bus.$emit(emit,obj.bus_emit_obj[emit]);
              }
          },
          emitRequest(obj){
              //buscando objeto emit_request
              let objeto = obj.emit_request;
              for(let emit in objeto){
                  let linha = objeto[emit];
                  if(linha.data){
                      var instancia = linha.data;
                      for(let dados in instancia){
                          //Alterar palavra 'chave_store' pelo valor da variável caso a palavra seja encontrada no abjeto
                          if(instancia[dados] === 'chave_store'){
                              instancia[dados] = this.$store.state.registro[dados];
                          }
                      }

                      linha.data = instancia;
                  }
                  //Emitindo bus_emit
                  bus.$emit('gerencia-request',linha);
              }
          },
          commitState(state,data){
              if(typeof state === 'string'){
                  this.$store.state[state] = data;
              }else{
                  console.log(state);
                  this.$store.state[state.state][state.chave] = data;
                  console.log(this.$store.state[state.state][state.chave]);
              }
          },
          commitSubState(substate,data){
              this.$store.state[substate.state][substate.substate] = data;
          },
          clicar(click,tentativas = 0){
              if(tentativas  === 20){return false;} //4 segundos
              var elemento = document.getElementById(click);
              if(elemento){
                  elemento.click();
              }else{
                  setTimeout(()=>{
                      this.clicar(click,tentativas + 1)
                  },200)
              }
          },
          clearEvent(event_session){
              setTimeout(() => {
                  bus.$emit('gerencia-request', {
                      url: this.path + '/gerenciar_request/notificacoes',
                      data:{
                          'function':'clear_session',
                          session:event_session
                      },
                  });
              },1000)
          },
          featherIcons(){setTimeout(function (){feather.replace();},50)},
        },
        created(){
            this.$store.state.modal['confirm_alert-gerencia_request'] = true;
            bus.$on('gerencia-request', obj => {
                this.fila.push(obj);
                this.runFila();
            });

            bus.$on('gerencia-response', obj => {
                this.response(obj)
            });
        },
        mounted() {
            if(this.emit){
                setTimeout(() => {bus.$emit(this.emit);},1200);
                this.clearEvent('emit');
            }else if(this.checkbox){
                setTimeout(() => {document.getElementById(this.checkbox).click();},1000);
                this.clearEvent('checkbox');
            }else if(this.select){
                setTimeout(() => {bus.$emit('set-' + this.select.busca,{select:{id:this.select.id,chave:this.select.chave,valor:this.select.val}});},500);
                this.clearEvent('select');
            }else if(this.click){
                this.clicar(this.click);
                this.clearEvent('click');
            }

            $('#confirm_alert-gerencia_request').on("hidden.bs.modal", () => {
                for(var modal in this.$store.state.modal){
                    if(document.getElementById(modal) && document.getElementById(modal).classList.contains('show')){
                        document.getElementById('body_page').classList.add('modal-open');
                    }
                }
            }).on('shown.bs.modal', () => {
                //
            });
        }
    }
</script>
