<template>
    <!-- CAMPO DE BUSCA -->

    <div class="row">
        <div v-if="busca" :class="'mb-1 mb-lg-0 ' + ( class_busca || 'col-lg-auto')">
            <form autocomplete="off" v-on:submit.prevent>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <div class="input-group-text" :id="'label-campo_buscar-' + id_busca"><i data-feather="search" class="icon-md cursor-pointer"></i></div>
                    </div>
                    <input type="search" :id="id_busca" :name="id_busca" class="form-control" :placeholder="placeholder_busca || 'Buscar'" v-model="buscar" autocomplete="off">
                </div>
            </form>
        </div>

        <div v-if="filtros" class="col-lg-auto pl-md-1 mb-1 mb-lg-0 form-inline">
                <span v-for="(filtro,index) in filtros" class="">
                    <div v-if="filtro.titulo"  class="input-group rounded border border-white" :title="filtro.title">
                        <div class="input-group-prepend">
                            <span :id="'label-' + filtro.id_filtro" class="input-group-text font-weight-light">{{filtro.titulo}}</span>
                        </div>

                        <select class="form-control text-dark cursor-pointer mr-1" :id="filtro.id" v-on:change="changeFiltro(filtro)">
                            <option v-for="(option,index) in filtro.options" :value="option.valor">{{option.titulo}}</option>
                        </select>
                    </div>

                    <select v-else class="form-control text-dark cursor-pointer mr-1" :id="filtro.id" v-on:change="changeFiltro(filtro)">
                            <option v-for="(option,index) in filtro.options" :value="option.valor">{{option.titulo}}</option>
                    </select>
                </span>
        </div>

        <div v-if="checkbox" class="col-lg-auto d-flex align-items-end justify-content-start">
            <div v-for="check in checkbox" :class="'custom-control custom-checkbox custom-control-inline pt-2' + (condicao(check) ? '' : ' d-none')" :title="check.title">
                <input v-if="condicao(check)" type="checkbox" class="custom-control-input cursor-pointer" :id="check.id" :value="check.value" v-on:change="changeCheckbox(check)">
                <label v-if="condicao(check)" class="custom-control-label font-size-08 pt-1 cursor-pointer" :for="check.id" v-html="check.label"></label>
            </div>
        </div>

        <div v-if="hidde_filtros || $slots.hidde_filtros" class="col-lg-auto d-flex align-items-end">
            <button v-if="!display_filtros" class="btn btn-link p-0" type="button" data-toggle="collapse" :data-target="'#' + set_componente + '-hidde_filtros'" aria-expanded="false" :aria-controls="set_componente + '-hidde_filtros'" v-on:click="display_filtros = true">
                <span>mais filtros</span>
            </button>
        </div>

        <div v-if="hidde_filtros || $slots.hidde_filtros" :id="set_componente + '-hidde_filtros'" class="col-12 collapse">
                <div v-for="(filtro,index) in hidde_filtros" :class="'pt-2 ' + filtro.class_filtro">
                    <select-3
                        v-if="filtro.type === 'select_api'"
                        :id_select="filtro.id"
                        :class_select="filtro.class"
                        :placeholder_select="filtro.placeholder"
                        :placeholder_dropdown="filtro.placeholder_dropdown"
                        :required="filtro.required"
                        title_select=""
                        :options="filtro.key_label_options"
                        :chave_value="filtro.key_value_options"
                        text_edit="Editar"
                        :request="filtro.request"
                        v-on:select_item="changeSelectApi(filtro,$event)"
                    >
                    </select-3>
                </div>
                <slot name="hidde_filtros">

                </slot>
        </div>

        <div class="col-12">
            <div class="row py-1 d-flex align-items-center">
                <div class="col">
                    <div v-if="label_alert && !hidde_label_results" :id="id_busca + '-alert'" v-html="label_alert"></div>
                </div>
                <div class="col-lg-auto">
                    <span class="float-right">
                        <paginate :id_paginate="set_componente" v-on:buscarPagina="pag($event)"></paginate>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <!-- END CAMPO DE BUSCA -->

</template>

<script>
    import _ from 'lodash';
    import {bus} from '../../app';
    export default {
        props:[
            'set_componente',
            'busca',
            'class_busca',
            'placeholder_busca',
            'filtros',
            'hidde_filtros',
            'checkbox',
            'validate',
            'min_char',
            'hidde_label_results',
        ],
        data: function () {
            return {
                id_busca: 'busca-' + this.set_componente,
                buscar:'',
                filtro_id:'', //Deve armazenar o id do filtro no momento do spinner,
                minChar: this.min_char || 3,
                label_alert:'',
                filter:[],
                tot_registros:0,
                display_filtros:false,
            }
        },
        methods:{
            emit(data){
                if(this.validate && this.validar(data) === false){
                    this.sem_notificacao_de_busca();
                    this.label_campo_busca();
                    if(data.filtro){
                        this.load_filtro(false,data.filtro.id);
                    }
                    bus.$emit('set_request-' + this.set_componente,{validate:'invalido'});
                }else{
                    this.buscando();
                    bus.$emit('set_request-' + this.set_componente,data);
                }
            },
            pag(obj){
                bus.$emit('pagina-' + this.set_componente,obj);
            },
            select(obj,tentativas = 0){
                if(tentativas  === 20){return false;} //4 segundos
                var elemento = document.getElementById(obj.id);
                if(elemento){
                    document.getElementById(obj.id).value = obj.valor;
                    this.changeFiltro(obj);
                }else{
                    setTimeout(()=>{
                        this.select(obj,tentativas + 1)
                    },200)
                }
            },
            changeFiltro(obj){
                if(document.getElementById(obj.id).value === '' || document.getElementById(obj.id).value === null || document.getElementById(obj.id).value === 'undefined'){
                    this.borda_filtro(false,obj.id);
                    this.load_filtro(true,obj.id);
                    this.emit({
                        chave:obj.chave || obj.id,
                        valor:null,
                    });
                }else{
                    this.load_filtro(true,obj.id);
                    this.emit({
                        chave:obj.chave || obj.id,
                        valor:document.getElementById(obj.id).value,
                    });
                    this.borda_filtro(true,obj.id);
                }

                this.filtro_id = obj.id;
            },
            changeSelectApi(filtro,obj){
                this.emit({
                    chave: filtro.key_value_options,
                    valor: obj[filtro.key_value_options],
                });
            },
            changeCheckbox(obj){
                let val = null;
                if(document.getElementById(obj.id).checked){
                    val = document.getElementById(obj.id).value;
                }
                this.emit({
                    chave:obj.chave || obj.id,
                    valor:val,
                });
            },
            condicao(obj){
                if(obj.if === true || obj.if === false){
                    return obj.if;
                }
                return true;
            },
            validar(data){
                var i = 0;
                var c = 0;
                for(i = 0; i < this.validate.length; i++){
                    if(document.getElementById(this.validate[i]).value !== ''){
                        c++;
                    }
                }
                if(c === 0){
                    return false;
                }
                return true;
            },
            /*=====================================NOTIFICAÇÕES DE BUSCA===============================*/
            digitando(){
                this.label_alert = "<span class='text-color-animate-grey badge badge-pill badge-light font-weight-normal'>digitando...</span>";
                this.type_campo_busca();
            },
            buscando(){
                this.label_alert = "<span class='text-color-pulse-primary badge badge-pill badge-light font-weight-normal'>buscando...</span>";
            },
            caracteres_insuficientes(){
                this.label_alert = "<span class='text-color-animate-danger badge badge-pill badge-light font-weight-normal'>caracteres insuficientes!</span>"
                document.getElementById('label-campo_buscar-' + this.id_busca).classList.add('text-color-animate-danger');
                document.getElementById('label-campo_buscar-' + this.id_busca).classList.remove('text-color-pulse-primary');
                document.getElementById('label-campo_buscar-' + this.id_busca).classList.remove('text-color-animate-grey');
            },
            sem_notificacao_de_busca(){
                this.label_alert = "";
            },
            n_registros_encontrados(valor){
                    valor = parseInt(valor);
                    if(valor === 0){
                        this.label_alert = "";
                    }else{
                        this.label_alert = "<span class='text-animate-blur-dark badge badge-pill badge-light'>" + (valor || 0) + (valor === 1 ? " registro encontrato" : " registros encontratos" ) +" </span>";
                    }
            },
            load_campo_busca(){
                document.getElementById('label-campo_buscar-' + this.id_busca).classList.add('text-color-pulse-primary');
                document.getElementById('label-campo_buscar-' + this.id_busca).classList.remove('text-color-animate-grey');
                document.getElementById('label-campo_buscar-' + this.id_busca).classList.remove('text-color-animate-danger');
            },
            type_campo_busca(){
                document.getElementById('label-campo_buscar-' + this.id_busca).classList.add('text-color-animate-grey');
                document.getElementById('label-campo_buscar-' + this.id_busca).classList.remove('text-color-pulse-primary');
                document.getElementById('label-campo_buscar-' + this.id_busca).classList.remove('text-color-animate-danger');
            },
            label_campo_busca(){
                document.getElementById('label-campo_buscar-' + this.id_busca).classList.remove('text-color-animate-grey');
                document.getElementById('label-campo_buscar-' + this.id_busca).classList.remove('text-color-pulse-primary');
                document.getElementById('label-campo_buscar-' + this.id_busca).classList.remove('text-color-pulse-danger');
                // document.getElementById('label-campo_buscar-' + this.id_busca).innerHTML = "<i data-feather=\"search\" class=\"icon-md cursor-pointer\"></i>";
                // this.featherIcons();
            },
            load_filtro(application,id_filtro){
                if(document.getElementById('label-' + id_filtro)){
                    if(application === true){
                        document.getElementById('label-' + id_filtro).classList.add('text-color-pulse-primary');
                    }else{
                        document.getElementById('label-' + id_filtro).classList.remove('text-color-pulse-primary');
                        this.filtro_id = '';
                    }
                }
            },
            spinner_filtro(application,id_filtro){
                if(application === true){
                    this.filtro_label = document.getElementById('label-' + id_filtro).innerHTML;
                    this.filtro_id = id_filtro;
                    document.getElementById('label-' + id_filtro).innerHTML = "<i class=\"fas fa-circle-notch fa-spin\"></i>";
                }else{
                    document.getElementById('label-' + id_filtro).innerHTML = this.filtro_label;
                    this.filtro_id = '';
                }
            },
            borda_campo_busca(aplication){
                if(aplication === true){
                    document.getElementById(this.id_busca).classList.add('bg-lightprimary');
                }else{
                    document.getElementById(this.id_busca).classList.remove('bg-lightprimary');
                }
            },
            borda_filtro(aplication,id_filtro){
                if (aplication === true) {
                    document.getElementById(id_filtro).classList.add('bg-lightprimary');
                } else {
                    document.getElementById(id_filtro).classList.remove('bg-lightprimary');
                }
            },
            featherIcons(){
                setTimeout(function (){feather.replace();},1);
            },
            //Delay para emitir a request
            search: _.debounce(function (val) {
                if(val.length >= this.minChar){
                    this.borda_campo_busca(true);
                }else if(val.length < this.minChar && val.length > 0){
                    this.caracteres_insuficientes();
                    this.borda_campo_busca(false);
                    return false;
                }else if(val.length === 0){
                    val = null;
                    this.borda_campo_busca(false);
                }
                //Request
                this.load_campo_busca();
                this.emit({
                    chave:'busca',
                    valor:val,
                });
            }, 1500),
        },
        watch: {
            buscar: function (newVal, oldVal) {
                if(newVal.length > 0){
                    this.digitando();
                }
                this.search(newVal);
            }
        },
        created() {
            bus.$on('get_response-' + this.id_busca, (obj) => {
                if(obj.last_page){
                    this.n_registros_encontrados(obj.total);
                }else{
                    this.n_registros_encontrados(obj.length || obj.data.length);
                }

                this.label_campo_busca();

                if(this.filtro_id !== ''){
                    this.load_filtro(false,this.filtro_id);
                }
            });

            bus.$on('set-' + this.id_busca, (obj) => {
                if(obj.select){
                    this.select(obj.select);
                }
            });
        },
        mounted() {
            this.featherIcons();
        },
        destroyed() {
            bus.$off('get_response-' + this.id_busca);
            bus.$off('set-' + this.id_busca);
        },
    }
</script>

<style scoped>

</style>
