<template>
    <div :class="'text-center ' + cor" :style="'position: relative; ' + estilo" :title="title">
        <span v-if="altura === '3' && tipo === '%'">{{valor_exibido}}%</span>
        <span v-if="altura === '3' && tipo !== '%'">{{valor_exibido}}{{this.maximo ? ' / ' + referencia : ''}}</span>
        <div class="progress" :style="'height: ' + altura + 'px'">
            <div :class="'progress-bar ' + background" role="progressbar" :style="'width: ' + barra + '%'" :aria-valuenow="valor_exibido" :aria-valuemin="minimo" :aria-valuemax="referencia">
                <span v-if="altura === '20' && tipo === '%'">{{valor_exibido}}%</span>
                <span v-if="altura === '20' && tipo !== '%'">{{valor_exibido}}{{this.maximo ? ' / ' + referencia : ''}}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import {bus} from '../../app'
    export default {
        props:['maximo','minimo','valor_atual','valor_referencia','gatilho','height','background','color','bus_on','tipo','title','estilo'],
        data:function () {
            return{
                altura: this.height == 'large' ? '20' : '3',
                referencia: 0, //Valor total da escala
                valor: 0, //Valor do progresso da barra
                valor_exibido:0, //Valor a ser exibido (número real ou porcentagem)
                barra:0, //Tamanho da barra
                cor: this.color || 'text-primary', //Cor da barra
            }
        },
        methods:{
            population(){
              bus.$emit('gerencia-request',this.valor_atual);
              bus.$emit('gerencia-request',this.valor_referencia);
            },

            valorAtual(valor_atual,valor_referencia){
                this.valor = valor_atual;
                if(this.tipo === '%'){
                   this.valor_exibido = Math.floor((valor_atual / valor_referencia) * 100) || 0;
                }else{
                   this.valor_exibido = valor_atual;
                }
                this.barraDeProgresso();
            },
            valorReferencia(valor_referencia){
                this.referencia = valor_referencia;
                if(this.tipo === '%'){
                    this.valor_exibido = Math.floor((this.valor / this.referencia) * 100) || 0;
                }
                this.barraDeProgresso();
            },
            barraDeProgresso(){
                this.barra = Math.floor((this.valor / this.referencia) * 100);
            }
        },

        created(){
            //Escuta o callback do emit do gerencia-request para o valor atual
            if(this.valor_atual.bus_on){
                bus.$on(this.valor_atual.bus_on, (obj) => {
                    this.valorAtual(obj.data.data.valor, this.referencia);
                });
            }
            //Escuta o callback do emit do gerencia-request para o valor de referência
            if(this.valor_referencia.bus_on){
                bus.$on(this.valor_referencia.bus_on, (obj) => {
                    this.valorReferencia(obj.data.data.valor);
                });
            }

            //Gatilho que escuta e que dispara a atualização do population
            if(this.gatilho){
                bus.$on(this.gatilho,(obj) => {
                    this.population();
                });
            }


            /*
            this.valorReferencia(this.valor_referencia);
            this.valorAtual(this.valor_atual,this.valor_referencia);

            if(this.bus_on){
                if(this.bus_on.alterar_valor_atual){
                    bus.$on(this.bus_on.alterar_valor_atual, (obj) => {
                        this.valorAtual(obj.data.data.valor, this.referencia);
                    });
                }


                if(this.bus_on.alterar_valor_referencia){
                    bus.$on(this.bus_on.alterar_valor_referencia, (obj) => {
                        this.valorReferencia(obj.data.data.valor);
                    });
                }


            }
            */
        },

        mounted(){
            this.population()
        }
    }
</script>

<style type="scss">

</style>
