<template>
        <Bar :chart-id="chart_id"
             :chart-data="chartData"
             :chart-options="chart_options"
             :width="width_"
             :height="height_"
             :plugins="plugins"
        />
</template>
<script>
import { Bar } from 'vue-chartjs/legacy';
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
    components: {
        Bar
    },
    props: {
        chart_id: {
            type: String,
            default: 'bar_chart-    ' + Date.now()
        },
        chart_data: {
            type: Object,
        },
        custom_data:{
            type: Object,
        },
        chart_options: {
            type: Object,
            default: () => {}
        },
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 400
        },
        auto_colors:{
            type: Boolean,
            default:false
        },
        auto_size:{
            type: Boolean,
            default:false
        },
    },
    data: function () {
        return {
            height_:500,
            width_:900,
            chartData:{},
            plugins:{},
        }
    },
    methods:{
        customData(dados){
            const chartdata = {
                labels:[],
                datasets:[]
            };

            const data_val = [];
            for (let i in dados.obj){
                chartdata.labels.push(dados.obj[i][dados.label]);
                data_val.push(dados.obj[i][dados.valor]);
            }
            chartdata.datasets.push({data:data_val,backgroundColor:this.custom_data.backgroundColor});
            return chartdata;
        },
    },
    created() {
        this.plugins = [ChartDataLabels];

        if(this.custom_data){
            this.chartData = this.customData(this.custom_data);
        }else{
            this.chartData = this.chart_data;
        }

        if(this.height){this.height_ = this.height;}
        if(this.width){this.width_ = this.width;}

        if(this.auto_size){
            let datas = this.chartData.datasets[0].data.length;
            if(datas > 70){this.height_ = 1000;}
            else if (datas > 60){this.height_ = 900;}
            else if (datas > 50){this.height_ = 800;}
            else if (datas > 40){this.height_ = 700;}
        }

        if (this.auto_colors){
            const colors = [];

            for (let i = 0;i < this.chartData.datasets[0].data.length;i++){
                const r = Math.floor(Math.random() * 255);
                const g = Math.floor(Math.random() * 255);
                const b = Math.floor(Math.random() * 255);
                colors.push('rgba('+r+','+g+','+b+',0.8)');
            }

            this.chartData.datasets[0].backgroundColor = colors;
        }
    },
    mounted () {

    }
}
</script>

<style scoped>

</style>
