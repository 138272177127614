<template>
    <div>
        <editor-menu-bar :editor="editor_msg">
            <div
                    class="menubar mb-1"
                    :id="'menubar-' + id_editor"
                    :class="{ 'is-focused': focused }"
                    slot-scope="{ commands, isActive, focused }"
            >

                <button
                        class="menubar__button"
                        :class="{ 'is-active': isActive.bold() }"
                        @click="commands.bold"
                        title="Negrito"
                >
                    <strong>N</strong>
                </button>

                <button
                        class="menubar__button"
                        :class="{ 'is-active': isActive.italic() }"
                        @click="commands.italic"
                        title="Itálico"
                >
                    <span style="font-family: Serif" class="font-italic">I</span>
                </button>

                <button
                        class="menubar__button"
                        :class="{ 'is-active': isActive.underline() }"
                        @click="commands.underline"
                        title="Sublinhado"
                >
                    <span style="text-decoration: underline">S</span>
                </button>


                <button
                        class="menubar__button"
                        :class="{ 'is-active': isActive.heading({ level: 5 }) }"
                        @click="commands.heading({ level: 5 })"
                        title="Título"
                >
                    T
                </button>


            </div>
        </editor-menu-bar>

        <editor-content :id="'text_box-' + id_editor" class="editor__content border p-2 rounded" :editor="editor_msg" />

        <div v-if="send_email" class="custom-control custom-checkbox pt-3 d-none" :id="'check_send_mail-' + id_editor">
            <input type="checkbox" class="custom-control-input cursor-pointer" :name="'check-send_mail-' + id_editor" v-model="check_send_mail" :id="'check-send_mail-' + id_editor">
            <label class="custom-control-label pt-1 cursor-pointer" :for="'check-send_mail-' + id_editor">Enviar mensagem por e-mail</label>
        </div>

        <button :id="'btn_submit-' + id_editor" class="btn btn-sm btn-block btn-primary float-right d-none mt-3" title="Salvar" v-on:click="actionSubmit()"  v-html="btn_submit.titulo"></button>

    </div>
</template>

<script>
    import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
    import {bus} from '../../app';
    import {
        Heading,
        ListItem,
        OrderedList,
        Bold,
        Italic,
        Link,
        Underline,
        History,
    } from 'tiptap-extensions';
    export default {
        props:[
            'id_editor',
            'send_email',
            'btn_submit',
            'request',
        ],
        components: {
            EditorContent,
            EditorMenuBar
        },
        data() {
            return {
                editor_msg: new Editor({
                    extensions: [
                        new Heading({ levels: [5] }),
                        new Bold(),
                        new Italic(),
                        new Link(),
                        new Underline(),
                        new History(),
                    ],
                    content:'',
                    placeholder:'Mensagem...',
                    emitUpdate:true,
                    onUpdate: ( { state, getHTML, getJSON, transaction } ) => {
                        if(getHTML().length > 7){
                            document.getElementById('btn_submit-' + this.id_editor).classList.remove('d-none');
                            document.getElementById('check_send_mail-' + this.id_editor).classList.remove('d-none');
                        }else{
                            document.getElementById('btn_submit-' + this.id_editor).classList.add('d-none');
                            document.getElementById('check_send_mail-' + this.id_editor).classList.add('d-none');
                        }
                    }
                }),
                url: this.request,
                d_send_msg:false,
                check_send_mail:this.send_email
            }
        },
        methods:{
            actionSubmit(){
                this.$store.state.registro.msg_content = this.editor_msg.getHTML();

                if(this.btn_submit.bus_emit){
                    var i;
                    for (i = 0; i < this.btn_submit.bus_emit.length; i++) {
                        bus.$emit(this.btn_submit.bus_emit[i]);
                    }
                }

                if(this.request){
                    this.salvarMensagem();
                }
            },
            adicionarComentario(){
                this.editor_msg.focus();
            },
            salvarMensagem(){
                this.url.data.comentario = this.editor_msg.getHTML();
                if(this.check_send_mail){this.url.data.send_email = true;}
                bus.$emit('gerencia-request',this.url);
            },
            resetarEditor(){
                this.editor_msg.clearContent(true);
                this.editor_msg.blur();
            }
        },
        created(){
            if(this.request){
                this.url.id_loading = 'btn_submit-' + this.id_editor;
                this.url.content = 'btn_submit-' + this.id_editor;
            }

            bus.$on('reset-' + this.id_editor, (obj) => {
                this.resetarEditor();
            });

            bus.$on('focus-' + this.id_editor, (obj) => {
                setTimeout(() => {
                    this.editor_msg.focus();
                },500);
            });

        },
        mounted() {


        }
    }
</script>

<style scoped>
    .editor p.is-empty:first-child::before {
        content: attr(data-empty-text);
        float: left;
        color: black;
        pointer-events: none;
        height: 0;
        font-style: italic;
    }

</style>
