<template>
    <div>
        <div v-if="chat.participante === 1 || (index_chats || mini_chat)">
            <div class="row chat-wrapper position-relative" :class="{'mini-chat':mini_chat}">
                <div v-if="index_chats || mini_chat" class="col-lg-4 chat-aside border-lg-right">
                    <div class="aside-content">
                        <div class="aside-header">
                            <request
                                :id_request="'request-' + id"
                                :key="'request-' + id"
                                :request="{
                                    url:url + '/gerenciar_request/chats',
                                    data:{
                                        function:'list',
                                    },
                                    bus_emit:['get_chats-' + id],
                                    reicons:true
                                }"
                                :start="!mini_chat"
                            >
                                <busca-2
                                    :set_componente="'request-' + id"
                                    placeholder_busca="Usuário ou nome do chat"
                                    class_busca="col-lg-12"
                                    :busca="true"
                                    :hidde_label_results="true"
                                ></busca-2>
                            </request>
                        </div>
                        <div class="aside-body" style="overflow-y: auto;min-height: 70vh;max-height: 90vh">
                            <div class="tab-content">
                                <div class="tab-pane fade show active" id="chats" role="tabpanel" aria-labelledby="chats-tab">
                                    <div>
                                        <p class="text-muted mb-2">Conversas recentes</p>
                                        <div v-if="carregando" class="text-center my-3 text-color-pulse-primary">Carregando...</div>
                                        <div v-if="alert_index" v-html="alert_index"></div>
                                        <ul class="list-unstyled chat-list px-1">
                                            <li v-for="chat in indexChats" class="chat-item pr-1" >
                                                <a class="d-flex align-items-center bg-hover-lightprimary" :class="{'bg-lightprimary':chat.id_chat === idChat,'cursor-pointer':chat.id_chat}" v-on:click="setChat(chat.id_chat)">
                                                    <figure class="mb-0 mr-2">
                                                        <img v-if="chat.src_img" :src="url + '/' + chat.src_img" class="img-xs rounded-circle border" alt="user">
                                                        <img v-else-if="chat.grupo === true" :src="url + '/assets/images/user-group.png'" class="img-xs rounded-circle border" alt="grupo">
                                                        <img v-else :src="url + '/assets/images/user-muted.png'" class="img-xs rounded-circle border" alt="user">
<!--                                                        <div class="status online"></div>-->
<!--                                                        <div class="status offline"></div>-->
                                                    </figure>
                                                    <div class="d-flex justify-content-between flex-grow border-bottom">
                                                        <div>
                                                            <p class="text-body font-weight-bold text-wrap" v-html="chat.nome"></p>
                                                            <p class="text-muted tx-13" v-html="limitText(chat.last_msg,30)"></p>
                                                        </div>
                                                        <div class="d-flex flex-column align-items-end">
                                                            <p v-if="chat.data_atualizacao" class="text-muted tx-13 mb-1 text-right">{{ chat.data_atualizacao | moment("calendar")}}</p>
                                                            <button v-else-if="!chat.data_atualizacao && chat.grupo === false" class="btn btn-link px-0" title="Iniciar chat privado"
                                                                v-on:click="iniciarChatPrivado(chat)"
                                                            >
                                                                Iniciar chat <i data-feather="message-square"></i>
                                                            </button>
                                                            <div v-if="chat.msgs_nao_lidas > 0" class="badge badge-pill badge-warning ml-auto">{{chat.msgs_nao_lidas}}</div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg chat-content">
                    <div v-if="idChat" class="chat-header pb-2 px-0">
                        <div class="row">
                            <div class="col d-flex align-items-center">
                                <figure class="mb-0 mr-2">
                                    <img v-if="chat.src_img" :src="url + '/' + chat.src_img"
                                         class="img-sm rounded-circle border" alt="user">
                                    <img v-else-if="chat.grupo === true" :src="url + '/assets/images/user-group.png'"
                                         class="img-sm rounded-circle border" alt="grupo">
                                    <img v-else :src="url + '/assets/images/user-muted.png'"
                                         class="img-sm rounded-circle border" alt="user">
                                </figure>
                                <div>
                                    <p v-if="chat.grupo === true && index_chats"><a
                                        class="cursor-pointer text-hover-underline text-primary"
                                        title="Acessar elemento." v-on:click="acessarElemento(chat)">{{ chat.nome }}</a>
                                    </p>
                                    <p v-else>{{ chat.nome }}</p>

                                    <p v-if="chat.grupo">
                                        <a class="text-hover-primary text-hover-underline cursor-pointer text-wrap"
                                           v-if="displayUsers === false" v-on:click="displayUsers = true">
                                            <span class="text-muted font-size-06" v-for="(user,index) in usuarios" v-if="index <=2 ">&nbsp;{{user.first_name }} {{ (usuarios[index + 1] ? ',' : '') }}</span>
                                            <span class="font-size-06" v-if="usuarios.length > 3">e mais {{ usuarios.length - 3 }}</span>
                                        </a>
                                    </p>
                                </div>
                            </div>
                            <div class="col d-flex align-items-center justify-content-end">
                                <div class="d-flex align-items-center mr-n1">
                                    <!--                                            <a v-if="chat.grupo" class="d-none d-sm-block">-->
                                    <!--                                                <i data-feather="user-plus" class="icon-lg text-muted" data-toggle="tooltip" title="Add to contacts"></i>-->
                                    <!--                                            </a>-->
<!--                                    <a v-if="chat.notificacoes === true" :key="id + '-notificacao_ativada'"-->
<!--                                       class="cursor-pointer text-hover-primary" v-on:click="updateNotificacoes(false)">-->
<!--                                        <i data-feather="bell" class="icon-lg" title="Notificações ativadas"></i>-->
<!--                                    </a>-->
<!--                                    <a v-else-if="chat.notificacoes === false" :key="id + '-notificacao_desativada'"-->
<!--                                       class="cursor-pointer text-muted text-hover-primary"-->
<!--                                       v-on:click="updateNotificacoes(true)">-->
<!--                                        <i data-feather="bell-off" class="icon-lg" title="Notificações desativadas"></i>-->
<!--                                    </a>-->
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-3 mb-2" v-if="displayUsers === true && idChat">
                            <div class="h-100" style="overflow-y: auto; border: 1px solid #EBEDF2;max-height: calc(100vh - 340px);">
                                <div class="font-size-06 text-center p-1">Usuários no chat <span class="float-right text-primary text-hover-underline cursor-pointer" v-on:click="displayUsers = false">Ocultar</span></div>
                                <div class="py-1 px-2 font-size-06 text-wrap" v-for="user in usuarios">
                                    <div class="d-inline">
                                        <img v-if="user.src_img" class="img-xs rounded-circle" :src="url + '/' + user.src_img" alt="" style="height: 20px; width: 20px"/>
                                        <img v-else class="img-xs rounded-circle border" :src="url + '/assets/images/user-muted.png'" alt="" style="height: 20px; width: 20px"/>
                                    </div>
                                    {{user.nome}}
                                </div>
                            </div>
                        </div>
                        <div v-if="idChat" class="col-lg mb-2">
                            <div class="chat-body my-0" :id="'chat-body-' + id" style="overflow-y: auto; border: 1px solid #EBEDF2;" v-on:scroll="scrollin()">
                                <div class="d-block mb-1" style="min-height: 0.875rem" v-show="this.msgs.length > 0" v-html="label_top"></div>
                                <ul class="messages pt-2">
                                    <div v-for="(msg,index) in msgs"
                                         :class="(msg.id_msg_usuario && !msg.data_leitura ? 'msg-nao-lida' : '')"
                                         :key="'msg-'+ msg.id_msg">
                                        <li :class="'message-item ' + (msg.id_usuario == id_usuario ? 'me' : 'friend')" :id="'msg-'+ msg.id_msg">
                                            <img v-if="msg.src_img" class="img-xs rounded-circle" :src="url + '/' + msg.src_img" alt="" style="height: 37px; width: 37px"/>
                                            <img v-else class="img-xs rounded-circle border" :src="url + '/assets/images/user-muted.png'" alt="" style="height: 37px; width: 37px"/>

                                            <div class="content">
                                                <div class="message">
                                                    <div class="bubble">
                                                        <div v-if="msg.id_usuario != id_usuario"
                                                             class="font-weight-bold text-muted font-size-07">
                                                            {{ msg.nome_usuario }} <small>({{ msg.email_usuario }})</small>
                                                        </div>
                                                        <p v-html="msg.content" class="message-content"></p>
                                                    </div>
                                                    <span class="text-muted d-block font-size-07" :title="msg.data_envio">{{ msg.data_criacao | moment("calendar") }}</span>
                                                </div>
                                            </div>
                                        </li>
                                    </div>
                                </ul>
                                <div class="last-message"></div>
                                <div class="d-block" style="min-height: 0.875rem" v-show="this.msgs.length > 0" v-html="label_bottom"></div>
                            </div>

                                <div class="row pt-2" v-show="idChat && chat.participante === 1">
                                    <div class="col-lg-10">
                                        <editor
                                            v-model="content_msg"
                                            :id="'msg_chat_chamado-' + id"
                                            :key="'msg_chat_chamado-' + id"
                                            :init="{
                                                height: 120,
                                                menubar: false,
                                                plugins:'emoticons',
                                                language:'pt_BR',
                                                toolbar:'bold italic underline forecolor backcolor emoticons'
                                            }"
                                        >
                                        </editor>
                                    </div>
                                    <div class="col-lg-2">
                                        <button class="btn btn-light btn-block rounded-0 h-100 text-primary"
                                                v-if="content_msg.length > 6"
                                                v-on:click="sendMsg()"
                                        >
                                            Enviar
                                        </button>
                                        <button v-else class="btn btn-light btn-block rounded-0 h-100" disabled title="Insira uma mensagem válida antes de enviar.">
                                            Enviar
                                        </button>
                                    </div>
                                </div>
                        </div>

                        <div class="col-lg chat-content" v-else>
                            <div class="alert alert-light">
                                Selecione uma conversa para exibir as mensagens.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="chat.participante === 0 && chat.privado">
            <div class="alert alert-fill-warning mt-3">
                <i class="fas fa-shield-alt mr-2"></i> Esse chat é privado!
            </div>
        </div>
        <div v-else-if="!chat.privado && chat.participante === 0">
            <div class="alert alert-info text-center">
                <i data-feather="message-square" title="Chats" style="width: 100px !important; height: 100px !important;"></i>
                <div>
                    Ingresse no chat para interagir com os outros usuários!
                </div>
            </div>
            <button class="btn btn-block btn-primary mt-3" v-on:click="ingressarChat()">Ingressar no chat <i class='far fa-comment ml-2'></i></button>
        </div>
    </div>
</template>

<script>
import {bus} from '../../app';
import { HollowDotsSpinner } from 'epic-spinners';
import Editor from '@tinymce/tinymce-vue'
export default {
    components: {HollowDotsSpinner,editor:Editor},
    props:[
        'id',
        'id_chat',
        'url',
        'id_usuario',
        'tipo_usuario',
        'permissao_unidade',
        'permissao_gestor',
        'index_chats',
        'destroy_emit',
        'mini_chat',
    ],
    data() {
        return {
            idChat:this.id_chat,
            chat:{},
            indexChats:{},
            msgs:[],
            indexMsgs:{},
            usuarios:{},
            carregando:true,
            content_msg:'',
            displayUsers:false,
            msgs_nao_lidas:0,
            repeat:null,
            current_url_mgs:null,
            next_url_mgs:null,
            prev_url_mgs:null,
            paginate:30,
            label_top:null,
            label_bottom:null,
            alert_index:null
        }
    },
    methods:{
        setChat(id_chat){
            if(!id_chat){return false}
            this.idChat = id_chat;
            this.msgs = [];
            this.chat = {};
            this.msgs_nao_lidas = 0;
            this.usuarios = {};
            this.current_url_mgs = null;
            this.next_url_mgs = null;
            this.prev_url_mgs = null;
            this.getChat();
            this.getUsuarios();
        },
        refresh(){
            if(this.chat.participante === 0){return false;}
            if(this.idChat){this.getPositionMsgs();}
            if(!this.id_chat){bus.$emit('refresh-request-' + this.id,{});}
        },
        getChat(){
            var url = {
                url:this.url + '/gerenciar_request/chats',
                data:{
                    function:'get_chat',
                    id_chat:this.idChat,
                    id_usuario:this.id_usuario,
                },
                bus_emit:['get_chat-' + this.id],
                reicons:true
            }
            bus.$emit('gerencia-request',url);
        },
        getPositionMsgs(){
            var url = {
                url:this.url + '/gerenciar_request/chats',
                data:{
                    function:'get_position_msgs',
                    id_chat:this.idChat,
                    paginate:this.paginate
                },
                bus_emit:['get_position_msgs-' + this.id]
            }
            bus.$emit('gerencia-request',url);
        },
        getMsgs(){
            var url = {
                url:this.current_url_mgs,
                data:{
                    function:'get_msgs',
                    id_chat:this.idChat,
                    id_usuario:this.id_usuario,
                    paginate:this.paginate
                },
                bus_emit:['get_msgs-' + this.id]
            }
            bus.$emit('gerencia-request',url);
        },
        getUsuarios(){
            var url = {
                url:this.url + '/gerenciar_request/chats',
                data:{
                    function:'get_usuarios',
                    id_chat:this.idChat,
                },
                bus_emit:['get_usuarios-' + this.id]
            }
            bus.$emit('gerencia-request',url);
        },
        sendMsg(){
            var url = {
                url:this.url + '/gerenciar_request/chats',
                data:{
                    function:'nova_msg',
                    id_chat:this.idChat,
                    content:this.content_msg
                },
                bus_emit:['get_nova_msg-' + this.id]
            }
            bus.$emit('gerencia-request',url);
        },
        ingressarChat(){
            var url = {
                url:this.url + '/gerenciar_request/chats',
                confirm_alert:{content:'Ao ingressar no chat você poderá receber notificações das novas mensagens compartilhadas. Deseja prosseguir?',class:'border-info',btn:'btn btn-sm btn-block btn-info'},
                data:{
                    function:'ingressar_chat',
                    id_chat:this.idChat,
                    id_usuario:this.id_usuario
                },
                loading:['page'],
                bus_emit:['get_ingressar_chat-' + this.id]
            }
            bus.$emit('gerencia-request',url);
        },
        iniciarChatPrivado(chat){
            var url = {
                url:this.url + '/gerenciar_request/chats',
                confirm_alert:{content:'Deseja iniciar uma conversa com ' + chat.nome + '?',class:'border-info',btn:'btn btn-sm btn-block btn-info'},
                data:{
                    function:'iniciar_chat_privado',
                    id_usuario:chat.id_usuario
                },
                loading:['page'],
                bus_emit:['get_iniciar_chat_privado-' + this.id]
            }
            bus.$emit('gerencia-request',url);
        },
        confirmarLeitura(){
            var url = {
                url:this.url + '/gerenciar_request/chats',
                data:{
                    function:'confirmar_leitura',
                    id_chat:this.idChat,
                },
                bus_emit:['get_confirmar_leitura-' + this.id]
            }
            bus.$emit('gerencia-request',url);
        },
        updateNotificacoes(state){
            var url = {
                url:this.url + '/gerenciar_request/chats',
                data:{
                    function:'update_notificacoes',
                    id_chat:this.idChat,
                    state:state
                },
                bus_emit:['get_update_notificacoes-' + this.id],
                reicons:true
            }
            bus.$emit('gerencia-request',url);
        },
        limitText(string,limit){
            if(string.length <= limit){
                return string.replace(/(<([^>]+)>)/ig,"");
            }else{
                let text = string.replace(/(<([^>]+)>)/ig,"");
                return text.substring(0,limit) + '...';
            }
        },
        acessarElemento(chat){
            if(chat.id_pedido){
                return window.location.href = this.url + '/pedidos/' + chat.id_pedido;
            }else if(chat.id_processo){
                return window.location.href = this.url + '/processos/' + chat.id_processo;
            }else if(chat.id_chamado){
                return window.location.href = this.url + '/chamados/' + chat.id_chamado;
            }
        },
        loading(aplication){
            this.carregando = aplication;
        },
        scrollin () {
            let e = document.getElementById('chat-body-' + this.id);
            let top = e.scrollTop === 0;
            let bottom = (e.scrollTop + e.offsetHeight) - 2 === e.scrollHeight;//  -2 por causa da borda superior e inferior
            if(top){
                this.prevPage();
            }else if(bottom){
                this.nextPage();
            }
        },
        prevPage(){
            if(!this.prev_url_mgs){return false;}
            this.label_top = "<div class='text-color-pulse-primary text-center'>Carregando...</div>"
            var url = {
                url:this.prev_url_mgs,
                data:{
                    function:'get_msgs',
                    id_chat:this.idChat,
                    id_usuario:this.id_usuario,
                    paginate:this.paginate
                },
                bus_emit:['get_prev_msgs-' + this.id]
            }
            setTimeout(()=>{bus.$emit('gerencia-request',url);},500);
            this.prev_url_mgs = null;
        },
        nextPage(){
            if(!this.next_url_mgs){return false;}
            this.label_bottom = "<div class='text-color-pulse-primary text-center'>Carregando...</div>"
            var url = {
                url:this.next_url_mgs,
                data:{
                    function:'get_msgs',
                    id_chat:this.idChat,
                    id_usuario:this.id_usuario,
                    paginate:this.paginate
                },
                bus_emit:['get_next_msgs-' + this.id]
            }
            setTimeout(()=>{bus.$emit('gerencia-request',url);},500);
            this.next_url_mgs = null;
        },
        setLabelPages(){
          if(!this.next_url_mgs){this.label_bottom = "<div class='bg-lightgrey text-center text-muted font-size-06'>Fim da conversa</div>"}
          if(!this.prev_url_mgs){this.label_top = "<div class='bg-lightgrey text-center text-muted font-size-06'>Início da conversa</div>"}
        },
        scrollDown(){
            if(this.chat.mensagens_nao_lidas > 0){
                var off = $('.chat-body').offset().top + 20;
                $('.chat-body').animate({
                    scrollTop: ($('.msg-nao-lida:first').offset().top - off)
                }, 500);
                $('#msg-nao-lida').remove();
                $('.msg-nao-lida:first').prepend("<div class='bg-lightgold text-center my-2' id='msg-nao-lida'>Não Lidas ( " + this.chat.mensagens_nao_lidas + " )</div>");
            }else{
                var div = $('.chat-body');
                div.prop("scrollTop", div.prop("scrollHeight"));
            }
        },
        startPolling(){
            this.repeat = setInterval(()=>{this.refresh();},4000);
        },
        stopPolling(){
            clearInterval(this.repeat);
        }
    },
    created(){
        bus.$on('get_chat-' + this.id, (obj) => {
            this.chat = obj;
            if(this.chat.participante === 0){return false;}
            this.getPositionMsgs();
        });

        bus.$on('get_chats-' + this.id, (obj) => {
            this.indexChats = obj;
            if(this.indexChats.length === 0){
                this.alert_index = "<div class='alert alert-light'>Nenhuma conversa recente foi encontrada. <br><br>Utilize o campo de busca para encontrar alguém.</div>";
            }else{
                this.alert_index = null;
            }
            this.loading(false);
        });

        bus.$on('get_position_msgs-' + this.id, (obj) => {
            if(obj.position){this.current_url_mgs = '/gerenciar_request/chats?page=' + Math.ceil(parseInt(obj.position) / parseInt(this.paginate));
            }else{this.current_url_mgs = '/gerenciar_request/chats?page=' + Math.ceil(parseInt(obj.msgs) / parseInt(this.paginate));}
            if(this.msgs.length === 0 || obj.position){this.getMsgs();}
        });

        bus.$on('get_msgs-' + this.id, (obj) => {
            this.indexMsgs = obj;
            this.next_url_mgs = obj.next_page_url;
            this.prev_url_mgs = obj.prev_page_url;
            setTimeout(()=>{this.scrollDown();},50);
            setTimeout(()=>{this.scrollin();},500);
            this.confirmarLeitura();
            this.msgs = obj.data;
            this.loading(false);
            this.$emit('set_msgs',obj);
            this.setLabelPages();
        });

        bus.$on('get_next_msgs-' + this.id, (obj) => {
            for(let msg in obj.data){this.msgs.push(obj.data[msg]);}
            this.label_bottom = null;
            this.next_url_mgs = obj.next_page_url;
            this.setLabelPages();
        });

        bus.$on('get_prev_msgs-' + this.id, (obj) => {
            this.prev_url_mgs = obj.prev_page_url;
            let firstElement = $('.message-item:first').parent();
            var off = $('.chat-body').offset().top + 27;

            var arr = obj.data.reverse();
            for(let msg in arr){
                this.msgs.unshift(obj.data[msg]);
            }

            this.label_top = null;

            setTimeout(()=>{
                var previous_height = off;
                firstElement.prevAll().each(function() {
                    previous_height += $(this).outerHeight();
                });
                $('.chat-body').scrollTop(firstElement.offset().top - off);
            },0);
            this.setLabelPages();
        });

        bus.$on('get_usuarios-' + this.id, (obj) => {
            this.usuarios = obj;
        });

        bus.$on('get_nova_msg-' + this.id, (obj) => {
            this.content_msg = '';
            $('#msg-nao-lida').remove();
            $('#tinymce').focus();
            this.getMsgs();
        });

        bus.$on('get_confirmar_leitura-' + this.id, (obj) => {
            this.chat.mensagens_nao_lidas = 0;
            this.$emit('confirm_leitura_chat',obj);
            if(!this.id_chat){bus.$emit('refresh-request-' + this.id,{});}
        });

        bus.$on('get_update_notificacoes-' + this.id, (obj) => {
            this.getChat();
        });

        bus.$on('get_ingressar_chat-' + this.id, (obj) => {
            this.getChat();
            this.getUsuarios();
        });

        bus.$on('get_iniciar_chat_privado-' + this.id, (obj) => {
            bus.$emit('refresh-request-' + this.id,{});
            this.setChat(obj.id_chat);
        });

        bus.$on('start-' + this.id, (obj) => {
            if(this.indexChats.length === 0){this.loading(true);}
            this.startPolling();
            bus.$emit('refresh-request-' + this.id,{});
        });

        bus.$on('stop-' + this.id, (obj) => {
            this.stopPolling();
        });
    },
    mounted() {
        if(this.id_chat){
            this.setChat(this.id_chat);
        }
        if(!this.mini_chat){
            this.startPolling();
        }
    },
    destroyed() {
        bus.$off('get_chat-' + this.id);
        bus.$off('get_chats-' + this.id);
        bus.$off('get_position_msgs-' + this.id);
        bus.$off('get_msgs-' + this.id);
        bus.$off('get_next_msgs-' + this.id);
        bus.$off('get_prev_msgs-' + this.id);
        bus.$off('get_nova_msg-' + this.id);
        bus.$off('get_confirmar_leitura-' + this.id);
        bus.$off('get_update_notificacoes-' + this.id);
        bus.$off('get_ingressar_chat-' + this.id);
        bus.$off('start-' + this.id);
        bus.$off('stop-' + this.id);
        this.stopPolling();
        if(this.destroy_emit){bus.$emit(this.destroy_emit,{});}
    },
}
</script>

<style scoped>
    .mini-chat .tx-13{
        font-size: 10px !important;
    }

    .mini-chat .text-body{
        font-size: 12px !important;
    }
</style>
