<template>
    <div>
<!--        <div id="v-step-0">A DOM element on your page. The first step will pop on this element because its ID is 'v-step-0'.</div>-->
<!--        <div class="v-step-1">A DOM element on your page. The second step will pop on this element because its ID is 'v-step-1'.</div>-->
<!--        <div data-v-step="2">A DOM element on your page. The third and final step will pop on this element because its ID is 'v-step-2'.</div>-->

        <v-tour name="myTour" :steps="steps" :callbacks="myCallbacks" :options="{useKeyboardNavigation: true,stopOnTargetNotFound:false}" v-on:targetNotFound="tgNotFound($event)">
            <template slot-scope="tour">
                <v-step
                    v-for="(step, index) of tour.steps"
                    :key="index"
                    v-if="tour.currentStep === index"
                    :step="step"
                    :previous-step="tour.previousStep"
                    :next-step="tour.nextStep"
                    :stop="tour.stop"
                    :skip="tour.skip"
                    :is-first="tour.isFirst"
                    :is-last="tour.isLast"
                    :labels="tour.labels"
                    :highlight="step.highlight"
                    v-on:targetNotFound="tgNotFound($event)"
                    v-bind:show="showStep()"
                >
                    <template>
                        <div v-if="step.header" slot="header" class="mb-3" v-html="step.header">

                        </div>
                        <div slot="content" class="mb-3" v-html="step.content">

                        </div>
                        <div slot="actions">
                            <button @click="tour.skip" class="btn btn-secondary mr-3">Sair</button>
                            <button v-if="!tour.isFirst" @click="tour.previousStep" class="btn btn-light">Anterior
                            </button>
                            <button v-if="!tour.isLast" @click="tour.nextStep" class="btn btn-light">Próximo</button>
                            <button v-if="tour.isLast" @click="tour.finish" class="btn btn-light">Fim</button>
                        </div>
                    </template>
                </v-step>
            </template>
        </v-tour>
    </div>
</template>

<script>
import {bus} from '../../app';
export default {
    data () {
        return {
            steps: [{target:'',content:'',params:{placement:'bottom'}},],
            myCallbacks: {
                onStart:this.startTour,
                onPreviousStep: this.prevStep,
                onNextStep: this.nextStep,
                onSkip:this.skipTour,
                onFinish:this.finishTour
            },
            lastClick:'next',
        }
    },
    methods:{
        showStep(){
            if(this.steps[this.$tours['myTour'].currentStep].if === undefined || this.steps[this.$tours['myTour'].currentStep].if === true){
                if(this.steps[this.$tours['myTour'].currentStep].action){this.action(this.steps[this.$tours['myTour'].currentStep].action);}
                if(this.steps[this.$tours['myTour'].currentStep].event){this.steps[this.$tours['myTour'].currentStep].event();}
                if(this.steps[this.$tours['myTour'].currentStep].focus){this.focusStep(this.steps[this.$tours['myTour'].currentStep].focus,this.steps[this.$tours['myTour'].currentStep].target);}
            }else{
                if(this.lastClick === 'next'){
                    this.$tours['myTour'].nextStep();
                }else{
                    this.$tours['myTour'].previousStep();
                }
            }
        },
        startTour(){
            this.playGDN();
        },
        prevStep(){
            this.removeBgStep(this.steps[this.$tours['myTour'].currentStep].target);
            this.lastClick = 'prev';
        },
        nextStep(){
            this.removeBgStep(this.steps[this.$tours['myTour'].currentStep].target);
            this.lastClick = 'next';
        },
        skipTour(){
            this.removeBgStep(this.steps[this.$tours['myTour'].currentStep].target);
            this.stopGDN();
        },
        finishTour(){
            this.removeBgStep(this.steps[this.$tours['myTour'].currentStep].target);
            this.stopGDN();
        },
        tgNotFound(emit){
            if(this.lastClick === 'next'){
                this.$tours['myTour'].nextStep();
            }else{
                this.$tours['myTour'].previousStep();
            }
        },
        action(action){
            for(var a in action){
                if(action[a].click){this.click(action[a].click);}
                if(action[a].target){this.target(action[a].target);}
            }
        },
        click(id){if(document.getElementById(id)){bus.$emit('action', {click:{id:id}});}},
        // target(target){
        //     setTimeout(()=>{
        //         var el = document.getElementById(target.id);
        //         if(el){
        //             var rect = el.getBoundingClientRect(), scrollLeft = window.pageXOffset || document.documentElement.scrollLeft, scrollTop = (window.pageYOffset || document.documentElement.scrollTop) - 100;
        //             var pos = { top: rect.top + scrollTop, left: rect.left + scrollLeft };
        //             let widthStep = $('.v-step').width();
        //             $('.v-step').css({"transform": "translate3d(" + (pos.left -  (widthStep + 40)) + "px, " + ( pos.top + 5 ) + "px, 0px)"});
        //         }
        //     },target.timeout);
        // },
        focusStep(focus,id){
            for(var a in focus){
                if(focus[a] === 'bg'){this.addBgStep(id);}
            }
        },
        addBgStep(id){
            if($(id)){$(id).addClass('bg-fade-color-grey');}
        },
        removeBgStep(id){
            if($(id)){$(id).removeClass('bg-fade-color-grey');}
        },
        playGDN(){
            $('#app').append("<span style='position: fixed; bottom: 1.5rem; left: 1.5rem' id='icon-gdn' class='badge badge-pill badge-dark font-size-06 p-2 font-weight-light' title='Guia Dinâmico de Navegação'><i class='fas fa-play mr-1 text-color-animate-grey'></i>GDN</span>");
        },
        stopGDN(){
            $('#icon-gdn').remove();
        }
    },
    created() {
        bus.$on('tour', (obj) => {
            this.steps = obj.steps;
            setTimeout(()=>{this.$tours['myTour'].start();},500);
        });
    },
    mounted() {

    }
}
</script>
<style type="scss">
@media (min-width: 968px){
    .v-step{
        max-width: 450px !important;
        font-weight: lighter;
    }
}
</style>
