<template>
    <div>
        <button id="btn_show-password_confirm" type="button" data-toggle="modal" data-target="#modal-password_confirm" hidden></button>

        <!-- Modal -->
        <div class="modal fade" id="modal-password_confirm" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                <div class="modal-content shadow border border-secondary">
                    <div class="modal-body">
                        <button id="btn_hidde-password_confirm" type="button" class="btn btn-icon border-0 float-right" data-dismiss="modal" aria-label="Close" hidden><i class="fas fa-times font-size-09"></i></button>
                        <div class="mb-2">Validar ação com sua senha de acesso.</div>

                        <div class="form-row">
                            <div class="col-lg-8">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-key" style="font-size: 12pt"></i></span>
                                    </div>
                                    <input type="text" id="login_verify" name="login_verify" class="form-control" :value="login" disabled hidden>
                                    <input :key="'password_verify-' + countVerify" type="password" id="password_verify" name="password_verify" class="form-control" v-model="password_verify"
                                        v-on:keypress.enter="passwordVerify()"
                                    >
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <button v-if="password_verify" class="btn btn-primary btn-sm btn-block mt-2 mt-lg-0 h-100"
                                    v-on:click="passwordVerify()"
                                >
                                    <div v-if="carregando" class="d-flex align-items-center justify-content-center">
                                        <hollow-dots-spinner
                                            :animation-duration="1000"
                                            :dot-size="6"
                                            :dots-num="3"
                                            color="#ffffff"
                                        />
                                    </div>
                                    <span v-else>Validar</span>
                                </button>
                            </div>
                        </div>
                        <div v-if="alerta"><div class='text-danger mt-2'>Senha incorreta!</div></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {bus} from '../../app';
    import { HollowDotsSpinner } from 'epic-spinners';
    export default {
        components: {HollowDotsSpinner},
        props:['path','token','login'],
        data: function () {
            return {
                url:'',
                password_verify:null,
                carregando:false,
                alerta:false,
                countVerify:0
            }
        },
        methods:{
            executeRequest(){
                delete this.url.password_confirm;
                bus.$emit('gerencia-request',this.url);
            },
            passwordVerify(){
                this.alerta = false;
                this.carregando = true;
                axios({
                    url:this.path + '/password_verify',
                    method:'POST',
                    token: this.token,
                    data:{password:this.password_verify,login:this.login}
                }).then(res => {
                    this.carregando = false;
                    this.countVerify++;
                    if(res.data){
                        this.executeRequest();
                        document.getElementById('btn_hidde-password_confirm').click();
                    }else{
                        this.password_verify = null;
                        this.alerta = true;
                        setTimeout(() => {this.alerta = false;},3000);
                    }
                }).catch(error => {
                    console.log(error);
                    this.carregando = false;
                });
            }
        },
        created() {
            this.$store.state.modal['modal-password_confirm'] = true;

            bus.$on('password_confirm', (obj) => {
                this.url = obj;
                this.password_verify = null;
                document.getElementById('btn_show-password_confirm').click();
                setTimeout(function () {document.getElementById('password_verify').focus();},750);
            });
        },
        destroyed() {
            bus.$off('password_confirm');
        },
        mounted() {
            $('#modal-password_confirm').on("hidden.bs.modal", () => {
                for(var modal in this.$store.state.modal){
                    if(document.getElementById(modal) && document.getElementById(modal).classList.contains('show')){
                        document.getElementById('body_page').classList.add('modal-open');
                    }
                }
            })
        }
    }
</script>

<style scoped>

</style>
