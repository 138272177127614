<template>
    <nav v-if="paginate.last_page > 1" aria-label="..." class="p-1">

        <ul v-if="paginate.last_page <= 7" class="pagination pagination-tiny m-0">
            <li  class="page-item disabled" title="Páginas"><span class="page-link" >Pag</span></li>

            <li :id="'pagina-' + id_paginate + '-' + n"  v-for="n in paginate.last_page" :class="'page-item ' + (n === paginate.current_page ? 'active' : '')" >
                <span class="page-link" v-on:click="buscarPagina(paginate.path + '?page=' + n,n)" style="cursor: pointer;" >{{n}}</span>
            </li>
        </ul>

        <span v-else-if="paginate.last_page > 7 && paginate.current_page < 5">
            <ul class="pagination pagination-tiny m-0">
                <li  class="page-item disabled" title="Páginas"><span class="page-link" >Pag</span></li>
                <li :id="'pagina-' + id_paginate + '-' + n"  v-for="n in 5" :class="'page-item ' + (n === paginate.current_page ? 'active' : '')" >
                    <span class="page-link" v-on:click="buscarPagina(paginate.path + '?page=' + n,n)" style="cursor: pointer;" >{{n}}</span>
                </li>
                <li  class="page-item">
                    <span class="page-link cursor-pointer p-0" v-if="select_page === 'final'"  v-on:mouseleave="select_page = null">
                        <select :id="id_paginate + '-select'" class="select-paginate px-0" style="font-size: inherit" v-on:change="selectPage()">
                            <option disabled selected>...</option>
                            <option v-for="n in paginate.last_page" v-if="(n + 5) < paginate.last_page" :value="(n + 5)">{{(n + 5)}}</option>
                        </select>
                    </span>
                    <span v-else class="page-link cursor-pointer" v-on:mouseenter="select_page = 'final'">...</span>
                </li>
                <li  class="page-item" :id="'pagina-' + id_paginate + '-' + paginate.last_page">
                    <span class="page-link" v-on:click="buscarPagina(paginate.path + '?page=' + paginate.last_page,paginate.last_page)" style="cursor: pointer;">{{paginate.last_page}}</span>
                </li>
            </ul>
        </span>

        <span v-else-if="paginate.last_page > 7 && (paginate.last_page - paginate.current_page) < 4">
            <ul class="pagination pagination-tiny m-0">
                <li  class="page-item disabled" title="Páginas"><span class="page-link" >Pag</span></li>
                <li  class="page-item" :id="'pagina-' + id_paginate + '-' + 1">
                    <span class="page-link" v-on:click="buscarPagina(paginate.path + '?page=' + 1,1)" style="cursor: pointer;">1</span>
                </li>
                <li  class="page-item">
                    <span class="page-link cursor-pointer p-0" v-if="select_page === 'inicial'" v-on:mouseleave="select_page = null">
                        <select :id="id_paginate + '-select'" class="select-paginate px-0" style="font-size: inherit" v-on:change="selectPage()">
                            <option disabled selected>...</option>
                            <option v-for="n in paginate.last_page" v-if="(n + 1) < (paginate.last_page - 4)" :value="(n + 1)">{{(n + 1)}}</option>
                        </select>
                    </span>
                    <span v-else class="page-link cursor-pointer" v-on:mouseenter="select_page = 'inicial'">...</span>
                </li>
                <li :id="'pagina-' + id_paginate + '-' + ((paginate.last_page - 5) + n)"  v-for="n in 5" :class="'page-item ' + (((paginate.last_page - 5) + n) === paginate.current_page ? 'active' : '')" >
                    <span class="page-link" v-on:click="buscarPagina(paginate.path + '?page=' + ((paginate.last_page - 5) + n),((paginate.last_page - 5) + n))" style="cursor: pointer;" >{{((paginate.last_page - 5) + n)}}</span>
                </li>

            </ul>
        </span>

        <span v-else >
            <ul class="pagination pagination-tiny m-0">
                <li  class="page-item disabled" ><span class="page-link" >Pag</span></li>
                <li  class="page-item" :id="'pagina-' + id_paginate + '-' + 1">
                    <span class="page-link" v-on:click="buscarPagina(paginate.path + '?page=' + 1,1)" style="cursor: pointer;">1</span>
                </li>
                <li  class="page-item" >
                    <li  class="page-item">
                    <span class="page-link cursor-pointer p-0" v-if="select_page === 'inicial_mid'" v-on:mouseleave="select_page = null">
                        <select :id="id_paginate + '-select'" class="select-paginate px-0" style="font-size: inherit" v-on:change="selectPage()">
                            <option disabled selected>...</option>
                            <option v-for="n in paginate.current_page" v-if="(n + 1) < (paginate.current_page - 1)" :value="(n + 1)">{{(n + 1)}}</option>
                        </select>
                    </span>
                    <span v-else class="page-link cursor-pointer" v-on:mouseenter="select_page = 'inicial_mid'">...</span>
                </li>
                <li :id="'pagina-' + id_paginate + '-' + (paginate.current_page - 1)"  class="page-item" >
                    <span class="page-link" v-on:click="buscarPagina(paginate.path + '?page=' + (paginate.current_page - 1),(paginate.current_page - 1))" style="cursor: pointer;" >{{(paginate.current_page - 1)}}</span>
                </li>
                <li :id="'pagina-' + id_paginate + '-' + paginate.current_page"  class="page-item active " >
                    <span class="page-link" v-on:click="buscarPagina(paginate.path + '?page=' + paginate.current_page,paginate.current_page)" style="cursor: pointer;" >{{paginate.current_page}}</span>
                </li>
                <li :id="'pagina-' + id_paginate + '-' + (paginate.current_page + 1)"  class="page-item" >
                    <span class="page-link" v-on:click="buscarPagina(paginate.path + '?page=' + (paginate.current_page + 1),(paginate.current_page + 1))" style="cursor: pointer;" >{{(paginate.current_page + 1)}}</span>
                </li>
                <li  class="page-item" >
                    <li  class="page-item">
                    <span class="page-link cursor-pointer p-0" v-if="select_page === 'final_mid'" v-on:mouseleave="select_page = null">
                        <select :id="id_paginate + '-select'" class="select-paginate px-0" style="font-size: inherit" v-on:change="selectPage()">
                            <option disabled selected>...</option>
                            <option v-for="n in paginate.last_page" v-if="n > paginate.current_page && (n + 1) < paginate.last_page" :value="(n + 1)">{{(n + 1)}}</option>
                        </select>
                    </span>
                    <span v-else class="page-link cursor-pointer" v-on:mouseenter="select_page = 'final_mid'">...</span>
                </li>
                <li  class="page-item" :id="'pagina-' + id_paginate + '-' + paginate.last_page">
                    <span class="page-link" v-on:click="buscarPagina(paginate.path + '?page=' + paginate.last_page,paginate.last_page)" style="cursor: pointer;">{{paginate.last_page}}</span>
                </li>
            </ul>
        </span>

    </nav>
</template>

<script>
    import {bus} from '../../app';
    export default {
        props:[
            'id_paginate',
            'paginas'
        ],
        data: function () {
            return {
                paginate:'',
                prev_page:'',
                block_pages:false,
                select_page:null
            }
        },
        methods:{
            buscarPagina(url,n){
                if(this.block_pages){
                    return false;
                }
                if(document.getElementById('pagina-' + this.id_paginate + '-' + n)){
                    document.getElementById('pagina-' + this.id_paginate + '-' + n).children[0].classList.add('text-color-pulse-primary');
                }
                this.prev_page = n;
                this.block_pages = true;
                this.$emit('buscarPagina',url);
            },
            selectPage(){
                let val = document.getElementById(this.id_paginate + '-select').value;
                this.buscarPagina(this.paginate.path + '?page=' + val,val);
            },
        },
        created() {
            bus.$on('set_paginate-' + this.id_paginate, (obj) => {
                if(this.prev_page){
                    if(document.getElementById('pagina-' + this.id_paginate + '-' + this.prev_page)){
                        document.getElementById('pagina-' + this.id_paginate + '-' + this.prev_page).children[0].classList.remove('text-color-pulse-primary');
                    }
                }

                this.paginate = obj;

                this.prev_page = '';
                this.select_page = null;
                this.block_pages = false;
            });
        },
        destroyed() {
            bus.$off('set_paginate-' + this.id_paginate);
        }

    }
</script>

<style type="scss">
    .select-paginate{
        height: 26px !important;
        max-width: 32px !important;
        border: 0;
    }
</style>
