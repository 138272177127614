<template>
    <div>
        <slot name="header"></slot>

        <!-- PAGINATION -->
        <div v-if="paginate.last_page > 1 && !$slots.header" :id="'paginate-' + id_table" class="d-flex align-items-end justify-content-end">
            <paginate :id_paginate="id_table" v-on:buscarPagina="pagina($event)"></paginate>
        </div>
        <!-- END PAGINATION -->
        <div class="position-relative">
            <table :id="id_table" class="table table-sm table-responsive-sm table-hover mb-0 text-wrap">
                <!-- --------------------------CABEÇALHO----------------------------- -->
                <thead>
                <tr>
                    <slot name="thead">

                    </slot>
                </tr>
                </thead>
                <!-- ---------------------------------------------------------------- -->
                <!-- --------------------------CORPO----------------------------- -->
                <tbody>
                <tr v-for="(registro, row) in listaRegistros" :id="id_table + '-' + row" :key="'row-'+id_table+'-' + row">
                    <slot name="body" v-bind="{index:row,data:registro,paginate:expo_paginate}">

                    </slot>
                </tr>
                </tbody>

            </table>

            <div v-if="$slots.empty_rows" :id="'alert_table-' + id_table"  class="col-12 px-0 d-none my-2">
                <slot name="empty_rows">

                </slot>
            </div>
            <div v-else :id="'alert_table-' + id_table" class="alert alert-warning col-12 d-none my-2" role="alert">
                Nenhum registro encontrado!

                <slot name="alert_table">

                </slot>
            </div>
            <div :id="'alert_validate-' + id_table" class="alert alert-info col-12 d-none my-2" role="alert">
                Utilize o campo de busca e/ou os filtros para encontrar os registros!
            </div>

            <!-- PAGINATION -->
            <div :id="'paginate-' + id_table + '-2'">
                <div class="float-right pt-2">
                    <paginate :id_paginate="id_table + '-2'" v-on:buscarPagina="pagina($event)"></paginate>
                </div>
            </div>

            <hr class="mt-0">

            <!-- LOADING -->
            <transition name="fade">
                <div v-if="carregando" class="p-3 text-center position-absolute col-12 d-flex align-items-center justify-content-center" style="top: 24px;background-color: rgba(255,255,255,0.5);height:calc(100% - 24px)">
                        <hollow-dots-spinner
                            :animation-duration="1000"
                            :dot-size="10"
                            :dots-num="3"
                            color="#6F787D"
                        />
                </div>
            </transition>
        </div>

    </div>
</template>

<script>
import {bus} from '../../app';
import { HollowDotsSpinner } from 'epic-spinners';
export default {
    components: {HollowDotsSpinner},
    props:[
        'id_table',
        'validate',
        'request',
        'break',
        'group_store_index',
    ],
    data: function () {
        return {
            url: this.request,
            uri: this.request.url,
            listaRegistros:{},
            group_store:{},
            paginate: '',
            expo_paginate:'',
            carregando: (!(!this.validate || !this.break)),
            disabledAction:[], //Serve para impedir que a mesma ação se repita antes da tabela ser atualizada,
        }
    },
    methods:{
        population(url) {
            //Verificar se a necessidade de validar o request 'validar_populations'
            if(document.getElementById(this.id_table) === null){
                console.log('Ação interompida');
                return false;
            }
            document.getElementById('alert_validate-' + this.id_table).classList.add('d-none');
            this.loading(true);
            bus.$emit('gerencia-request',url);
        },
        pagina(url){
            this.$store.state.request[this.id_table].url = url;
            this.population(this.$store.state.request[this.id_table]);
        },
        clearPaginate(){
            this.url.url = this.uri;
        },
        expoPaginate(obj){
              this.expo_paginate = {
                  current_page:obj.current_page,
                  first_page_url:obj.first_page_url,
                  from:obj.from,
                  last_page:obj.last_page,
                  last_page_url:obj.last_page_url,
                  next_page_url:obj.next_page_url,
                  path:obj.path,
                  per_page:obj.per_page,
                  prev_page_url:obj.prev_page_url,
                  to:obj.to,
                  total:obj.total
              };
        },
        loading(aplication){
            this.carregando = aplication;
        },
        registros_encontrados(){
            document.getElementById('alert_table-' + this.id_table).classList.add('d-none');
        },
        nenhum_registro_encontrado(){
            document.getElementById('alert_table-' + this.id_table).classList.remove('d-none');
        },
    },
    created() {
        //Adicionando elemento bus_emit para retornar ser emitido para get_response
        if(this.url.bus_emit){
            this.url.bus_emit.push('get_response-' + this.id_table);
        }else{
            this.url.bus_emit = ['get_response-' + this.id_table];
        }

        this.$store.state.request[this.id_table] = this.url;
        this.$store.state.request[this.id_table].uri = this.uri;

        bus.$on('get_response-' + this.id_table, (obj) => {
            this.expoPaginate(obj);
            this.listaRegistros = obj.data || obj;

            this.$emit('update_table',this.listaRegistros);

            //Enable the actions disabled
            this.disabledAction = [];

            //Verificando se algum registro foi encontrado
            if (this.listaRegistros.length > 0) {
                this.registros_encontrados();
            } else {
                this.nenhum_registro_encontrado();
            }

            //Enviando informações ao componente busca
            bus.$emit('get_response-busca-' + this.id_table, obj);


            //Configurando Paginate
            bus.$emit('set_paginate-' + this.id_table, obj);
            bus.$emit('set_paginate-' + this.id_table + '-2', obj);
            if (obj.last_page > 1) {
                bus.$emit('paginate', obj);
                this.paginate = obj;
            } else {
                this.paginate = '';
                if (obj.current_page > 1) {
                    this.pagina(obj.prev_page_url);
                    return false;
                }
            }
            this.loading(false);
        });

        bus.$on('refresh-' + this.id_table, (url) => {
            if(url && url.url){
                this.$store.state.request[this.id_table] = url;
                this.population(this.$store.state.request[this.id_table]);
            }else{
                this.population(this.$store.state.request[this.id_table]);
            }
        });

        bus.$on('pagina-' + this.id_table, (obj) => {
            this.pagina(obj);
        });

        bus.$on('reset-' + this.id_table, (url) => {
            this.paginate = {};
            this.listaRegistros = [];
            this.loading(true);
            this.population(this.url);
        });

        bus.$on('clear-' + this.id_table, (url) => {
            this.listaRegistros = [];
        });

        bus.$on('set_request-' + this.id_table, (obj) => {
            if(obj.validate){
                this.listaRegistros = [];
                this.paginate = [];
                this.clearPaginate();
                document.getElementById('alert_validate-' + this.id_table).classList.remove('d-none');
                return false;
            }else if(obj.valor === null){
                delete this.$store.state.request[this.id_table].data[obj.chave];
            }else{
                this.$store.state.request[this.id_table].data[obj.chave] = obj.valor;
            }
            this.clearPaginate();
            this.population(this.$store.state.request[this.id_table]);
        });

    },
    destroyed() {
        bus.$off('get_response-' + this.id_table);
        bus.$off('set_request-' + this.id_table);
        bus.$off('input-' + this.id_table);
        bus.$off('clear-' + this.id_table);
        bus.$off('reset-' + this.id_table);
        bus.$off('pagina-' + this.id_table);
        bus.$off('refresh-' + this.id_table);
    },
    mounted() {
        if(this.request && !this.break){
            if(this.validate){
                document.getElementById('alert_validate-' + this.id_table).classList.remove('d-none');
                return false;
            }
            this.population(this.$store.state.request[this.id_table]);
        }
    },
}
</script>

<style scoped>

</style>
