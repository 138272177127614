<template>
    <div class="row" style="position: relative">
        <!-- MAIS COMENTÁRIOS -->
        <div :id="'before_comments-' + id_comentarios" class="bg-white p-0 text-center col-12 d-none pb-2">
            <a class="text-primary m-0 font-weight-bold" title="Mostrar comentários anteriores" style="cursor:pointer"
               v-on:click="paginaAnterior()"
            >{{paginate.total - paginate.to + ((paginate.total - paginate.to) === 1 ? ' comentário anterior' : ' comentários anteriores')}} <i class='far fa-comment'></i></a>

            <span class="text-primary m-0 font-weight-bold text-color-pulse-primary d-none" >Carregando...</span>
        </div>
        <!-------------->

        <div v-for="(comentario,index) in comentarios" class="col-12">
                <div class="row">
                    <div class="col-auto">
                        <!-- IMAGEM -->
                        <img v-if="comentario.src_img" class="rounded-circle" :src="path + '/' + comentario.src_img" alt="" style="height: 40px; width: 40px"/>

                        <div v-else class="border rounded-circle p-2 text-center" style="height: 40px; width: 40px">
                            <i class='far fa-user text-lightgrey font-size-12'></i>
                        </div>
                        <!-- END IMAGEM -->
                    </div>
                    <div class="col pl-0">
                        <div class="card p-2 bg-waterprimary mb-2 shadow-none">
                            <div><span class="my-0 font-size-07 font-weight-bold" v-html="comentario.first_name" data-toggle="tooltip" data-placement="bottom" :title="comentario.email"></span> <span class="my-0 font-size-07 text-muted float-right"> {{comentario.data_criacao}}</span></div>
                            <div class="comentarios px-0 py-1" v-html="comentario.comentario">

                            </div>
                        </div>
                    </div>
                </div>
        </div>

        <div :id="'alert-' + id_comentarios" class="col-12 d-none">
            <div class="alert alert-warning mt-2" role="alert">
                Nenhum comentário encontrado!
            </div>
        </div>

        <!-- LOADING -->
        <div class="bg-white p-2 text-center col-12 d-none" :id="'loading-' + id_comentarios" style="border-radius: 0.5rem">
            <div style="margin-left: 44%">
                <hollow-dots-spinner
                        :animation-duration="1000"
                        :dot-size="10"
                        :dots-num="3"
                        color="#6F787D"
                />
            </div>
        </div>
        <!-------------->

        <!--+++++++++++++++++SLOT PARA EDITOR DE COMENTÁRIOS+++++++++++++++++++++-->
        <slot></slot>
        <!--+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++-->

    </div>
</template>

<script>
    import {bus} from '../../app';
    import { HollowDotsSpinner } from 'epic-spinners';
    export default {
        components: {HollowDotsSpinner},
        props:[
            'id_comentarios',
            'request',
            'path',
        ],
        data() {
            return {
                url: this.request,
                uri: this.request.url,
                paginate:'',
                comentarios:'',
                after_comments:'',
                add_comment:false,
                block: false
            }
        },
        methods:{
            population(url) {
                bus.$emit('gerencia-request',url);
            },
            paginaAnterior() {
                if(this.block === true){
                    return false;
                }

                var url = this.url;
                url.url = this.uri + '?page=' + (this.paginate.current_page + 1);
                this.after_comments = this.comentarios;
                this.loading_before_comments(true);
                this.block = true;
                this.population(url);
            },
            getResponse(){
                if(this.url.bus_emit){
                    this.url.bus_emit.push('get_response-' + this.id_comentarios);
                }else{
                    this.url.bus_emit = ['get_response-' + this.id_comentarios];
                }
            },
            /*=====================================NOTIFICAÇÕES ===============================*/
            registros_encontrados(){
                document.getElementById('alert-' + this.id_comentarios).classList.add('d-none');
            },
            nenhum_registro_encontrado(){
                document.getElementById('alert-' + this.id_comentarios).classList.remove('d-none');
            },
            loading(aplication){
                if(aplication === true){
                    document.getElementById('loading-' +  this.id_comentarios).classList.remove('d-none');
                }else{
                    document.getElementById('loading-' +  this.id_comentarios).classList.add('d-none');
                }
            },
            beforeComments(aplication){
                if(aplication === true){
                    document.getElementById('before_comments-' +  this.id_comentarios).classList.remove('d-none');
                }else{
                    document.getElementById('before_comments-' +  this.id_comentarios).classList.add('d-none');
                }
            },
            loading_before_comments(aplication){
                if(aplication === true){
                    document.getElementById('before_comments-' + this.id_comentarios).children[0].classList.add('d-none');
                    document.getElementById('before_comments-' + this.id_comentarios).children[1].classList.remove('d-none');
                }else{
                    document.getElementById('before_comments-' + this.id_comentarios).children[0].classList.remove('d-none');
                    document.getElementById('before_comments-' + this.id_comentarios).children[1].classList.add('d-none');
                }
            },
            bg_fade(index){
                let fade = this.paginate.to - this.paginate.from;
                if(index <= fade){
                    return 'bg-fade-color-grey';
                }
            }
        },
        created() {
            //Adicionando elemento bus_emit para retornar ser emitido para get_response
            this.getResponse();

            if(this.url) {
                bus.$on('get_response-' + this.id_comentarios, (obj) => {
                    //Verificando se algum registro foi encontrado

                    var comments = {};

                    if(obj.data.length > 0){
                        let i = 0;

                        let c = obj.data.length;
                        for(c; c >= 1; c--){
                            comments[i] = obj.data[c - 1];
                            i++;
                        }

                        if(this.after_comments){
                            for(var comentario in this.after_comments){
                                comments[i] = this.after_comments[comentario];
                                i++;
                            }
                        }

                    }

                    this.comentarios = comments;

                    this.paginate = obj;
                    if(this.paginate.current_page < this.paginate.last_page){
                        this.beforeComments(true);
                    }else{
                        this.beforeComments(false);
                    }

                    if(obj.data.length > 0){
                        this.registros_encontrados();
                    }else{
                        this.nenhum_registro_encontrado();
                    }

                    this.loading(false);
                    this.loading_before_comments(false);

                    this.block = false;
                });
            }

            bus.$on('refresh-' + this.id_comentarios, (obj) => {
                this.url.url = this.uri;
                this.comentarios = '';
                this.paginate = '';
                this.after_comments = '';
                this.registros_encontrados();
                this.beforeComments(false);
                this.loading(true);
                this.population(this.url);
            });

        },
        mounted() {

        }
    }
</script>

<style scoped>

</style>
