<template>
    <!-- LOADING -->

</template>

<script>
    import {bus} from '../../app';
    import { HollowDotsSpinner } from 'epic-spinners';
    export default {
        components: {HollowDotsSpinner},
        data: function () {
            return {
                content_element:{} //Armazena o conteúdo do elemento caso seja necesário mostrá-lo novamente
            }
        },
        methods:{
            page(obj){
                if(obj.display === true){
                    document.getElementById('loading_page').style.display = 'block';
                }else{
                    document.getElementById('loading_page').style.display = 'none';
                }
            },
            pageWaiting(obj){
                if(obj.display === true){
                    document.getElementById('loading_page_waiting').style.display = 'block';
                }else{
                    document.getElementById('loading_page_waiting').style.display = 'none';
                }
            },
            spin(obj){
                if(document.getElementById(obj.id)){
                    if(obj.display === true && document.getElementById(obj.id).innerHTML !== '<i class="fas fa-circle-notch fa-spin" style="color: inherit !important; font-size: 0.8em"></i>'){
                        this.content_element[obj.id] = document.getElementById(obj.id).innerHTML;
                        document.getElementById(obj.id).innerHTML = '<i class="fas fa-circle-notch fa-spin" style="color: inherit !important; font-size: 0.8em"></i>';
                    }else{
                        document.getElementById(obj.id).innerHTML = this.content_element[obj.id];
                    }
                }
            },
            add_spin(obj){
                if(document.getElementById(obj.id)) {
                    if (obj.display === true) {
                        document.getElementById(obj.id).innerHTML = document.getElementById(obj.id).innerHTML + " <i id='load-" + obj.id + "' class='fas fa-circle-notch fa-spin' style='color: inherit !important; font-size: 0.75em'></i>";
                    } else {
                        if (document.getElementById('load-' + obj.id)) {
                            var element = document.getElementById('load-' + obj.id);
                            element.remove();
                        }
                    }
                }
            },
            add_element(obj){
                if(document.getElementById(obj.id)) {
                    if (obj.display === true) {
                        document.getElementById(obj.id).innerHTML = document.getElementById(obj.id).innerHTML + " <span id='load-" + obj.id + "'>" + obj.element + "</span>";
                    } else {
                        if (document.getElementById('load-' + obj.id)) {
                            var element = document.getElementById('load-' + obj.id);
                            element.remove();
                        }
                    }
                }
            },
            dots(obj){
                console.log(obj);
            }
        },
        created() {
            bus.$on('loading', (obj) => {
                switch (obj.type) {
                    case 'page':
                        this.page(obj);
                        break;
                    case 'page_waiting':
                        this.pageWaiting(obj);
                        break;
                    case 'spin':
                        this.spin(obj);
                        break;
                    case 'add_spin':
                        this.add_spin(obj);
                        break;
                    case 'add_element':
                        this.add_element(obj);
                        break;
                    case 'dots':
                        this.dots(obj);
                        break;
                }
            });
        }
    }
</script>

<style scoped>

</style>
