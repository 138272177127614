<template>
    <select :class="'form-control ' + class_select + ' ' + (select_class_change || '') + ' ' + loader" :title="title_select" :id="id_select" v-on:change="changeSelect()" v-model="val_">
        <option v-for="(option,index) in options" :value="index" class="text-dark" v-html="option.descricao"></option>
    </select>
</template>

<script>
    import {bus} from '../../app';
    export default {
        props:[
            'class_select',
            'id_select',
            'title_select',
            'options',
            'valor',
            'state',
            'chave',
            'request',
            'required',
            'disabled',
        ],
        data: function () {
            return {
                url: this.request,
                select_class_change:'',
                loader:'',
            }
        },
        methods:{
            formatar(option){
                if(option){
                    this.select_class_change = option.select_class;
                }else{
                    this.select_class_change = '';
                }
            },
            changeSelect(){
                if(this.request){
                    this.loading(true);
                    bus.$emit('gerencia-request',this.url);
                }
            },
            selectChange(val){
                this.formatar(this.options[val]);
            },
            loading(application){
                if(application === true){
                    this.loader = 'border-color-pulse-primary';
                    //document.getElementById(this.id_select).classList.add('text-color-pulse-primary');
                }else{
                    this.loader = '';
                    //document.getElementById(this.id_select).classList.remove('text-color-pulse-primary');
                }
            },
        },
        watch:{
            val_(newVal, oldVal){
                this.selectChange(newVal);
            }
        },
        computed: {
            val_: {
                get: function() {
                    return this.$store.state[this.state][this.chave];
                },
                set: function(val) {
                    this.$store.state[this.state][this.chave] = val;
                    this.$emit('change_select', val);
                    this.selectChange(val);
                }
            }
        },
        created() {
            //Adicionando elemento bus_emit para retornar ser emitido para get_response
            if(this.url.bus_emit){
                this.url.bus_emit.push('get_response-' + this.id_select);
            }else{
                this.url.bus_emit = ['get_response-' + this.id_select];
            }

            if(this.request) {
                bus.$on('get_response-' + this.id_select, (obj) => {
                    this.loading(false);
                });
            }
        },
        mounted() {
            if(this.required){
                document.getElementById(this.id_select).required = true;
            }

            if(this.disabled){
                document.getElementById(this.id_select).disabled = true;
            }

            this.val_ = this.$store.state[this.state][this.chave];
        }
    }
</script>

<style scoped>

</style>
