<template>
    <div :class="(drop.align ? drop.align : 'dropdown') + ' ' + drop_class">
        <button :class="'btn ' + btn.class" type="button" :id="id_dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                v-html="btn.titulo" :title="title">
        </button>
        <div :class="'dropdown-menu ' + drop.class" :aria-labelledby="id_dropdown">
            <slot>

            </slot>
        </div>
    </div>
</template>

<script>
    export default {
        props:['id_dropdown','btn','drop','drop_class','title']
    }
</script>

<style scoped>

</style>
