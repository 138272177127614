<template>
    <input type="range" class="custom-range" :id="id_range" :min="min" :max="max" :step="step" v-model="$store.state.registro[store]" v-on:input="changeRange()">
</template>

<script>
    import {bus} from '../../app';
    export default {
        props:['id_range','min','max','step','start','store'],
        methods:{
            changeRange(){
                console.log(this.$store.state.registro[this.store]);
            }
        },
        created(){
            bus.$on('refresh-' + this.id_range, (obj) => {
                this.$store.state.registro[this.store] = this.start;
                console.log(this.$store.state.registro[this.store]);
            });
        },
        mounted() {
            this.$store.state.registro[this.store] = this.start;
        }
    }
</script>

<style scoped>

</style>