<template>
    <div class="modal fade" :id="id_modal" tabindex="-1" role="dialog" aria-hidden="true" :data-backdrop="backdrop">
        <div :class="'modal-dialog ' + class_modal" role="document">
            <div :id="'content-' + id_modal" :class="'modal-content ' + class_modal_content">
                    <div v-if="!lock" class="modal-header d-flex justify-content-end align-center p-1 border-0 shadow-sm" style="background-color: #f9fafb;">
                        <slot name="header"></slot>

                        <button v-if="backdrop === 'static'" type="button" :id="'btn_close-' + id_modal" class="btn btn-sm py-1 px-2 border-0 text-danger b-close" data-dismiss="modal" aria-label="Close" title="Fechar">
                            <i class="fas fa-times font-size-09"></i>
                        </button>
                        <button v-else type="button" :id="'btn_close-' + id_modal" class="btn btn-tiny border-0 py-1 px-2 b-close" data-dismiss="modal" aria-label="Close" title="Fechar">
                            <i class="fas fa-times font-size-09"></i>
                        </button>
                    </div>
                    <div class="modal-body position-relative border-0">
                        <slot></slot>
                    </div>
            </div>
        </div>

        <button :id="'btn_toggle-' + id_modal" type="button" class="btn btn-primary" data-toggle="modal" :data-target="'#' + id_modal" hidden>
            Toggle
        </button>
    </div>
</template>

<script>
    import {bus} from '../../app'
    export default {
        props:[
            'id_modal',
            'class_header',
            'class_modal',
            'class_modal_content',
            'header',
            'titulo',
            'scroll',
            'backdrop',
            'close_emit',
            'open_emit',
            'close_watch', //vigia as alterações/mutations e dispara algum evento
            'lock',
            'lock_label'
        ],
        data: function () {
            return {
                barra_scroll: '',
                validateCloseWatch:false,
                store:{}
            }
        },
        methods:{
            closeEmit(){
                for(var emit in this.close_emit){
                    if(typeof this.close_emit[emit] === 'string'){
                        bus.$emit(this.close_emit[emit], {});
                    }else{
                        let emit = this.close_emit[emit];
                        setTimeout(() => {bus.$emit(emit.emit,emit.obj);},emit.timeout);
                    }
                }
            },
            openEmit(){
                for(var emit in this.open_emit){
                    if(typeof this.open_emit[emit] === 'string'){
                        bus.$emit(this.open_emit[emit], {});
                    }else{
                        let emit = this.open_emit[emit];
                        setTimeout(() => {bus.$emit(emit.emit,emit.obj);},emit.timeout);
                    }
                }
            },
        },
        created(){
            this.$store.state.modal[this.id_modal] = true;
            bus.$on('show_modal-' + this.id_modal, (obj) => {
                $('#' + this.id_modal).modal('show');
            });
            bus.$on('hidde_modal-' + this.id_modal, (obj) => {
                this.validateCloseWatch = false;
                $('#' + this.id_modal).modal('hide');
            });
            if(this.scroll){
                this.barra_scroll = 'height:' + this.scroll + 'vh;overflow-y: auto;'
            }
        },
        mounted() {

            $('#' + this.id_modal).on("hide.bs.modal", () => {
                if(this.validateCloseWatch){
                    let updates = 0;
                    for (let state in this.store){
                        for (let chave in this.$store.state[state]){
                            if(this.store[state][chave] !== this.$store.state[state][chave]){
                                updates++;
                            }
                        }
                        if(updates > 0){
                            this.close_watch.function();
                            this.validateCloseWatch = false;
                            return false;
                        }
                    }
                }
            }).on("hidden.bs.modal", () => {
                this.$emit('closed',{});
                if(this.close_emit){this.closeEmit();}
                for(var modal in this.$store.state.modal){
                    if(document.getElementById(modal) && document.getElementById(modal).classList.contains('show')){
                        document.getElementById('body_page').classList.add('modal-open');
                    }
                }
                $('#' + this.id_modal).off('click');
                $('#content-' + this.id_modal).off('mouseenter').off('mouseleave');
                if(document.getElementById('btn_close-' + this.id_modal)){
                    document.getElementById('btn_close-' + this.id_modal).classList.add('border-0');
                    document.getElementById('btn_close-' + this.id_modal).classList.remove('border-color-pulse-danger');
                }
                this.validateCloseWatch = false;
            }).on('show.bs.modal', () => {
                if(this.close_watch){
                    for (let state in this.close_watch.state){
                        this.store[this.close_watch.state[state]] = {};
                        for (let chave in this.$store.state[this.close_watch.state[state]]){
                            this.store[this.close_watch.state[state]][chave] = this.$store.state[this.close_watch.state[state]][chave];
                        }
                    }
                    this.validateCloseWatch = true;
                }
            }).on('shown.bs.modal', () => {
                this.$emit('opened',{});
                if(this.open_emit){
                    this.openEmit();
                }
                if(this.backdrop === 'static'){
                    if($('#content-' + this.id_modal).is(':hover')){
                        $('#content-' + this.id_modal).addClass('mouse_in');
                    }
                    $('#content-' + this.id_modal).on('mouseenter', function () {
                        $(this).addClass('mouse_in');
                    }).on('mouseleave', function () {
                        $(this).removeClass('mouse_in');
                    });

                    $('#' + this.id_modal).on('click', (e) => {
                        if(!$('#content-' + this.id_modal).hasClass('mouse_in')){
                            if (this.lock_label){
                                this.$noty.warning(this.lock_label,{layout:'center', timeout:3000});
                            }

                            var btn_close = document.getElementById('btn_close-' + this.id_modal);
                            if(btn_close){
                                btn_close.classList.remove('border-0');
                                btn_close.classList.add('border-color-pulse-danger');

                                setTimeout(() => {
                                    if(btn_close){
                                        btn_close.classList.add('border-0');
                                        btn_close.classList.remove('border-color-pulse-danger');
                                    }
                                },3000);
                            }
                        }
                        e.stopPropagation();
                    });
                }
            });

            bus.$emit('action', {input_required:true});
        },
        destroyed() {
            bus.$off('show_modal-' + this.id_modal);
            bus.$off('hidde_modal-' + this.id_modal);
        },
    }
</script>

<style scoped>
    .b-close{
        opacity: .5;
        cursor: pointer;
    }
    .b-close:hover{
        opacity: 1 !important;
    }

</style>
