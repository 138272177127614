<template>
    <LineChartGenerator
        :chart-id="chart_id"
        :chart-data="chartData"
        :chart-options="chart_options"
        :width="width"
        :height="height"
    />
</template>
<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement
} from 'chart.js'

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement
)
export default {
    components: {
        LineChartGenerator
    },
    props: {
        chart_id: {
            type: String,
            default: 'line_chart-' + Date.now()
        },
        chart_data: {
            type: Object,
        },
        custom_data:{
            type: Object,
        },
        chart_options: {
            type: Object,
            default: () => {}
        },
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 400
        },
        auto_colors:{
            type: Boolean,
            default:false
        },
        line_labels:{
            type: Boolean,
            default:false
        },
        delay_render:{
            type: Number,
            default:0
        },
    },
    data: function () {
        return {
            chartData:{},
            plugins:[],
        }
    },
    methods:{
        renderizar(){
            this.parseAxisKeys();
        },
        parseAxisKeys(){
            for (var key in this.chart_data.datasets){
                this.chart_data.datasets[key].data = this.parseObjectAxiskeys(this.chart_data.datasets[key]);
            }

            this.chartData = this.chart_data;
        },
        parseObjectAxiskeys(datasets){
            var arrayData = [];
            for (let key in datasets.data){
                arrayData[key] = {x:datasets.data[key][datasets.xKey],y:datasets.data[key][datasets.yKey]}
                // this.parseLabelsXAxis(datasets.data[key][datasets.xKey]);
            }
            return arrayData;
        },
        parseLabelsXAxis(xData){
            if(this.labelsX.indexOf(xData) === -1){
                this.labelsX.push(xData);
            }
        }
    },
    created () {
        if (this.delay_render){
            setTimeout(() => {
                this.renderizar();
            },this.delay_render);
        }else{
            this.renderizar();
        }
    }
}
</script>

<style scoped>

</style>
