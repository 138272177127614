<template>
    <span :id="id_timeline">
        <light-timeline :items='items'>

            <template slot='tag' slot-scope='{ item }'>
                {{item.date}}
            </template>

            <template slot='symbol' slot-scope='{ item }'>
                <div class="my_icon_class"><i :class="item.class"></i></div>
            </template>

            <template slot='content' slot-scope='{ item }'>
                {{item.content}}
            </template>

        </light-timeline>
    </span>
</template>

<script>
    export default {
        name: "Timeline",
        props:['id_timeline','registros'],
        data () {
            return {
                items:this.registros,
            }
        },
        watch: {
            items: function (newVal, oldVal) {
                console.log('Atualizou');
            }
        },
        methods:{
            ajustarLinha(){
                var ul = document.getElementById(this.id_timeline).children[0];
                ul.children[0].setAttribute( 'style', 'padding-top:0rem !important' );
            }
        },
        mounted(){
            this.ajustarLinha();
        }
    }
</script>

<style scoped>

</style>
