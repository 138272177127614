<template>
    <div v-show="files.length > 0 || drop_file" :class="'card border-secondary shadow-none ' + (drop_file ? 'bg-hover-ligthgrey' : '') " style="min-height: 150px;border: 1px dotted">
        <div class="card-body position-relative">

            <h6 v-if="!drop_file" class="mb-3">Anexar</h6>
            <h6 v-else-if="drop_file && files.length < max_files" class="text-center font-weight-light mb-4">
                <span class="d-none d-lg-block">
                    Para anexar, <button class="btn btn-link p-0" v-on:click="chooseFile()" style="font-size: inherit">selecione</button> ou arraste o arquivo até aqui!
                </span>
                <span class="d-lg-none">
                    <button class="btn btn-sm btn-success btn-block" v-on:click="chooseFile()">Incluir arquivo</button>
                </span>
            </h6>
            <h6 v-else-if="drop_file && files.length === max_files" class="text-center font-weight-light text-darkgold mb-4">Limite de arquivos atingido!</h6>

            <transition-group name="slide-fade">
                <div v-for="(file,index) in files" class="mt-2" v-show="file.active !== false || file.success !== true" :key="file.id" :id="file.id">
                    <i v-if="file.active === false && file.success === false" class="fas fa-cloud text-warning mr-1" title="Aguardando upload"></i>
                    <i v-if="file.active === true && file.success === false" class="fas fa-cloud-upload-alt text-color-pulse-primary   mr-1" title="Upload"></i>
                    <i v-if="file.active === false &&  file.success === true" class="fas fa-cloud text-success mr-1" title="Upload realizado com Sucesso!"></i>

                    <span>
                        {{file.name}}
                        <span v-if="(!$refs.upload || !$refs.upload.active) &&  file.success === false">
                            <span v-if="file.data.detalhes && file.data.detalhes !== null && file.data.detalhes !== ''" class="ml-2 text-muted" title="Anotações"><i class="far fa-sticky-note"></i></span>

                            <button
                                v-if="edit_info"
                                @click.prevent="editFile(file,index)"
                                class="btn btn-sm btn-outline-primary border-0 p-1 ml-2" :title="'Editar ' + file.name" >
                                editar
                            </button>
                            <button v-if="(!$refs.upload || !$refs.upload.active) &&  file.success === false"
                                    @click.prevent="remove(file)" class="btn btn-sm btn-outline-danger border-0 p-1 ml-2" :title="'Remover '+ file.name">
                                    remover
                            </button>
                        </span>
                    </span>
                </div>

                <div v-show="d_edit_info" :key="id_file + '-edit_file'" class="position-absolute shadow card p-3 w-file_edit mb-3" :style="'top:' + top_edit_info + ';left:' + left_edit_info" >
                    <div class="d-flex justify-content-end position-relative">
                        <button type="button" class="btn btn-icon border-0 btn-close" title="Fechar" @click.prevent="closeEditFile()">
                            <i class="fas fa-times font-size-09"></i>
                        </button>
                    </div>
                    <div class="form-group">
                        <label :for="id_file + '-edit_file_name'">Nome do arquivo</label>
                        <input type="text" :id="id_file + '-edit_file_name'" :name="id_file + '-edit_file_name'" class="form-control w-100 text-wrap" v-model.lazy="nome_arquivo_">
                    </div>

                    <div class="form-group">
                        <label :for="id_file + '-edit_file_detalhes'">Anotações adicionais</label>
                        <textarea :id="id_file + '-edit_file_detalhes'" :name="id_file + '-edit_file_detalhes'" class="form-control w-100" rows="4" v-model.lazy="info_adicionais_arquivo_"></textarea>
                    </div>
                </div>

            </transition-group>


            <div v-if="d_btn_submit">
                <button class="btn btn-primary btn-sm btn-block mt-3"
                        v-show="files.length > 0 && $refs.upload && !$refs.upload.active && btn_upload"
                        @click.prevent="$refs.upload.active = true; closeEditFile()" type="button">
                    <i class="fas fa-cloud-upload-alt font-size-1 mr-1" title="Upload"></i> Upload
                </button>
                <div v-if="$refs.upload && $refs.upload.active" class="text-color-pulse-primary text-center font-size-1 font-weight-light pt-2"><i class="fas fa-cloud-upload-alt font-size-1 mr-1"></i> Enviando...</div>
                <button class="btn btn-danger btn-sm btn-block mt-3" v-show="$refs.upload && $refs.upload.active"
                        @click.prevent="$refs.upload.active = false" type="button">
                    <i class="fas fa-cloud mr-1"></i> Stop upload
                </button>
            </div>
        </div>


        <file-upload
                ref="upload"

                :input-id="id_file"
                :name="name_file"
                :maximum="maximum_files"
                :multiple="true"
                :post-action="request.post"
                :data="dados"
                :headers="{'X-Token-CSRF': dados._token}"
                :drop="drop_file"
                @input="updateFiles"
                @input-file="inputFile"
                @input-filter="inputFilter"
        >
        </file-upload>

        <div hidden>
<!--            <croppa v-model="croppa_img"></croppa>-->
        </div>
    </div>
</template>

<script>
    import {bus} from '../../app';
    import Compressor from 'compressorjs';
    const VueUploadComponent = require('vue-upload-component');
    Vue.component('file-upload', VueUploadComponent);
    export default {
        props:['request','name_file','id_file','maximum_files','dados','drop_file','after_upload','edit_info','img_size_limit','submit','file_format'],
        components: {
            FileUpload: VueUploadComponent
        },
        data: function () {
            return {
                files: [],
                btn_upload:false,
                max_files:this.maximum_files,
                slots_empty:this.maximum_files,
                data_request:{},
                d_edit_info:false,
                top_edit_info:0,
                left_edit_info:0,
                nome_arquivo:null,
                info_adicionais_arquivo:null,
                index_file:null,
                croppa_img:null,
                d_btn_submit:true,
            }
        },
        methods: {
            updateFiles(val){
                this.files = val;
                this.$emit('files_load',this.files.length);
            },
            inputFile: function (newFile, oldFile) {
                if (newFile && oldFile && !newFile.active && oldFile.active) {
                    // Get response data
                    // console.log('response', newFile.response)
                    if(newFile.success === true && (newFile.response && newFile.response.upload)){
                        this.$emit('success_upload_file',newFile);
                    }
                    if (newFile.xhr) {
                        //console.log('status', newFile.xhr.status)
                    }
                }
                var files_upload = 0;
                for(var file in this.files){
                    if(this.files[file].active === false && this.files[file].success === false){this.btn_upload = true;}else{this.btn_upload = false;}
                    if(this.files[file].active === false && this.files[file].success === true){files_upload++;}
                }
                if(this.files && this.files.length > 0 && this.files.length === files_upload){
                    bus.$emit('gerencia-response',{data:newFile.response});
                    this.afterUpload();
                }
            },
            inputFilter(newFile, oldFile, prevent) {
                if(this.max_files === 0){this.$noty.error('Não é possível adicionar mais arquivos!',{layout:'center',});return prevent();}
                if (newFile && !oldFile) {
                    if(!this.file_format){
                        if (!/\.(doc|docx|pdf|txt|xls|xlsx|jpg|JPG|JPEG|jpeg|png|PNG|bmp|xml|ppt|zip|rar)$/i.test(newFile.name)) {
                            this.$noty.warning('Extensão de arquivo não permitida!',{layout:  'center',});
                            return prevent();
                        }
                    }else{
                        let fmt = new RegExp(this.file_format);
                        if (!fmt.test(newFile.name.toLowerCase())) {
                            this.$noty.warning('Extensão de arquivo não permitida!',{layout:  'center',});
                            return prevent();
                        }
                    }

                    // Automatic compression,
                    if (newFile && newFile.file && newFile.type.substr(0, 6) === 'image/' && !oldFile && newFile.size > this.img_size_limit) {
                        let quality = parseFloat((this.img_size_limit / newFile.size).toFixed(2))
                        quality = quality < 0.6 ? 0.6 : quality;
                        // Set an error to prevent being uploaded
                        newFile.error = 'compressor'
                        new Compressor(newFile.file, {
                            quality: quality,
                            maxWidth:1920,
                            maxHeight:1920,
                            success: (result) => {
                                // Successfully deleted the error to write the new file
                                this.$refs.upload.update(newFile, {
                                    file: result,
                                    error: '',
                                    size: result.size,
                                    type: result.type
                                })
                            },
                            error: (e) => {
                                this.$refs.upload.update(newFile, {error: e.message})
                            },
                        });
                    }else{
                        if(newFile.size > 41943040){
                            this.$noty.warning('Tamanho máximo de arquivo excedido: 3MB!',{layout:  'center',});
                            return prevent();
                        }
                    }

                    // Create a blob field
                    newFile.blob = '';
                    let URL = window.URL || window.webkitURL;
                    if (URL && URL.createObjectURL) {
                        newFile.blob = URL.createObjectURL(newFile.file);
                        newFile.data.name = newFile.name;
                        newFile.data.detalhes = null;
                        newFile.name_edit = newFile.data.name.substr(0, newFile.data.name.lastIndexOf('.'));
                        newFile.data.size = newFile.size;
                        newFile.data.extension = newFile.name.split('.').pop();
                        for (var dado in this.data_request) {newFile.data[dado] = this.data_request[dado];}
                        var i = 0;
                        if(this.dados.get_store){
                            for (i = 0; i < this.dados.get_store.length; i++) {
                                if(typeof this.dados.get_store[i] === 'string'){
                                    newFile.data[this.dados.get_store[i]] = this.$store.state.registro[this.dados.get_store[i]];
                                }else{
                                    newFile.data[this.dados.get_store[i].chave] = this.$store.state[this.dados.get_store[i].state][this.dados.get_store[i].chave];
                                }
                            }
                        }
                    }
                }
            },
            remove(file) {
                this.$refs.upload.remove(file);
            },
            editFile(file,index) {
                this.top_edit_info = (document.getElementById(file.id).offsetTop + document.getElementById(file.id).offsetHeight) + 'px';
                let child = document.getElementById(file.id).firstChild;
                this.left_edit_info = (document.getElementById(file.id).offsetLeft + child.offsetWidth + 5) + 'px';
                this.index_file = index;
                this.nome_arquivo_ = file.name_edit;
                this.info_adicionais_arquivo_ = file.data.detalhes;
                this.d_edit_info = true;
            },
            closeEditFile(){
                this.d_edit_info = false;
            },
            busEmit(obj,res){
                var i;
                for (i = 0; i < obj.length; i++) {
                    bus.$emit(obj[i],res.data);
                }
            },
            chooseFile(){
                document.getElementById(this.id_file).click();
            },
            afterUpload(){
                this.$emit('success', this.files);
                var count = 0;
                for(var file in this.files){if(this.files[file].success === true){count++;}}
                this.max_files = this.max_files - count;
                if(this.after_upload){
                    if(this.after_upload.bus_emit){
                        this.busEmit(this.after_upload.bus_emit, {});
                    }
                }
                this.$refs.upload.clear();
            },
            refresh(){
                this.files = [];
            }
        },
        computed:{
            nome_arquivo_: {
                get: function() {
                    return this.nome_arquivo;
                },
                set: function(val) {
                    this.nome_arquivo = val.replace(/[&\/\\#,+$%.'":*?<>{}]/g,'_');
                    this.files[this.index_file].name_edit = this.nome_arquivo;
                    this.files[this.index_file].name = (this.nome_arquivo !== '' ? this.nome_arquivo : 'arquivo_mbs' ) + '.' + this.files[this.index_file].data.extension;
                    this.files[this.index_file].data.name = this.files[this.index_file].name;
                }
            },
            info_adicionais_arquivo_: {
                get: function() {
                    return this.info_adicionais_arquivo;
                },
                set: function(val) {
                    this.info_adicionais_arquivo = val;
                    this.files[this.index_file].data.detalhes = this.info_adicionais_arquivo;
                }
            },
        },
        created() {
            bus.$on('choose_files-' + this.id_file, (obj) => {
                this.chooseFile();
            });

            bus.$on('set_upload-' + this.id_file, (obj) => {
                if(obj.data){
                    this.data_request = obj.data;
                }
            });

            bus.$on('submit-' + this.id_file, (obj) => {
                if(this.files.length === 0){
                    this.$emit('empty_submit',obj);
                }
                for (var file in this.files){
                    var dados = {};

                    if(obj && obj.data){
                        for (var dat in obj.data) {dados[dat] = obj.data[dat];}
                    }

                    for (var dado in this.files[file].data) {dados[dado] = this.files[file].data[dado];}
                    var i = 0;

                    if(this.dados.get_store){
                        for (i = 0; i < this.dados.get_store.length; i++) {
                            if(typeof this.dados.get_store[i] === 'string'){
                                dados[this.dados.get_store[i]] = this.$store.state.registro[this.dados.get_store[i]];
                            }else{
                                dados[this.dados.get_store[i].chave] = this.$store.state[this.dados.get_store[i].state][this.dados.get_store[i].chave];
                            }
                        }
                    }

                    this.$refs.upload.update(this.files[file], {data: dados})
                }
                this.$refs.upload.active = true;
            });

            bus.$on('refresh-' + this.id_file, (obj) => {
                this.refresh();
            });

            bus.$on('reset-' + this.id_file, (obj) => {
                this.files = [];
                this.btn_upload = false;
                this.max_files = this.maximum_files;
                this.slots_empty = this.maximum_files;
            });
        },
        mounted() {
            if(this.submit === true || this.submit === false){
                this.d_btn_submit = this.submit;
            }
        },
        destroyed() {
            bus.$off('choose_files-' + this.id_file);
            bus.$off('set_upload-' + this.id_file);
            bus.$off('submit-' + this.id_file);
            bus.$off('refresh-' + this.id_file);
            bus.$off('reset-' + this.id_file);
        }
    }
</script>

<style type="scss">
@media (min-width: 900px) {
    .w-file_edit {
        max-width: 50vw;
        min-width: 350px;
    }
}
@media (max-width: 900px) {
    .w-file_edit{
        max-width: 70vw;
        min-width: 250px;
    }
}

.btn-close{
    opacity: .5;
    position: absolute;
    top: -10px;
    right: -10px;
}
.btn-close:hover{
    opacity: 1 !important;
}
</style>
