<template>
        <Doughnut :chart-id="chart_id"
                  :chart-data="chartData"
                  :chart-options="chart_options"
                  :width="width_"
                  :height="height_"
                  :plugins="plugins"
        />
</template>
<script>
    import { Doughnut } from 'vue-chartjs/legacy';
    import {
        Chart as ChartJS,
        Title,
        Tooltip,
        Legend,
        ArcElement,
        CategoryScale
    } from 'chart.js';

    ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);
    export default {
        components: {
            Doughnut
        },
        props: {
            chart_id: {
                type: String,
                default: 'donut_chart-' + Date.now()
            },
            metricas: {
                type: Object,
                default: {
                    prefix:'R$',
                    group:null
                }
            },
            chart_data: {
                type: Object,
            },
            custom_data:{
                type: Object,
            },
            chart_options: {
                type: Object,
                default: () => {}
            },
            width: {
                type: Number,
                default: 400
            },
            height: {
                type: Number,
                default: 400
            },
            auto_colors:{
                type: Boolean,
                default:false
            },
            auto_size:{
                type: Boolean,
                default:false
            },
            line_labels:{
                type: Boolean,
                default:false
            },
        },
        data: function () {
            return {
                height_:500,
                width_:500,
                chartData:{},
                plugins:{},
            }
        },
        methods:{
          customData(dados){
              const chartdata = {
                  labels:[],
                  datasets:[]
              };

              const data_val = [];
              for (let i in dados.obj){
                  chartdata.labels.push(dados.obj[i][dados.label]);
                  data_val.push(dados.obj[i][dados.valor]);
              }
              chartdata.datasets.push({data:data_val});
              return chartdata;
          },
        },
        created() {
            const doughnutLabelsLine = {
                id: "doughnutLabelsLine",
                metricas:this.metricas,
                afterDraw(chart,args,options) {
                    const {ctx, chartArea: { top,bottom,left,right, width,height}} = chart;
                    const halfwidth = width / 2;
                    const halfheight = height / 2;

                    var dataQuad = {sd:0,id:0,ie:0,se:0};
                    var pixelsQuad = {sd:0,id:0,ie:0,se:0};
                    var indexQuad = {sd:[],id:[],ie:[],se:[]};
                    var indexTooltiposition = {};
                    var sumValues = 0;

                    chart.data.datasets.forEach((dataset,i) =>{
                        dataQuad = {sd:0,id:0,ie:0,se:0};
                        pixelsQuad = {sd:0,id:0,ie:0,se:0};
                        indexQuad = {sd:[],id:[],ie:[],se:[]};
                        indexTooltiposition = {};
                        sumValues = 0;
                        chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
                            const {x, y} = datapoint.tooltipPosition();
                            indexTooltiposition[index] = {x:x,y:y};

                            let quadrante = '';

                            if (y <= halfheight) {
                                quadrante += 's'; //Superior
                            } else {
                                quadrante += 'i'; //Inferior
                            }
                            if (x >= halfwidth) {
                                quadrante += 'd'; //Direito
                            } else {
                                quadrante += 'e'; //Esquerdo
                            }

                            dataQuad[quadrante]++;

                            pixelsQuad[quadrante] = Math.round(halfheight / dataQuad[quadrante]);

                            indexQuad[quadrante].push(index);

                            sumValues+= parseFloat(chart.data.datasets[i].data[index]);
                        });
                    });

                    const pxXLine = 60;
                    const marginX = (width - height) / 2;
                    var ajustXLine;
                    var ajustYLine;
                    // var sumValues = 0;
                    var countValues = 0;
                    for (let iq in indexQuad) {
                        for (let quad in indexQuad[iq]) {
                            let xPos = indexTooltiposition[indexQuad[iq][quad]].x;
                            let yPos = indexTooltiposition[indexQuad[iq][quad]].y;

                            if(iq === 'sd' || iq === 'id'){
                                ajustXLine = height + marginX + 20;
                            }else{
                                ajustXLine = marginX + 20;
                            }

                            const xLine = ajustXLine;

                            if (iq === 'sd'){
                                ajustYLine = 5 + parseInt(pixelsQuad.sd) * parseInt(indexQuad.sd.indexOf(indexQuad[iq][quad]));
                            }else if (iq === 'id'){
                                ajustYLine = halfheight + 30 + (parseInt(pixelsQuad.id) * parseInt(indexQuad.id.indexOf(indexQuad[iq][quad])));
                            }else if (iq === 'ie'){
                                ajustYLine = height - (parseInt(pixelsQuad.ie) * parseInt(indexQuad.ie.indexOf(indexQuad[iq][quad])));
                            }else if (iq === 'se'){
                                ajustYLine = halfheight - (parseInt(pixelsQuad.se) * parseInt(indexQuad.se.indexOf(indexQuad[iq][quad])));
                            }

                            const yLine = ajustYLine;

                            const extraLine = xPos >= halfwidth ? 15 : -15;

                            ctx.beginPath();
                            ctx.moveTo(xPos, yPos);
                            ctx.lineTo(xLine, yLine);
                            ctx.lineTo(xLine + extraLine, yLine);
                            ctx.strokeStyle = chart.data.datasets[0].backgroundColor[indexQuad[iq][quad]];
                            ctx.stroke();

                            const textXPosition = xPos >= halfwidth ? 'left' : 'right';
                            const plusFivePx = xPos >= halfwidth ? 3 : -3;
                            ctx.textAlign = textXPosition;
                            ctx.textBaseline = 'middle';
                            // ctx.fillStyle = 'rgb(150,150,150)';
                            let textLabel = chart.data.labels[indexQuad[iq][quad]];
                            if (textLabel.length >= 19) {
                                textLabel = textLabel.substring(0, 19) + '..';
                            }
                            ctx.font = "10px sans-serif";
                            textLabel+= ' ' + doughnutLabelsLine.metricas.prefix + ' ' + parseFloat(chart.data.datasets[0].data[indexQuad[iq][quad]]).toLocaleString('pt-BR');
                            textLabel+= ' (' + ((parseFloat(chart.data.datasets[0].data[indexQuad[iq][quad]]) / sumValues) * 100).toLocaleString() + '%)'
                            ctx.fillText(textLabel, xLine + extraLine + plusFivePx, yLine);
                            ctx.restore();
                            countValues++;
                        }
                    }

                    ctx.save();
                    ctx.font = "bold 18px sans-serif";
                    ctx.textAlign = 'center';
                    ctx.fillStyle = 'black';
                    ctx.fillText(countValues + ' ' + doughnutLabelsLine.metricas.group,halfwidth + 20,halfheight - 10);
                    ctx.fillText(doughnutLabelsLine.metricas.prefix + ' ' + parseFloat(sumValues).toLocaleString('pt-BR'),halfwidth + 20,halfheight + 10);
                    ctx.restore();
                },
            };

            if (this.line_labels){
                this.plugins = [doughnutLabelsLine];
            }

            if(this.custom_data){
                this.chartData = this.customData(this.custom_data);
            }else{
                this.chartData = this.chart_data;
            }

            if(this.height){this.height_ = this.height;}
            if(this.width){this.width_ = this.width;}

            if(this.auto_size){
                let datas = this.chartData.datasets[0].data.length;
                if(datas > 70){this.height_ = 700;}
                else if (datas > 60){this.height_ = 650;}
                else if (datas > 50){this.height_ = 600;}
                else if (datas > 40){this.height_ = 550;}
            }

            if (this.auto_colors){
                const colors = [];

                for (let i = 0;i < this.chartData.datasets[0].data.length;i++){
                    const r = Math.floor(Math.random() * 255);
                    const g = Math.floor(Math.random() * 255);
                    const b = Math.floor(Math.random() * 255);
                    colors.push('rgba('+r+','+g+','+b+',0.8)');
                }

                this.chartData.datasets[0].backgroundColor = colors;
            }
        },
        mounted () {

        }
    }
</script>

<style scoped>

</style>
