<template>
    <span>
        <img v-if="src_img" class="img-thumbnail" :src="src_img" alt="" :style="style_img"/>

        <div v-else class="border rounded p-3 text-center" :style="style_img" v-html="if_no_img"></div>
    </span>
</template>

<script>
    import {bus} from '../../app';
    export default {
        props:[
            'style_img',
            'request',
            'if_no_img',
            'registro',
            'src',
        ],
        data: function () {
            return {
                id_imagem:'',
                src_img:'',
                url: this.request,
                obj: this.registro,
            }
        },methods: {
            population() {
                if(this.request){
                    var objeto = this.obj;
                    var url = this.url;
                    let i = 0;
                    for (i = 0; i < url.chave_registros.length; i++) {
                        url.data[url.chave_registros[i]] = objeto[url.chave_registros[i]];
                    }

                    bus.$emit('gerencia-request',url);
                }else{
                    this.src_img = this.src;
                    if(this.src_img !== undefined){
                        this.id_img = this.src_img;
                        //this.exibirImg(this.id_img);
                    }
                }
            },
            exibirImg(id){
                console.log(id);
                var element = document.getElementById(id);
                element.children[0].classList.remove('d-none');
                element.children[1].classList.add('d-none');
            },
            exibirTumb(id){
                document.getElementById(id).children[0].classList.remove('d-none');
                document.getElementById(id).children[1].classList.add('d-none');
            }
        },
        created(){

        },
        mounted() {
            this.population();
        }
    }
</script>

<style scoped>

</style>